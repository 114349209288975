import moment from 'moment'
import { getDefaultValues } from '../utils/get-default-values'
import { roundToPlaces } from '../services/utilities'

interface BDNFile {
    filename: string,
    dropbox_url: string,
}

export interface TankStockObject {
    bdn_number: string,

    fuel_grade: string,
    iso_fuel_grade: string,
    bdn_figures: string,
    stable_until: string,
     
    quantity_ordered: number,
    quantity_received: number,
    sulphur: number, 
    sulphur_lab_analysis: number, 
    viscosity: number, 
    co2_eq_wtt: number, 
    cf_co2: number, 
    cf_ch4: number, 
    cf_n2o: number, 
    c_slip: number,
    e_value: number,

    parent_1: string,
    parent_2: string,
    sustainability_id: string,
    bdn_file: BDNFile,
    in_use: boolean,
 
   
    bunker_date: string,
    bunker_port: string,

    amount_before_bunkering: number,
    quantity_added_in_tank: number,
    amount: number,
    co2_eq_well_to_tank: number,
    fuel_grade_added_in_tank: string,

    // # Fuel specifications
    sulphur_before_bunkering: number,
    sulphur_after_bunkering: number,
    density: number, 
    lcv: number,
    lcv_before_bunkering: number,
    density_before_bunkering: number,
    imo_lower_heating_value: number,

    fuel_consumer_unit_class: string, // # Fuel Consumer Unit Class.
   
    amount_after_transfer: number,

}

const calculatePercentage = (incomingAmount, incomingPercentage, currentAmount, currentPercentage) => {
    if(!(incomingAmount && incomingPercentage && currentAmount && currentPercentage)) return null
    const totalFuel = currentAmount.amount + incomingAmount.amount
    const ratio1 = currentPercentage * currentAmount;
    const ratio2 = incomingPercentage * incomingAmount;
    return (ratio1 + ratio2) / totalFuel;
}


// TODO (79695) update tank stock with above fields, including bunker tab: 
export class TankStock {
    suffix: number
    bdn_no_suffix: string
    bdn_number: string
    fuel_grade: string
    iso_fuel_grade: string
    bdn_figures: string
    stable_until: string
    is_bio_fuel: boolean

    quantity_ordered: number
    quantity_received: number
    sulphur: number
    sulphur_lab_analysis: number
    viscosity: number

    co2_eq_wtt: number
    cf_co2: number
    cf_ch4: number
    cf_n2o: number
    c_slip: number
    e_value: number

    parent_1: string
    parent_2: string
    sustainability_id: string
    bdn_file: BDNFile
    in_use: boolean
 
   
    bunker_date: string
    bunker_port: string

    amount_before_bunkering: number
    quantity_added_in_tank: number
    amount: number
    co2_eq_well_to_tank: number
    fuel_grade_added_in_tank: string

    // # Fuel specifications
    sulphur_before_bunkering: number
    sulphur_after_bunkering: number
    density: number 
    lcv: number
    lcv_before_bunkering: number
    density_before_bunkering: number
    imo_lower_heating_value: number

    fuel_consumer_unit_class: string // # Fuel Consumer Unit Class.;
    amount_after_transfer: number

    

    constructor (tankStockObject?: TankStockObject) {
        if (tankStockObject)  {
            this.import(tankStockObject)
            return
        }

        this.bdn_number = '';
        this.bdn_no_suffix = '';
        this.fuel_grade = '';
        this.iso_fuel_grade = '';
        this.bdn_figures = '';
        this.stable_until = '';
        this.density;
        this.lcv;
        this.quantity_ordered;
        this.quantity_received;
        this.sulphur;
        this.sulphur_lab_analysis;
        this.viscosity;
        this.co2_eq_wtt;
        this.cf_co2;
        this.cf_ch4;
        this.cf_n2o;
        this.c_slip;
        this.e_value;
        this.is_bio_fuel = false
    }
  
   getBdnNumber(fuelGrade, date = moment(new Date())){
        return `BDN${date.format('YYYYMMDDHHmm')}${fuelGrade.toUpperCase()}`
    }

  setFuelGrade(fuelGrade, date = moment(new Date())) {
        this.fuel_grade = fuelGrade
        this.bdn_number = `BDN${date.format('YYYYMMDDHHmm')}${fuelGrade.toUpperCase()}`
        this.bdn_no_suffix = this.bdn_number

        this.setDefaultValues(fuelGrade)
    }

  updateBdnNumber() {
        if(!(this.bdn_no_suffix && this.bdn_no_suffix)) return
        if (this.suffix === null) {
            this.bdn_number = `${this.bdn_no_suffix}`;
            return;
        }

        this.bdn_number = `${this.bdn_no_suffix}_${this.suffix}`;
    }

    setDefaultValues (fuelGrade: string) {
        const {
            lcv,
            co2_eq_wtt,
            cf_co2,
            cf_ch4,
            cf_n2o,
            c_slip,
            e_value,
            is_bio_fuel,
        } = getDefaultValues(fuelGrade)


        this.lcv = lcv
        this.co2_eq_wtt = co2_eq_wtt
        this.cf_co2 = cf_co2
        this.cf_ch4 = cf_ch4
        this.cf_n2o = cf_n2o
        this.c_slip = c_slip
        this.e_value = e_value
        this.is_bio_fuel = is_bio_fuel
    }

    displayFuelGrade () {
        return this.fuel_grade?.toUpperCase();
    }

    // internal check if tank stock is valid
    isValid () {
        if (!this.bdn_number)  return false

        return true
    }

    public static createChildBdn(from: TankStockObject, to: TankStockObject, quantity: number, isBunkering: boolean) {
    const childBdn = new TankStock(to);
    childBdn.setFuelGrade('blend');
    if (!childBdn.amount_before_bunkering) childBdn.amount_before_bunkering = 0;
    
    const sulphur = roundToPlaces(calculatePercentage(quantity, from.sulphur_before_bunkering, to.amount_before_bunkering, to.sulphur_before_bunkering), 2)
    const lcv = roundToPlaces(calculatePercentage(quantity, from.lcv_before_bunkering, to.amount_before_bunkering, to.lcv_before_bunkering), 2)
    const density = roundToPlaces(calculatePercentage(quantity, from.density_before_bunkering, to.amount_before_bunkering, to.density), 2)

    const suffix =  isBunkering? '_after_bunkering'  : '_after_transfer'

    childBdn[`sulphur${suffix}`] = sulphur
    childBdn[`lcv${suffix}`] = lcv
    childBdn[`density${suffix}`] = density
    childBdn.amount_after_transfer = quantity + to.amount_before_bunkering
    !childBdn.in_use? childBdn.amount = childBdn.amount_after_transfer : null

    childBdn.parent_1 = from.bdn_number
    childBdn.parent_2 = to.bdn_number
    return childBdn;  
  }

    import (tankStockObject: TankStockObject) {
        const {
            bdn_number,
            fuel_grade,
            iso_fuel_grade,
            bdn_figures,
            stable_until,
            quantity_ordered,
            quantity_received,
            sulphur,
            sulphur_lab_analysis,
            viscosity,
            co2_eq_wtt,
            cf_co2,
            cf_ch4,
            cf_n2o,
            c_slip,
            e_value,
            parent_1,
            parent_2,
            sustainability_id,
            bdn_file,
            in_use,
            bunker_date,
            bunker_port,
            amount_before_bunkering,
            quantity_added_in_tank,
            amount,
            co2_eq_well_to_tank,
            fuel_grade_added_in_tank,
            sulphur_before_bunkering,
            sulphur_after_bunkering,
            density,
            lcv,
            lcv_before_bunkering,
            density_before_bunkering,
            imo_lower_heating_value,
            fuel_consumer_unit_class,
            amount_after_transfer,
        } = tankStockObject;

        const {
            is_bio_fuel,
        } = getDefaultValues(fuel_grade)

        this.is_bio_fuel = is_bio_fuel;

        this.bdn_number = bdn_number;
        this.fuel_grade = fuel_grade;
        this.iso_fuel_grade = iso_fuel_grade;
        this.bdn_figures = bdn_figures;
        this.stable_until = stable_until;
        this.density = density;
        this.lcv = lcv;
        this.quantity_ordered = quantity_ordered;
        this.quantity_received = quantity_received;
        this.sulphur = sulphur;
        this.sulphur_lab_analysis = sulphur_lab_analysis;
        this.viscosity = viscosity;
        this.co2_eq_wtt = co2_eq_wtt;
        this.cf_co2 = cf_co2;
        this.cf_ch4 = cf_ch4;
        this.cf_n2o = cf_n2o;
        this.c_slip = c_slip;
        this.e_value = e_value;
        this.parent_1 = parent_1;
        this.parent_2 = parent_2;
        this.sustainability_id = sustainability_id;
        this.bdn_file = bdn_file;
        this.in_use = in_use;
        this.bunker_date = bunker_date;
        this.bunker_port = bunker_port;
        this.amount_before_bunkering = amount_before_bunkering;
        this.quantity_added_in_tank = quantity_added_in_tank;
        this.amount = amount;
        this.co2_eq_well_to_tank = co2_eq_well_to_tank;
        this.fuel_grade_added_in_tank = fuel_grade_added_in_tank;
        this.sulphur_before_bunkering = sulphur_before_bunkering;
        this.sulphur_after_bunkering = sulphur_after_bunkering;
        this.density = density ;
        this.lcv = lcv;
        this.lcv_before_bunkering = lcv_before_bunkering;
        this.density_before_bunkering = density_before_bunkering;
        this.imo_lower_heating_value = imo_lower_heating_value;
        this.fuel_consumer_unit_class = fuel_consumer_unit_class; 
        this.amount_after_transfer = amount_after_transfer
    }

    export (): TankStockObject {
        return {
            bdn_number: this.bdn_number,
            fuel_grade: this.fuel_grade,
            iso_fuel_grade: this.iso_fuel_grade,
            bdn_figures: this.bdn_figures,
            stable_until: this.stable_until,
            quantity_ordered: this.quantity_ordered, 
            quantity_received: this.quantity_received, 
            sulphur: this.sulphur, 
            sulphur_lab_analysis: this.sulphur_lab_analysis, 
            viscosity: this.viscosity, 
            co2_eq_wtt: this.co2_eq_wtt, 
            cf_co2: this.cf_co2, 
            cf_ch4: this.cf_ch4, 
            cf_n2o: this.cf_n2o, 
            c_slip: this.c_slip,
            e_value: this.e_value,
            parent_1: this.parent_1,
            parent_2: this.parent_2,
            sustainability_id: this.sustainability_id,
            bdn_file: this.bdn_file,
            in_use: this.in_use,
            bunker_date: this.bunker_date,
            bunker_port: this.bunker_port,
            amount_before_bunkering: this.amount_before_bunkering,
            quantity_added_in_tank: this.quantity_added_in_tank,
            amount: this.amount,
            co2_eq_well_to_tank: this.co2_eq_well_to_tank,
            fuel_grade_added_in_tank: this.fuel_grade_added_in_tank,
            // # Fuel specifications
            sulphur_before_bunkering: this.sulphur_before_bunkering,
            sulphur_after_bunkering: this.sulphur_after_bunkering,
            density: this.density, 
            lcv: this.lcv,
            lcv_before_bunkering: this.lcv_before_bunkering,
            density_before_bunkering: this.density_before_bunkering,
            imo_lower_heating_value: this.imo_lower_heating_value,
            fuel_consumer_unit_class: this.fuel_consumer_unit_class, 
            amount_after_transfer: this.amount_after_transfer,
        }
    }
}