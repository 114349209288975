import Highcharts from 'highcharts';
import moment from 'moment';
import angular from 'angular';
import { roundToPlaces, routerApp, setTankGroupColors, range, Color, removeEmptyValues, themePalette, enableOnPremise } from '../app.module';
import { getCacheBustedTemplate } from '../app.route';
import $ from 'jquery';
import { modalService } from '../services/modal.service';
import { hasSameProps, showByDefault } from '../services/utilities';
import { fuelGrades, addUsedFuelstoListOfFuels } from '../services/fuelTypes';
import { IForm } from '../models/vessel';
import { 
    mainFlowMeterHasFuelAndGas, 
    boilerFlowMeterHasFuelAndGas, 
    autoPopulateMainFlowMeterTags, 
    autoPopulateBoilerFlowMeterTags, 
    requireByDefault,
    zeroIfUndefined,
} from '../services/utilities';
import { CustomLatitudePaths, CustomLongitudePaths } from '../analytics/services/sensor.service';
import { TankStock } from '../models/TankStock';
import _ from 'lodash';
import { IBdnTankConsumption } from '../models/TankConsumption';
import { parseBdnObjects } from '../utils/parse-bdn-objects';

var sumValuesFuel = function(newValues) {
    var sum = 0;
    for (var k = 0; k < newValues.length; k++) {
        if (newValues[k] != undefined) {
            newValues[k] = newValues[k] || 0
            sum += parseFloat(newValues[k]);
        }
    }
    return sum;
}

var sumValues = function(newValues) {
    var sum = 0;
    for (var k = 0; k < newValues.length; k++) {
        if (newValues[k] != undefined) {
            sum += parseFloat(newValues[k]);
        }
    }
    return sum;
}

var bfFromTrueWindSpeed = function(trueWindSpeed) {
    if (trueWindSpeed == undefined) return null;

    var thresholds = [0.3, 1.5, 3.3, 5.5, 7.9, 10.7, 13.8, 17.1, 20.7, 24.4, 28.4, 32.6];
    for (var i = 0; i < thresholds.length; i++) {
        if (trueWindSpeed < thresholds[i]) {
            break;
        }
    }
    return i;
};

const filterListByVesselForm = (form: IForm, path: string, defaultValue: any[]) => {
    /**
     * returns list of key value pairs that SHOULD match the default value structure [{key, value}, ...]
     * @param form passed in form object from $scope.vessel_specfications.form
     * @param path specify reporting field path to dropdown selection
     * @param defaultValue reporting field default list
     */

    const createOptionFromConfig = ({ code_data_type, code_key, code, display_key, display_message}) => {
        const option = {};
        let codeValue = code;
        if (code_data_type === 'int') {
            codeValue = Number(code);
        }
        option[code_key] = codeValue;
        option[display_key] = display_message;
        return option;
    }

    if (!form) return defaultValue;

    let formPart = form['fields'][path];
    if (!formPart) return defaultValue;
    // check if there are options
    if (formPart.options && formPart.options.length > 0) {
        // need to remove '$$hasKey' since angular adds that automatically to all selection dropdown as a reference id when added to $scope
        const referenceOption = defaultValue[0];
        delete referenceOption['$$hashKey'];
        
        // sanitize the options coming from server to ensure they match the original data structure for the selection
        const filteredOptions = formPart.options
        .map(createOptionFromConfig)
        .filter(option => hasSameProps(referenceOption, option));

        // return default if nothing matches
        return filteredOptions.length > 0 ? filteredOptions : defaultValue;
    }
    // if there's no options return default;
    return defaultValue;
}

const operatingCodes = [
    { code: '2', name:'Normal Cruising' },
    { code: '3', name:'Variable Speed' },
    { code: '6', name:'Sailing in Ice (ice-classed)' },
    { code: '7', name:'Endanger Safe Navigation' }
];

type fuelPropertyLimits = { min: number, max: number};
type fuelPropertyMap = {
    [id: string]: fuelPropertyLimits;
};

// TODO: combined fuelTypeProperties with main_fuel_type_specs
const fuelTypeProperties: fuelPropertyMap = {
    'lcv_hfo': { min: 37000, max: 44000},
    'lcv_lfo': { min: 37000, max: 44000},
    'lcv_mgo': { min: 37000, max: 44000},
    'lcv_mdo': { min: 37000, max: 44000},
    'lcv_b10lfo': { min: 37000, max: 44000},
    'lcv_b10mgo': { min: 37000, max: 44000},
    'lcv_biolfo': { min: 37000, max: 44000},
    'lcv_biomgo': { min: 37000, max: 44000},
    'lcv_ulsfo2020': { min: 37000, max: 44000},
    'lcv_ulslfo2020': { min: 37000, max: 44000},
    'lcv_ulsmdo2020': { min: 37000, max: 44000},
    'lcv_ulsmgo2020': { min: 37000, max: 44000},
    'lcv_vlsfo2020': { min: 37000, max: 44000},
    'lcv_vlslfo2020': { min: 37000, max: 44000},
    'lcv_lpgp': { min: 37000, max: 56000},
    'lcv_lpgb': { min: 37000, max: 56000},
    'lcv_lng': { min: 37000, max: 56000},
    'lcv_methanol': { min: 20000, max: 44000},
    'lcv_ethanol': { min: 20000, max: 44000},
    'lcv_other': { min: 37000, max: 44000},

    'density_hfo': { min: 600, max: 1200},
    'density_lfo': { min: 600, max: 1200},
    'density_mgo': { min: 600, max: 1200},
    'density_mdo': { min: 600, max: 1200},
    'density_b10lfo': { min: 600, max: 1200},
    'density_b10mgo': { min: 600, max: 1200},
    'density_biolfo': { min: 600, max: 1200},
    'density_biomgo': { min: 600, max: 1200},
    'density_ulsfo2020': { min: 600, max: 1200},
    'density_ulslfo2020': { min: 600, max: 1200},
    'density_ulsmdo2020': { min: 600, max: 1200},
    'density_ulsmgo2020': { min: 600, max: 1200},
    'density_vlsfo2020': { min: 600, max: 1200},
    'density_vlslfo2020': { min: 600, max: 1200},
    'density_lpgp': { min: 350, max: 800},
    'density_lpgb': { min: 350, max: 800},
    'density_lng': { min: 350, max: 800},
    'density_methanol': { min: 600, max: 1200},
    'density_ethanol': { min: 600, max: 1200},
    'density_other': { min: 600, max: 1200},
};

const getStockTankObjects = (scope) => {
    const stockTankObjects = []
    Object.keys(scope.report.stock.tanks).map((tankId) => {
        if (scope.report.stock.tanks[tankId] instanceof TankStock) {
            stockTankObjects.push(scope.report.stock.tanks[tankId])
        } else {
            stockTankObjects.push(new TankStock(scope.report.stock.tanks[tankId]))
        }
    })
    return stockTankObjects
}

routerApp.directive('operationalTab', ['TimezoneService', function(TimezoneService) {
    return {
        bindToController: true,
        templateUrl: getCacheBustedTemplate('pages/vessel-reports/tabs/operational.html'),
        link: function(scope, element, attributes, control) {
            // do nothing, or maybe move all the operational tab logic here from
            // sea report controller

            let vesselSpecs = scope.vessel_specifications;
            scope.operatingCodes = filterListByVesselForm(vesselSpecs.form, 'report.operational.operating_code', operatingCodes);
            if (vesselSpecs.information.sts_voyage_allowed) {
                scope.operatingCodes.push({code: '8', name: 'STS'})
            }

            scope.otherSpeedSteaming = [
                { code: '1', name:'Not Applicable'},
                { code: '2', name:'Reduced Visibility'},
                { code: '3', name:'Heavy Vessel Congestion'},
                { code: '4', name:'Adjust for Tide Window'},
                { code: '5', name:'Testing Gear Prior to Arrival'},
                { code: '6', name:'Whale Zones (regulatory)'},
                { code: '7', name:'Other (add comments)'}
            ];

            scope.$watchGroup(['report.operational.report_to', 'report.operational.timezone', 'report.operational.eta', 'report.operational.eta_timezone'], (newValues) => {
                const reportTo = TimezoneService.dtWithTz(newValues[0], newValues[1]);
                const eta = TimezoneService.dtWithTz(newValues[2], newValues[3]);
                if (scope?.reportForm?.operational?.eta) {
                    scope.reportForm.operational.eta.$setValidity('eta_date', (eta >= reportTo));
                }
            });

            scope.$watch('report.operational.other_speed_steaming', function(newValue, oldValue, scope) {
                if (newValue != undefined) {
                    scope.report.operational.other_speed_steaming = newValue;
                }
                else {
                    scope.report.operational.other_speed_steaming = scope.otherSpeedSteaming[0].code
                }
            });

            scope.containerTypes = [
                {
                    name: "20’ ST TEU 8’6” plus 20’ High Cube (HC) (Empty)",
                    value: 2
                },
                {
                    name: "20’ ST TEU 8’6” plus 20’ High Cube (HC)",
                    value: 12
                },
                {
                    name: "40’ ST FFE 8’ 6” (forty- foot equivalent unit) (Empty)",
                    value: 4
                },
                {
                    name: "40’ ST FFE 8’ 6” (forty- foot equivalent unit)",
                    value: 24
                },
                {
                    name: "40’ High Cube (FFE 9’6”) plus 45’ and 48’ (Empty)",
                    value: 4.5
                },
                {
                    name: "40’ High Cube (FFE 9’6”) plus 45’ and 48’",
                    value: 27
                }
            ];

            scope.$watchGroup(['report.operational.default_unit_weight', 'report.operational.number_of_units'], function(newValues, oldValues, scope) {
                if (newValues[0] && newValues[1]) {
                    scope.report.operational.weight = newValues[0] * newValues[1];
                }
            });

            scope.$watch('report.operational.operating_code', function(newValue, oldValue, scope) {
                if (newValue && newValue == "8") scope.report.operational.in_sts_voyage = true;
            });

        }
    }
}]);

export enum OperationType {
    LOADING = "Loading",
    DISCHARGING = "Discharging",
    LOADING_AND_DISCHARGING = "Loading and Discharging",
    IDLING = "Idling",
    DRY_DOCK_REPAIR = "Dry Dock or Repair",
}

export enum CruiseOperationType {
    EMBARKATION = "Embarkation",
    DISEMBARKATION = "Disembarkation",
    EMBARKATION_DISEMBARKATION = "Embarkation and Disembarkation",
    DRY_DOCK_REPAIR = "Dry Dock or Repair",
}

routerApp.directive('portOperationalTab', ['$rootScope','$timeout', function($rootScope, $timeout) {
    return {
        templateUrl: getCacheBustedTemplate('pages/vessel-reports/tabs/port-operational.html'),
        link: function(scope, element, attributes, control) {
            const vesselSpecs = scope.vessel_specifications;
            scope.OperationType = vesselSpecs?.information.vessel_type === 'cruise_passenger' ? CruiseOperationType : OperationType;
            scope.locationTypes = vesselSpecs?.information.vessel_type === 'cruise_passenger' ? ["In Port/Berth", "At Anchor"] : ["In Port/Berth", "SBM/SPM"];
            if (vesselSpecs.information.sts_voyage_allowed) {
                scope.locationTypes.push("Ship to Ship Transfer")
            }
            scope.tugsAllFast = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14 ,15 ,16 ,17, 18, 19, 20, 21, 22, 23, 24, 25];
            scope.tugsLastLine = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14 ,15 ,16 ,17, 18, 19, 20, 21, 22, 23, 24, 25];
            scope.isDryDock = function() {
                return scope.report.cargo.operation_type == scope.OperationType.DRY_DOCK_REPAIR;
            };
            scope.isReloadAllowed = function() {
                return !!scope.sensorData?.navboxTags?.some(tag => tag.tag_name === "lat_nmea" || tag.tag_name === "Latitude");
            };

            scope.reloadPosition = function() {
                $rootScope.$broadcast('updateSelectedSensorTag', 'report.position');
            };

            scope.$watch('report.cargo.operation_type', function(newValue,oldValue,scope) {
                if (vesselSpecs.information.sts_voyage_allowed) {
                    var locations = [];
                    if (newValue == 'Idling' || newValue == 'Dry Dock or Repair') {
                        for (var i=0;i<scope.locationTypes.length;i++) {
                            if (scope.locationTypes[i] != "Ship to Ship Transfer") {
                                locations.push(scope.locationTypes[i])
                            }
                        }
                    }
                    else locations = scope.locationTypes
                    scope.portLocations = locations;
                }
                else scope.portLocations = scope.locationTypes;
            })
            scope.$watch('report.cargo.location', function(newValue, oldValue, scope) {
                if (newValue && newValue == 'Ship to Ship Transfer') scope.report.operational.in_sts_voyage = true;
            });

        }
    }
}]);

routerApp.directive('anchorOperationalTab', function() {
    return {
        templateUrl: getCacheBustedTemplate('pages/vessel-reports/tabs/anchor-operational.html'),
        link: function(scope, element, attributes, control) {
            // do nothing, why am i putting those here?
            scope.otherSpeedSteaming = [
                { code: '1', name:'Not Applicable'},
                { code: '2', name:'Reduced Visibility'},
                { code: '3', name:'Heavy Vessel Congestion'},
                { code: '4', name:'Adjust for Tide Window'},
                { code: '5', name:'Testing Gear Prior to Arrival'},
                { code: '6', name:'Whale Zones (regulatory)'},
                { code: '7', name:'Other (add comments)'}
            ];
            
            scope.$watch('report.operational.other_speed_steaming', function(newValue, oldValue, scope) {
                if (newValue != undefined) {
                    scope.report.operational.other_speed_steaming = newValue;
                }
                else {
                    scope.report.operational.other_speed_steaming = scope.otherSpeedSteaming[0].code
                }
            });
        }
    }
});

routerApp.directive('manOperationalTab', function() {
    return {
        templateUrl: getCacheBustedTemplate('pages/vessel-reports/tabs/man-operational.html'),
        link: function(scope, element, attributes, control) {
            scope.tugsAllFast = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14 ,15 ,16 ,17, 18, 19, 20, 21, 22, 23, 24, 25];
            scope.tugsLastLine = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14 ,15 ,16 ,17, 18, 19, 20, 21, 22, 23, 24, 25];

            scope.otherSpeedSteaming = [
                { code: '1', name:'Not Applicable'},
                { code: '2', name:'Reduced Visibility'},
                { code: '3', name:'Heavy Vessel Congestion'},
                { code: '4', name:'Adjust for Tide Window'},
                { code: '5', name:'Testing Gear Prior to Arrival'},
                { code: '6', name:'Whale Zones (regulatory)'},
                { code: '7', name:'Other (add comments)'}
            ];

            scope.$watch('report.operational.other_speed_steaming', function(newValue, oldValue, scope) {
                if (newValue != undefined) {
                    scope.report.operational.other_speed_steaming = newValue;
                }
                else {
                    scope.report.operational.other_speed_steaming = scope.otherSpeedSteaming[0].code
                }
            });

            scope.containerTypes = [
                {
                    name: "20’ ST TEU 8’6” plus 20’ High Cube (HC) (Empty)",
                    value: 2
                },
                {
                    name: "20’ ST TEU 8’6” plus 20’ High Cube (HC)",
                    value: 12
                },
                {
                    name: "40’ ST FFE 8’ 6” (forty- foot equivalent unit) (Empty)",
                    value: 4
                },
                {
                    name: "40’ ST FFE 8’ 6” (forty- foot equivalent unit)",
                    value: 24
                },
                {
                    name: "40’ High Cube (FFE 9’6”) plus 45’ and 48’ (Empty)",
                    value: 4.5
                },
                {
                    name: "40’ High Cube (FFE 9’6”) plus 45’ and 48’",
                    value: 27
                }
            ];

            scope.$watchGroup(['report.operational.default_unit_weight', 'report.operational.number_of_units'], function(newValues, oldValues, scope) {
                if (newValues[0] && newValues[1]) {
                    scope.report.operational.weight = newValues[0] * newValues[1];
                }
            });
        }
    }
});

routerApp.directive('cargoDetailsTab', function() {
    return {
        templateUrl: getCacheBustedTemplate('pages/vessel-reports/tabs/cargo-details.html'),
        link: function(scope, element, attributes, control) {
            let vesselSpecs = scope.vessel_specifications;

            scope.nCargoes = vesselSpecs.form.fields['report.cargo.grade_p_s']?.visble ? [1, 2, 3, 4, 5, 6, 7, 8] : [1, 2, 3, 4, 5, 6, 7];
            if (scope.isRioTintoVessel()) {
                scope.nCargoes = [1, 2];
            }
            scope.nGrades = [0, 1, 2, 3];

            if (scope.report != undefined && scope.report.cargo != undefined && !scope.report.cargo.n_cargoes) {
                var n_cargoes = vesselSpecs.form.fields['report.cargo.grade_p_s']?.visible ? 8 : 7;
                if (scope.isRioTintoVessel()) {
                    n_cargoes = 2;
                }
                scope.report.cargo.n_cargoes = n_cargoes;
            }

            scope.$watch('report.cargo.n_cargoes', function(newValue) {
                if (newValue != undefined) {
                    var newSet = [];
                    for (let i = 0; i < newValue; i++) {
                        newSet.push(i);
                    }
                    scope.nGrades = newSet;
                }
            });

            scope.nHoses = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
            scope.protestsReceived = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14 ,15 ,16 ,17, 18, 19, 20, 21, 22, 23, 24, 25];
            scope.protestsIssued = scope.protestsReceived;
            scope.storedTank = ["Stored Tank 1", "Stored Tank 2"];

            scope.total_bl_cargo_quantity = 0;
            scope.$watch('report.cargo.bl_quantity', function(newValue: number[], oldValue, scope) {
                let total = 0;
                if (newValue != undefined) {
                    for (var i = 0; i < newValue.length; i++) {
                        var blQuantityValue = newValue[i];
                        if (blQuantityValue != undefined) {
                            total += blQuantityValue;
                        }
                    }
                }
                scope.total_bl_cargo_quantity = total;
            }, true);

            scope.getGradeColumnName = function(grade) {
                if (vesselSpecs.form.fields['report.cargo.grade_p_s']?.visible) {
                    // 1P, 1S ... 4P, 4S
                    return grade % 2 == 0 ? Math.ceil((grade + 1) / 2) + 'P' : Math.ceil(grade / 2) + 'S';
                } else {
                    return 'Grade ' + (grade + 1);
                }
            }

        }
    }
});

routerApp.directive('anchorPositionTab', function() {
    return {
        templateUrl: getCacheBustedTemplate('pages/vessel-reports/tabs/position.html'),
        link: function(scope, element, attributes, control) {
            // do nothing
        }
    }

});


routerApp.directive('positionObservation', ['$rootScope','TimezoneService', 'VesselSpecificationService', function($rootScope, TimezoneService, VesselSpecificationService) {
    return {
        templateUrl: getCacheBustedTemplate('pages/vessel-reports/tabs/position-observation.html'),
        scope: {
            r: '=',
            obs: '=',
            reportForm: '=',
            sensorData: '=',
            formName: '@',
            observationIndex: '=',
            form: '=',
            onInputFocus: '=',
            onInputBlur: '=',
            onInputChange: '=',
        },
        link: function(scope, element, attributes, control) {

            scope.isEBVessel = function() {
                return $rootScope.selectedVessel.company == 'Eagle Bulk Shipping, Inc';
            };

            var isOfReportType = function(report, type) {
                if (report != undefined) {
                    return report._cls == type;
                } else {
                    return false;
                }
            }
            scope.isSeaReport = function(report) {
                return isOfReportType(report, 'Report.SeaReport');
            }
            scope.isPortReport = function(report) {
                return isOfReportType(report, 'Report.PortReport');
            }
            scope.isAnchorReport = function(report) {
                return isOfReportType(report, 'Report.AnchorReport');
            }
            scope.isManReport = function(report) {
                return isOfReportType(report, 'Report.ManeuveringReport');
            }
            scope.isPortOrAnchorReport = function(report) {
                return scope.isPortReport(report) || scope.isAnchorReport(report);
            }

            scope.shouldRequireWeatherFields = function() {
                let veslinkEnbled = (scope.vesselSpecs?.veslink?.enabled && !scope.isEBVessel() && !isOfReportType(scope.r, 'Report.ManeuveringReport'));
                return (isOfReportType(scope.r, 'Report.SeaReport') || veslinkEnbled) && !scope.isAnchorReport(scope.r);
            };

            scope.shouldRequireRemainingDistance = function() {
                return (scope.form?.fields && scope.form.fields['report.position.remaining_distance']?.required)
                    || scope.isEBVessel() && (scope.isSeaReport(scope.r) || (scope.isManReport(scope.r) && scope.r.operational.maneuvering_type == 'departure'))
            };

            scope.isManualEntry = function() {
                let fields = scope.form?.fields;
                return (
                    fields &&
                    fields.hasOwnProperty('report.position.relative_wind_speed') && !fields['report.position.relative_wind_speed'].visible &&
                    fields.hasOwnProperty('report.position.relative_wind_direction') && !fields['report.position.relative_wind_direction'].visible &&
                    fields.hasOwnProperty('report.position.true_wind_speed') && !fields['report.position.true_wind_speed'].visible &&
                    fields.hasOwnProperty('report.position.true_wind_direction') && !fields['report.position.true_wind_direction'].visible
                )
            };

            scope.allTimezones = TimezoneService.getTimezones();
            scope.swellOptions = [
                { text: '0 - No Swell', value: 0 },
                { text: '1 - Very Low', value: 1 },
                { text: '2 - Low ', value: 2 },
                { text: '3 - Light', value: 3 },
                { text: '4 - Moderate', value: 4 },
                { text: '5 - Moderate Rough', value: 5 },
                { text: '6 - Rough', value: 6 },
                { text: '7 - High', value: 7 },
                { text: '8 - Very High', value: 8 },
                { text: '9 - Confused', value: 9 },
            ];

            scope.$watch('r', function(r) {
                if (scope.r != undefined && scope.r.position != undefined && scope.r.position.observations == undefined) {
                    scope.r.position.observations = [];
                }
            });

            scope.clearObservation = function() {
                var previousObservation = scope.obs;
                var previousTimezone = scope.obs.observation_timezone;
                scope.obs = {
                    observations: scope.r.position.observations || [],
                    observation_timezone: previousTimezone,
                    fin_stabilizer_operational: previousObservation.fin_stabilizer_operational
                }
            }

            scope.$watchGroup(['obs.observation', 'obs.propeller_rpm', 'obs.observed_distance', 'r.operational.report_from', 'obs.observation_timezone', 'r.operational.previous_timezone', 'obs.propeller_pitch_percent'], function(newValues) {
                if (!(newValues[0] && newValues[1] && newValues[2] && newValues[3])) return;
                var observationDate = TimezoneService.dtWithTz(newValues[0], newValues[4]);

                var propellerRPM = newValues[1];
                var observedDistance = newValues[2];
                var vesselSpecs = VesselSpecificationService.getSpecifications();
                var propellerPitch = vesselSpecs.propeller.propeller_pitch;
                var propellerPitchPercent = newValues[6];

                if (propellerRPM > 0 && observedDistance != undefined && propellerPitch != undefined) {
                    var previousObservation = scope.getPreviousObservation(scope.observationIndex);
                    var observationPeriodMinutes = (observationDate - previousObservation) / 1000 / 60;
                    var estimatedRPM = Math.round(((observedDistance * 1850) / (observationPeriodMinutes * propellerPitch * ((propellerPitchPercent || 100) / 100))));
                    if (!scope.reportForm[scope.formName]) { scope.reportForm[scope.formName] = {}; }
                    if (!scope.reportForm[scope.formName].warningMessages) { scope.reportForm[scope.formName].warningMessages = {}; }
                    if (propellerRPM < estimatedRPM * 0.8 || propellerRPM > estimatedRPM * 1.2) {
                        scope.reportForm[scope.formName].warningMessages['report.position.propeller_rpm'] = "Entered RPM value should be within +- 20% of estimated RPM value (" + estimatedRPM + ") based on observation distance and period";
                    } else {
                        scope.reportForm[scope.formName].warningMessages['report.position.propeller_rpm'] = undefined;
                    }
                } else {
                    scope.reportForm[scope.formName].warningMessages['report.position.propeller_rpm'] = undefined;
                }
            });

            scope.getPreviousObservation = function(currentObservationIndex) {
                if (currentObservationIndex == 1) {
                    if (!scope.obs.observation_timezone) {
                        scope.obs.observation_timezone = scope.obs.previous_timezone;
                    }
                    var reportFrom = TimezoneService.dtWithTz(scope.r.operational.report_from, scope.r.operational.previous_timezone);
                    return reportFrom;
                } else {
                    var observationDate = TimezoneService.dtWithTz(scope.obs.observation, scope.obs.observation_timezone || scope.r.operational.timezone);
                    var previousObservations = removeEmptyValues(scope.r.position.observations).map(function(o) {
                            return TimezoneService.dtWithTz(o.observation, o.observation_timezone || scope.r.operational.timezone);
                        })
                        .filter(function(date) { return date < observationDate; })
                        .sort(function(a, b) { return  b - a; });
                    return previousObservations[0];
                }
            };

            scope.updateObservationDate = function(newValue) {
                if (newValue != undefined) {
                    scope.obs.observation_date = moment.utc([newValue.year(), newValue.month(), newValue.date()]);
                } else {
                    scope.obs.observation_date = null;
                }
            }

            scope.$watchGroup(['r.operational.report_from', 'r.operational.report_to', 'obs.observation'], function(newValues, oldValues, scope) {
                if (newValues[0] != undefined && newValues[1] != undefined && newValues[2] != undefined) {
                    var fromMoment = moment(newValues[0], 'DD/MM/YYYY HH:mm');
                    var toMoment= moment(newValues[1], 'DD/MM/YYYY HH:mm');
                    var observationDate = moment(newValues[2], 'DD/MM/YYYY HH:mm')
                    if (scope.reportForm.position[scope.formName].observationDate == undefined) return;
                    scope.reportForm.position[scope.formName].observationDate.$setValidity('observation', (observationDate >= fromMoment && observationDate <= toMoment));
                }
            });

            /* calculate relative wind speed and direction */
            scope.$watchGroup(['obs.observed_speed', 'obs.true_wind_speed', 'obs.true_wind_direction'], function(newValues, oldValues, scope) {
                if (!scope.obs.true_wind_reporting) {
                    // makes sure this watch statement doesn't allow a loop so
                    // that it doesn't re-update the relative wind speed/values
                    // that are input by the user
                    return;
                }
                var observed_speed = parseFloat(newValues[0]);
                var true_wind_speed = parseFloat(newValues[1]);
                var true_wind_direction = parseFloat(newValues[2]);

                if (newValues[0] == undefined || observed_speed == undefined || observed_speed == 0) {
                    scope.obs.relative_wind_speed = roundToPlaces(true_wind_speed, 2);
                    scope.obs.relative_wind_direction = roundToPlaces(true_wind_direction, 2);
                    return;
                }

                var c1 = Math.pow(true_wind_speed, 2);
                var c2 = Math.pow(observed_speed * 0.51444, 2);
                var c3 = 2 * true_wind_speed * (observed_speed * 0.51444) * Math.cos((true_wind_direction * Math.PI) / 180);
                var relative_wind_speed = Math.sqrt(c1 + c2 + c3);
                var acosArg = (true_wind_speed * Math.cos(true_wind_direction * Math.PI / 180) + (observed_speed * 0.51444 )) / relative_wind_speed;
                acosArg = acosArg > 1 ? 1 : acosArg;
                acosArg = acosArg < -1 ? -1 : acosArg;
                var relative_wind_direction = Math.acos(acosArg) * 180 / Math.PI;
                if (true_wind_direction >= 180) {
                    relative_wind_direction = 360 - relative_wind_direction;
                }

                scope.obs.relative_wind_speed = roundToPlaces(relative_wind_speed, 2);
                scope.obs.relative_wind_direction = roundToPlaces(relative_wind_direction, 2);
            });

            /* calculate true wind speed and direction */
            scope.$watchGroup(['obs.observed_speed', 'obs.relative_wind_direction', 'obs.relative_wind_speed'], function(n, o, scope) {
                if (n[1] != undefined && n[2] != undefined) {
                    if (scope.obs.true_wind_reporting) {
                        // makes sure this watch statement doesn't allow a loop so
                        // that it doesn't re-update the true wind speed/values
                        // that are input by the user
                        return;
                    }
                    var observedSpeed = parseFloat(n[0]);
                    var relativeWindDirection = parseFloat(n[1]);
                    var relativeWindSpeed = parseFloat(n[2]);

                    if (n[0] == undefined || observedSpeed == undefined || observedSpeed == 0) {
                        scope.obs.true_wind_speed = roundToPlaces(relativeWindSpeed, 2);
                        scope.obs.true_wind_direction = roundToPlaces(relativeWindDirection, 2);
                        return;
                    }

                    var trueWindSpeed = Math.sqrt(Math.pow(relativeWindSpeed, 2) + Math.pow(observedSpeed * 0.51444, 2) - 2 * relativeWindSpeed * (observedSpeed * 0.51444) * Math.cos(relativeWindDirection * Math.PI / 180));
                    var acosArg = (relativeWindSpeed * Math.cos(relativeWindDirection * Math.PI / 180) - (observedSpeed * 0.51444))  / trueWindSpeed;
                    acosArg = acosArg > 1 ? 1 : acosArg;
                    acosArg = acosArg < -1 ? -1 : acosArg;
                    var trueWindDirection180 = Math.acos(acosArg) * 180 / Math.PI;
                    var trueWindDirection = relativeWindDirection > 180 ? 360 - trueWindDirection180 : trueWindDirection180;

                    scope.obs.true_wind_speed = roundToPlaces(trueWindSpeed, 2);
                    scope.obs.true_wind_direction = roundToPlaces(trueWindDirection, 2);
                }
            });

            /* calculate true wind effect */
            scope.$watchGroup(['obs.true_wind_direction', 'obs.cog'], function(newValues, oldValues, scope) {
                var true_wind_direction = parseFloat(newValues[0]);
                var cog = parseFloat(newValues[1]);

                var true_wind_effect = 0;
                if (cog != undefined && true_wind_direction != undefined) {
                    var true_wind_effect = true_wind_direction + cog;
                    if (true_wind_effect > 360) {
                        true_wind_effect = true_wind_effect - 360;
                    }
                }
                scope.obs.true_wind_effect = roundToPlaces(true_wind_effect, 2);
            });

            /* calculate relative current speed and direction */
            scope.$watchGroup(['obs.observed_speed', 'obs.current_speed', 'obs.true_current_effect', 'obs.cog'], function(newValues, oldValues, scope) {
                var observedSpeed = parseFloat(newValues[0]);
                var trueCurrentSpeed = parseFloat(newValues[1]);
                var trueCurrentDirection = parseFloat(newValues[2]);
                var cog = parseFloat(newValues[3]);

                if ((newValues[0] == undefined || observedSpeed == undefined || observedSpeed == 0 || cog == undefined) && (newValues[1] != undefined && newValues[2] != undefined)) {
                    scope.obs.relative_current_speed = roundToPlaces(trueCurrentSpeed, 2);
                    scope.obs.current_direction = roundToPlaces(trueCurrentDirection, 2);
                    return;
                }
                if (newValues[2] != undefined && newValues[3] != undefined) {
                    var apparentCurrentDirection = trueCurrentDirection - cog;
                    if (apparentCurrentDirection < 0) {
                        apparentCurrentDirection += 360;
                    }
                    var apparentCurrentSpeed = trueCurrentSpeed * Math.cos(apparentCurrentDirection * Math.PI / 180)

                    scope.obs.relative_current_speed = roundToPlaces(apparentCurrentSpeed, 2);
                    scope.obs.current_direction = roundToPlaces(apparentCurrentDirection, 2);
                }
            });

            /* true wind force AKA BF value */
            scope.$watch('obs.true_wind_speed', function(newValue, oldValue, scope) {
                var trueWindSpeed = parseFloat(<any>newValue);
                if (trueWindSpeed != undefined && !isNaN(trueWindSpeed)) {
                    scope.obs.true_wind_force = bfFromTrueWindSpeed(trueWindSpeed);
                }
            });

            /* calculate resistance due to wind */
            scope.$watchGroup(['obs.true_wind_speed', 'obs.true_wind_effect'], function(newValues, oldValues, scope) {
                // todo: get topsite area for the vessel, right now it's hardcoded for atlantic gemini
                var topsite_area = 450;
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.obs.resistance = 0.5 * 1.3 * Math.pow((newValues[0] * Math.cos((Math.PI / 180) * newValues[1])), 3) * topsite_area;
                }
            });

            /* calculate extra power due to wind */
            scope.$watchGroup(['obs.resistance', 'obs.true_wind_speed', 'obs.true_wind_effect'], function(newValues, oldValues, scope) {
                if (newValues[0] != undefined && newValues[1] != undefined && newValues[2] != undefined) {
                    var resistance = newValues[0];
                    var trueWindSpeed = newValues[1];
                    var trueWindEffect = newValues[2];
                    scope.obs.extra_power_due_to_wind = (resistance * trueWindSpeed * Math.cos(trueWindEffect * Math.PI / 180)) * 2.1 / 1000;
                } else {
                    scope.obs.extra_power_due_to_wind = 0;
                }
            });

            /* calculate speed loss due to wind */
            scope.$watchGroup(['obs.observed_speed', 'report.operational.displacement', 'obs.extra_power_due_to_wind'], function(newValues, oldValues, scope) {
                // todo: get this coefficient from the vessel specifications, right now it's hardcoded for atlantic gemini
                var admiraltyCoefficient = 750;
                if (newValues[0] != undefined && newValues[1] != undefined && newValues[2] != undefined) {
                    var observedSpeed = newValues[0];
                    var displacement = newValues[1];
                    var extraPowerDueToWind = newValues[2];
                    scope.obs.speed_loss_due_to_wind = (observedSpeed * 0.5114 - (Math.pow((((Math.pow(displacement, 2/3) * Math.pow(observedSpeed, 3)) / admiraltyCoefficient) - extraPowerDueToWind) / 10.8, 1/3))) / 0.5114
                } else {
                    scope.obs.speed_loss_due_to_wind = 0;
                }
            });

            /* log speed validation */
            if (!scope.reportForm[scope.formName]) scope.reportForm[scope.formName] = {};
            scope.reportForm[scope.formName].warningMessages = {};
            scope.$watchGroup([
                'obs.log_distance',
                'obs.log_speed',
                'obs.observed_distance',
                'obs.observed_speed',
                'report.operational.report_period',
                'obs.observation',
                'vesselCrossedDateLine',
                'obs.observation_timezone',
            ], function(newValues) {
                var logDistance = newValues[0];
                var logSpeed = newValues[1];
                var observedDistance = newValues[2];
                var observedSpeed = newValues[3];

                if (!newValues[5] || scope.isManReport(scope.r) || scope.isAnchorReport(scope.r)) return;
                var observationTime = TimezoneService.dtWithTz(newValues[5], newValues[7]);

                var previousObservation = scope.getPreviousObservation(scope.observationIndex);
                var observationPeriod = (observationTime - previousObservation) / 1000 / 60 / 60;
                var crossedDateline = newValues[6];

                scope.reportForm[scope.formName].warningMessages['report.position.log_distance'] = null;
                scope.reportForm[scope.formName].warningMessages['report.position.observed_distance'] = null;

                if (logDistance != undefined && observedDistance != undefined && observationPeriod != undefined && !crossedDateline) {
                    var invalid = Math.abs(logDistance - observedDistance) / observationPeriod >= 5;
                    scope.reportForm.position[scope.formName].logDistance?.$setValidity('difference', !invalid);
                    scope.reportForm.position[scope.formName].observedDistance?.$setValidity('difference', !invalid);
                    if (invalid) {
                        var message = 'Difference between log distance and observed distance too large';
                        scope.reportForm[scope.formName].warningMessages['report.position.log_distance'] = { message: message };
                        scope.reportForm[scope.formName].warningMessages['report.position.observed_distance'] = { message: message };
                    }
                }

                var threshold = 0.20;

                if (logDistance != undefined && logSpeed != undefined && observationPeriod != undefined && !crossedDateline) {
                    var expectedLogDistance = logSpeed * observationPeriod;
                    var change = expectedLogDistance * threshold;
                    var startRange = expectedLogDistance - change;
                    var endRange = expectedLogDistance + change;
                    var invalid = logDistance < startRange || logDistance > endRange;
                    scope.reportForm.position[scope.formName].logDistance.$setValidity('range', !invalid);
                    if (invalid) {
                        scope.reportForm[scope.formName].warningMessages['report.position.log_distance'] = {
                            message: 'Log distance should be between ' + startRange.toFixed(2) + ' and ' + endRange.toFixed(2) + ' NM'
                        };
                    }
                }

                if (observedDistance != undefined && observedSpeed != undefined && observationPeriod != undefined && !crossedDateline) {
                    var expectedObservedDistance = observedSpeed * observationPeriod;
                    var change = expectedObservedDistance * threshold;
                    var startRange = expectedObservedDistance - change;
                    var endRange = expectedObservedDistance + change;
                    var invalid = observedDistance < startRange || observedDistance > endRange;
                    scope.reportForm.position.observation.observedDistance.$setValidity('range', !invalid);
                    if (invalid) {
                        scope.reportForm[scope.formName].warningMessages['report.position.observed_distance'] = {
                            message: 'Observed distance should be between ' + startRange.toFixed(2) + ' and ' + endRange.toFixed(2) + ' NM'
                        }
                    };
                }

                var zeroObs = false;
                var zeroLog = false;

                if ((observedDistance == 0) && (observedSpeed > 0 || logSpeed > 0 || logDistance > 0)) {
                    zeroObs = true;
                    scope.reportForm[scope.formName].warningMessages['report.position.observed_distance'] = {
                        message: 'Observed distance should be greater than 0 NM'
                    }
                }

                if (logDistance == 0 && logSpeed > 0) {
                    zeroLog = true;
                    scope.reportForm[scope.formName].warningMessages['report.position.log_distance'] = {
                            message: 'Log distance should be greater than 0 NM'
                    }
                }

                scope.reportForm.position[scope.formName].observedDistance?.$setValidity('zero', !zeroObs);
                scope.reportForm.position[scope.formName].logDistance?.$setValidity('zero', !zeroLog);
            });

            // position & weather visual
            var centerX = 280;
            var centerY = 180;

            var height = 130;

            var updateDirection = function(elementName, angle, offset) {
                angle += 180;
                var shiftDown = centerY + height * Math.cos(angle * Math.PI / 180);
                var shiftLeft = centerX - height * Math.cos((90 - angle) * Math.PI / 180) / 1.5;
                var element = $('ng-form[name=' + scope.formName + '] .' + elementName);
                element.attr('transform', 'translate(' + shiftLeft + ', ' + shiftDown + ') rotate(' + angle + ')');
            }

            scope.windDirectionArrowVisible = false;
            scope.waveDirectionArrowVisible = false;
            scope.swellDirectionArrowVisible = false;

            scope.$watch('obs.relative_wind_direction', function(n) {
                if (n != undefined) {
                    updateDirection('windDirectionArrow', n, 20);
                    scope.windDirectionArrowVisible = true;
                } else {
                    scope.windDirectionArrowVisible = false;
                }
            });
            scope.$watch('obs.wave_direction', function(n) {
                if (n != undefined) {
                    updateDirection('waveDirectionArrow', n, 40);
                    scope.waveDirectionArrowVisible = true;
                } else {
                    scope.waveDirectionArrowVisible = false;
                }
            });
            scope.$watch('obs.current_direction', function(n) {
                if (n != undefined) {
                    updateDirection('swellDirectionArrow', n, 60);
                    scope.swellDirectionArrowVisible = true;
                } else {
                    scope.swellDirectionArrowVisible = false;
                }
            });

            scope.showByDefault = function(path, formPartType) {
                return showByDefault(scope.form,scope.r._cls,path,formPartType)
            };

            scope.requireByDefault = function(path: string, formPartType: string) {
                if (!scope.form) return true;
                var formPart = scope.form[formPartType || 'fields'][path];

                if (!formPart) {
                    // Require by default: return true if formPart doesn't exist (which means no rule exists for it).
                    return true;
                } else if (formPart.for_report_types && (formPart.for_report_types.length == 0 || formPart.for_report_types.indexOf(scope.r._cls) != -1)) {
                    // Check if rule applies for current report type. It applies if the report type is in `for_report_types`, or
                    // if the `for_report_types` list is empty (meaning the rule applies for all report types). If it does
                    // apply, then return requirement.
                    return !!formPart.required && !!formPart.visible;
                } else {
                    // Require by default: return true if no rule applies.
                    return true;
                }
            };

            scope.$watchGroup(['r.operational.time_in_eca', 'obs.observed_distance', 'obs.observed_distance_in_eca', 'r.operational.report_period'], function(newValues) {
                let [timeInEca, observedDistance, distanceInEca, reportPeriod] = newValues;

                if (timeInEca === 0 && distanceInEca > 0) {
                    scope.reportForm[scope.formName].warningMessages['report.position.observed_distance_in_eca'] = {
                        message: "Distance in ECA was reported, while Time in ECA was zero. Please enter time stamp for 'Enter ECA' in the Operational Tab"
                    };
                } else if (reportPeriod > timeInEca && timeInEca > 0 && observedDistance > 0 && distanceInEca == 0) {
                    scope.reportForm[scope.formName].warningMessages['report.position.observed_distance_in_eca'] = {
                        message: "Time In ECA was reported, while Distance In ECA was 0. Please ensure to report Exit ECA in Operational Tab or enter the Distance in ECA."
                    };
                } else if (roundToPlaces(timeInEca, 2) == roundToPlaces(reportPeriod, 2) && distanceInEca != observedDistance) {
                    scope.reportForm[scope.formName].warningMessages['report.position.observed_distance_in_eca'] = {
                        message: "Time in ECA was equal to the report period. Please ensure the distance in ECA is equal to observed distance or correct the time stamp for Exit ECA in the Operational Tab."
                    };
                } else if (distanceInEca > observedDistance && observedDistance > 0) {
                    scope.reportForm[scope.formName].warningMessages['report.position.observed_distance_in_eca'] = {
                        message: "Distance In ECA cannot be greater than Observed Distance."
                    };
                } else {
                    scope.reportForm[scope.formName].warningMessages['report.position.observed_distance_in_eca'] = null;
                }
            });

            scope.isReloadAllowed = function() {
                return !!scope.sensorData?.navboxTags?.some(tag => tag.tag_name === "lat_nmea" || tag.tag_name === "Latitude");
            };

            scope.reloadPosition = function() {
                $rootScope.$broadcast('updateSelectedSensorTag', 'report.position');
            };
        }
    }
}]);


routerApp.directive('positionTab', ['TimezoneService', function(TimezoneService) {
    return {
        templateUrl: getCacheBustedTemplate('pages/vessel-reports/tabs/position.html'),
        link: function(scope, element, attributes, control) {

            // sort all observations before doing anything else
            if (scope.report && scope.report.position && scope.report.position.observations && removeEmptyValues(scope.report.position.observations).length) {
                scope.report.position.observations
                    .sort(function(a, b) {
                        if ( a == null) { // ensure nulls are moved towards the end
                            return 1;
                        }
                        a = moment(a.observation, 'DD/MM/YYYY HH:mm');
                        b = moment(b.observation, 'DD/MM/YYYY HH:mm');
                        return a - b;
                    });
            }

            scope.obsLength = function() {
                var length = 0;
                if (scope.report && scope.report.position && scope.report.position.observations && removeEmptyValues(scope.report.position.observations).length) {
                    length = removeEmptyValues(scope.report.position.observations).length;
                }
                return length;
            }
            scope.observationIndex = scope.obsLength() + 1;
            scope.nextObs = function() {
                scope.observationIndex = Math.min(
                    scope.observationIndex + 1,
                    scope.obsLength() + 1
                );
            };
            scope.prevObs = function() {
                scope.observationIndex = Math.max(scope.observationIndex - 1, 1);
            };
            scope.getObs = function(index) {
                if (index == 0) {
                    return scope.report.position;
                }
                else {
                    return scope.report.position.observations[index - 1];
                }
            }

            scope.swellOptions = [
                {
                    text: scope.enableValidationsV51() ? 'Please select a Swell Code' : '',
                    value: undefined
                },
                { text: '0 - No Swell', value: 0 },
                { text: '1 - Very Low', value: 1 },
                { text: '2 - Low ', value: 2 },
                { text: '3 - Light', value: 3 },
                { text: '4 - Moderate', value: 4 },
                { text: '5 - Moderate Rough', value: 5 },
                { text: '6 - Rough', value: 6 },
                { text: '7 - High', value: 7 },
                { text: '8 - Very High', value: 8 },
                { text: '9 - Confused', value: 9 },
            ];

            scope.$watch('report', function(report) {
                if (scope.report != undefined && scope.report.position != undefined && scope.report.position.observations == undefined) {
                    scope.report.position.observations = [];
                }
            });

            scope.clearObservation = function() {
                var previousObservation = scope.report.position;
                var previousTimezone = scope.report.position.observation_timezone;
                scope.report.position = {
                    observations: scope.report.position.observations || [],
                    observation_timezone: previousTimezone,
                    fin_stabilizer_operational: previousObservation.fin_stabilizer_operational
                }
            }

            scope.register(scope, 'awd_wave_dir_diff');

            scope.removeRecentObservation = function() {
                if (scope.report.position.observations != undefined && removeEmptyValues(scope.report.position.observations).length > 0) {
                    
                    var deletedObservations = scope.report.position.observations.filter( obs => obs == null);
                    var currentObservations = removeEmptyValues(scope.report.position.observations);
                    // remove the most recent observation from the list
                    var mostRecentObservation = currentObservations.splice(-1, 1)[0];
                    // add null to indicate observation has been deleted
                    deletedObservations.push(null);
                    scope.report.position.observations = currentObservations.concat(deletedObservations);
                    // copy it / set it to the current observation
                    scope.report.position.observation = mostRecentObservation.observation;
                    scope.report.position.observation = mostRecentObservation.observation;
                    scope.report.position.true_wind_speed = mostRecentObservation.true_wind_speed;
                    scope.report.position.true_wind_direction = mostRecentObservation.true_wind_direction;
                    scope.report.position.true_wind_reporting = mostRecentObservation.true_wind_reporting;
                    scope.report.position.true_wind_force = mostRecentObservation.true_wind_force;
                    scope.report.position.relative_wind_speed = mostRecentObservation.relative_wind_speed;
                    scope.report.position.relative_wind_direction = mostRecentObservation.relative_wind_direction;
                    scope.report.position.true_wind_effect = mostRecentObservation.true_wind_effect;
                    scope.report.position.true_current_effect = mostRecentObservation.true_current_effect;
                    scope.report.position.relative_current_speed = mostRecentObservation.relative_current_speed;
                    scope.report.position.swell = mostRecentObservation.swell;
                    scope.report.position.wave_height = mostRecentObservation.wave_height;
                    scope.report.position.water_depth = mostRecentObservation.water_depth;
                    scope.report.position.current_direction = mostRecentObservation.current_direction;
                    scope.report.position.current_speed = mostRecentObservation.current_speed;
                    scope.report.position.wave_direction = mostRecentObservation.wave_direction;
                    scope.report.position.air_temperature = mostRecentObservation.air_temperature;
                    scope.report.position.sea_temperature = mostRecentObservation.sea_temperature;
                    scope.report.position.atmospheric_pressure = mostRecentObservation.atmospheric_pressure;
                    scope.report.position.salinity = mostRecentObservation.salinity;

                    scope.report.position.trip_counter_gps = mostRecentObservation.trip_counter_gps;
                    scope.report.position.trip_counter_log = mostRecentObservation.trip_counter_log;
                    scope.report.position.speed_log_malfunction = mostRecentObservation.speed_log_malfunction;
                    scope.report.position.log_speed = mostRecentObservation.log_speed;
                    scope.report.position.log_distance = mostRecentObservation.log_distance;
                    scope.report.position.cog = mostRecentObservation.cog;
                    scope.report.position.observed_speed = mostRecentObservation.observed_speed;
                    scope.report.position.observed_distance = mostRecentObservation.observed_distance;
                    scope.report.position.observed_distance_in_eca = mostRecentObservation.observed_distance_in_eca;
                    scope.report.position.observed_distance_for_total_sea_passage = mostRecentObservation.observed_distance_for_total_sea_passage;
                    scope.report.position.remaining_distance = mostRecentObservation.remaining_distance;
                    scope.report.position.bad_weather_hours = mostRecentObservation.bad_weather_hours;
                    scope.report.position.bad_weather_observed_distance = mostRecentObservation.bad_weather_observed_distance;
                    scope.report.position.wind_corrected_speed = mostRecentObservation.wind_corrected_speed;
                    scope.report.position.fin_stabilizer_operational = mostRecentObservation.fin_stabilizer_operational;

                    scope.report.position.propeller_rpm = mostRecentObservation.propeller_rpm;

                    scope.report.position.ship_lat_hours = mostRecentObservation.ship_lat_hours;
                    scope.report.position.ship_lat_minutes = mostRecentObservation.ship_lat_minutes;
                    scope.report.position.ship_lat_seconds = mostRecentObservation.ship_lat_seconds;
                    scope.report.position.ship_lat_direction = mostRecentObservation.ship_lat_direction;
                    scope.report.position.ship_lon_hours = mostRecentObservation.ship_lon_hours;
                    scope.report.position.ship_lon_minutes = mostRecentObservation.ship_lon_minutes;
                    scope.report.position.ship_lon_seconds = mostRecentObservation.ship_lon_seconds;
                    scope.report.position.ship_lon_direction = mostRecentObservation.ship_lon_direction;
                }
                var observationIndex = 1;
                if (scope.report.position.observations != undefined && removeEmptyValues(scope.report.position.observations).length > 0) {
                    observationIndex += removeEmptyValues(scope.report.position.observations).length;
                }
                scope.observationIndex = observationIndex;
            }

            scope.addNewObservation = function() {
                if (scope.reportForm.position.observation.$invalid) { return; }

                // create new observation to save it in the list
                var newObservation = {
                    observation: scope.report.position.observation,
                    observation_timezone: scope.report.position.observation_timezone,
                    true_wind_speed: scope.report.position.true_wind_speed,
                    true_wind_direction: scope.report.position.true_wind_direction,
                    true_wind_force: scope.report.position.true_wind_force,
                    true_wind_reporting: scope.report.position.true_wind_reporting,
                    relative_wind_speed: scope.report.position.relative_wind_speed,
                    relative_wind_direction: scope.report.position.relative_wind_direction,
                    true_wind_effect: scope.report.position.true_wind_effect,
                    wave_height: scope.report.position.wave_height,
                    water_depth: scope.report.position.water_depth,
                    true_current_effect: scope.report.position.true_current_effect,
                    current_speed: scope.report.position.current_speed,
                    current_direction: scope.report.position.current_direction,
                    relative_current_speed: scope.report.position.relative_current_speed,
                    swell: scope.report.position.swell,
                    wave_direction: scope.report.position.wave_direction,
                    air_temperature: scope.report.position.air_temperature,
                    sea_temperature: scope.report.position.sea_temperature,
                    atmospheric_pressure: scope.report.position.atmospheric_pressure,
                    salinity: scope.report.position.salinity,

                    trip_counter_gps: scope.report.position.trip_counter_gps,
                    trip_counter_log: scope.report.position.trip_counter_log,
                    speed_log_malfunction: scope.report.position.speed_log_malfunction,
                    log_speed: scope.report.position.log_speed,
                    log_distance: scope.report.position.log_distance,
                    cog: scope.report.position.cog,
                    observed_speed: scope.report.position.observed_speed,
                    observed_distance: scope.report.position.observed_distance,
                    observed_distance_in_eca: scope.report.position.observed_distance_in_eca,
                    observed_distance_for_total_sea_passage: scope.report.position.observed_distance_for_total_sea_passage,
                    remaining_distance: scope.report.position.remaining_distance,
                    bad_weather_hours: scope.report.position.bad_weather_hours,
                    bad_weather_observed_distance: scope.report.position.bad_weather_observed_distance,
                    wind_corrected_speed: scope.report.position.wind_corrected_speed,
                    fin_stabilizer_operational: scope.report.position.fin_stabilizer_operational,

                    propeller_rpm: scope.report.position.propeller_rpm,
                    propeller_pitch_percent: scope.report.position.propeller_pitch_percent,

                    ship_lat_hours: scope.report.position.ship_lat_hours,
                    ship_lat_minutes: scope.report.position.ship_lat_minutes,
                    ship_lat_seconds: scope.report.position.ship_lat_seconds,
                    ship_lat_direction: scope.report.position.ship_lat_direction,
                    ship_lon_hours: scope.report.position.ship_lon_hours,
                    ship_lon_minutes: scope.report.position.ship_lon_minutes,
                    ship_lon_seconds: scope.report.position.ship_lon_seconds,
                    ship_lon_direction: scope.report.position.ship_lon_direction
                }
                var deletedObservations = scope.report.position.observations.filter(obs => obs == null);
                if (deletedObservations.length > 0) {
                    deletedObservations.pop();
                }
                var currentObservations = removeEmptyValues(scope.report.position.observations);

                currentObservations.push(newObservation);
                scope.report.position.observations = currentObservations.concat(deletedObservations);
                // reset original observation
                scope.clearObservation();

                scope.observationIndex += 1;
            }

            scope.observationSummaryAverage = function(property) {
                if (scope.report.position && scope.report.position.observations) {
                    return removeEmptyValues(scope.report.position.observations).map(function(obs) { return obs[property]; }).concat([scope.report.position[property]]).mean();
                }
            };
            scope.observationSummaryBF = function() {
                if (scope.report.position && scope.report.position.observations) {
                    return bfFromTrueWindSpeed(scope.observationSummaryAverage('true_wind_speed'));
                }
            };
            scope.observationSummaryTotal = function(property) {
                if (scope.report.position && scope.report.position.observations) {
                    return removeEmptyValues(scope.report.position.observations).map(function(obs) { return obs[property]; }).concat([scope.report.position[property]]).sum();
                }
            };
        }
    }
}]);

routerApp.directive('powerTab', function() {
    return {
        templateUrl: getCacheBustedTemplate('pages/vessel-reports/tabs/power.html'),
        link: function(scope, element, attributes, control) {

            scope.$watchGroup([
                'report.power.aux_engine_1_power',
                'report.power.aux_engine_2_power',
                'report.power.aux_engine_3_power',
                'report.power.aux_engine_4_power',
                'report.power.shaft_power',
                'report.power.turbo_power'
            ], function(newValues) {
                if (scope.report.power == undefined) return;
                scope.report.power.aux_engine_total_power = sumValues(newValues);
            });

            // returns the right unit to display on the input field
            var getUnitFromString = function(readingUnitText) {
                if (readingUnitText == 'mass') {
                    return 'kg';
                } else if (readingUnitText == 'volumetric_gallon')  {
                    return 'gal';
                } else {
                    return 'L';
                } 
            }

            // get main engine label from string
            var getLabelFromString = function(fmType) {
                if (fmType == 'common') {
                    return 'Common (ME + AE)';
                } else {
                    return 'Main Engine';
                }
            }

            // get extra css needed to display string
            var getCSSForString = function(fmType) {
                if (fmType == 'common') {
                    return {padding: '0px'};
                } else {
                    return {}
                }
            }
            
            // sets variables to hide entire sections if all sections / fields within it are disabled/hidden  
            scope.$watchGroup(['form', 'vesselSpecs' ], function(newValues) {
                var form = newValues[0];
                var vesselSpec = newValues[1];
                if (form && vesselSpec) {
                    // power tab
                    scope.powerTabDisabled = form.tabs['power']?.visible == false;
                    // power tab section
                    scope.powerSectionDisabled = scope.showByDefault('power.engine_propulsion', 'sections') == false
                    && !scope.isDieselElectricPropulsion()
                    && scope.showByDefault('power.generator_engine', 'sections') == false
                    && !scope.hasPTOorPTI()
                    && scope.showByDefault('power.miscellaneous', 'sections') == false
                    || form.tabs['power']?.visible == false;
                }
            });

            scope.allSectionsDisabled = function() {
                return scope.showByDefault('power.engine_propulsion', 'sections') == false
                && !scope.isDieselElectricPropulsion()
                && scope.showByDefault('power.generator_engine', 'sections') == false
                && !scope.hasPTOorPTI()
                && scope.showByDefault('power.miscellaneous', 'sections') == false
            }

            scope.$watchGroup(['report.operational.report_period','report.power.shaft_generator_pto_power','report.power.shaft_generator_pti_power','report.power.shaft_generator_pto_rh','report.power.shaft_motor_pti_rh'], function(newValues, oldValues, scope) {
                var pto_power = newValues[1] || 0;
                var pti_power = newValues[2] || 0;
                var pto_rh = newValues[3] || 0;
                var pti_rh = newValues[4] || 0;
                if ((scope.vessel_specifications.information.has_pto || scope.vessel_specifications.information.has_pti) && (pto_power + pti_power > 0) && ((pto_rh + pti_rh) >= newValues[0]) ) {
                    scope.auxEngineFieldRequired = false;
                }
                else {
                    scope.auxEngineFieldRequired = true;
                }
            })

            // store FM units
            scope.$watch('vesselSpecs', function(vesselSpecs: any) {
                if (vesselSpecs && vesselSpecs.prime_mover) {
                    scope.vesselSpecs.prime_mover.dg_indices = [];
                    for (var i = 0; i < scope.vesselSpecs.prime_mover.number_of_diesel_generators; i++) {
                        scope.vesselSpecs.prime_mover.dg_indices.push(i)
                    }
                }    
                if (vesselSpecs && vesselSpecs.fm_configuration) {
                    var fmc = vesselSpecs.fm_configuration;
                    scope.meFlowMeterUnit = getUnitFromString(fmc.me_fm_reading);
                    scope.aeFlowMeterUnit = getUnitFromString(fmc.ae_fm_reading);
                    scope.buFlowMeterUnit = getUnitFromString(fmc.bu_fm_reading);
                    scope.hdgFlowMeterUnit = getUnitFromString(fmc.hdg_fm_reading);

                    scope.meFlowMeterLabel = getLabelFromString(fmc.me_fm_type);
                    scope.meFlowMeterLabelCss = getCSSForString(fmc.me_fm_type);
                } else {
                    // default setting
                    scope.meFlowMeterUnit = getUnitFromString('volumetric');
                    scope.aeFlowMeterUnit = getUnitFromString('volumetric');
                    scope.buFlowMeterUnit = getUnitFromString('volumetric');
                    scope.hdgFlowMeterUnit = getUnitFromString('volumetric');

                    scope.meFlowMeterLabel = getLabelFromString(null);
                    scope.meFlowMeterLabelCss = getCSSForString(null);
                }
            });

            scope.getDieselGenRHTooltip = function(index) {
                var i = index - 1;

                var currentRH = 0;
                var prevRH = 0;
                var diffRH = 0;
                var rh_max = prevRH + scope.report.operational.report_period + 2;
                var dgPower = 0;

                if ('power' in scope.report) {
                    if ('diesel_gen_'+index+'_cur_rh' in scope.report.power) currentRH = scope.report.power['diesel_gen_'+index+'_cur_rh'];
                    if ('diesel_gen_'+index+'_prev_rh' in scope.report.power) prevRH = scope.report.power['diesel_gen_'+index+'_prev_rh'];
                    if ('diesel_gen_'+index+'_diff_rh' in scope.report.power) diffRH = scope.report.power['diesel_gen_'+index+'_diff_rh'];
                    if ('diesel_gen_'+index+'_power' in scope.report.power) dgPower = scope.report.power['diesel_gen_'+index+'_power'];
                }
                
                var isValid = true;
                var message = null;
                if (!scope.isDieselElectricPropulsion() || scope.report?.power?.shore_power_is_used == true) {
                    isValid = true;
                }
                else {
                    if (currentRH == undefined) {
                        scope.DieselGentooltipClass = 'tooltip-warning'
                        isValid = false;
                        message = scope.vessel_specifications.prime_mover.diesel_generators[i].name + ' running hours must be more than ' + prevRH + ' and less than ' + rh_max + ' based on your reporting period'
                    }
                    else if (currentRH < prevRH) {
                        scope.DieselGentooltipClass = 'tooltip-error'
                        isValid = false;
                        message = scope.vessel_specifications.prime_mover.diesel_generators[i].name + ' running hours must be at least ' + prevRH
                    }
                    else if (dgPower > 0 && diffRH == 0) {
                        scope.DieselGentooltipClass = 'tooltip-error'
                        isValid = false;
                        message = 'A diff RH > 0 is needed since ' + scope.vessel_specifications.prime_mover.diesel_generators[i].name + ' power is reported'
                    }
                    var totalRH = 0;
                    for (var i = 0; i<scope.vesselSpecs.prime_mover.dg_indices.length; i++) {
                        if ('power' in scope.report) {
                            if ('diesel_gen_'+(i+1)+'_diff_rh' in scope.report.power) totalRH += scope.report.power['diesel_gen_'+(i+1)+'_diff_rh'];
                        }
                    }
                    if (totalRH < scope.report.operational.report_period) {
                        scope.DieselGentooltipClass = 'tooltip-error'
                        isValid = false;
                        message = 'Total Diesel Generator running hours must be greater than the reporting period, currently it is ' + totalRH + ' hours but it must be at least ' + scope.report.operational.report_period + ' hours' 
                    }
                }
                scope.reportForm?.power?.runningHours?.$setValidity('dg_hours', isValid);
                return message
            }

            scope.enableDieselGenRHTooltip = function(index) {
                return scope.getDieselGenRHTooltip(index) != null;
            }
            
            scope.getCargoCompressorTooltip = function(index) {
                /**
                 * 1. if Cargo Compressor RH is not visible set no validation
                 * 2. if cargo compressor RH is visible then apply validation rules
                 * 3. current reading must be greater than or equal previous reading 
                 * 4. if cargo compressor RH DIFF > 0 then cargoCompressor reading must be greater than previous reading 
                 * 5. if cargo Reading is required than reading cannot be blank
                 */
                var currentReading = scope.report?.power['cargo_compressor_'+index+'_cur_reading'];
                var prevReading = scope.report?.power['cargo_compressor_'+index+'_prev_reading'];
                var cargoCompRH = scope.report?.power['cargo_compressor_'+index+'_diff_rh'];
                const isRequired = scope.vesselSpecs?.form?.fields['report.power.cargo_compressor_'+index+'_cur_reading']?.required;
                var isValid = true;
                var message = null;
                const shouldValidateCargoCoolingSystem = scope.vesselSpecs.form.fields['report.power.cargo_compressor_'+index+'_cur_rh'].visible;

                if (shouldValidateCargoCoolingSystem) {
                    if (cargoCompRH > 0 && (zeroIfUndefined(currentReading) - zeroIfUndefined(prevReading)) == 0) {
                        scope['cargoCompressor'+index+'tooltipclass'] = 'tooltip-warning'
                        message = 'The current reading should be greater than the previous reading (' + prevReading +' kWh) since running hours is greater than 0'
                        if (isRequired) {
                            scope['cargoCompressor'+index+'tooltipclass'] = 'tooltip-error'
                            isValid = false;
                        }
                    } else if (zeroIfUndefined(currentReading) < zeroIfUndefined(prevReading)) {
                        scope['cargoCompressor'+index+'tooltipclass'] = 'tooltip-error'
                        if (isRequired) {
                            isValid = false;
                        }
                        message = `The current reading must be at least ${zeroIfUndefined(prevReading)} or not undefined`
                    } else if (currentReading == undefined) {
                        scope['cargoCompressor'+index+'tooltipclass'] = 'tooltip-warning'
                        message = 'The current reading must be greater than or equal to the previous reading of ' + prevReading
                    }
                } else {
                    isValid = true;
                }
                scope.reportForm?.power?.cargoCoolingkWhCounter?.['cargoCompressor'+index].$setValidity('cargoCompressorReadingValidity', isValid);
                return message
            }

            scope.enableCargoCompressorTooltip = function(index) {
                return scope.getCargoCompressorTooltip(index) != null;
            }

            scope.requireCargoCompressorReading = {};
            const cargCompressorIndices = [1, 2, 3, 4];
            cargCompressorIndices.forEach((index) => {
                scope.$watch(`report.power.cargo_compressor_${index}_diff_rh`, function (runningHours) {
                    const isRequired = scope.vesselSpecs?.form?.fields['report.power.cargo_compressor_'+index+'_cur_reading']?.required;

                    if (runningHours && runningHours > 0 && isRequired) {
                        scope.requireCargoCompressorReading[index] = true;
                    } else if (runningHours == 0 || runningHours == undefined) {
                        scope.requireCargoCompressorReading[index] = false;
                    }
                })
            })

            scope.cargoCompressorReadingIsRequired = function(index: number) {
                return scope.requireCargoCompressorReading[index];
            };

            scope.getOtherCargoCoolingTooltip = function() {
                var currentReading = scope.report?.power?.other_cargo_cooling_cur_reading;
                var prevReading = scope.report?.power?.other_cargo_cooling_prev_reading;
                var cargoCompRH = scope.report?.power?.other_cargo_cooling_rh;
                const isRequired = scope.vesselSpecs?.form?.fields['report.power.other_cargo_cooling_cur_reading']?.required;
                var isValid = true;
                var message = null;
                if (!scope.vesselSpecs.form.fields['report.power.cargo_compressor_1_cur_rh'].visible) {
                    isValid = true;
                }
                else {
                    if (currentReading == undefined) {
                        scope['otherCargoCoolingtooltipclass'] = 'tooltip-warning'
                        message = 'The current reading must be greater than or equal to the previous reading of ' + prevReading
                        if (isRequired) {
                            scope['otherCargoCoolingtooltipclass'] = 'tooltip-error';
                            isValid = false;
                        }
                    }
                    else if (currentReading < prevReading) {
                        scope['otherCargoCoolingtooltipclass'] = 'tooltip-error'
                        isValid = false;
                        message = 'The current reading must be at least ' + prevReading
                    }
                    else if (cargoCompRH > 0 && (currentReading - prevReading) == 0) {
                        scope['otherCargoCoolingtooltipclass'] = 'tooltip-error'
                        isValid = false;
                        message = 'The current reading should be greater than the previous reading (' + prevReading +' kWh) since running hours is greater than 0'
                    }
                }
                scope.reportForm?.power?.cargoCoolingkWhCounter?.['othercargoCooling'].$setValidity('othercargoCoolingValidation', isValid);
                return message
            }

            scope.enableOtherCargoCoolingTooltip = function() {
                return scope.getOtherCargoCoolingTooltip() != null;
            }

            scope.register(scope, 'mdg_power_meter_1');
            scope.register(scope, 'mdg_power_meter_2');
            scope.register(scope, 'mdg_power_meter_3');
            scope.register(scope, 'mdg_power_meter_4');
            scope.register(scope, 'mdg_power_meter_5');
            scope.register(scope, 'mdg_power_meter_6');

            scope.requireDieselGenRH = function() {
                for (var i=0; i<scope.vesselSpecs.prime_mover.dg_indices.length;i++) {
                    return true
                }
            }

            scope.$watchGroup([
                'report.power.main_engine_1_diff_rh',
                'report.power.main_engine_2_diff_rh',
                'report.operational.vessel_underway',
                ], function(n, o, scope) {
                    var mainEngine1RH = n[0] || 0;
                    var mainEngine2RH = n[1] || 0;
                    var vesselUnderway = n[2] || 0;
                    var main_engine_1_and_2_rh = Math.max(mainEngine1RH, mainEngine2RH);
                    if (vesselUnderway > 0) {
                        main_engine_1_and_2_rh = vesselUnderway;
                    }
                    scope.report.power.main_engine_1_and_2_rh = main_engine_1_and_2_rh;
            });

            // main engine rpm counter difference
            scope.$watchGroup(['report.power.main_engine_1_current_rpm', 'report.power.main_engine_1_previous_rpm'], function(newValues) {
                if (scope.report.power != undefined) {
                    var difference = 0;
                    if (newValues[0] != undefined && newValues[1] != undefined) {
                        difference = newValues[0] - newValues[1];
                    }
                    scope.report.power.main_engine_1_difference_rpm = difference;
                }
            });

            // calculated main engine rpm counter
            scope.$watchGroup(['report.power.main_engine_1_difference_rpm', 'report.power.main_engine_1_diff_rh'], function(newValues) {
                if (scope.report.power != undefined) {
                    var calculatedRPM = 0;
                    if (newValues[0] != undefined && newValues[1] != undefined && newValues[1] != 0) {
                        calculatedRPM = newValues[0] / (newValues[1] * 60);
                    }
                    scope.report.power.main_engine_1_calculated_rpm = calculatedRPM;
                }
            });

            scope.$watch('report.power.aux_engine_1_power', function(newValue: number) {
                var engine = scope.vessel_specifications.engine.ae_engines[0];
                if (newValue !== undefined && engine != undefined && engine.mcr != 0) {
                    scope.report.power.aux_engine_1_max_rating = newValue / engine.mcr * 100;
                }
            });
            scope.$watch('report.power.aux_engine_2_power', function(newValue: number) {
                var engine = scope.vessel_specifications.engine.ae_engines[1];
                if (newValue !== undefined && engine != undefined && engine.mcr != 0) {
                    scope.report.power.aux_engine_2_max_rating = newValue / engine.mcr * 100;
                }
            });
            scope.$watch('report.power.aux_engine_3_power', function(newValue: number) {
                var engine = scope.vessel_specifications.engine.ae_engines[2];
                if (newValue !== undefined && engine != undefined && engine.mcr != 0) {
                    scope.report.power.aux_engine_3_max_rating = newValue / engine.mcr * 100;
                }
            });
            scope.$watch('report.power.aux_engine_4_power', function(newValue: number) {
                var engine = scope.vessel_specifications.engine.ae_engines[3];
                if (newValue !== undefined && engine != undefined && engine.mcr != 0) {
                    scope.report.power.aux_engine_4_max_rating = newValue / engine.mcr * 100;
                }
            });

            scope.getAuxEnginePowerTooltip = function(index) {
                if (!scope.report || !scope.report.power) return null

                var aux_engine_max_rating = scope.report.power['aux_engine_'+ index +'_max_rating'];
                var aux_engine_rh = scope.report.power['aux_engine_' + index + '_diff_rh'];
                var aux_engine_power = scope.report.power['aux_engine_'+ index + '_power'];
                var isValid = true;
                var message = null;
                if (aux_engine_max_rating > 100) {
                    isValid = false;
                    message = 'Max Rating must be less than or equal to 100%. Please adjust Aux Engine '+ index +' Power accordingly.'
                }
                else if (aux_engine_rh > 0 && (aux_engine_power == null || aux_engine_power == 0)) {
                    isValid = false;
                    message = 'Please enter a power greater than 0 since auxiliary engine ' + index + ' running hours difference is greater than 0'
                }
                else {
                    isValid = true;
                    message = null;
                }

                if (scope.reportForm?.power?.generatorEngine) {
                    scope.reportForm.power.generatorEngine['auxEngine'+index]?.$setValidity('auxEngine'+index,isValid);
                }
                return message
            }

            scope.enableAuxEnginePowerTooltip = function(index) {
                return scope.getAuxEnginePowerTooltip(index) != null;
            }

            scope.$watch('report.power.diesel_gen_1_power', function(newValue: number) {
                var prime_mover = scope.vessel_specifications.prime_mover.diesel_generators[0];
                if (newValue !== undefined && prime_mover != undefined && prime_mover.mcr != 0) {
                    scope.report.power.diesel_gen_1_max_rating = newValue / prime_mover.mcr * 100;
                }
            });

            scope.$watch('report.power.diesel_gen_2_power', function(newValue: number) {
                var prime_mover = scope.vessel_specifications.prime_mover.diesel_generators[1];
                if (newValue !== undefined && prime_mover != undefined && prime_mover.mcr != 0) {
                    scope.report.power.diesel_gen_2_max_rating = newValue / prime_mover.mcr * 100;
                }
            });

            scope.$watch('report.power.diesel_gen_3_power', function(newValue: number) {
                var prime_mover = scope.vessel_specifications.prime_mover.diesel_generators[2];
                if (newValue !== undefined && prime_mover != undefined && prime_mover.mcr != 0) {
                    scope.report.power.diesel_gen_3_max_rating = newValue / prime_mover.mcr * 100;
                }
            });

            scope.$watch('report.power.diesel_gen_4_power', function(newValue: number) {
                var prime_mover = scope.vessel_specifications.prime_mover.diesel_generators[3];
                if (newValue !== undefined && prime_mover != undefined && prime_mover.mcr != 0) {
                    scope.report.power.diesel_gen_4_max_rating = newValue / prime_mover.mcr * 100;
                }
            });

            scope.$watch('report.power.diesel_gen_5_power', function(newValue: number) {
                var prime_mover = scope.vessel_specifications.prime_mover.diesel_generators[4];
                if (newValue !== undefined && prime_mover != undefined && prime_mover.mcr != 0) {
                    scope.report.power.diesel_gen_5_max_rating = newValue / prime_mover.mcr * 100;
                }
            });

            scope.$watch('report.power.diesel_gen_6_power', function(newValue: number) {
                var prime_mover = scope.vessel_specifications.prime_mover.diesel_generators[5];
                if (newValue !== undefined && prime_mover != undefined && prime_mover.mcr != 0) {
                    scope.report.power.diesel_gen_6_max_rating = newValue / prime_mover.mcr * 100;
                }
            });

            scope.getDieselGenPowerTooltip = function(index) {
                var dg_index = index + 1;
                var diesel_gen_max_rating = scope.report?.power['diesel_gen_'+ dg_index +'_max_rating'];
                var diesel_gen_rh = scope.report?.power['diesel_gen_' + dg_index + '_diff_rh'];
                var diesel_gen_power = scope.report?.power['diesel_gen_'+ dg_index + '_power'];
                var isValid = true;
                var message = null;
                if (diesel_gen_max_rating > 100) {
                    isValid = false;
                    message = 'Max Rating must be less than or equal to 100%. Please adjust ' + scope.vessel_specifications.prime_mover.diesel_generators[index].name + ' Power accordingly.'
                }
                else if (diesel_gen_rh > 0 && (diesel_gen_power == null || diesel_gen_power == 0)) {
                    isValid = false;
                    message = 'Please enter a power greater than 0 since ' + scope.vessel_specifications.prime_mover.diesel_generators[index].name + ' running hours difference is greater than 0'
                }
                else {
                    isValid = true;
                    message = null;
                }
                scope.reportForm?.power?.generatorEngine['dieselGen'+dg_index].$setValidity('dieselGen'+dg_index,isValid);
                return message
            }

            scope.enableDieselGenPowerTooltip = function(index) {
                return scope.getDieselGenPowerTooltip(index) != null;
            }

            scope.$watchGroup(['report.power.main_engine_1_power', 'report.power.main_engine_1_and_2_power', 'vessel_specifications.engine.me_engines[0].mcr','selectedVessel.dualEngine'], function(newValues, oldValues, scope) {
                var power;
                if (!newValues[3]) {
                    power = newValues[0];
                } else {
                    power = newValues[1];
                }
                if (power) {
                    var mcrPercent = 100;
                    if (power && newValues[2] != 0) {
                        mcrPercent = power * 100 / newValues[2];
                    }
                    mcrPercent = mcrPercent > 100 ? 100 : mcrPercent;
                    mcrPercent = mcrPercent < 0 ? 0 : mcrPercent;
                }
                scope.enableMCRMandatory = mcrPercent > 40;
            });

            scope.$watchGroup(['report.power.main_engine_1_shaft_power_meter_counter', 'report.power.main_engine_1_shaft_power_meter_counter_previous'], function(newValues) {
                if (scope.report.power != undefined) {
                    var difference = 0;
                    if (newValues[0] != undefined && newValues[1] != undefined) {
                        difference = newValues[0] - newValues[1];
                    }
                    scope.report.power.main_engine_1_shaft_power_meter_difference = difference;
                }
            });

            scope.$watchGroup([
                'report.power.main_engine_1_shaft_power_meter_calculated',
                'report.power.main_engine_1_and_2_rh',
                'report.power.shaft_power',
                'report.power.shaft_rh',
            ], function(newValues) {
                if (newValues[0] != undefined) {
                    var totalPower = newValues[0] * 1.03;
                    if (newValues[1] != undefined && newValues[2] != undefined && newValues[3] != undefined) {
                        totalPower += newValues[2] * newValues[3] * 1.04 / newValues[1];
                    }
                    scope.report.power.main_engine_1_and_2_power = totalPower;
                }
            });

            scope.$watchGroup([
                'report.power.main_engine_1_shaft_power_meter_difference',
                'report.power.main_engine_1_diff_rh',
                'report.power.main_engine_2_diff_rh',
                'report.operational.vessel_underway'
            ], function(newValues) {
                if (scope.report.power != undefined) {
                    var calculated = 0;
                    var vesselUnderway = newValues[3] || 0;
                    var meRHs = Math.max(newValues[1] || 0, newValues[2] || 0);
                    if (scope.isKuokuyoClass()) {
                        if (vesselUnderway > 0) {
                            meRHs = vesselUnderway;
                        } else {
                            meRHs = scope.report.operational.report_period;
                        }
                    }
                    if (newValues[0] > 0 && meRHs != 0) {
                        calculated = newValues[0] / meRHs;
                        scope.report.power.main_engine_1_shaft_power_meter_calculated = roundToPlaces(calculated, 2);
                    }
                }
            });

            scope.$watch('report.power.shaft_generator_pto_power', function(newValue: number, oldValue: number, scope) {
                if (newValue != undefined && scope.vessel_specifications?.engine?.pto?.mcr > 0) {
                    scope.report.power.shaft_generator_pto_max_rating = (newValue / scope.vessel_specifications.engine.pto.mcr) * 100;
                }
            });

            scope.$watch('report.power.shaft_motor_pti_power', function(newValue: number, oldValue: number, scope) {
                if (newValue != undefined && scope.vesselSpecs?.engine?.pti?.mcr > 0) {
                    scope.report.power.shaft_motor_pti_max_rating = (newValue / scope.vesselSpecs.engine.pti.mcr) * 100;
                }
            });

            // Machinery Running Hours
            scope.$watchGroup(['report.power.main_engine_1_cur_rh', 'report.power.main_engine_1_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.main_engine_1_diff_rh = newValues[0] - newValues[1];
                } else if (newValues[0] == null || newValues[1] == null) {
                    scope.report.power.main_engine_1_diff_rh = null;
                }
            });
            scope.$watchGroup(['report.power.main_engine_2_cur_rh', 'report.power.main_engine_2_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.main_engine_2_diff_rh = newValues[0] - newValues[1];
                } else if (newValues[0] == null || newValues[1] == null) {
                    scope.report.power.main_engine_2_diff_rh = null;
                }
            });
            scope.$watchGroup(['report.power.diesel_gen_1_cur_rh', 'report.power.diesel_gen_1_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.diesel_gen_1_diff_rh = newValues[0] - newValues[1];
                }
            });
            scope.$watchGroup(['report.power.diesel_gen_2_cur_rh', 'report.power.diesel_gen_2_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.diesel_gen_2_diff_rh = newValues[0] - newValues[1];
                }
            });
            scope.$watchGroup(['report.power.diesel_gen_3_cur_rh', 'report.power.diesel_gen_3_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.diesel_gen_3_diff_rh = newValues[0] - newValues[1];
                }
            });
            scope.$watchGroup(['report.power.diesel_gen_4_cur_rh', 'report.power.diesel_gen_4_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.diesel_gen_4_diff_rh = newValues[0] - newValues[1];
                }
            });
            scope.$watchGroup(['report.power.diesel_gen_5_cur_rh', 'report.power.diesel_gen_5_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.diesel_gen_5_diff_rh = newValues[0] - newValues[1];
                }
            });
            scope.$watchGroup(['report.power.diesel_gen_6_cur_rh', 'report.power.diesel_gen_6_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.diesel_gen_6_diff_rh = newValues[0] - newValues[1];
                }
            });

            scope.$watchGroup(['report.power.aux_engine_1_cur_rh', 'report.power.aux_engine_1_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.aux_engine_1_diff_rh = newValues[0] - newValues[1];
                } else if (newValues[0] == null || newValues[1] == null) {
                    scope.report.power.aux_engine_1_diff_rh = null;
                }
            });
            scope.$watchGroup(['report.power.aux_engine_2_cur_rh', 'report.power.aux_engine_2_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.aux_engine_2_diff_rh = newValues[0] - newValues[1];
                } else if (newValues[0] == null || newValues[1] == null) {
                    scope.report.power.aux_engine_2_diff_rh = null;
                }
            });
            scope.$watchGroup(['report.power.aux_engine_3_cur_rh', 'report.power.aux_engine_3_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.aux_engine_3_diff_rh = newValues[0] - newValues[1];
                } else if (newValues[0] == null || newValues[1] == null) {
                    scope.report.power.aux_engine_3_diff_rh = null;
                }
            });
            scope.$watchGroup(['report.power.aux_engine_4_cur_rh', 'report.power.aux_engine_4_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.aux_engine_4_diff_rh = newValues[0] - newValues[1];
                } else if (newValues[0] == null || newValues[1] == null) {
                    scope.report.power.aux_engine_4_diff_rh = null;
                }
            });
            scope.$watchGroup(['report.power.shaft_cur_rh', 'report.power.shaft_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.shaft_rh = newValues[0] - newValues[1];
                }
            });
            scope.$watchGroup(['report.power.aux_boiler_cur_rh', 'report.power.aux_boiler_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.aux_boiler_diff_rh = newValues[0] - newValues[1];
                }
            });
            scope.$watchGroup(['report.power.aux_boiler_2_cur_rh', 'report.power.aux_boiler_2_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.aux_boiler_2_diff_rh = newValues[0] - newValues[1];
                }
            });
            scope.$watchGroup(['report.power.composite_boiler_cur_rh', 'report.power.composite_boiler_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.composite_boiler_diff_rh = newValues[0] - newValues[1];
                }
            });
            scope.$watchGroup(['report.power.main_engine_comp_1_cur_rh', 'report.power.main_engine_comp_1_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined && newValues[1] > 0) {
                    scope.report.power.main_engine_comp_1_diff_rh = newValues[0] - newValues[1];
                } else {
                    scope.report.power.main_engine_comp_1_diff_rh = 0;
                }
            });
            scope.$watchGroup(['report.power.main_engine_comp_2_cur_rh', 'report.power.main_engine_comp_2_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined && newValues[1] > 0) {
                    scope.report.power.main_engine_comp_2_diff_rh = newValues[0] - newValues[1];
                } else {
                    scope.report.power.main_engine_comp_2_diff_rh = 0;
                }
            });
            scope.$watchGroup(['report.power.main_engine_comp_3_cur_rh', 'report.power.main_engine_comp_3_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined && newValues[1] > 0) {
                    scope.report.power.main_engine_comp_3_diff_rh = newValues[0] - newValues[1];
                } else {
                    scope.report.power.main_engine_comp_3_diff_rh = 0;
                }
            });
            scope.$watchGroup(['report.power.main_engine_comp_4_cur_rh', 'report.power.main_engine_comp_4_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined && newValues[1] > 0) {
                    scope.report.power.main_engine_comp_4_diff_rh = newValues[0] - newValues[1];
                } else {
                    scope.report.power.main_engine_comp_4_diff_rh = 0;
                }
            });

            scope.$watchGroup(['report.power.hd1_port_current', 'report.power.hd2_port_current'], function(newValues) {
                var hd1_port = newValues[0] || 0;
                var hd2_port = newValues[1] || 0;
                scope.report.power.propulsion_motor_1_current = hd1_port + hd2_port;
            });

            scope.$watchGroup(['report.power.hd1_starboard_current', 'report.power.hd2_starboard_current'], function(newValues) {
                var hd1_starboard = newValues[0] || 0;
                var hd2_starboard = newValues[1] || 0;
                scope.report.power.propulsion_motor_2_current = hd1_starboard + hd2_starboard;
            });

            scope.getHDPortCurrentTooltip = function() {
                var propulsion_motor_1_current = scope.report?.power?.propulsion_motor_1_current;
                var port_current_max = scope.vesselSpecs?.prime_mover?.propulsion_motors[0].current;
                var isValid = true;
                var message = null;
                if (!scope.isDieselElectricPropulsion() || scope.report?.power?.shore_power_is_used == true) {
                    isValid = true;
                }
                else {
                    if (propulsion_motor_1_current > port_current_max) {
                        isValid = false;
                        message = "Propulsion Motor (Port) Current must be less than or equal to the propulsion motor's rated current (" + port_current_max + ")";
                    }
                }
                scope.reportForm?.power?.hd1PortCurrent?.$setValidity('hd_port_current', isValid);
                scope.reportForm?.power?.hd2PortCurrent?.$setValidity('hd_port_current', isValid);
                return message
            }

            scope.enableHDPortCurrentTooltip = function() {
                return scope.getHDPortCurrentTooltip() != null;
            }

            scope.getHDStarboardCurrentTooltip = function() {
                var propulsion_motor_2_current = scope.report?.power?.propulsion_motor_2_current;
                var starboard_current_max = scope.vesselSpecs?.prime_mover?.propulsion_motors[1].current;
                var isValid = true;
                var message = null;
                if (!scope.isDieselElectricPropulsion() || scope.report?.power?.shore_power_is_used == true) {
                    isValid = true;
                }
                else {
                    if (propulsion_motor_2_current > starboard_current_max) {
                        isValid = false;
                        message = "Propulsion Motor (Starboard) Current must be less than or equal to the propulsion motor's rated current (" + starboard_current_max +")";
                    }
                }
                scope.reportForm?.power?.hd1StarboardCurrent?.$setValidity('hd_starboard_current', isValid);
                scope.reportForm?.power?.hd2StarboardCurrent?.$setValidity('hd_starboard_current', isValid);
                return message
            }

            scope.enableHDStarboardCurrentTooltip = function() {
                return scope.getHDStarboardCurrentTooltip() != null;
            }

            // Flow Meters

            // hfo booster unit
            scope.$watchGroup(['report.power.booster_unit_hfo_cur_rh', 'report.power.booster_unit_hfo_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.booster_unit_hfo_diff_rh = newValues[0] - newValues[1];
                }
            });

            // gas oil booster unit
            scope.$watchGroup(['report.power.booster_unit_gas_oil_cur_rh', 'report.power.booster_unit_gas_oil_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.booster_unit_gas_oil_diff_rh = newValues[0] - newValues[1];
                }
            });

            // main engine
            scope.$watchGroup(['report.power.main_engine_flow_cur_rh', 'report.power.main_engine_flow_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.main_engine_flow_diff_rh = newValues[0] - newValues[1];
                }
            });
            // main engine inlet
            scope.$watchGroup(['report.power.main_engine_flow_inlet_cur_rh', 'report.power.main_engine_flow_inlet_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.main_engine_flow_inlet_diff_rh = newValues[0] - newValues[1];
                }
            });
            // main engine hfo
            scope.$watchGroup(['report.power.common_flow_hfo_cur_rh', 'report.power.common_flow_hfo_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.common_flow_hfo_diff_rh = newValues[0] - newValues[1];
                }
            });
            // main engine do
            scope.$watchGroup(['report.power.main_engine_flow_do_cur_rh', 'report.power.main_engine_flow_do_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.main_engine_flow_do_diff_rh = newValues[0] - newValues[1];
                }
            });
            // main engine outlet
            scope.$watchGroup(['report.power.main_engine_flow_outlet_cur_rh', 'report.power.main_engine_flow_outlet_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.main_engine_flow_outlet_diff_rh = newValues[0] - newValues[1];
                }
            });
            // main engine mgo
            scope.$watchGroup(['report.power.common_flow_mgo_cur_rh', 'report.power.common_flow_mgo_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.common_flow_mgo_diff_rh = newValues[0] - newValues[1];
                }
            });
            // main engine inlet hfo
            scope.$watchGroup(['report.power.main_engine_flow_inlet_hfo_cur_rh', 'report.power.main_engine_flow_inlet_hfo_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.main_engine_flow_inlet_hfo_diff_rh = newValues[0] - newValues[1];
                }
            });
            // main engine inlet mgo
            scope.$watchGroup(['report.power.main_engine_flow_inlet_mgo_cur_rh', 'report.power.main_engine_flow_inlet_mgo_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.main_engine_flow_inlet_mgo_diff_rh = newValues[0] - newValues[1];
                }
            });

            // aux engine
            scope.$watchGroup(['report.power.aux_engine_flow_cur_rh', 'report.power.aux_engine_flow_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.aux_engine_flow_diff_rh = newValues[0] - newValues[1];
                }
            });
            // aux engine inlet
            scope.$watchGroup(['report.power.aux_engine_flow_inlet_cur_rh', 'report.power.aux_engine_flow_inlet_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.aux_engine_flow_inlet_diff_rh = newValues[0] - newValues[1];
                }
            });
            // aux engine hfo
            scope.$watchGroup(['report.power.aux_engine_flow_hfo_cur_rh', 'report.power.aux_engine_flow_hfo_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.aux_engine_flow_hfo_diff_rh = newValues[0] - newValues[1];
                }
            });
            // aux engine do
            scope.$watchGroup(['report.power.aux_engine_flow_do_cur_rh', 'report.power.aux_engine_flow_do_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.aux_engine_flow_do_diff_rh = newValues[0] - newValues[1];
                }
            });
            // aux engine outlet
            scope.$watchGroup(['report.power.aux_engine_flow_outlet_cur_rh', 'report.power.aux_engine_flow_outlet_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.aux_engine_flow_outlet_diff_rh = newValues[0] - newValues[1];
                }
            });
            // aux engine inlet hfo
            scope.$watchGroup(['report.power.aux_engine_flow_inlet_hfo_cur_rh', 'report.power.aux_engine_flow_inlet_hfo_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.aux_engine_flow_inlet_hfo_diff_rh = newValues[0] - newValues[1];
                }
            });
            // aux engine inlet mgo
            scope.$watchGroup(['report.power.aux_engine_flow_inlet_mgo_cur_rh', 'report.power.aux_engine_flow_inlet_mgo_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.aux_engine_flow_inlet_mgo_diff_rh = newValues[0] - newValues[1];
                }
            });

            scope.$watchGroup(['report.power.aux_boiler_flow_cur_rh', 'report.power.aux_boiler_flow_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.aux_boiler_flow_diff_rh = newValues[0] - newValues[1];
                }
            });
            scope.$watchGroup(['report.power.aux_boiler_2_flow_cur_rh', 'report.power.aux_boiler_2_flow_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.aux_boiler_2_flow_diff_rh = newValues[0] - newValues[1];
                }
            });
            scope.$watchGroup(['report.power.cylinder_lube_oil_cur', 'report.power.cylinder_lube_oil_prev'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.cylinder_lube_oil_diff = newValues[0] - newValues[1];
                }
            });
            scope.$watchGroup(['report.power.aux_boiler_flow_inlet_cur_rh', 'report.power.aux_boiler_flow_inlet_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.aux_boiler_flow_inlet_diff_rh = newValues[0] - newValues[1];
                }
            });
            scope.$watchGroup(['report.power.aux_boiler_flow_outlet_cur_rh', 'report.power.aux_boiler_flow_outlet_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.aux_boiler_flow_outlet_diff_rh = newValues[0] - newValues[1];
                }
            });
            scope.$watchGroup(['report.power.aux_boiler_2_flow_inlet_cur_rh', 'report.power.aux_boiler_2_flow_inlet_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.aux_boiler_2_flow_inlet_diff_rh = newValues[0] - newValues[1];
                }
            });
            scope.$watchGroup(['report.power.aux_boiler_2_flow_outlet_cur_rh', 'report.power.aux_boiler_2_flow_outlet_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.aux_boiler_2_flow_outlet_diff_rh = newValues[0] - newValues[1];
                }
            });
            // composite boiler
            scope.$watchGroup(['report.power.composite_boiler_flow_cur_rh', 'report.power.composite_boiler_flow_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.composite_boiler_flow_diff_rh = newValues[0] - newValues[1];
                }
            });

            scope.$watchGroup(['report.power.fo_supply_port_flow_cur_rh', 'report.power.fo_supply_port_flow_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.fo_supply_port_flow_diff_rh = newValues[0] - newValues[1];
                }
            });

            scope.$watchGroup(['report.power.fo_supply_starboard_flow_cur_rh', 'report.power.fo_supply_starboard_flow_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.fo_supply_starboard_flow_diff_rh = newValues[0] - newValues[1];
                }
            });

            scope.$watchGroup(['report.power.hdg_flow_cur_rh', 'report.power.hdg_flow_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.hdg_flow_diff_rh = newValues[0] - newValues[1];
                }
            });

            scope.$watchGroup(['report.power.composite_boiler_1_flow_cur_rh', 'report.power.composite_boiler_1_flow_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.composite_boiler_1_flow_diff_rh = newValues[0] - newValues[1];
                }
            });

            scope.$watchGroup(['report.power.composite_boiler_2_flow_cur_rh', 'report.power.composite_boiler_2_flow_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.composite_boiler_2_flow_diff_rh = newValues[0] - newValues[1];
                }
            });


            scope.$watchGroup(['report.power.aux_engine_1_diff_rh',
                               'report.power.aux_engine_2_diff_rh',
                               'report.power.aux_engine_3_diff_rh',
                               'report.power.aux_engine_4_diff_rh',
                               'report.power.shaft_rh'], function(newValues, oldValues, scope) {
                   if (scope.report.power) {
                    scope.report.power.aux_engine_total_rh = roundToPlaces(sumValues(newValues),1);
                   }
            });

            scope.$watchGroup(['report.power.diesel_gen_1_diff_rh',
                               'report.power.diesel_gen_2_diff_rh',
                               'report.power.diesel_gen_3_diff_rh',
                               'report.power.diesel_gen_4_diff_rh',
                               'report.power.diesel_gen_5_diff_rh',
                               'report.power.diesel_gen_6_diff_rh'], function(newValues, oldValues, scope) {
                   if (scope.report.power) {
                    scope.report.power.diesel_gen_total_rh = roundToPlaces(sumValues(newValues),1);
                   }
            });

            // MDG Power Meter
            scope.$watchGroup(['report.power.mdg_power_meter_1_cur', 'report.power.mdg_power_meter_1_prev'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.mdg_power_meter_1_diff = newValues[0] - newValues[1];
                }
            });
            scope.$watchGroup(['report.power.mdg_power_meter_2_cur', 'report.power.mdg_power_meter_2_prev'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.mdg_power_meter_2_diff = newValues[0] - newValues[1];
                }
            });
            scope.$watchGroup(['report.power.mdg_power_meter_3_cur', 'report.power.mdg_power_meter_3_prev'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.mdg_power_meter_3_diff = newValues[0] - newValues[1];
                }
            });
            scope.$watchGroup(['report.power.mdg_power_meter_4_cur', 'report.power.mdg_power_meter_4_prev'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.mdg_power_meter_4_diff = newValues[0] - newValues[1];
                }
            });
            scope.$watchGroup(['report.power.mdg_power_meter_5_cur', 'report.power.mdg_power_meter_5_prev'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.mdg_power_meter_5_diff = newValues[0] - newValues[1];
                }
            });
            scope.$watchGroup(['report.power.mdg_power_meter_6_cur', 'report.power.mdg_power_meter_6_prev'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.mdg_power_meter_6_diff = newValues[0] - newValues[1];
                }
            });

            // MDG Power Meter auto calculate Diesel Generator Engine power
            scope.$watchGroup(['report.power.mdg_power_meter_1_diff','report.power.diesel_gen_1_diff_rh'], function(newValues) {
                const [ 
                    mdgPowerReading1Diff, 
                    dieselGen1DiffRh 
                ] = newValues;
                if (mdgPowerReading1Diff && dieselGen1DiffRh) {
                    scope.report.power.diesel_gen_1_power = Math.max(roundToPlaces(mdgPowerReading1Diff / dieselGen1DiffRh, 2), 0);
                }
            })
            scope.$watchGroup(['report.power.mdg_power_meter_2_diff','report.power.diesel_gen_2_diff_rh'], function(newValues) {
                const [ 
                    mdgPowerReading2Diff, 
                    dieselGen2DiffRh 
                ] = newValues;
                if (mdgPowerReading2Diff && dieselGen2DiffRh) {
                    scope.report.power.diesel_gen_2_power = Math.max(roundToPlaces(mdgPowerReading2Diff / dieselGen2DiffRh, 2), 0);
                }
            })
            scope.$watchGroup(['report.power.mdg_power_meter_3_diff','report.power.diesel_gen_3_diff_rh'], function(newValues) {
                const [ 
                    mdgPowerReading3Diff, 
                    dieselGen3DiffRh 
                ] = newValues;
                if (mdgPowerReading3Diff && dieselGen3DiffRh) {
                    scope.report.power.diesel_gen_3_power = Math.max(roundToPlaces(mdgPowerReading3Diff / dieselGen3DiffRh, 2), 0);
                }
            })
            scope.$watchGroup(['report.power.mdg_power_meter_4_diff','report.power.diesel_gen_4_diff_rh'], function(newValues) {
                const [ 
                    mdgPowerReading4Diff, 
                    dieselGen4DiffRh 
                ] = newValues;
                if (mdgPowerReading4Diff && dieselGen4DiffRh) {
                    scope.report.power.diesel_gen_4_power = Math.max(roundToPlaces(mdgPowerReading4Diff / dieselGen4DiffRh, 2), 0);
                }
            })
            scope.$watchGroup(['report.power.mdg_power_meter_5_diff','report.power.diesel_gen_5_diff_rh'], function(newValues) {
                const [ 
                    mdgPowerReading5Diff, 
                    dieselGen5DiffRh 
                ] = newValues;
                if (mdgPowerReading5Diff && dieselGen5DiffRh) {
                    scope.report.power.diesel_gen_5_power = Math.max(roundToPlaces(mdgPowerReading5Diff / dieselGen5DiffRh, 2), 0);
                }
            })
            scope.$watchGroup(['report.power.mdg_power_meter_6_diff','report.power.diesel_gen_6_diff_rh'], function(newValues) {
                const [ 
                    mdgPowerReading6Diff, 
                    dieselGen6DiffRh 
                ] = newValues;
                if (mdgPowerReading6Diff && dieselGen6DiffRh) {
                    scope.report.power.diesel_gen_6_power = Math.max(roundToPlaces(mdgPowerReading6Diff / dieselGen6DiffRh, 2), 0);
                }
            })

            // Main Air Comps 1 and 2
            scope.shouldRequireMainAirComps1and2 = function() {
                return scope.isGCCVessel();
            };

            scope.getRHMin = function(counterName) {
                if (scope.report && scope.report.power) return scope.report.power[`${counterName}_prev_rh`];
            };
            scope.getRHMax = function(counterName) {
                if (scope.report && scope.report.power && scope.report.operational && scope.report.power[`${counterName}_prev_rh`] > 0) {
                    return scope.report.power[`${counterName}_prev_rh`] + scope.report.operational.report_period + 2;
                } else {
                    return null;
                }
            };

            // Pumps
            scope.$watchGroup(['report.power.me_lo_pump_1_cur_rh', 'report.power.me_lo_pump_1_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.me_lo_pump_1_diff_rh = newValues[0] - newValues[1];
                }
            });
            scope.$watchGroup(['report.power.me_lo_pump_2_cur_rh', 'report.power.me_lo_pump_2_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.me_lo_pump_2_diff_rh = newValues[0] - newValues[1];
                }
            });
            scope.$watchGroup(['report.power.sea_water_pump_1_cur_rh', 'report.power.sea_water_pump_1_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.sea_water_pump_1_diff_rh = newValues[0] - newValues[1];
                }
            });
            scope.$watchGroup(['report.power.sea_water_pump_2_cur_rh', 'report.power.sea_water_pump_2_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.sea_water_pump_2_diff_rh = newValues[0] - newValues[1];
                }
            });
            scope.$watchGroup(['report.power.sg_pump_1_cur_rh', 'report.power.sg_pump_1_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.sg_pump_1_diff_rh = newValues[0] - newValues[1];
                }
            });
            scope.$watchGroup(['report.power.sg_pump_2_cur_rh', 'report.power.sg_pump_2_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.sg_pump_2_diff_rh = newValues[0] - newValues[1];
                }
            });
            scope.$watchGroup(['report.power.fo_purifier_1_cur_rh', 'report.power.fo_purifier_1_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.fo_purifier_1_diff_rh = newValues[0] - newValues[1];
                }
            });
            scope.$watchGroup(['report.power.fo_purifier_2_cur_rh', 'report.power.fo_purifier_2_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.fo_purifier_2_diff_rh = newValues[0] - newValues[1];
                }
            });
            scope.$watchGroup(['report.power.er_fan_1_cur_rh', 'report.power.er_fan_1_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.er_fan_1_diff_rh = newValues[0] - newValues[1];
                }
            });
            scope.$watchGroup(['report.power.er_fan_2_cur_rh', 'report.power.er_fan_2_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.er_fan_2_diff_rh = newValues[0] - newValues[1];
                }
            });
            scope.$watchGroup(['report.power.er_fan_3_cur_rh', 'report.power.er_fan_3_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.er_fan_3_diff_rh = newValues[0] - newValues[1];
                }
            });
            scope.$watchGroup(['report.power.er_fan_4_cur_rh', 'report.power.er_fan_4_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.er_fan_4_diff_rh = newValues[0] - newValues[1];
                }
            });

            // Dorian cargo compressors
            scope.$watchGroup(['report.power.cargo_compressor_1_cur_rh', 'report.power.cargo_compressor_1_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.cargo_compressor_1_diff_rh = newValues[0] - newValues[1];
                } else {
                    scope.report.power.cargo_compressor_1_diff_rh = 0;
                }
            });
            scope.$watchGroup(['report.power.cargo_compressor_2_cur_rh', 'report.power.cargo_compressor_2_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.cargo_compressor_2_diff_rh = newValues[0] - newValues[1];
                } else {
                    scope.report.power.cargo_compressor_2_diff_rh = 0;
                }
            });
            scope.$watchGroup(['report.power.cargo_compressor_3_cur_rh', 'report.power.cargo_compressor_3_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.cargo_compressor_3_diff_rh = newValues[0] - newValues[1];
                } else {
                    scope.report.power.cargo_compressor_3_diff_rh = 0;
                }
            });
            scope.$watchGroup(['report.power.cargo_compressor_4_cur_rh', 'report.power.cargo_compressor_4_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.cargo_compressor_4_diff_rh = newValues[0] - newValues[1];
                } else {
                    scope.report.power.cargo_compressor_4_diff_rh = 0;
                }
            });
            scope.$watchGroup(['report.power.cargo_pump_1_cur_rh', 'report.power.cargo_pump_1_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.cargo_pump_1_diff_rh = newValues[0] - newValues[1];
                } else {
                    scope.report.power.cargo_pump_1_diff_rh = 0;
                }
            });
            scope.$watchGroup(['report.power.cargo_pump_2_cur_rh', 'report.power.cargo_pump_2_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.cargo_pump_2_diff_rh = newValues[0] - newValues[1];
                } else {
                    scope.report.power.cargo_pump_2_diff_rh = 0;
                }
            });
            scope.$watchGroup(['report.power.cargo_pump_3_cur_rh', 'report.power.cargo_pump_3_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.cargo_pump_3_diff_rh = newValues[0] - newValues[1];
                } else {
                    scope.report.power.cargo_pump_3_diff_rh = 0;
                }
            });
            scope.$watchGroup(['report.power.cargo_pump_4_cur_rh', 'report.power.cargo_pump_4_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.cargo_pump_4_diff_rh = newValues[0] - newValues[1];
                } else {
                    scope.report.power.cargo_pump_4_diff_rh = 0;
                }
            });
            scope.$watchGroup(['report.power.cargo_pump_5_cur_rh', 'report.power.cargo_pump_5_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.cargo_pump_5_diff_rh = newValues[0] - newValues[1];
                } else {
                    scope.report.power.cargo_pump_5_diff_rh = 0;
                }
            });
            scope.$watchGroup(['report.power.cargo_pump_6_cur_rh', 'report.power.cargo_pump_6_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.cargo_pump_6_diff_rh = newValues[0] - newValues[1];
                } else {
                    scope.report.power.cargo_pump_6_diff_rh = 0;
                }
            });
            scope.$watchGroup(['report.power.cargo_pump_7_cur_rh', 'report.power.cargo_pump_7_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.cargo_pump_7_diff_rh = newValues[0] - newValues[1];
                } else {
                    scope.report.power.cargo_pump_7_diff_rh = 0;
                }
            });
            scope.$watchGroup(['report.power.cargo_pump_8_cur_rh', 'report.power.cargo_pump_8_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.cargo_pump_8_diff_rh = newValues[0] - newValues[1];
                } else {
                    scope.report.power.cargo_pump_8_diff_rh = 0;
                }
            });
            scope.$watchGroup(['report.power.ballast_pump_1_cur_rh', 'report.power.ballast_pump_1_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.ballast_pump_1_diff_rh = newValues[0] - newValues[1];
                } else {
                    scope.report.power.ballast_pump_1_diff_rh = 0;
                }
            });
            scope.$watchGroup(['report.power.ballast_pump_2_cur_rh', 'report.power.ballast_pump_2_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.ballast_pump_2_diff_rh = newValues[0] - newValues[1];
                } else {
                    scope.report.power.ballast_pump_2_diff_rh = 0;
                }
            });
            scope.$watchGroup(['report.power.bow_thruster_cur_rh', 'report.power.bow_thruster_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.bow_thruster_diff_rh = newValues[0] - newValues[1];
                } else {
                    scope.report.power.bow_thruster_diff_rh = 0;
                }
            });
            scope.$watchGroup(['report.power.stern_thruster_cur_rh', 'report.power.stern_thruster_prev_rh'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.stern_thruster_diff_rh = newValues[0] - newValues[1];
                } else {
                    scope.report.power.stern_thruster_diff_rh = 0;
                }
            });

            scope.$watchGroup(['report.power.cargo_compressor_1_cur_reading', 'report.power.cargo_compressor_1_prev_reading'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.cargo_compressor_1_diff_reading = newValues[0] - newValues[1];
                } else {
                    scope.report.power.cargo_compressor_1_diff_reading = 0;
                }
            });
            scope.$watchGroup(['report.power.cargo_compressor_2_cur_reading', 'report.power.cargo_compressor_2_prev_reading'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.cargo_compressor_2_diff_reading = newValues[0] - newValues[1];
                } else {
                    scope.report.power.cargo_compressor_2_diff_reading = 0;
                }
            });
            scope.$watchGroup(['report.power.cargo_compressor_3_cur_reading', 'report.power.cargo_compressor_3_prev_reading'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.cargo_compressor_3_diff_reading = newValues[0] - newValues[1];
                } else {
                    scope.report.power.cargo_compressor_3_diff_reading = 0;
                }
            });
            scope.$watchGroup(['report.power.cargo_compressor_4_cur_reading', 'report.power.cargo_compressor_4_prev_reading'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.cargo_compressor_4_diff_reading = newValues[0] - newValues[1];
                } else {
                    scope.report.power.cargo_compressor_4_diff_reading = 0;
                }
            });
            scope.$watchGroup(['report.power.other_cargo_cooling_cur_reading', 'report.power.other_cargo_cooling_prev_reading'], function(newValues) {
                if (newValues[0] != undefined && newValues[1] != undefined) {
                    scope.report.power.other_cargo_cooling_diff_reading = newValues[0] - newValues[1];
                } else {
                    scope.report.power.other_cargo_cooling_diff_reading = 0;
                }
            });
            scope.$watchGroup(['report.power.cargo_compressor_1_diff_reading', 'report.power.cargo_compressor_1_diff_rh'], function(newValues) {
                if (newValues[0] > 0 && newValues[1] == 0) {
                    scope.report.warningMessages['report.power.cargo_compressor_1_cur_rh'] = 'Running hours must be greater than ' + scope.report.power.cargo_compressor_1_prev_rh+ ' since the current kWh reading is greater than the previous.'
                }
                else scope.report.warningMessages['report.power.cargo_compressor_1_cur_rh'] = null;
                if (newValues[0] != undefined && newValues[1] != undefined && newValues[1] > 0) {
                    scope.report.power.cargo_compressor_1_power = newValues[0] / newValues[1];
                } else {
                    scope.report.power.cargo_compressor_1_power = 0;
                }
            });
            scope.$watchGroup(['report.power.cargo_compressor_2_diff_reading', 'report.power.cargo_compressor_2_diff_rh'], function(newValues) {
                if (newValues[0] > 0 && newValues[1] == 0) {
                    scope.report.warningMessages['report.power.cargo_compressor_2_cur_rh'] = 'Running hours must be greater than ' + scope.report.power.cargo_compressor_2_prev_rh+ ' since the current kWh reading is greater than the previous.'
                }
                else scope.report.warningMessages['report.power.cargo_compressor_2_cur_rh'] = null;
                if (newValues[0] != undefined && newValues[1] != undefined && newValues[1] > 0) {
                    scope.report.power.cargo_compressor_2_power = newValues[0] / newValues[1];
                } else {
                    scope.report.power.cargo_compressor_2_power = 0;
                }
            });
            scope.$watchGroup(['report.power.cargo_compressor_3_diff_reading', 'report.power.cargo_compressor_3_diff_rh'], function(newValues) {
                if (newValues[0] > 0 && newValues[1] == 0) {
                    scope.report.warningMessages['report.power.cargo_compressor_3_cur_rh'] = 'Running hours must be greater than ' + scope.report.power.cargo_compressor_3_prev_rh+ ' since the current kWh reading is greater than the previous.'
                }
                else scope.report.warningMessages['report.power.cargo_compressor_3_cur_rh'] = null;
                if (newValues[0] != undefined && newValues[1] != undefined && newValues[1] > 0) {
                    scope.report.power.cargo_compressor_3_power = newValues[0] / newValues[1];
                } else {
                    scope.report.power.cargo_compressor_3_power = 0;
                }
            });
            scope.$watchGroup(['report.power.cargo_compressor_4_diff_reading', 'report.power.cargo_compressor_4_diff_rh'], function(newValues) {
                if (newValues[0] > 0 && newValues[1] == 0) {
                    scope.report.warningMessages['report.power.cargo_compressor_4_cur_rh'] = 'Running hours must be greater than ' + scope.report.power.cargo_compressor_4_prev_rh+ ' since the current kWh reading is greater than the previous.'
                }
                else scope.report.warningMessages['report.power.cargo_compressor_4_cur_rh'] = null;
                if (newValues[0] != undefined && newValues[1] != undefined && newValues[1] > 0) {
                    scope.report.power.cargo_compressor_4_power = newValues[0] / newValues[1];
                } else {
                    scope.report.power.cargo_compressor_4_power = 0;
                }
            });
            scope.$watchGroup(['report.power.other_cargo_cooling_diff_reading', 'report.power.other_cargo_cooling_rh'], function(newValues) {
                if (newValues[0] > 0 && (newValues[1] == 0 || newValues[1] == undefined)) {
                    scope.report.warningMessages['report.power.other_cargo_cooling_rh'] = 'Running hours must be greater than 0 since the current kWh reading is greater than the previous.'
                }
                else scope.report.warningMessages['report.power.other_cargo_cooling_rh'] = null;
                if (newValues[0] != undefined && newValues[1] != undefined && newValues[1] > 0) {
                    scope.report.power.other_cargo_cooling_power = newValues[0] / newValues[1];
                } else {
                    scope.report.power.other_cargo_cooling_power = 0;
                }
            });

            scope.report.power.scrubber_in_use_radio_button = scope.report.power.scrubber_in_use ? 'yes' : 'no';

            scope.$watch('report.power.scrubber_in_use_radio_button', function(radioButtonValue) {
                scope.report.power.scrubber_in_use = radioButtonValue == 'yes';
            });

            scope.$watch('report.power.scrubber_in_use', function(scrubberInUse) {
                if (!scrubberInUse) {
                    scope.report.power.scrubber_mode = undefined;
                }
            });

            scope.report.power.gas_operation_radio_button = scope.report.power.gas_operation ? 'yes' : 'no';

            scope.$watch('report.power.gas_operation_radio_button', function(radioButtonValue) {
                scope.report.power.gas_operation = radioButtonValue == 'yes';
            });

            scope.$watchGroup(['report.operational.report_period', 'report.power.aux_engine_total_rh', 'report.power.shaft_power', 'report.power.shore_power_is_used','report.power.shaft_generator_pto_rh','report.power.shaft_motor_pti_rh', 'form'], function(newValues, oldValues, scope) {
                var reportPeriod = newValues[0];
                var shorePowerIsUsed = newValues[3];
                var ptoRH = newValues[4] || 0;
                var ptiRH = newValues[5] || 0;
                var auxEngineRH = newValues[1] || 0;
                var sumPtoPtiAuxRh = ptoRH+ptiRH+auxEngineRH;
                var totalAuxPtoPtiRhGreaterThanPeriod = sumPtoPtiAuxRh >= reportPeriod;
                var auxEngRhGreaterThanPeriod = (auxEngineRH > reportPeriod - 2);
                var isDieselElectricPropulsion = (scope.vessel_specifications?.information?.prime_mover_type=='diesel_electric_propulsion');
                var hasPTOorPTI = scope.vesselSpecs?.information?.has_pto || scope.vesselSpecs?.information?.has_pti;

                scope.auxPtoPtiRH = ptoRH + ptiRH + auxEngineRH;
                var machineryRHCounterEnabled = scope.showByDefault('power.machinery_rh_counter', 'sections');
                if (scope.reportForm.power.runningHours == undefined && machineryRHCounterEnabled) return;
                scope.reportForm.power.runningHours?.$setValidity('aux_hours', (shorePowerIsUsed || (auxEngRhGreaterThanPeriod || totalAuxPtoPtiRhGreaterThanPeriod)) || (scope.vessel_specifications.information.prime_mover_type=='diesel_electric_propulsion'));
                if (scope.reportForm.power.shaftGeneratorMotor) scope.reportForm.power.shaftGeneratorMotor?.$setValidity('pto_pti_hours', (
                    (shorePowerIsUsed || (auxEngRhGreaterThanPeriod || totalAuxPtoPtiRhGreaterThanPeriod) && hasPTOorPTI)
                    || isDieselElectricPropulsion
                    || (!scope.requireByDefault('shaftGeneratorMotor', 'sections') && !totalAuxPtoPtiRhGreaterThanPeriod))
                );
            });

            scope.mainFlowMeterHasFuelAndGas = function() {
                var main_flow_meter_install = scope.vesselSpecs?.main_flow_meter_installation?._cls;
                return mainFlowMeterHasFuelAndGas(main_flow_meter_install);
            }

            scope.boilerFlowMeterHasFuelAndGas = function() {
                var boiler_flow_meter_install = scope.vesselSpecs?.boiler_flow_meter_installation?._cls;
                return boilerFlowMeterHasFuelAndGas(boiler_flow_meter_install);
            }

            scope.autoPopulateMainFlowMeterTags = function() {
                var main_flow_meter_install = scope.vesselSpecs?.main_flow_meter_installation?._cls;
                return enableOnPremise && autoPopulateMainFlowMeterTags(main_flow_meter_install);
            }
            
            scope.autoPopulateBoilerFlowMeterTags = function() {
                var boiler_flow_meter_install = scope.vesselSpecs?.boiler_flow_meter_installation?._cls;
                return enableOnPremise && autoPopulateBoilerFlowMeterTags(boiler_flow_meter_install);
            }

            scope.$watchGroup(['report.power.main_engine_1_power', 'vessel_specifications.engine.me_engines[0].mcr'], function(newValues, oldValues, scope) {
                var power = newValues[0];

                if (power) {
                    var mcrPercent = 100;
                    if (power && newValues[1] != 0) {
                        mcrPercent = power * 100 / newValues[1];
                    }
                    mcrPercent = mcrPercent > 100 ? 100 : mcrPercent;
                    mcrPercent = mcrPercent < 0 ? 0 : mcrPercent;
                    scope.report.power.main_engine_1_load = mcrPercent;
                }
            });

            scope.$watchGroup(['report.power.main_engine_1_and_2_power', 'vessel_specifications.engine.me_engines[0].mcr'], function(newValues, oldValues, scope) {
                var power = newValues[0];

                if (power) {
                    var mcrPercent = 100;
                    if (power && newValues[1] != 0) {
                        mcrPercent = power * 100 / newValues[1];
                    }
                    mcrPercent = mcrPercent > 100 ? 100 : mcrPercent;
                    mcrPercent = mcrPercent < 0 ? 0 : mcrPercent;
                    scope.report.power.main_engine_12_load = mcrPercent;
                }
            });

            scope.editDualEnginePowerTag = function() {
                return enableOnPremise && scope.vessel_specifications.navbox_tags.find(tag => tag.tag_name === "derived_pwr_me1_me2");
            }
        }
    }
});

routerApp.directive('engineTab', ['$rootScope', '$timeout', 'ModelsApiService', function($rootScope, $timeout, ModelsApiService) {
    return {
        templateUrl: getCacheBustedTemplate('pages/vessel-reports/tabs/engine.html'),
        link: function(scope, element, attributes, control) {
            var setEngineModelValues = function(engineObject, data) {
                engineObject.air_cooler_pressure_drop_model = data.airCoolerPressureDrop;
                engineObject.air_filter_pressure_drop_model = data.airFilterPressureDrop;
                engineObject.exhaust_gas_temperature_model = data.exhaustGasTemperatureCylOut;
                engineObject.scavenge_air_temperature_model = data.scavengeAirTemperature;
                engineObject.scavenge_air_pressure_model = data.scavengingAirPressure;
                engineObject.tcrpm_model = data.tcRPM;
                engineObject.tcrpm_2_model = data.tcRPM2;
                engineObject.turbine_back_pressure_model = data.turbineBackPressure;
                engineObject.turbine_inlet_pressure_model = data.turbineInletPressure;
                engineObject.turbine_inlet_temperature_model = data.turbineInletTemperature;
                engineObject.fuel_pump_index_model = data.fuelPumpIndex;
                engineObject.air_cooler_pressure_drop_2_model = data.airCoolerPressureDrop2;
                engineObject.turbine_back_pressure_2_model = data.turbineBackPressure2;
                engineObject.air_filter_pressure_drop_2_model = data.airFilterPressureDrop2;
                engineObject.turbine_outlet_temperature_2_model = data.turbineOutletTemperature2;
                engineObject.blower_inlet_temperature_2_model = data.blowerInletTemperature2;
            }

            scope.shouldHideEngineAnalysis = function() {
                return scope.selectedVessel.dualEngine || (scope.role == 'ship_user' && scope.report.status != 'completed');
            }

            scope.$watchGroup(['report.power.main_engine_1_power', 'report.engine.main_engines[0].main_engine_instant_power', 'vessel_specifications.engine.me_engines[0].mcr'], function(newValues, oldValues, scope) {
                var power = newValues[0];
                if (newValues[1]) {
                    power = newValues[1];
                }

                if (power) {
                    var mcrPercent = 100;
                    if (power && newValues[2] != 0) {
                        mcrPercent = power * 100 / newValues[2];
                    }
                    mcrPercent = mcrPercent > 100 ? 100 : mcrPercent;
                    mcrPercent = mcrPercent < 0 ? 0 : mcrPercent;
                    scope.report.engine.main_engines[0].main_engine_load = mcrPercent;
                    // reduce network request for edge build
                    if (enableOnPremise) return;
                    ModelsApiService.getModelsForEngineLoad(mcrPercent).then(function (response, status, headers, config) {
                        var data = response.data;
                        setEngineModelValues(scope.report.engine.main_engines[0], data);
                    }, function(response, status, header, config) {
                        var data = response.data;
                        console.log('error fetching engine models data');
                        console.log(data);
                    });
                }
            });
            scope.$watchGroup(['report.power.main_engine_2_power', 'report.engine.main_engines[1].main_engine_instant_power', 'vessel_specifications.engine.me_engines[1].mcr'], function(newValues, oldValues, scope) {
                var power = newValues[0];
                if (newValues[1]) {
                    power = newValues[1];
                }

                if (power) {
                    var mcrPercent = 100;
                    if (power && newValues[2] != 0) {
                        mcrPercent = power * 100 / newValues[2];
                    }
                    scope.report.engine.main_engines[1].main_engine_load = mcrPercent;
                    // reduce network request for edge build
                    if (enableOnPremise) return;
                    ModelsApiService.getModelsForEngineLoad(mcrPercent).then(function (response, status, headers, config) {
                        var data = response.data;
                        setEngineModelValues(scope.report.engine.main_engines[1], data);
                    }, function(response, status, header, config) {
                        var data = response.data;
                        console.log('error fetching engine models data');
                        console.log(data);
                    });
                }
            });
            scope.$watchGroup(['report.engine.main_engines[0].air_cooler_pressure_drop_model', 'report.engine.main_engines[0].air_cooler_pressure_drop_reported'], function(newValues, oldValues, scope) {
                if (newValues[0]) {
                    scope.report.engine.main_engines[0].air_cooler_pressure_drop_deviation = (newValues[1] - newValues[0]) * 100 / newValues[0];
                } else {
                    scope.report.engine.main_engines[0].air_cooler_pressure_drop_deviation = null;
                }
            });
            scope.$watchGroup(['report.engine.main_engines[0].air_filter_pressure_drop_model', 'report.engine.main_engines[0].air_filter_pressure_drop_reported'], function(newValues, oldValues, scope) {
                if (newValues[0]) {
                    scope.report.engine.main_engines[0].air_filter_pressure_drop_deviation = (newValues[1] - newValues[0]) * 100 / newValues[0];
                } else {
                    scope.report.engine.main_engines[0].air_filter_pressure_drop_deviation = null;
                }
            });
            scope.$watchGroup(['report.engine.main_engines[0].exhaust_gas_temperature_model', 'report.engine.main_engines[0].exhaust_gas_temperature_reported'], function(newValues, oldValues, scope) {
                if (newValues[0]) {
                    scope.report.engine.main_engines[0].exhaust_gas_temperature_deviation = (newValues[1] - newValues[0]) * 100 / newValues[0];
                } else {
                    scope.report.engine.main_engines[0].exhaust_gas_temperature_deviation = null;
                }
            });
            scope.$watchGroup(['report.engine.main_engines[0].scavenge_air_temperature_model', 'report.engine.main_engines[0].scavenge_air_temperature_reported'], function(newValues, oldValues, scope) {
                if (newValues[0]) {
                    scope.report.engine.main_engines[0].scavenge_air_temperature_deviation = (newValues[1] - newValues[0]) * 100 / newValues[0];
                } else {
                    scope.report.engine.main_engines[0].scavenge_air_temperature_deviation = null;
                }
            });
            scope.$watchGroup(['report.engine.main_engines[0].scavenge_air_pressure_model', 'report.engine.main_engines[0].scavenge_air_pressure_reported'], function(newValues, oldValues, scope) {
                if (newValues[0]) {
                    scope.report.engine.main_engines[0].scavenge_air_pressure_deviation = (newValues[1] - newValues[0]) * 100 / newValues[0];
                } else {
                    scope.report.engine.main_engines[0].scavenge_air_pressure_deviation = null;
                }
            });
            scope.$watchGroup(['report.engine.main_engines[0].tcrpm_model', 'report.engine.main_engines[0].tcrpm_reported'], function(newValues, oldValues, scope) {
                if (newValues[0]) {
                    scope.report.engine.main_engines[0].tcrpm_deviation = (newValues[1] - newValues[0]) * 100 / newValues[0];
                } else {
                    scope.report.engine.main_engines[0].tcrpm_deviation = null;
                }
            });
            scope.$watchGroup(['report.engine.main_engines[0].turbine_back_pressure_model', 'report.engine.main_engines[0].turbine_back_pressure_reported'], function(newValues, oldValues, scope) {
                if (newValues[0]) {
                    scope.report.engine.main_engines[0].turbine_back_pressure_deviation = (newValues[1] - newValues[0]) * 100 / newValues[0];
                } else {
                    scope.report.engine.main_engines[0].turbine_back_pressure_deviation = null;
                }
            });
            scope.$watchGroup(['report.engine.main_engines[0].turbine_inlet_pressure_model', 'report.engine.main_engines[0].turbine_inlet_pressure_reported'], function(newValues, oldValues, scope) {
                if (newValues[0]) {
                    scope.report.engine.main_engines[0].turbine_inlet_pressure_deviation = (newValues[1] - newValues[0]) * 100 / newValues[0];
                } else {
                    scope.report.engine.main_engines[0].turbine_inlet_pressure_deviation = null;
                }
            });
            scope.$watchGroup(['report.engine.main_engines[0].turbine_inlet_temperature_model', 'report.engine.main_engines[0].turbine_inlet_temperature_reported'], function(newValues, oldValues, scope) {
                if (newValues[0]) {
                    scope.report.engine.main_engines[0].turbine_inlet_temperature_deviation = (newValues[1] - newValues[0]) * 100 / newValues[0];
                } else {
                    scope.report.engine.main_engines[0].turbine_inlet_temperature_deviation = null;
                }
            });
            scope.$watchGroup(['report.engine.main_engines[0].fuel_pump_index_model', 'report.engine.main_engines[0].fuel_pump_index_reported'], function(newValues, oldValues, scope) {
                if (newValues[0]) {
                    scope.report.engine.main_engines[0].fuel_pump_index_deviation = (newValues[1] - newValues[0]) * 100 / newValues[0];
                } else {
                    scope.report.engine.main_engines[0].fuel_pump_index_deviation = null;
                }
            });

            // TC/2
            scope.$watchGroup(['report.engine.main_engines[0].turbine_back_pressure_2_model', 'report.engine.main_engines[0].turbine_back_pressure_2_reported'], function(newValues, oldValues, scope) {
                if (newValues[0]) {
                    scope.report.engine.main_engines[0].turbine_back_pressure_2_deviation = (newValues[1] - newValues[0]) * 100 / newValues[0];
                } else {
                    scope.report.engine.main_engines[0].turbine_back_pressure_2_deviation = null;
                }
            });
            scope.$watchGroup(['report.engine.main_engines[0].air_filter_pressure_drop_2_model', 'report.engine.main_engines[0].air_filter_pressure_drop_2_reported'], function(newValues, oldValues, scope) {
                if (newValues[0]) {
                    scope.report.engine.main_engines[0].air_filter_pressure_drop_2_deviation = (newValues[1] - newValues[0]) * 100 / newValues[0];
                } else {
                    scope.report.engine.main_engines[0].air_filter_pressure_drop_2_deviation = null;
                }
            });
            scope.$watchGroup(['report.engine.main_engines[0].air_cooler_pressure_drop_2_model', 'report.engine.main_engines[0].air_cooler_pressure_drop_2_reported'], function(newValues, oldValues, scope) {
                if (newValues[0]) {
                    scope.report.engine.main_engines[0].air_cooler_pressure_drop_2_deviation = (newValues[1] - newValues[0]) * 100 / newValues[0];
                } else {
                    scope.report.engine.main_engines[0].air_cooler_pressure_drop_2_deviation = null;
                }
            });
            scope.$watchGroup(['report.engine.main_engines[0].tcrpm_2_model', 'report.engine.main_engines[0].tcrpm_2_reported'], function(newValues, oldValues, scope) {
                if (newValues[0]) {
                    scope.report.engine.main_engines[0].tcrpm_2_deviation = (newValues[1] - newValues[0]) * 100 / newValues[0];
                } else {
                    scope.report.engine.main_engines[0].tcrpm_2_deviation = null;
                }
            });
            scope.$watchGroup(['report.engine.main_engines[0].turbine_inlet_temperature_2_model', 'report.engine.main_engines[0].turbine_inlet_temperature_2_reported'], function(newValues, oldValues, scope) {
                if (newValues[0]) {
                    scope.report.engine.main_engines[0].turbine_inlet_temperature_2_deviation = (newValues[1] - newValues[0]) * 100 / newValues[0];
                } else {
                    scope.report.engine.main_engines[0].turbine_inlet_temperature_2_deviation = null;
                }
            });
            scope.$watchGroup(['report.engine.main_engines[0].turbine_outlet_temperature_2_model', 'report.engine.main_engines[0].turbine_outlet_temperature_2_reported'], function(newValues, oldValues, scope) {
                if (newValues[0]) {
                    scope.report.engine.main_engines[0].turbine_outlet_temperature_2_deviation = (newValues[1] - newValues[0]) * 100 / newValues[0];
                } else {
                    scope.report.engine.main_engines[0].turbine_outlet_temperature_2_deviation = null;
                }
            });

            scope.useCalculatedInstantaneousPower = function() {
                var hasKWHCounter = scope.vessel_specifications.information.has_torsionmeter;
                var hasTorqueMeter = scope.vessel_specifications.information.has_torque_meter;
                var hasPMISystem =  scope.vessel_specifications.information.has_pmi_system;
                return !hasKWHCounter && !hasTorqueMeter && !hasPMISystem;
            };

            scope.useInstantaneousRpm = function() {
                var useCalculatedInstantaneousPower = scope.useCalculatedInstantaneousPower();
                // this applies to Alba Tankers only for now
                var isAlbaTanker = scope.vessel_specifications.propeller.propeller_type == 'controllable' && !scope.selectedVessel.dualEngine;
                return useCalculatedInstantaneousPower && !isAlbaTanker;
            };

            scope.$watchGroup(['report.engine.main_engines[0].main_engine_instant_rpm', 'report.engine.main_engines[0].tcrpm_reported', 'report.engine.main_engines[0].tcrpm_2_reported'], function(newValues) {
                var merpm = newValues[0];
                if (scope.useCalculatedInstantaneousPower() && merpm != undefined) {
                    ModelsApiService.getMeRpmModel(merpm).then(function(response) {
                        var instantRPMPower = response.data.data.meRPMPower;
                        ModelsApiService.getTCRPMModel(newValues[1], newValues[2]).then(function(response) {
                            var instantTCPower = response.data.data.tcrpmPower;
                            if (instantRPMPower && instantTCPower) {
                                var mean = roundToPlaces([instantRPMPower, instantTCPower].mean(), 1);
                                scope.report.engine.main_engines[0].main_engine_instant_power = mean;
                            } else {
                                scope.report.engine.main_engines[0].main_engine_instant_power = roundToPlaces(instantRPMPower || instantTCPower, 1);
                            }
                        });
                    });
                }
            });

            scope.$watchGroup(['report.engine.main_engines[1].main_engine_instant_rpm', 'report.engine.main_engines[1].tcrpm_reported', 'report.engine.main_engines[1].tcrpm_2_reported'], function(newValues) {
                var merpm = newValues[0];
                if (scope.useCalculatedInstantaneousPower() && merpm != undefined) {
                    ModelsApiService.getMeRpmModel(merpm).then(function(response) {
                        var instantRPMPower = response.data.data.meRPMPower;
                        ModelsApiService.getTCRPMModel(newValues[1], newValues[2]).then(function(response) {
                            var instantTCPower = response.data.data.tcrpmPower;
                            if (instantRPMPower && instantTCPower) {
                                var mean = roundToPlaces([instantRPMPower, instantTCPower].mean(), 1);
                                scope.report.engine.main_engines[1].main_engine_instant_power = mean;
                            } else {
                                scope.report.engine.main_engines[1].main_engine_instant_power = roundToPlaces(instantRPMPower || instantTCPower, 1);
                            }
                        });
                    });
                }
            });

            scope.$watch('report.engine.main_engines[0].main_engine_instant_rpm', function(newValue, oldValue, scope) {
                if (scope.vessel_specifications && scope.vessel_specifications.engine) var max_rpm = scope.vessel_specifications.engine.me_engines[0].max_rpm;
                scope.maxInstantRpm0 = roundToPlaces(max_rpm * 1.1 || 200, 1)
                var invalid = !(newValue < scope.maxInstantRpm0) || newValue == undefined
                var message = 'Instantaneous RPM must be greater than 0 and less than 110% of vessel\'s max RPM (' + scope.maxInstantRpm0 + ')'
                scope.report.warningMessages['report.engine.main_engines[0].main_engine_instant_rpm'] = invalid ? message : null;
            })

            scope.$watch('report.engine.main_engines[1].main_engine_instant_rpm', function(newValue, oldValue, scope) {
                if (scope.vessel_specifications && scope.vessel_specifications.engine && scope.vessel_specifications.engine.me_engines.length>1) var max_rpm = scope.vessel_specifications.engine.me_engines[1].max_rpm;
                scope.maxInstantRpm1 = roundToPlaces(max_rpm * 1.1 || 200, 1)
                var invalid = !(newValue < scope.maxInstantRpm1)  || newValue == undefined
                var message = 'Instantaneous RPM must be greater than 0 and less than 110% of vessel\'s max RPM (' + scope.maxInstantRpm1 + ')'
                scope.report.warningMessages['report.engine.main_engines[1].main_engine_instant_rpm'] = invalid ? message : null;
            })
        }
    }
}]);

routerApp.directive('consumptionTab', function() {
    return {
        templateUrl: getCacheBustedTemplate('pages/vessel-reports/tabs/consumption.html'),
        link: function(scope, element, attributes, control) {
            if (!scope.reportForm.warningMessages) scope.reportForm.warningMessages = {};

            scope.report.consumption.new_fuel_configuration = true;

            scope.availableFuelGrades = [];
            var availableFuelGrades = []
            if (scope.vessel_specifications.emissions) {
                for (var key in fuelGrades) {
                    if (scope.vessel_specifications.emissions[key+'_enabled']) {
                        availableFuelGrades.push(key)
                    }
                }
            }

            if (availableFuelGrades.length == 0) {
                for (var key in fuelGrades) {
                    scope.availableFuelGrades.push({code: key, name: fuelGrades[key]})
                }
            }
            else {
                var cons_fuel_in_use = []
                for (var j=1; j<=3; j++) {
                    if (scope.report.consumption['fuel_type_'+j]) {
                        cons_fuel_in_use.push(scope.report.consumption['fuel_type_'+j])
                    }
                }
                availableFuelGrades = addUsedFuelstoListOfFuels(availableFuelGrades, cons_fuel_in_use)
                availableFuelGrades = addUsedFuelstoListOfFuels(availableFuelGrades, scope.report.computed?.enabled_fuel_types)

                for (var i=0; i<availableFuelGrades.length; i++) {
                    scope.availableFuelGrades[availableFuelGrades[i]] = availableFuelGrades[i].toUpperCase()
                }
            }

            scope.fuelProperties = fuelTypeProperties;

            // initialize values so that 'required' validation works on these fields
            scope.total_main_engine_consumption = 0;
            scope.total_aux_engine_consumption = 0;
            scope.total_mdg_port_consumption = 0;
            scope.total_mdg_starboard_consumption = 0;
            scope.total_hdg_consumption = 0;
            scope.total_dg_consumption = 0;
            scope.consumption_pristine = {}

            scope.$watchGroup(['report.consumption.cyl_oil_consumption', 'report.consumption.cyl_oil_density_at_15', 'report.consumption.cyl_oil_tank_temperature'], function(newValues, oldValues, scope) {
                var actualConsumptionLiters = newValues[0];
                var cloDensityAt15 = newValues[1];
                var cloTankTemp = newValues[2];
                if (actualConsumptionLiters != undefined && cloDensityAt15 != undefined && cloTankTemp != undefined) {
                    var actualConsKg = actualConsumptionLiters * cloDensityAt15 / 1000 * (1 - ((cloTankTemp - 15) * 0.00064))
                    scope.report.consumption.cyl_oil_kg_consumption = actualConsKg;
                } else {
                    scope.report.consumption.cyl_oil_kg_consumption = 0;
                }
            });

            scope.$watchGroup(['report.consumption.cyl_oil_kg_consumption', 'report.power.main_engine_1_power', 'report.power.main_engine_1_diff_rh', 'report.power.tcrpm_power', 'vessel_specifications.information.use_tcrpm_power'], function(newValues, oldValues, scope) {
                var cylOilKgCons = newValues[0];
                var reportedPower = newValues[1];
                var rh = newValues[2];
                var tcrpmPower = newValues[3];
                var useTcrpmPower = newValues[4];
                if (newValues[0] != undefined && newValues[1] != undefined && newValues[1] != 0 && newValues[2] != undefined && newValues[2] != 0) {
                    var power = useTcrpmPower ? tcrpmPower : reportedPower;
                    scope.report.consumption.cyl_oil_sloc = cylOilKgCons * 1000 / (power * rh);
                } else {
                    scope.report.consumption.cyl_oil_sloc = 0;
                }
            });


            var sumValues = function(newValues) {
                var sum = 0;
                for (var k = 0; k < newValues.length; k++) {
                    if (newValues[k] != undefined) {
                        sum += parseFloat(newValues[k]);
                    }
                }
                return sum;
            }
            // cargo consumptions total
            var hshfoCargo = [
                    "report.consumption.ig_generator_hshfo",
                    "report.consumption.ae_crane_operations_hshfo",
                    "report.consumption.ae_cdl_operations_hshfo",
                    "report.consumption.other_hshfo",
                    "report.consumption.ae_tank_cleaning_hshfo",
                    "report.consumption.ab_tank_cleaning_hshfo",
                    "report.consumption.ae_cargo_heating_hshfo",
                    "report.consumption.ab_cargo_heating_hshfo",
                    "report.consumption.ae_cargo_shifting_hshfo",
                    "report.consumption.ab_cargo_shifting_hshfo",
                    "report.consumption.ae_inert_gas_systems_hshfo",
                    "report.consumption.ab_inert_gas_systems_hshfo",
                    "report.consumption.ae_cargo_hold_washing_hshfo",
                    "report.consumption.ae_bunker_heating_hshfo",
                    "report.consumption.ab_bunker_heating_hshfo",
            ];
            var lshfoCargo = [
                    "report.consumption.ig_generator_lshfo",
                    "report.consumption.ae_crane_operations_lshfo",
                    "report.consumption.ae_cdl_operations_lshfo",
                    "report.consumption.other_lshfo",
                    "report.consumption.ae_tank_cleaning_lshfo",
                    "report.consumption.ab_tank_cleaning_lshfo",
                    "report.consumption.ae_cargo_heating_lshfo",
                    "report.consumption.ab_cargo_heating_lshfo",
                    "report.consumption.ae_cargo_shifting_lshfo",
                    "report.consumption.ab_cargo_shifting_lshfo",
                    "report.consumption.ae_inert_gas_systems_lshfo",
                    "report.consumption.ab_inert_gas_systems_lshfo",
                    "report.consumption.ae_cargo_hold_washing_lshfo",
                    "report.consumption.ae_bunker_heating_lshfo",
                    "report.consumption.ab_bunker_heating_lshfo",
            ];
            var ulsfoCargo = [
                    "report.consumption.ig_generator_ulsfo",
                    "report.consumption.ae_crane_operations_ulsfo",
                    "report.consumption.ae_cdl_operations_ulsfo",
                    "report.consumption.other_ulsfo",
                    "report.consumption.ae_tank_cleaning_ulsfo",
                    "report.consumption.ab_tank_cleaning_ulsfo",
                    "report.consumption.ae_cargo_heating_ulsfo",
                    "report.consumption.ab_cargo_heating_ulsfo",
                    "report.consumption.ae_cargo_shifting_ulsfo",
                    "report.consumption.ab_cargo_shifting_ulsfo",
                    "report.consumption.ae_inert_gas_systems_ulsfo",
                    "report.consumption.ab_inert_gas_systems_ulsfo",
                    "report.consumption.ae_cargo_hold_washing_ulsfo",
                    "report.consumption.ae_bunker_heating_ulsfo",
                    "report.consumption.ab_bunker_heating_ulsfo",
            ];
            var hsmdoCargo = [
                    "report.consumption.ig_generator_hsmdo",
                    "report.consumption.ae_crane_operations_hsmdo",
                    "report.consumption.ae_cdl_operations_hsmdo",
                    "report.consumption.other_hsmdo",
                    "report.consumption.ae_tank_cleaning_hsmdo",
                    "report.consumption.ab_tank_cleaning_hsmdo",
                    "report.consumption.ae_cargo_heating_hsmdo",
                    "report.consumption.ab_cargo_heating_hsmdo",
                    "report.consumption.ae_cargo_shifting_hsmdo",
                    "report.consumption.ab_cargo_shifting_hsmdo",
                    "report.consumption.ae_inert_gas_systems_hsmdo",
                    "report.consumption.ab_inert_gas_systems_hsmdo",
                    "report.consumption.ae_cargo_hold_washing_hsmdo",
                    "report.consumption.ae_bunker_heating_hsmdo",
                    "report.consumption.ab_bunker_heating_hsmdo",
            ];
            var lsmdoCargo = [
                    "report.consumption.ig_generator_lsmdo",
                    "report.consumption.ae_crane_operations_lsmdo",
                    "report.consumption.ae_cdl_operations_lsmdo",
                    "report.consumption.other_lsmdo",
                    "report.consumption.ae_tank_cleaning_lsmdo",
                    "report.consumption.ab_tank_cleaning_lsmdo",
                    "report.consumption.ae_cargo_heating_lsmdo",
                    "report.consumption.ab_cargo_heating_lsmdo",
                    "report.consumption.ae_cargo_shifting_lsmdo",
                    "report.consumption.ab_cargo_shifting_lsmdo",
                    "report.consumption.ae_inert_gas_systems_lsmdo",
                    "report.consumption.ab_inert_gas_systems_lsmdo",
                    "report.consumption.ae_cargo_hold_washing_lsmdo",
                    "report.consumption.ae_bunker_heating_lsmdo",
                    "report.consumption.ab_bunker_heating_lsmdo",
            ];
            var hsmgoCargo = [
                    "report.consumption.ig_generator_hsmgo",
                    "report.consumption.ae_crane_operations_hsmgo",
                    "report.consumption.ae_cdl_operations_hsmgo",
                    "report.consumption.other_hsmgo",
                    "report.consumption.ae_tank_cleaning_hsmgo",
                    "report.consumption.ab_tank_cleaning_hsmgo",
                    "report.consumption.ae_cargo_heating_hsmgo",
                    "report.consumption.ab_cargo_heating_hsmgo",
                    "report.consumption.ae_cargo_shifting_hsmgo",
                    "report.consumption.ab_cargo_shifting_hsmgo",
                    "report.consumption.ae_inert_gas_systems_hsmgo",
                    "report.consumption.ab_inert_gas_systems_hsmgo",
                    "report.consumption.ae_cargo_hold_washing_hsmgo",
                    "report.consumption.ae_bunker_heating_hsmgo",
                    "report.consumption.ab_bunker_heating_hsmgo",
            ];
            var lsmgoCargo = [
                    "report.consumption.ig_generator_lsmgo",
                    "report.consumption.ae_crane_operations_lsmgo",
                    "report.consumption.ae_cdl_operations_lsmgo",
                    "report.consumption.other_lsmgo",
                    "report.consumption.ae_tank_cleaning_lsmgo",
                    "report.consumption.ab_tank_cleaning_lsmgo",
                    "report.consumption.ae_cargo_heating_lsmgo",
                    "report.consumption.ab_cargo_heating_lsmgo",
                    "report.consumption.ae_cargo_shifting_lsmgo",
                    "report.consumption.ab_cargo_shifting_lsmgo",
                    "report.consumption.ae_inert_gas_systems_lsmgo",
                    "report.consumption.ab_inert_gas_systems_lsmgo",
                    "report.consumption.ae_cargo_hold_washing_lsmgo",
                    "report.consumption.ae_bunker_heating_lsmgo",
                    "report.consumption.ab_bunker_heating_lsmgo",
            ];

            scope.$watchGroup(hshfoCargo, function(newValues, oldValues, scope) {
                scope.report.consumption.cargo_related_hshfo = sumValues(newValues);
            });
            scope.$watchGroup(lshfoCargo, function(newValues, oldValues, scope) {
                scope.report.consumption.cargo_related_lshfo = sumValues(newValues);
            });
            scope.$watchGroup(ulsfoCargo, function(newValues, oldValues, scope) {
                scope.report.consumption.cargo_related_ulsfo = sumValues(newValues);
            });
            scope.$watchGroup(hsmdoCargo, function(newValues, oldValues, scope) {
                scope.report.consumption.cargo_related_hsmdo = sumValues(newValues);
            });
            scope.$watchGroup(lsmdoCargo, function(newValues, oldValues, scope) {
                scope.report.consumption.cargo_related_lsmdo = sumValues(newValues);
            });
            scope.$watchGroup(hsmgoCargo, function(newValues, oldValues, scope) {
                scope.report.consumption.cargo_related_hsmgo = sumValues(newValues);
            });
            scope.$watchGroup(lsmgoCargo, function(newValues, oldValues, scope) {
                scope.report.consumption.cargo_related_lsmgo = sumValues(newValues);
            });

            var hfoCargo = [
                    "report.consumption.ig_generator_hfo",
                    "report.consumption.ae_crane_operations_hfo",
                    "report.consumption.ae_cdl_operations_hfo",
                    "report.consumption.other_hfo",
                    "report.consumption.ae_tank_cleaning_hfo",
                    "report.consumption.ab_tank_cleaning_hfo",
                    "report.consumption.ae_cargo_heating_hfo",
                    "report.consumption.ab_cargo_heating_hfo",
                    "report.consumption.ae_cargo_shifting_hfo",
                    "report.consumption.ab_cargo_shifting_hfo",
                    "report.consumption.ae_inert_gas_systems_hfo",
                    "report.consumption.ab_inert_gas_systems_hfo",
                    "report.consumption.ae_cargo_hold_washing_hfo",
            ];
            var lfoCargo = [
                    "report.consumption.ig_generator_lfo",
                    "report.consumption.ae_crane_operations_lfo",
                    "report.consumption.ae_cdl_operations_lfo",
                    "report.consumption.other_lfo",
                    "report.consumption.ae_tank_cleaning_lfo",
                    "report.consumption.ab_tank_cleaning_lfo",
                    "report.consumption.ae_cargo_heating_lfo",
                    "report.consumption.ab_cargo_heating_lfo",
                    "report.consumption.ae_cargo_shifting_lfo",
                    "report.consumption.ab_cargo_shifting_lfo",
                    "report.consumption.ae_inert_gas_systems_lfo",
                    "report.consumption.ab_inert_gas_systems_lfo",
                    "report.consumption.ae_cargo_hold_washing_lfo",
            ];
            var mgoCargo = [
                    "report.consumption.ig_generator_mgo",
                    "report.consumption.ae_crane_operations_mgo",
                    "report.consumption.ae_cdl_operations_mgo",
                    "report.consumption.other_mgo",
                    "report.consumption.ae_tank_cleaning_mgo",
                    "report.consumption.ab_tank_cleaning_mgo",
                    "report.consumption.ae_cargo_heating_mgo",
                    "report.consumption.ab_cargo_heating_mgo",
                    "report.consumption.ae_cargo_shifting_mgo",
                    "report.consumption.ab_cargo_shifting_mgo",
                    "report.consumption.ae_inert_gas_systems_mgo",
                    "report.consumption.ab_inert_gas_systems_mgo",
                    "report.consumption.ae_cargo_hold_washing_mgo",
            ];
            var mdoCargo = [
                    "report.consumption.ig_generator_mdo",
                    "report.consumption.ae_crane_operations_mdo",
                    "report.consumption.ae_cdl_operations_mdo",
                    "report.consumption.other_mdo",
                    "report.consumption.ae_tank_cleaning_mdo",
                    "report.consumption.ab_tank_cleaning_mdo",
                    "report.consumption.ae_cargo_heating_mdo",
                    "report.consumption.ab_cargo_heating_mdo",
                    "report.consumption.ae_cargo_shifting_mdo",
                    "report.consumption.ab_cargo_shifting_mdo",
                    "report.consumption.ae_inert_gas_systems_mdo",
                    "report.consumption.ab_inert_gas_systems_mdo",
                    "report.consumption.ae_cargo_hold_washing_mdo",
            ];
            var b10lfoCargo = [
                    "report.consumption.ig_generator_b10lfo",
                    "report.consumption.ae_crane_operations_b10lfo",
                    "report.consumption.ae_cdl_operations_b10lfo",
                    "report.consumption.other_b10lfo",
                    "report.consumption.ae_tank_cleaning_b10lfo",
                    "report.consumption.ab_tank_cleaning_b10lfo",
                    "report.consumption.ae_cargo_heating_b10lfo",
                    "report.consumption.ab_cargo_heating_b10lfo",
                    "report.consumption.ae_cargo_shifting_b10lfo",
                    "report.consumption.ab_cargo_shifting_b10lfo",
                    "report.consumption.ae_inert_gas_systems_b10lfo",
                    "report.consumption.ab_inert_gas_systems_b10lfo",
                    "report.consumption.ae_cargo_hold_washing_b10lfo",
            ];
            var b10mgoCargo = [
                    "report.consumption.ig_generator_b10mgo",
                    "report.consumption.ae_crane_operations_b10mgo",
                    "report.consumption.ae_cdl_operations_b10mgo",
                    "report.consumption.other_b10mgo",
                    "report.consumption.ae_tank_cleaning_b10mgo",
                    "report.consumption.ab_tank_cleaning_b10mgo",
                    "report.consumption.ae_cargo_heating_b10mgo",
                    "report.consumption.ab_cargo_heating_b10mgo",
                    "report.consumption.ae_cargo_shifting_b10mgo",
                    "report.consumption.ab_cargo_shifting_b10mgo",
                    "report.consumption.ae_inert_gas_systems_b10mgo",
                    "report.consumption.ab_inert_gas_systems_b10mgo",
                    "report.consumption.ae_cargo_hold_washing_b10mgo",
            ];
            var biolfoCargo = [
                    "report.consumption.ig_generator_biolfo",
                    "report.consumption.ae_crane_operations_biolfo",
                    "report.consumption.ae_cdl_operations_biolfo",
                    "report.consumption.other_biolfo",
                    "report.consumption.ae_tank_cleaning_biolfo",
                    "report.consumption.ab_tank_cleaning_biolfo",
                    "report.consumption.ae_cargo_heating_biolfo",
                    "report.consumption.ab_cargo_heating_biolfo",
                    "report.consumption.ae_cargo_shifting_biolfo",
                    "report.consumption.ab_cargo_shifting_biolfo",
                    "report.consumption.ae_inert_gas_systems_biolfo",
                    "report.consumption.ab_inert_gas_systems_biolfo",
                    "report.consumption.ae_cargo_hold_washing_biolfo",
            ];
            var biomgoCargo = [
                    "report.consumption.ig_generator_biomgo",
                    "report.consumption.ae_crane_operations_biomgo",
                    "report.consumption.ae_cdl_operations_biomgo",
                    "report.consumption.other_biomgo",
                    "report.consumption.ae_tank_cleaning_biomgo",
                    "report.consumption.ab_tank_cleaning_biomgo",
                    "report.consumption.ae_cargo_heating_biomgo",
                    "report.consumption.ab_cargo_heating_biomgo",
                    "report.consumption.ae_cargo_shifting_biomgo",
                    "report.consumption.ab_cargo_shifting_biomgo",
                    "report.consumption.ae_inert_gas_systems_biomgo",
                    "report.consumption.ab_inert_gas_systems_biomgo",
                    "report.consumption.ae_cargo_hold_washing_biomgo",
            ];
            var ulsfo2020Cargo = [
                    "report.consumption.ig_generator_ulsfo2020",
                    "report.consumption.ae_crane_operations_ulsfo2020",
                    "report.consumption.ae_cdl_operations_ulsfo2020",
                    "report.consumption.other_ulsfo2020",
                    "report.consumption.ae_tank_cleaning_ulsfo2020",
                    "report.consumption.ab_tank_cleaning_ulsfo2020",
                    "report.consumption.ae_cargo_heating_ulsfo2020",
                    "report.consumption.ab_cargo_heating_ulsfo2020",
                    "report.consumption.ae_cargo_shifting_ulsfo2020",
                    "report.consumption.ab_cargo_shifting_ulsfo2020",
                    "report.consumption.ae_inert_gas_systems_ulsfo2020",
                    "report.consumption.ab_inert_gas_systems_ulsfo2020",
                    "report.consumption.ae_cargo_hold_washing_ulsfo2020",
            ];
            var ulslfo2020Cargo = [
                    "report.consumption.ig_generator_ulslfo2020",
                    "report.consumption.ae_crane_operations_ulslfo2020",
                    "report.consumption.ae_cdl_operations_ulslfo2020",
                    "report.consumption.other_ulslfo2020",
                    "report.consumption.ae_tank_cleaning_ulslfo2020",
                    "report.consumption.ab_tank_cleaning_ulslfo2020",
                    "report.consumption.ae_cargo_heating_ulslfo2020",
                    "report.consumption.ab_cargo_heating_ulslfo2020",
                    "report.consumption.ae_cargo_shifting_ulslfo2020",
                    "report.consumption.ab_cargo_shifting_ulslfo2020",
                    "report.consumption.ae_inert_gas_systems_ulslfo2020",
                    "report.consumption.ab_inert_gas_systems_ulslfo2020",
                    "report.consumption.ae_cargo_hold_washing_ulslfo2020",
            ];
            var ulsmdo2020Cargo = [
                    "report.consumption.ig_generator_ulsmdo2020",
                    "report.consumption.ae_crane_operations_ulsmdo2020",
                    "report.consumption.ae_cdl_operations_ulsmdo2020",
                    "report.consumption.other_ulsmdo2020",
                    "report.consumption.ae_tank_cleaning_ulsmdo2020",
                    "report.consumption.ab_tank_cleaning_ulsmdo2020",
                    "report.consumption.ae_cargo_heating_ulsmdo2020",
                    "report.consumption.ab_cargo_heating_ulsmdo2020",
                    "report.consumption.ae_cargo_shifting_ulsmdo2020",
                    "report.consumption.ab_cargo_shifting_ulsmdo2020",
                    "report.consumption.ae_inert_gas_systems_ulsmdo2020",
                    "report.consumption.ab_inert_gas_systems_ulsmdo2020",
                    "report.consumption.ae_cargo_hold_washing_ulsmdo2020",
            ];
            var ulsmgo2020Cargo = [
                    "report.consumption.ig_generator_ulsmgo2020",
                    "report.consumption.ae_crane_operations_ulsmgo2020",
                    "report.consumption.ae_cdl_operations_ulsmgo2020",
                    "report.consumption.other_ulsmgo2020",
                    "report.consumption.ae_tank_cleaning_ulsmgo2020",
                    "report.consumption.ab_tank_cleaning_ulsmgo2020",
                    "report.consumption.ae_cargo_heating_ulsmgo2020",
                    "report.consumption.ab_cargo_heating_ulsmgo2020",
                    "report.consumption.ae_cargo_shifting_ulsmgo2020",
                    "report.consumption.ab_cargo_shifting_ulsmgo2020",
                    "report.consumption.ae_inert_gas_systems_ulsmgo2020",
                    "report.consumption.ab_inert_gas_systems_ulsmgo2020",
                    "report.consumption.ae_cargo_hold_washing_ulsmgo2020",
            ];
            var vlsfo2020Cargo = [
                    "report.consumption.ig_generator_vlsfo2020",
                    "report.consumption.ae_crane_operations_vlsfo2020",
                    "report.consumption.ae_cdl_operations_vlsfo2020",
                    "report.consumption.other_vlsfo2020",
                    "report.consumption.ae_tank_cleaning_vlsfo2020",
                    "report.consumption.ab_tank_cleaning_vlsfo2020",
                    "report.consumption.ae_cargo_heating_vlsfo2020",
                    "report.consumption.ab_cargo_heating_vlsfo2020",
                    "report.consumption.ae_cargo_shifting_vlsfo2020",
                    "report.consumption.ab_cargo_shifting_vlsfo2020",
                    "report.consumption.ae_inert_gas_systems_vlsfo2020",
                    "report.consumption.ab_inert_gas_systems_vlsfo2020",
                    "report.consumption.ae_cargo_hold_washing_vlsfo2020",
            ];
            var vlslfo2020Cargo = [
                    "report.consumption.ig_generator_vlslfo2020",
                    "report.consumption.ae_crane_operations_vlslfo2020",
                    "report.consumption.ae_cdl_operations_vlslfo2020",
                    "report.consumption.other_vlslfo2020",
                    "report.consumption.ae_tank_cleaning_vlslfo2020",
                    "report.consumption.ab_tank_cleaning_vlslfo2020",
                    "report.consumption.ae_cargo_heating_vlslfo2020",
                    "report.consumption.ab_cargo_heating_vlslfo2020",
                    "report.consumption.ae_cargo_shifting_vlslfo2020",
                    "report.consumption.ab_cargo_shifting_vlslfo2020",
                    "report.consumption.ae_inert_gas_systems_vlslfo2020",
                    "report.consumption.ab_inert_gas_systems_vlslfo2020",
                    "report.consumption.ae_cargo_hold_washing_vlslfo2020",
            ];
            var lpgpCargo = [
                    "report.consumption.ig_generator_lpgp",
                    "report.consumption.ae_crane_operations_lpgp",
                    "report.consumption.ae_cdl_operations_lpgp",
                    "report.consumption.other_lpgp",
                    "report.consumption.ae_tank_cleaning_lpgp",
                    "report.consumption.ab_tank_cleaning_lpgp",
                    "report.consumption.ae_cargo_heating_lpgp",
                    "report.consumption.ab_cargo_heating_lpgp",
                    "report.consumption.ae_cargo_shifting_lpgp",
                    "report.consumption.ab_cargo_shifting_lpgp",
                    "report.consumption.ae_inert_gas_systems_lpgp",
                    "report.consumption.ab_inert_gas_systems_lpgp",
                    "report.consumption.ae_cargo_hold_washing_lpgp",
            ];
            var lpgbCargo = [
                    "report.consumption.ig_generator_lpgb",
                    "report.consumption.ae_crane_operations_lpgb",
                    "report.consumption.ae_cdl_operations_lpgb",
                    "report.consumption.other_lpgb",
                    "report.consumption.ae_tank_cleaning_lpgb",
                    "report.consumption.ab_tank_cleaning_lpgb",
                    "report.consumption.ae_cargo_heating_lpgb",
                    "report.consumption.ab_cargo_heating_lpgb",
                    "report.consumption.ae_cargo_shifting_lpgb",
                    "report.consumption.ab_cargo_shifting_lpgb",
                    "report.consumption.ae_inert_gas_systems_lpgb",
                    "report.consumption.ab_inert_gas_systems_lpgb",
                    "report.consumption.ae_cargo_hold_washing_lpgb",
            ];
            var lngCargo = [
                    "report.consumption.ig_generator_lng",
                    "report.consumption.ae_crane_operations_lng",
                    "report.consumption.ae_cdl_operations_lng",
                    "report.consumption.other_lng",
                    "report.consumption.ae_tank_cleaning_lng",
                    "report.consumption.ab_tank_cleaning_lng",
                    "report.consumption.ae_cargo_heating_lng",
                    "report.consumption.ab_cargo_heating_lng",
                    "report.consumption.ae_cargo_shifting_lng",
                    "report.consumption.ab_cargo_shifting_lng",
                    "report.consumption.ae_inert_gas_systems_lng",
                    "report.consumption.ab_inert_gas_systems_lng",
                    "report.consumption.ae_cargo_hold_washing_lng",
            ];
            var methanolCargo = [
                    "report.consumption.ig_generator_methanol",
                    "report.consumption.ae_crane_operations_methanol",
                    "report.consumption.ae_cdl_operations_methanol",
                    "report.consumption.other_methanol",
                    "report.consumption.ae_tank_cleaning_methanol",
                    "report.consumption.ab_tank_cleaning_methanol",
                    "report.consumption.ae_cargo_heating_methanol",
                    "report.consumption.ab_cargo_heating_methanol",
                    "report.consumption.ae_cargo_shifting_methanol",
                    "report.consumption.ab_cargo_shifting_methanol",
                    "report.consumption.ae_inert_gas_systems_methanol",
                    "report.consumption.ab_inert_gas_systems_methanol",
                    "report.consumption.ae_cargo_hold_washing_methanol",
            ];
            var ethanolCargo = [
                    "report.consumption.ig_generator_ethanol",
                    "report.consumption.ae_crane_operations_ethanol",
                    "report.consumption.ae_cdl_operations_ethanol",
                    "report.consumption.other_ethanol",
                    "report.consumption.ae_tank_cleaning_ethanol",
                    "report.consumption.ab_tank_cleaning_ethanol",
                    "report.consumption.ae_cargo_heating_ethanol",
                    "report.consumption.ab_cargo_heating_ethanol",
                    "report.consumption.ae_cargo_shifting_ethanol",
                    "report.consumption.ab_cargo_shifting_ethanol",
                    "report.consumption.ae_inert_gas_systems_ethanol",
                    "report.consumption.ab_inert_gas_systems_ethanol",
                    "report.consumption.ae_cargo_hold_washing_ethanol",
            ];
            var otherCargo = [
                    "report.consumption.ig_generator_other",
                    "report.consumption.ae_crane_operations_other",
                    "report.consumption.ae_cdl_operations_other",
                    "report.consumption.other_other",
                    "report.consumption.ae_tank_cleaning_other",
                    "report.consumption.ab_tank_cleaning_other",
                    "report.consumption.ae_cargo_heating_other",
                    "report.consumption.ab_cargo_heating_other",
                    "report.consumption.ae_cargo_shifting_other",
                    "report.consumption.ab_cargo_shifting_other",
                    "report.consumption.ae_inert_gas_systems_other",
                    "report.consumption.ab_inert_gas_systems_other",
                    "report.consumption.ae_cargo_hold_washing_other",
            ];

            scope.$watchGroup(hfoCargo, function(newValues, oldValues, scope) {
                scope.report.consumption.cargo_related_hfo = sumValues(newValues);
            });
            scope.$watchGroup(lfoCargo, function(newValues, oldValues, scope) {
                scope.report.consumption.cargo_related_lfo = sumValues(newValues);
            });
            scope.$watchGroup(mgoCargo, function(newValues, oldValues, scope) {
                scope.report.consumption.cargo_related_mgo = sumValues(newValues);
            });
            scope.$watchGroup(mdoCargo, function(newValues, oldValues, scope) {
                scope.report.consumption.cargo_related_mdo = sumValues(newValues);
            });
            scope.$watchGroup(b10lfoCargo, function(newValues, oldValues, scope) {
                scope.report.consumption.cargo_related_b10lfo = sumValues(newValues);
            });
            scope.$watchGroup(b10mgoCargo, function(newValues, oldValues, scope) {
                scope.report.consumption.cargo_related_b10mgo = sumValues(newValues);
            });
            scope.$watchGroup(biolfoCargo, function(newValues, oldValues, scope) {
                scope.report.consumption.cargo_related_biolfo = sumValues(newValues);
            });
            scope.$watchGroup(biomgoCargo, function(newValues, oldValues, scope) {
                scope.report.consumption.cargo_related_biomgo = sumValues(newValues);
            });
            scope.$watchGroup(ulsfo2020Cargo, function(newValues, oldValues, scope) {
                scope.report.consumption.cargo_related_ulsfo2020 = sumValues(newValues);
            });
            scope.$watchGroup(ulslfo2020Cargo, function(newValues, oldValues, scope) {
                scope.report.consumption.cargo_related_ulslfo2020 = sumValues(newValues);
            });
            scope.$watchGroup(ulsmdo2020Cargo, function(newValues, oldValues, scope) {
                scope.report.consumption.cargo_related_ulsmdo2020 = sumValues(newValues);
            });
            scope.$watchGroup(ulsmgo2020Cargo, function(newValues, oldValues, scope) {
                scope.report.consumption.cargo_related_ulsmgo2020 = sumValues(newValues);
            });
            scope.$watchGroup(vlsfo2020Cargo, function(newValues, oldValues, scope) {
                scope.report.consumption.cargo_related_vlsfo2020 = sumValues(newValues);
            });
            scope.$watchGroup(vlslfo2020Cargo, function(newValues, oldValues, scope) {
                scope.report.consumption.cargo_related_vlslfo2020 = sumValues(newValues);
            });
            scope.$watchGroup(lpgpCargo, function(newValues, oldValues, scope) {
                scope.report.consumption.cargo_related_lpgp = sumValues(newValues);
            });
            scope.$watchGroup(lpgbCargo, function(newValues, oldValues, scope) {
                scope.report.consumption.cargo_related_lpgb = sumValues(newValues);
            });
            scope.$watchGroup(lngCargo, function(newValues, oldValues, scope) {
                scope.report.consumption.cargo_related_lng = sumValues(newValues);
            });
            scope.$watchGroup(methanolCargo, function(newValues, oldValues, scope) {
                scope.report.consumption.cargo_related_methanol = sumValues(newValues);
            });
            scope.$watchGroup(ethanolCargo, function(newValues, oldValues, scope) {
                scope.report.consumption.cargo_related_ethanol = sumValues(newValues);
            });
            scope.$watchGroup(otherCargo, function(newValues, oldValues, scope) {
                scope.report.consumption.cargo_related_other = sumValues(newValues);
            });

            var hshfoConsumptions = ["report.consumption.main_engine_hshfo", "report.consumption.aux_engine_hshfo", "report.consumption.mdg_port_hshfo", "report.consumption.mdg_starboard_hshfo", "report.consumption.hdg_hshfo", "report.consumption.dg_hshfo", "report.consumption.aux_boiler_hshfo", "report.consumption.ig_generator_hshfo", "report.consumption.other_hshfo", "report.consumption.incinerator_hshfo"];
            var lshfoConsumptions = ["report.consumption.main_engine_lshfo", "report.consumption.aux_engine_lshfo", "report.consumption.mdg_port_lshfo", "report.consumption.mdg_starboard_lshfo", "report.consumption.hdg_lshfo", "report.consumption.dg_lshfo", "report.consumption.aux_boiler_lshfo", "report.consumption.ig_generator_lshfo", "report.consumption.other_lshfo", "report.consumption.incinerator_lshfo"];
            var ulsfoConsumptions = ["report.consumption.main_engine_ulsfo", "report.consumption.aux_engine_ulsfo", "report.consumption.mdg_port_ulsfo", "report.consumption.mdg_starboard_ulsfo", "report.consumption.hdg_ulsfo", "report.consumption.dg_ulsfo", "report.consumption.aux_boiler_ulsfo", "report.consumption.ig_generator_ulsfo", "report.consumption.other_ulsfo", "report.consumption.incinerator_ulsfo"];
            var hsmdoConsumptions = ["report.consumption.main_engine_hsmdo", "report.consumption.aux_engine_hsmdo", "report.consumption.mdg_port_hsmdo", "report.consumption.mdg_starboard_hsmdo", "report.consumption.hdg_hsmdo", "report.consumption.dg_hsmdo", "report.consumption.aux_boiler_hsmdo", "report.consumption.ig_generator_hsmdo", "report.consumption.other_hsmdo", "report.consumption.incinerator_hsmdo"];
            var lsmdoConsumptions = ["report.consumption.main_engine_lsmdo", "report.consumption.aux_engine_lsmdo", "report.consumption.mdg_port_lsmdo", "report.consumption.mdg_starboard_lsmdo", "report.consumption.hdg_lsmdo", "report.consumption.dg_lsmdo", "report.consumption.aux_boiler_lsmdo", "report.consumption.ig_generator_lsmdo", "report.consumption.other_lsmdo", "report.consumption.incinerator_lsmdo"];
            var hsmgoConsumptions = ["report.consumption.main_engine_hsmgo", "report.consumption.aux_engine_hsmgo", "report.consumption.mdg_port_hsmgo", "report.consumption.mdg_starboard_hsmgo", "report.consumption.hdg_hsmgo", "report.consumption.dg_hsmgo", "report.consumption.aux_boiler_hsmgo", "report.consumption.ig_generator_hsmgo", "report.consumption.other_hsmgo", "report.consumption.incinerator_hsmgo"];
            var lsmgoConsumptions = ["report.consumption.main_engine_lsmgo", "report.consumption.aux_engine_lsmgo", "report.consumption.mdg_port_lsmgo", "report.consumption.mdg_starboard_lsmgo", "report.consumption.hdg_lsmgo", "report.consumption.dg_lsmgo", "report.consumption.aux_boiler_lsmgo", "report.consumption.ig_generator_lsmgo", "report.consumption.other_lsmgo", "report.consumption.incinerator_lsmgo"];

            scope.$watchGroup(hshfoConsumptions, function(newValues, oldValues, scope) {
                scope.report.consumption.total_consumption_hshfo = sumValues(newValues);
            });
            scope.$watchGroup(lshfoConsumptions, function(newValues, oldValues, scope) {
                scope.report.consumption.total_consumption_lshfo = sumValues(newValues);
            });
            scope.$watchGroup(ulsfoConsumptions, function(newValues, oldValues, scope) {
                scope.report.consumption.total_consumption_ulsfo = sumValues(newValues);
            });
            scope.$watchGroup(hsmdoConsumptions, function(newValues, oldValues, scope) {
                scope.report.consumption.total_consumption_hsmdo = sumValues(newValues);
            });
            scope.$watchGroup(lsmdoConsumptions, function(newValues, oldValues, scope) {
                scope.report.consumption.total_consumption_lsmdo = sumValues(newValues);
            });
            scope.$watchGroup(hsmgoConsumptions, function(newValues, oldValues, scope) {
                scope.report.consumption.total_consumption_hsmgo = sumValues(newValues);
            });
            scope.$watchGroup(lsmgoConsumptions, function(newValues, oldValues, scope) {
                scope.report.consumption.total_consumption_lsmgo = sumValues(newValues);
            });
            // i'll have to add it here
            var hfoConsumptions = ["report.consumption.main_engine_hfo", "report.consumption.aux_engine_hfo", "report.consumption.mdg_port_hfo", "report.consumption.mdg_starboard_hfo", "report.consumption.hdg_hfo", "report.consumption.dg_hfo", "report.consumption.aux_boiler_hfo", "report.consumption.ig_generator_hfo", "report.consumption.other_hfo", "report.consumption.incinerator_hfo"];
            var lfoConsumptions = ["report.consumption.main_engine_lfo", "report.consumption.aux_engine_lfo", "report.consumption.mdg_port_lfo", "report.consumption.mdg_starboard_lfo", "report.consumption.hdg_lfo", "report.consumption.dg_lfo", "report.consumption.aux_boiler_lfo", "report.consumption.ig_generator_lfo", "report.consumption.other_lfo", "report.consumption.incinerator_lfo"];
            var mgoConsumptions = ["report.consumption.main_engine_mgo", "report.consumption.aux_engine_mgo", "report.consumption.mdg_port_mgo", "report.consumption.mdg_starboard_mgo", "report.consumption.hdg_mgo", "report.consumption.dg_mgo", "report.consumption.aux_boiler_mgo", "report.consumption.ig_generator_mgo", "report.consumption.other_mgo", "report.consumption.incinerator_mgo"];
            var mdoConsumptions = ["report.consumption.main_engine_mdo", "report.consumption.aux_engine_mdo", "report.consumption.mdg_port_mdo", "report.consumption.mdg_starboard_mdo", "report.consumption.hdg_mdo", "report.consumption.dg_mdo", "report.consumption.aux_boiler_mdo", "report.consumption.ig_generator_mdo", "report.consumption.other_mdo", "report.consumption.incinerator_mdo"];
            var b10lfoConsumptions = ["report.consumption.main_engine_b10lfo", "report.consumption.aux_engine_b10lfo", "report.consumption.mdg_port_b10lfo", "report.consumption.mdg_starboard_b10lfo", "report.consumption.hdg_b10lfo", "report.consumption.dg_b10lfo", "report.consumption.aux_boiler_b10lfo", "report.consumption.ig_generator_b10lfo", "report.consumption.other_b10lfo", "report.consumption.incinerator_b10lfo"];
            var b10mgoConsumptions = ["report.consumption.main_engine_b10mgo", "report.consumption.aux_engine_b10mgo", "report.consumption.mdg_port_b10mgo", "report.consumption.mdg_starboard_b10mgo", "report.consumption.hdg_b10mgo", "report.consumption.dg_b10mgo", "report.consumption.aux_boiler_b10mgo", "report.consumption.ig_generator_b10mgo", "report.consumption.other_b10mgo", "report.consumption.incinerator_b10mgo"];
            var biolfoConsumptions = ["report.consumption.main_engine_biolfo", "report.consumption.aux_engine_biolfo", "report.consumption.mdg_port_biolfo", "report.consumption.mdg_starboard_biolfo", "report.consumption.hdg_biolfo", "report.consumption.dg_biolfo", "report.consumption.aux_boiler_biolfo", "report.consumption.ig_generator_biolfo", "report.consumption.other_biolfo", "report.consumption.incinerator_biolfo"];
            var biomgoConsumptions = ["report.consumption.main_engine_biomgo", "report.consumption.aux_engine_biomgo", "report.consumption.mdg_port_biomgo", "report.consumption.mdg_starboard_biomgo", "report.consumption.hdg_biomgo", "report.consumption.dg_biomgo", "report.consumption.aux_boiler_biomgo", "report.consumption.ig_generator_biomgo", "report.consumption.other_biomgo", "report.consumption.incinerator_biomgo"];
            var ulsfo2020Consumptions = ["report.consumption.main_engine_ulsfo2020", "report.consumption.aux_engine_ulsfo2020", "report.consumption.mdg_port_ulsfo2020", "report.consumption.mdg_starboard_ulsfo2020", "report.consumption.hdg_ulsfo2020", "report.consumption.dg_ulsfo2020", "report.consumption.aux_boiler_ulsfo2020", "report.consumption.ig_generator_ulsfo2020", "report.consumption.other_ulsfo2020", "report.consumption.incinerator_ulsfo2020"];
            var ulslfo2020Consumptions = ["report.consumption.main_engine_ulslfo2020", "report.consumption.aux_engine_ulslfo2020", "report.consumption.mdg_port_ulslfo2020", "report.consumption.mdg_starboard_ulslfo2020", "report.consumption.hdg_ulslfo2020", "report.consumption.dg_ulslfo2020", "report.consumption.aux_boiler_ulslfo2020", "report.consumption.ig_generator_ulslfo2020", "report.consumption.other_ulslfo2020", "report.consumption.incinerator_ulslfo2020"];
            var ulsmdo2020Consumptions = ["report.consumption.main_engine_ulsmdo2020", "report.consumption.aux_engine_ulsmdo2020", "report.consumption.mdg_port_ulsmdo2020", "report.consumption.mdg_starboard_ulsmdo2020", "report.consumption.hdg_ulsmdo2020", "report.consumption.dg_ulsmdo2020", "report.consumption.aux_boiler_ulsmdo2020", "report.consumption.ig_generator_ulsmdo2020", "report.consumption.other_ulsmdo2020", "report.consumption.incinerator_ulsmdo2020"];
            var ulsmgo2020Consumptions = ["report.consumption.main_engine_ulsmgo2020", "report.consumption.aux_engine_ulsmgo2020", "report.consumption.mdg_port_ulsmgo2020", "report.consumption.mdg_starboard_ulsmgo2020", "report.consumption.hdg_ulsmgo2020", "report.consumption.dg_ulsmgo2020", "report.consumption.aux_boiler_ulsmgo2020", "report.consumption.ig_generator_ulsmgo2020", "report.consumption.other_ulsmgo2020", "report.consumption.incinerator_ulsmgo2020"];
            var vlsfo2020Consumptions = ["report.consumption.main_engine_vlsfo2020", "report.consumption.aux_engine_vlsfo2020", "report.consumption.mdg_port_vlsfo2020", "report.consumption.mdg_starboard_vlsfo2020", "report.consumption.hdg_vlsfo2020", "report.consumption.dg_vlsfo2020", "report.consumption.aux_boiler_vlsfo2020", "report.consumption.ig_generator_vlsfo2020", "report.consumption.other_vlsfo2020", "report.consumption.incinerator_vlsfo2020"];
            var vlslfo2020Consumptions = ["report.consumption.main_engine_vlslfo2020", "report.consumption.aux_engine_vlslfo2020", "report.consumption.mdg_port_vlslfo2020", "report.consumption.mdg_starboard_vlslfo2020", "report.consumption.hdg_vlslfo2020", "report.consumption.dg_vlslfo2020", "report.consumption.aux_boiler_vlslfo2020", "report.consumption.ig_generator_vlslfo2020", "report.consumption.other_vlslfo2020", "report.consumption.incinerator_vlslfo2020"];
            var lpgpConsumptions = ["report.consumption.main_engine_lpgp", "report.consumption.aux_engine_lpgp", "report.consumption.mdg_port_lpgp", "report.consumption.mdg_starboard_lpgp", "report.consumption.hdg_lpgp", "report.consumption.dg_lpgp", "report.consumption.aux_boiler_lpgp", "report.consumption.ig_generator_lpgp", "report.consumption.other_lpgp", "report.consumption.incinerator_lpgp"];
            var lpgbConsumptions = ["report.consumption.main_engine_lpgb", "report.consumption.aux_engine_lpgb", "report.consumption.mdg_port_lpgb", "report.consumption.mdg_starboard_lpgb", "report.consumption.hdg_lpgb", "report.consumption.dg_lpgb", "report.consumption.aux_boiler_lpgb", "report.consumption.ig_generator_lpgb", "report.consumption.other_lpgb", "report.consumption.incinerator_lpgb"];
            var lngConsumptions = ["report.consumption.main_engine_lng", "report.consumption.aux_engine_lng", "report.consumption.mdg_port_lng", "report.consumption.mdg_starboard_lng", "report.consumption.hdg_lng", "report.consumption.dg_lng", "report.consumption.aux_boiler_lng", "report.consumption.ux_boiler_lng", "report.consumption.ig_generator_lng", "report.consumption.other_lng", "report.consumption.incinerator_lng"];
            var methanolConsumptions = ["report.consumption.main_engine_methanol", "report.consumption.aux_engine_methanol", "report.consumption.mdg_port_methanol", "report.consumption.mdg_starboard_methanol", "report.consumption.hdg_methanol", "report.consumption.dg_methanol", "report.consumption.aux_boiler_methanol", "report.consumption.ig_generator_methanol", "report.consumption.other_methanol", "report.consumption.incinerator_methanol"];
            var ethanolConsumptions = ["report.consumption.main_engine_ethanol", "report.consumption.aux_engine_ethanol", "report.consumption.mdg_port_ethanol", "report.consumption.mdg_starboard_ethanol", "report.consumption.hdg_ethanol", "report.consumption.dg_ethanol", "report.consumption.aux_boiler_ethanol", "report.consumption.ig_generator_ethanol", "report.consumption.other_ethanol", "report.consumption.incinerator_ethanol"];
            var otherConsumptions = ["report.consumption.main_engine_other", "report.consumption.aux_engine_other", "report.consumption.mdg_port_other", "report.consumption.mdg_starboard_other", "report.consumption.hdg_other", "report.consumption.dg_other", "report.consumption.aux_boiler_other", "report.consumption.ig_generator_other", "report.consumption.other_other", "report.consumption.incinerator_other"];
            
            scope.$watchGroup(hfoConsumptions, function(newValues, oldValues, scope) {
                scope.report.consumption.total_consumption_hfo = sumValues(newValues);
            });
            scope.$watchGroup(lfoConsumptions, function(newValues, oldValues, scope) {
                scope.report.consumption.total_consumption_lfo = sumValues(newValues);
            });
            scope.$watchGroup(mgoConsumptions, function(newValues, oldValues, scope) {
                scope.report.consumption.total_consumption_mgo = sumValues(newValues);
            });
            scope.$watchGroup(mdoConsumptions, function(newValues, oldValues, scope) {
                scope.report.consumption.total_consumption_mdo = sumValues(newValues);
            });
            scope.$watchGroup(b10lfoConsumptions, function(newValues, oldValues, scope) {
                scope.report.consumption.total_consumption_b10lfo = sumValues(newValues);
            });
            scope.$watchGroup(b10mgoConsumptions, function(newValues, oldValues, scope) {
                scope.report.consumption.total_consumption_b10mgo = sumValues(newValues);
            });
            scope.$watchGroup(biolfoConsumptions, function(newValues, oldValues, scope) {
                scope.report.consumption.total_consumption_biolfo = sumValues(newValues);
            });
            scope.$watchGroup(biomgoConsumptions, function(newValues, oldValues, scope) {
                scope.report.consumption.total_consumption_biomgo = sumValues(newValues);
            });
            scope.$watchGroup(ulsfo2020Consumptions, function(newValues, oldValues, scope) {
                scope.report.consumption.total_consumption_ulsfo2020 = sumValues(newValues);
            });
            scope.$watchGroup(ulslfo2020Consumptions, function(newValues, oldValues, scope) {
                scope.report.consumption.total_consumption_ulslfo2020 = sumValues(newValues);
            });
            scope.$watchGroup(ulsmdo2020Consumptions, function(newValues, oldValues, scope) {
                scope.report.consumption.total_consumption_ulsmdo2020 = sumValues(newValues);
            });
            scope.$watchGroup(ulsmgo2020Consumptions, function(newValues, oldValues, scope) {
                scope.report.consumption.total_consumption_ulsmgo2020 = sumValues(newValues);
            });
            scope.$watchGroup(vlsfo2020Consumptions, function(newValues, oldValues, scope) {
                scope.report.consumption.total_consumption_vlsfo2020 = sumValues(newValues);
            });
            scope.$watchGroup(vlslfo2020Consumptions, function(newValues, oldValues, scope) {
                scope.report.consumption.total_consumption_vlslfo2020 = sumValues(newValues);
            });
            scope.$watchGroup(lpgpConsumptions, function(newValues, oldValues, scope) {
                scope.report.consumption.total_consumption_lpgp = sumValues(newValues);
            });
            scope.$watchGroup(lpgbConsumptions, function(newValues, oldValues, scope) {
                scope.report.consumption.total_consumption_lpgb = sumValues(newValues);
            });
            scope.$watchGroup(lngConsumptions, function(newValues, oldValues, scope) {
                scope.report.consumption.total_consumption_lng = sumValues(newValues);
            });
            scope.$watchGroup(methanolConsumptions, function(newValues, oldValues, scope) {
                scope.report.consumption.total_consumption_methanol = sumValues(newValues);
            });
            scope.$watchGroup(ethanolConsumptions, function(newValues, oldValues, scope) {
                scope.report.consumption.total_consumption_ethanol = sumValues(newValues);
            });
            scope.$watchGroup(otherConsumptions, function(newValues, oldValues, scope) {
                scope.report.consumption.total_consumption_other = sumValues(newValues);
            });

            var mainEngineConsumptions = [
                "report.consumption.main_engine_hfo",
                "report.consumption.main_engine_lfo",
                "report.consumption.main_engine_mgo",
                "report.consumption.main_engine_b10lfo",
                "report.consumption.main_engine_mdo",
                "report.consumption.main_engine_b10mgo",
                "report.consumption.main_engine_biolfo",
                "report.consumption.main_engine_biomgo",
                "report.consumption.main_engine_ulsfo2020",
                "report.consumption.main_engine_ulslfo2020",
                "report.consumption.main_engine_ulsmgo2020",
                "report.consumption.main_engine_ulsmdo2020",
                "report.consumption.main_engine_vlsfo2020",
                "report.consumption.main_engine_vlslfo2020",
                "report.consumption.main_engine_lpgp",
                "report.consumption.main_engine_lpgb",
                "report.consumption.main_engine_methanol",
                "report.consumption.main_engine_lng",
                "report.consumption.main_engine_ethanol",
                "report.consumption.main_engine_other"
            ];
            scope.$watchGroup(mainEngineConsumptions, function(newValues, oldValues, scope) {
                scope.total_main_engine_consumption = sumValues(newValues);
            });

            // basic load calculation for aux engines
            var hshfoAuxEngineBreakdown = [
                'report.consumption.aux_engine_hshfo',
                'report.consumption.ae_ballast_operations_hshfo',
                'report.consumption.ae_crane_operations_hshfo',
                'report.consumption.ae_cdl_operations_hshfo',
                'report.consumption.ae_cargo_heating_hshfo',
                'report.consumption.ae_bunker_heating_hshfo',
                'report.consumption.ae_tank_cleaning_hshfo',
                'report.consumption.ae_cargo_shifting_hshfo',
                'report.consumption.ae_inert_gas_systems_hshfo',
                'report.consumption.ae_cargo_hold_washing_hshfo',
                'report.consumption.ae_scrubber_hshfo',
            ]
            var lshfoAuxEngineBreakdown = [
                'report.consumption.aux_engine_lshfo',
                'report.consumption.ae_ballast_operations_lshfo',
                'report.consumption.ae_crane_operations_lshfo',
                'report.consumption.ae_cdl_operations_lshfo',
                'report.consumption.ae_cargo_heating_lshfo',
                'report.consumption.ae_bunker_heating_lshfo',
                'report.consumption.ae_tank_cleaning_lshfo',
                'report.consumption.ae_cargo_shifting_lshfo',
                'report.consumption.ae_inert_gas_systems_lshfo',
                'report.consumption.ae_cargo_hold_washing_lshfo',
                'report.consumption.ae_scrubber_lshfo',
            ]
            var ulsfoAuxEngineBreakdown = [
                'report.consumption.aux_engine_ulsfo',
                'report.consumption.ae_ballast_operations_ulsfo',
                'report.consumption.ae_crane_operations_ulsfo',
                'report.consumption.ae_cdl_operations_ulsfo',
                'report.consumption.ae_cargo_heating_ulsfo',
                'report.consumption.ae_bunker_heating_ulsfo',
                'report.consumption.ae_tank_cleaning_ulsfo',
                'report.consumption.ae_cargo_shifting_ulsfo',
                'report.consumption.ae_inert_gas_systems_ulsfo',
                'report.consumption.ae_cargo_hold_washing_ulsfo',
                'report.consumption.ae_scrubber_ulsfo',
            ]
            var lsmdoAuxEngineBreakdown = [
                'report.consumption.aux_engine_lsmdo',
                'report.consumption.ae_ballast_operations_lsmdo',
                'report.consumption.ae_crane_operations_lsmdo',
                'report.consumption.ae_cdl_operations_lsmdo',
                'report.consumption.ae_cargo_heating_lsmdo',
                'report.consumption.ae_bunker_heating_lsmdo',
                'report.consumption.ae_tank_cleaning_lsmdo',
                'report.consumption.ae_cargo_shifting_lsmdo',
                'report.consumption.ae_inert_gas_systems_lsmdo',
                'report.consumption.ae_cargo_hold_washing_lsmdo',
                'report.consumption.ae_scrubber_lsmdo',
            ]
            var hsmgoAuxEngineBreakdown = [
                'report.consumption.aux_engine_hsmgo',
                'report.consumption.ae_ballast_operations_hsmgo',
                'report.consumption.ae_crane_operations_hsmgo',
                'report.consumption.ae_cdl_operations_hsmgo',
                'report.consumption.ae_cargo_heating_hsmgo',
                'report.consumption.ae_bunker_heating_hsmgo',
                'report.consumption.ae_tank_cleaning_hsmgo',
                'report.consumption.ae_cargo_shifting_hsmgo',
                'report.consumption.ae_inert_gas_systems_hsmgo',
                'report.consumption.ae_cargo_hold_washing_hsmgo',
                'report.consumption.ae_scrubber_hsmgo',
            ]
            var lsmgoAuxEngineBreakdown = [
                'report.consumption.aux_engine_lsmgo',
                'report.consumption.ae_ballast_operations_lsmgo',
                'report.consumption.ae_crane_operations_lsmgo',
                'report.consumption.ae_cdl_operations_lsmgo',
                'report.consumption.ae_cargo_heating_lsmgo',
                'report.consumption.ae_bunker_heating_lsmgo',
                'report.consumption.ae_tank_cleaning_lsmgo',
                'report.consumption.ae_cargo_shifting_lsmgo',
                'report.consumption.ae_inert_gas_systems_lsmgo',
                'report.consumption.ae_cargo_hold_washing_lsmgo',
                'report.consumption.ae_scrubber_lsmgo',
            ]

            scope.$watchGroup(hshfoAuxEngineBreakdown, function(newValues, oldValues, scope){
                let auxBaseLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    auxBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ae_basic_load_hshfo = roundToPlaces(auxBaseLoadConsumption, 2);
                scope.reportForm?.consumptions?.aeBasicLoadHSHFO?.$setValidity('aeBasicLoadHSHFO', auxBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(lshfoAuxEngineBreakdown, function(newValues, oldValues, scope){
                let auxBaseLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    auxBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ae_basic_load_lshfo = roundToPlaces(auxBaseLoadConsumption, 2);
                scope.reportForm?.consumptions?.aeBasicLoadLSHFO?.$setValidity('aeBasicLoadLSHFO', auxBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(ulsfoAuxEngineBreakdown, function(newValues, oldValues, scope){
                let auxBaseLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    auxBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ae_basic_load_ulsfo = roundToPlaces(auxBaseLoadConsumption, 2);
                scope.reportForm?.consumptions?.aeBasicLoadULSFO?.$setValidity('aeBasicLoadULSFO', auxBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(lsmdoAuxEngineBreakdown, function(newValues, oldValues, scope){
                let auxBaseLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    auxBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ae_basic_load_lsmdo = roundToPlaces(auxBaseLoadConsumption, 2);
                scope.reportForm?.consumptions?.aeBasicLoadLSMDO?.$setValidity('aeBasicLoadLSMDO', auxBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(hsmgoAuxEngineBreakdown, function(newValues, oldValues, scope){
                let auxBaseLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    auxBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ae_basic_load_hsmgo = roundToPlaces(auxBaseLoadConsumption, 2);
                scope.reportForm?.consumptions?.aeBasicLoadHSMGO?.$setValidity('aeBasicLoadHSMGO', auxBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(lsmgoAuxEngineBreakdown, function(newValues, oldValues, scope){
                let auxBaseLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    auxBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ae_basic_load_lsmgo = roundToPlaces(auxBaseLoadConsumption, 2);
                scope.reportForm?.consumptions?.aeBasicLoadLSMGO?.$setValidity('aeBasicLoadLSMGO', auxBaseLoadConsumption >= 0);
            });

            var hfoAuxEngineBreakdown = [
                'report.consumption.aux_engine_hfo',
                'report.consumption.ae_ballast_operations_hfo',
                'report.consumption.ae_crane_operations_hfo',
                'report.consumption.ae_cdl_operations_hfo',
                'report.consumption.ae_cargo_heating_hfo',
                'report.consumption.ae_cargo_cooling_hfo',
                'report.consumption.ae_tank_cleaning_hfo',
                'report.consumption.ae_cargo_shifting_hfo',
                'report.consumption.ae_inert_gas_systems_hfo',
                'report.consumption.ae_cargo_hold_washing_hfo',
                'report.consumption.ae_scrubber_hfo',
            ]
            var lfoAuxEngineBreakdown = [
                'report.consumption.aux_engine_lfo',
                'report.consumption.ae_ballast_operations_lfo',
                'report.consumption.ae_crane_operations_lfo',
                'report.consumption.ae_cdl_operations_lfo',
                'report.consumption.ae_cargo_heating_lfo',
                'report.consumption.ae_cargo_cooling_lfo',
                'report.consumption.ae_tank_cleaning_lfo',
                'report.consumption.ae_cargo_shifting_lfo',
                'report.consumption.ae_inert_gas_systems_lfo',
                'report.consumption.ae_cargo_hold_washing_lfo',
                'report.consumption.ae_scrubber_lfo',
            ]
            var mgoAuxEngineBreakdown = [
                'report.consumption.aux_engine_mgo',
                'report.consumption.ae_ballast_operations_mgo',
                'report.consumption.ae_crane_operations_mgo',
                'report.consumption.ae_cdl_operations_mgo',
                'report.consumption.ae_cargo_heating_mgo',
                'report.consumption.ae_cargo_cooling_mgo',
                'report.consumption.ae_tank_cleaning_mgo',
                'report.consumption.ae_cargo_shifting_mgo',
                'report.consumption.ae_inert_gas_systems_mgo',
                'report.consumption.ae_cargo_hold_washing_mgo',
                'report.consumption.ae_scrubber_mgo',
            ]
            var mdoAuxEngineBreakdown = [
                'report.consumption.aux_engine_mdo',
                'report.consumption.ae_ballast_operations_mdo',
                'report.consumption.ae_crane_operations_mdo',
                'report.consumption.ae_cdl_operations_mdo',
                'report.consumption.ae_cargo_heating_mdo',
                'report.consumption.ae_cargo_cooling_mdo',
                'report.consumption.ae_tank_cleaning_mdo',
                'report.consumption.ae_cargo_shifting_mdo',
                'report.consumption.ae_inert_gas_systems_mdo',
                'report.consumption.ae_cargo_hold_washing_mdo',
                'report.consumption.ae_scrubber_mdo',
            ]
            var b10lfoAuxEngineBreakdown = [
                'report.consumption.aux_engine_b10lfo',
                'report.consumption.ae_ballast_operations_b10lfo',
                'report.consumption.ae_crane_operations_b10lfo',
                'report.consumption.ae_cdl_operations_b10lfo',
                'report.consumption.ae_cargo_heating_b10lfo',
                'report.consumption.ae_cargo_cooling_b10lfo',
                'report.consumption.ae_tank_cleaning_b10lfo',
                'report.consumption.ae_cargo_shifting_b10lfo',
                'report.consumption.ae_inert_gas_systems_b10lfo',
                'report.consumption.ae_cargo_hold_washing_b10lfo',
                'report.consumption.ae_scrubber_b10lfo',
            ]
            var b10mgoAuxEngineBreakdown = [
                'report.consumption.aux_engine_b10mgo',
                'report.consumption.ae_ballast_operations_b10mgo',
                'report.consumption.ae_crane_operations_b10mgo',
                'report.consumption.ae_cdl_operations_b10mgo',
                'report.consumption.ae_cargo_heating_b10mgo',
                'report.consumption.ae_cargo_cooling_b10mgo',
                'report.consumption.ae_tank_cleaning_b10mgo',
                'report.consumption.ae_cargo_shifting_b10mgo',
                'report.consumption.ae_inert_gas_systems_b10mgo',
                'report.consumption.ae_cargo_hold_washing_b10mgo',
                'report.consumption.ae_scrubber_b10mgo',
            ]

            scope.$watchGroup(['report.operational.time_sailed','report.power.propulsion_motor_1_power','report.power.propulsion_motor_1_rh','report.power.propulsion_motor_2_power','report.power.propulsion_motor_2_rh'], function(newValues, oldValues, scope) {
                var time_sailed = newValues[0] || scope.report.operational.time_sailed;
                var propulsion_motor_1_power = newValues[1] || 0;
                var propulsion_motor_1_rh = newValues[2] || 0;
                var propulsion_motor_2_power = newValues[3] || 0;
                var propulsion_motor_2_rh = newValues[4] || 0;
                scope.propulsion_power = ((propulsion_motor_1_power*propulsion_motor_1_rh)+(propulsion_motor_2_power*propulsion_motor_2_rh))/time_sailed;
            })

            scope.$watchGroup(['report.power.diesel_gen_1_power','report.power.diesel_gen_2_power','report.power.diesel_gen_3_power','report.power.diesel_gen_4_power','report.power.diesel_gen_5_power'], function(newValues, oldValues, scope) {
                var report_period = scope.report.operational.report_period;
                var dg_1_power = newValues[0] || 0;
                var dg_1_rh = scope.report?.power?.diesel_gen_1_diff_rh || 0;
                var dg_2_power = newValues[1] || 0;
                var dg_2_rh = scope.report?.power?.diesel_gen_2_diff_rh || 0;
                var dg_3_power = newValues[2] || 0;
                var dg_3_rh = scope.report?.power?.diesel_gen_3_diff_rh || 0;
                var dg_4_power = newValues[3] || 0;
                var dg_4_rh = scope.report?.power?.diesel_gen_4_diff_rh || 0;
                var dg_5_power = newValues[4] || 0;
                var dg_5_rh = scope.report?.power?.diesel_gen_5_diff_rh || 0;
                scope.diesel_gen_total_power = ((dg_1_power*dg_1_rh)+(dg_2_power*dg_2_rh)+(dg_3_power*dg_3_rh)+(dg_4_power*dg_4_rh)+(dg_5_power*dg_5_rh))/report_period;
            })
            var biolfoAuxEngineBreakdown = [
                'report.consumption.aux_engine_biolfo',
                'report.consumption.ae_ballast_operations_biolfo',
                'report.consumption.ae_crane_operations_biolfo',
                'report.consumption.ae_cdl_operations_biolfo',
                'report.consumption.ae_cargo_heating_biolfo',
                'report.consumption.ae_cargo_cooling_biolfo',
                'report.consumption.ae_tank_cleaning_biolfo',
                'report.consumption.ae_cargo_shifting_biolfo',
                'report.consumption.ae_inert_gas_systems_biolfo',
                'report.consumption.ae_cargo_hold_washing_biolfo',
                'report.consumption.ae_scrubber_biolfo',
            ]
            var biomgoAuxEngineBreakdown = [
                'report.consumption.aux_engine_biomgo',
                'report.consumption.ae_ballast_operations_biomgo',
                'report.consumption.ae_crane_operations_biomgo',
                'report.consumption.ae_cdl_operations_biomgo',
                'report.consumption.ae_cargo_heating_biomgo',
                'report.consumption.ae_cargo_cooling_biomgo',
                'report.consumption.ae_tank_cleaning_biomgo',
                'report.consumption.ae_cargo_shifting_biomgo',
                'report.consumption.ae_inert_gas_systems_biomgo',
                'report.consumption.ae_cargo_hold_washing_biomgo',
                'report.consumption.ae_scrubber_biomgo',
            ]
            var ulsfo2020AuxEngineBreakdown = [
                'report.consumption.aux_engine_ulsfo2020',
                'report.consumption.ae_ballast_operations_ulsfo2020',
                'report.consumption.ae_crane_operations_ulsfo2020',
                'report.consumption.ae_cdl_operations_ulsfo2020',
                'report.consumption.ae_cargo_heating_ulsfo2020',
                'report.consumption.ae_cargo_cooling_ulsfo2020',
                'report.consumption.ae_tank_cleaning_ulsfo2020',
                'report.consumption.ae_cargo_shifting_ulsfo2020',
                'report.consumption.ae_inert_gas_systems_ulsfo2020',
                'report.consumption.ae_cargo_hold_washing_ulsfo2020',
                'report.consumption.ae_scrubber_ulsfo2020',
            ]
            var ulslfo2020AuxEngineBreakdown = [
                'report.consumption.aux_engine_ulslfo2020',
                'report.consumption.ae_ballast_operations_ulslfo2020',
                'report.consumption.ae_crane_operations_ulslfo2020',
                'report.consumption.ae_cdl_operations_ulslfo2020',
                'report.consumption.ae_cargo_heating_ulslfo2020',
                'report.consumption.ae_cargo_cooling_ulslfo2020',
                'report.consumption.ae_tank_cleaning_ulslfo2020',
                'report.consumption.ae_cargo_shifting_ulslfo2020',
                'report.consumption.ae_inert_gas_systems_ulslfo2020',
                'report.consumption.ae_cargo_hold_washing_ulslfo2020',
                'report.consumption.ae_scrubber_ulslfo2020',
            ]
            var ulsmdo2020AuxEngineBreakdown = [
                'report.consumption.aux_engine_ulsmdo2020',
                'report.consumption.ae_ballast_operations_ulsmdo2020',
                'report.consumption.ae_crane_operations_ulsmdo2020',
                'report.consumption.ae_cdl_operations_ulsmdo2020',
                'report.consumption.ae_cargo_heating_ulsmdo2020',
                'report.consumption.ae_cargo_cooling_ulsmdo2020',
                'report.consumption.ae_tank_cleaning_ulsmdo2020',
                'report.consumption.ae_cargo_shifting_ulsmdo2020',
                'report.consumption.ae_inert_gas_systems_ulsmdo2020',
                'report.consumption.ae_cargo_hold_washing_ulsmdo2020',
                'report.consumption.ae_scrubber_ulsmdo2020',
            ]
            var ulsmgo2020AuxEngineBreakdown = [
                'report.consumption.aux_engine_ulsmgo2020',
                'report.consumption.ae_ballast_operations_ulsmgo2020',
                'report.consumption.ae_crane_operations_ulsmgo2020',
                'report.consumption.ae_cdl_operations_ulsmgo2020',
                'report.consumption.ae_cargo_heating_ulsmgo2020',
                'report.consumption.ae_cargo_cooling_ulsmgo2020',
                'report.consumption.ae_tank_cleaning_ulsmgo2020',
                'report.consumption.ae_cargo_shifting_ulsmgo2020',
                'report.consumption.ae_inert_gas_systems_ulsmgo2020',
                'report.consumption.ae_cargo_hold_washing_ulsmgo2020',
                'report.consumption.ae_scrubber_ulsmgo2020',
            ]
            var vlsfo2020AuxEngineBreakdown = [
                'report.consumption.aux_engine_vlsfo2020',
                'report.consumption.ae_ballast_operations_vlsfo2020',
                'report.consumption.ae_crane_operations_vlsfo2020',
                'report.consumption.ae_cdl_operations_vlsfo2020',
                'report.consumption.ae_cargo_heating_vlsfo2020',
                'report.consumption.ae_cargo_cooling_vlsfo2020',
                'report.consumption.ae_tank_cleaning_vlsfo2020',
                'report.consumption.ae_cargo_shifting_vlsfo2020',
                'report.consumption.ae_inert_gas_systems_vlsfo2020',
                'report.consumption.ae_cargo_hold_washing_vlsfo2020',
                'report.consumption.ae_scrubber_vlsfo2020',
            ]
            var vlslfo2020AuxEngineBreakdown = [
                'report.consumption.aux_engine_vlslfo2020',
                'report.consumption.ae_ballast_operations_vlslfo2020',
                'report.consumption.ae_crane_operations_vlslfo2020',
                'report.consumption.ae_cdl_operations_vlslfo2020',
                'report.consumption.ae_cargo_heating_vlslfo2020',
                'report.consumption.ae_cargo_cooling_vlslfo2020',
                'report.consumption.ae_tank_cleaning_vlslfo2020',
                'report.consumption.ae_cargo_shifting_vlslfo2020',
                'report.consumption.ae_inert_gas_systems_vlslfo2020',
                'report.consumption.ae_cargo_hold_washing_vlslfo2020',
                'report.consumption.ae_scrubber_vlslfo2020',
            ]
            var lpgpAuxEngineBreakdown = [
                'report.consumption.aux_engine_lpgp',
                'report.consumption.ae_ballast_operations_lpgp',
                'report.consumption.ae_crane_operations_lpgp',
                'report.consumption.ae_cdl_operations_lpgp',
                'report.consumption.ae_cargo_heating_lpgp',
                'report.consumption.ae_cargo_cooling_lpgp',
                'report.consumption.ae_tank_cleaning_lpgp',
                'report.consumption.ae_cargo_shifting_lpgp',
                'report.consumption.ae_inert_gas_systems_lpgp',
                'report.consumption.ae_cargo_hold_washing_lpgp',
                'report.consumption.ae_scrubber_lpgp',
            ]
            var lpgbAuxEngineBreakdown = [
                'report.consumption.aux_engine_lpgb',
                'report.consumption.ae_ballast_operations_lpgb',
                'report.consumption.ae_crane_operations_lpgb',
                'report.consumption.ae_cdl_operations_lpgb',
                'report.consumption.ae_cargo_heating_lpgb',
                'report.consumption.ae_cargo_cooling_lpgb',
                'report.consumption.ae_tank_cleaning_lpgb',
                'report.consumption.ae_cargo_shifting_lpgb',
                'report.consumption.ae_inert_gas_systems_lpgb',
                'report.consumption.ae_cargo_hold_washing_lpgb',
                'report.consumption.ae_scrubber_lpgb',
            ]
            var lngAuxEngineBreakdown = [
                'report.consumption.aux_engine_lng',
                'report.consumption.ae_ballast_operations_lng',
                'report.consumption.ae_crane_operations_lng',
                'report.consumption.ae_cdl_operations_lng',
                'report.consumption.ae_cargo_heating_lng',
                'report.consumption.ae_cargo_cooling_lng',
                'report.consumption.ae_tank_cleaning_lng',
                'report.consumption.ae_cargo_shifting_lng',
                'report.consumption.ae_inert_gas_systems_lng',
                'report.consumption.ae_cargo_hold_washing_lng',
                'report.consumption.ae_scrubber_lng',
            ]
            var methanolAuxEngineBreakdown = [
                'report.consumption.aux_engine_methanol',
                'report.consumption.ae_ballast_operations_methanol',
                'report.consumption.ae_crane_operations_methanol',
                'report.consumption.ae_cdl_operations_methanol',
                'report.consumption.ae_cargo_heating_methanol',
                'report.consumption.ae_cargo_cooling_methanol',
                'report.consumption.ae_tank_cleaning_methanol',
                'report.consumption.ae_cargo_shifting_methanol',
                'report.consumption.ae_inert_gas_systems_methanol',
                'report.consumption.ae_cargo_hold_washing_methanol',
                'report.consumption.ae_scrubber_methanol',
            ]
            var ethanolAuxEngineBreakdown = [
                'report.consumption.aux_engine_ethanol',
                'report.consumption.ae_ballast_operations_ethanol',
                'report.consumption.ae_crane_operations_ethanol',
                'report.consumption.ae_cdl_operations_ethanol',
                'report.consumption.ae_cargo_heating_ethanol',
                'report.consumption.ae_cargo_cooling_ethanol',
                'report.consumption.ae_tank_cleaning_ethanol',
                'report.consumption.ae_cargo_shifting_ethanol',
                'report.consumption.ae_inert_gas_systems_ethanol',
                'report.consumption.ae_cargo_hold_washing_ethanol',
                'report.consumption.ae_scrubber_ethanol',
            ]
            var otherAuxEngineBreakdown = [
                'report.consumption.aux_engine_other',
                'report.consumption.ae_ballast_operations_other',
                'report.consumption.ae_crane_operations_other',
                'report.consumption.ae_cdl_operations_other',
                'report.consumption.ae_cargo_heating_other',
                'report.consumption.ae_cargo_cooling_other',
                'report.consumption.ae_tank_cleaning_other',
                'report.consumption.ae_cargo_shifting_other',
                'report.consumption.ae_inert_gas_systems_other',
                'report.consumption.ae_cargo_hold_washing_other',
                'report.consumption.ae_scrubber_other',
            ]

            scope.$watchGroup(hfoAuxEngineBreakdown, function(newValues, oldValues, scope){
                let auxBaseLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    auxBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ae_basic_load_hfo = roundToPlaces(auxBaseLoadConsumption, 2);
                scope.reportForm?.consumptions?.aeBasicLoadHFO?.$setValidity('aeBasicLoadHFO', auxBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(lfoAuxEngineBreakdown, function(newValues, oldValues, scope){
                let auxBaseLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    auxBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ae_basic_load_lfo = roundToPlaces(auxBaseLoadConsumption, 2);
                scope.reportForm?.consumptions?.aeBasicLoadLFO?.$setValidity('aeBasicLoadLFO', auxBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(mgoAuxEngineBreakdown, function(newValues, oldValues, scope){
                let auxBaseLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    auxBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ae_basic_load_mgo = roundToPlaces(auxBaseLoadConsumption, 2);
                scope.reportForm?.consumptions?.aeBasicLoadMGO?.$setValidity('aeBasicLoadMGO', auxBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(mdoAuxEngineBreakdown, function(newValues, oldValues, scope){
                let auxBaseLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    auxBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ae_basic_load_mdo = roundToPlaces(auxBaseLoadConsumption, 2);
                scope.reportForm?.consumptions?.aeBasicLoadMDO?.$setValidity('aeBasicLoadMDO', auxBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(b10lfoAuxEngineBreakdown, function(newValues, oldValues, scope){
                let auxBaseLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    auxBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ae_basic_load_b10lfo = roundToPlaces(auxBaseLoadConsumption, 2);
                scope.reportForm?.consumptions?.aeBasicLoadB10LFO?.$setValidity('aeBasicLoadB10LFO', auxBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(b10mgoAuxEngineBreakdown, function(newValues, oldValues, scope){
                let auxBaseLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    auxBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ae_basic_load_b10mgo = roundToPlaces(auxBaseLoadConsumption, 2);
                scope.reportForm?.consumptions?.aeBasicLoadB10MGO?.$setValidity('aeBasicLoadB10MGO', auxBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(biolfoAuxEngineBreakdown, function(newValues, oldValues, scope){
                let auxBaseLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    auxBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ae_basic_load_biolfo = roundToPlaces(auxBaseLoadConsumption, 2);
                scope.reportForm?.consumptions?.aeBasicLoadBIOLFO?.$setValidity('aeBasicLoadBIOLFO', auxBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(biomgoAuxEngineBreakdown, function(newValues, oldValues, scope){
                let auxBaseLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    auxBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ae_basic_load_biomgo = roundToPlaces(auxBaseLoadConsumption, 2);
                scope.reportForm?.consumptions?.aeBasicLoadBIOMGO?.$setValidity('aeBasicLoadBIOMGO', auxBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(ulsfo2020AuxEngineBreakdown, function(newValues, oldValues, scope){
                let auxBaseLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    auxBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ae_basic_load_ulsfo2020 = roundToPlaces(auxBaseLoadConsumption, 2);
                scope.reportForm?.consumptions?.aeBasicLoadULSFO2020?.$setValidity('aeBasicLoadULSFO2020', auxBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(ulslfo2020AuxEngineBreakdown, function(newValues, oldValues, scope){
                let auxBaseLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    auxBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ae_basic_load_ulslfo2020 = roundToPlaces(auxBaseLoadConsumption, 2);
                scope.reportForm?.consumptions?.aeBasicLoadULSLFO2020?.$setValidity('aeBasicLoadULSLFO2020', auxBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(ulsmdo2020AuxEngineBreakdown, function(newValues, oldValues, scope){
                let auxBaseLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    auxBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ae_basic_load_ulsmdo2020 = roundToPlaces(auxBaseLoadConsumption, 2);
                scope.reportForm?.consumptions?.aeBasicLoadULSMDO2020?.$setValidity('aeBasicLoadULSMDO2020', auxBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(ulsmgo2020AuxEngineBreakdown, function(newValues, oldValues, scope){
                let auxBaseLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    auxBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ae_basic_load_ulsmgo2020 = roundToPlaces(auxBaseLoadConsumption, 2);
                scope.reportForm?.consumptions?.aeBasicLoadULSMGO2020?.$setValidity('aeBasicLoadULSMGO2020', auxBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(vlsfo2020AuxEngineBreakdown, function(newValues, oldValues, scope){
                let auxBaseLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    auxBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ae_basic_load_vlsfo2020 = roundToPlaces(auxBaseLoadConsumption, 2);
                scope.reportForm?.consumptions?.aeBasicLoadVLSFO2020?.$setValidity('aeBasicLoadVLSFO2020', auxBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(vlslfo2020AuxEngineBreakdown, function(newValues, oldValues, scope){
                let auxBaseLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    auxBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ae_basic_load_vlslfo2020 = roundToPlaces(auxBaseLoadConsumption, 2);
                scope.reportForm?.consumptions?.aeBasicLoadVLSLFO2020?.$setValidity('aeBasicLoadVLSLFO2020', auxBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(lpgpAuxEngineBreakdown, function(newValues, oldValues, scope){
                let auxBaseLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    auxBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ae_basic_load_lpgp = roundToPlaces(auxBaseLoadConsumption, 2);
                scope.reportForm?.consumptions?.aeBasicLoadLPGP?.$setValidity('aeBasicLoadLPGP', auxBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(lpgbAuxEngineBreakdown, function(newValues, oldValues, scope){
                let auxBaseLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    auxBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ae_basic_load_lpgb = roundToPlaces(auxBaseLoadConsumption, 2);
                scope.reportForm?.consumptions?.aeBasicLoadLPGB?.$setValidity('aeBasicLoadLPGB', auxBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(lngAuxEngineBreakdown, function(newValues, oldValues, scope){
                let auxBaseLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    auxBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ae_basic_load_lng = roundToPlaces(auxBaseLoadConsumption, 2);
                scope.reportForm?.consumptions?.aeBasicLoadLNG?.$setValidity('aeBasicLoadLNG', auxBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(methanolAuxEngineBreakdown, function(newValues, oldValues, scope){
                let auxBaseLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    auxBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ae_basic_load_methanol = roundToPlaces(auxBaseLoadConsumption, 2);
                scope.reportForm?.consumptions?.aeBasicLoadMETHANOL?.$setValidity('aeBasicLoadMETHANOL', auxBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(ethanolAuxEngineBreakdown, function(newValues, oldValues, scope){
                let auxBaseLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    auxBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ae_basic_load_ethanol = roundToPlaces(auxBaseLoadConsumption, 2);
                scope.reportForm?.consumptions?.aeBasicLoadETHANOL?.$setValidity('aeBasicLoadETHANOL', auxBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(otherAuxEngineBreakdown, function(newValues, oldValues, scope){
                let auxBaseLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    auxBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ae_basic_load_other = roundToPlaces(auxBaseLoadConsumption, 2);
                scope.reportForm?.consumptions?.aeBasicLoadOTHER?.$setValidity('aeBasicLoadOTHER', auxBaseLoadConsumption >= 0);
            });

            var dieselGenMDGPortConsumptions = [
                "report.consumption.mdg_port_hshfo",
                "report.consumption.mdg_port_lshfo",
                "report.consumption.mdg_port_ulsfo",
                "report.consumption.mdg_port_lsmdo",
                "report.consumption.mdg_port_hsmgo",
                "report.consumption.mdg_port_lsmgo"
            ];
            scope.$watchGroup(dieselGenMDGPortConsumptions, function(newValues, oldValues, scope) {
                scope.total_mdg_port_consumption = roundToPlaces(sumValues(newValues), 2);
            });
            var dieselGenMDGStarboardConsumptions = [
                "report.consumption.mdg_starboard_hshfo",
                "report.consumption.mdg_starboard_lshfo",
                "report.consumption.mdg_starboard_ulsfo",
                "report.consumption.mdg_starboard_lsmdo",
                "report.consumption.mdg_starboard_hsmgo",
                "report.consumption.mdg_starboard_lsmgo"
            ];
            scope.$watchGroup(dieselGenMDGStarboardConsumptions, function(newValues, oldValues, scope) {
                scope.total_mdg_starboard_consumption = roundToPlaces(sumValues(newValues), 2);
            });
            var dieselGenHDGConsumptions = [
                "report.consumption.hdg_hshfo",
                "report.consumption.hdg_lshfo",
                "report.consumption.hdg_ulsfo",
                "report.consumption.hdg_lsmdo",
                "report.consumption.hdg_hsmgo",
                "report.consumption.hdg_lsmgo"
            ];
            scope.$watchGroup(dieselGenHDGConsumptions, function(newValues, oldValues, scope) {
                scope.total_hdg_consumption = roundToPlaces(sumValues(newValues), 2);
            });

            var dieselGenMDGPortConsumptions = [
                "report.consumption.mdg_port_hfo",
                "report.consumption.mdg_port_lfo",
                "report.consumption.mdg_port_mgo",
                "report.consumption.mdg_port_mdo",
                "report.consumption.mdg_port_b10lfo",
                "report.consumption.mdg_port_b10mgo",
                "report.consumption.mdg_port_biolfo",
                "report.consumption.mdg_port_biomgo",
                "report.consumption.mdg_port_ulsfo2020",
                "report.consumption.mdg_port_ulslfo2020",
                "report.consumption.mdg_port_ulsmdo2020",
                "report.consumption.mdg_port_ulsmgo2020",
                "report.consumption.mdg_port_vlsfo2020",
                "report.consumption.mdg_port_vlslfo2020",
                "report.consumption.mdg_port_lpgp",
                "report.consumption.mdg_port_lpgb",
                "report.consumption.mdg_port_lng",
                "report.consumption.mdg_port_methanol",
                "report.consumption.mdg_port_ethanol",
                "report.consumption.mdg_port_other"
            ];
            scope.$watchGroup(dieselGenMDGPortConsumptions, function(newValues, oldValues, scope) {
                scope.total_mdg_port_consumption = roundToPlaces(sumValues(newValues), 2);
            });
            var dieselGenMDGStarboardConsumptions = [
                "report.consumption.mdg_starboard_hfo",
                "report.consumption.mdg_starboard_lfo",
                "report.consumption.mdg_starboard_mgo",
                "report.consumption.mdg_starboard_mdo",
                "report.consumption.mdg_starboard_b10lfo",
                "report.consumption.mdg_starboard_b10mgo",
                "report.consumption.mdg_starboard_biolfo",
                "report.consumption.mdg_starboard_biomgo",
                "report.consumption.mdg_starboard_ulsfo2020",
                "report.consumption.mdg_starboard_ulslfo2020",
                "report.consumption.mdg_starboard_ulsmdo2020",
                "report.consumption.mdg_starboard_ulsmgo2020",
                "report.consumption.mdg_starboard_vlsfo2020",
                "report.consumption.mdg_starboard_vlslfo2020",
                "report.consumption.mdg_starboard_lpgp",
                "report.consumption.mdg_starboard_lpgb",
                "report.consumption.mdg_starboard_lng",
                "report.consumption.mdg_starboard_methanol",
                "report.consumption.mdg_starboard_ethanol",
                "report.consumption.mdg_starboard_other"
            ];
            scope.$watchGroup(dieselGenMDGStarboardConsumptions, function(newValues, oldValues, scope) {
                scope.total_mdg_starboard_consumption = roundToPlaces(sumValues(newValues), 2);
            });
            var dieselGenHDGConsumptions = [
                "report.consumption.hdg_hfo",
                "report.consumption.hdg_lfo",
                "report.consumption.hdg_mgo",
                "report.consumption.hdg_mdo",
                "report.consumption.hdg_b10lfo",
                "report.consumption.hdg_b10mgo",
                "report.consumption.hdg_biolfo",
                "report.consumption.hdg_biomgo",
                "report.consumption.hdg_ulsfo2020",
                "report.consumption.hdg_ulslfo2020",
                "report.consumption.hdg_ulsmdo2020",
                "report.consumption.hdg_ulsmgo2020",
                "report.consumption.hdg_vlsfo2020",
                "report.consumption.hdg_vlslfo2020",
                "report.consumption.hdg_lpgp",
                "report.consumption.hdg_lpgb",
                "report.consumption.hdg_lng",
                "report.consumption.hdg_methanol",
                "report.consumption.hdg_ethanol",
                "report.consumption.hdg_other"
            ];
            scope.$watchGroup(dieselGenHDGConsumptions, function(newValues, oldValues, scope) {
                scope.total_hdg_consumption = roundToPlaces(sumValues(newValues), 2);
            });

            var dieselGenDGConsumptions = [
                "report.consumption.dg_hfo",
                "report.consumption.dg_lfo",
                "report.consumption.dg_mgo",
                "report.consumption.dg_mdo",
                "report.consumption.dg_b10lfo",
                "report.consumption.dg_b10mgo",
                "report.consumption.dg_biolfo",
                "report.consumption.dg_biomgo",
                "report.consumption.dg_ulsfo2020",
                "report.consumption.dg_ulslfo2020",
                "report.consumption.dg_ulsmdo2020",
                "report.consumption.dg_ulsmgo2020",
                "report.consumption.dg_vlsfo2020",
                "report.consumption.dg_vlslfo2020",
                "report.consumption.dg_lpgp",
                "report.consumption.dg_lpgb",
                "report.consumption.dg_lng",
                "report.consumption.dg_methanol",
                "report.consumption.dg_ethanol",
                "report.consumption.dg_other"
            ];
            scope.$watchGroup(dieselGenDGConsumptions, function(newValues, oldValues, scope) {
                scope.total_dg_consumption = roundToPlaces(sumValues(newValues), 2);
            });

            var hshfoDieselGenBreakdown = [
                'report.consumption.mdg_port_hshfo',
                'report.consumption.mdg_starboard_hshfo',
                'report.consumption.hdg_hshfo',
                'report.consumption.dg_hshfo',
                'report.consumption.dg_ballast_operations_hshfo',
                'report.consumption.dg_crane_operations_hshfo',
                'report.consumption.dg_cdl_operations_hshfo',
                'report.consumption.dg_cargo_heating_hshfo',
                'report.consumption.dg_tank_cleaning_hshfo',
                'report.consumption.dg_cargo_shifting_hshfo',
                'report.consumption.dg_inert_gas_systems_hshfo',
                'report.consumption.dg_cargo_hold_washing_hshfo',
                'report.consumption.dg_scrubber_hshfo',
            ]
            var lshfoDieselGenBreakdown = [
                'report.consumption.mdg_port_lshfo',
                'report.consumption.mdg_starboard_lshfo',
                'report.consumption.hdg_lshfo',
                'report.consumption.dg_lshfo',
                'report.consumption.dg_ballast_operations_lshfo',
                'report.consumption.dg_crane_operations_lshfo',
                'report.consumption.dg_cdl_operations_lshfo',
                'report.consumption.dg_cargo_heating_lshfo',
                'report.consumption.dg_tank_cleaning_lshfo',
                'report.consumption.dg_cargo_shifting_lshfo',
                'report.consumption.dg_inert_gas_systems_lshfo',
                'report.consumption.dg_cargo_hold_washing_lshfo',
                'report.consumption.dg_scrubber_lshfo',
            ]
            var ulsfoDieselGenBreakdown = [
                'report.consumption.mdg_port_ulsfo',
                'report.consumption.mdg_starboard_ulsfo',
                'report.consumption.hdg_ulsfo',
                'report.consumption.dg_ulsfo',
                'report.consumption.dg_ballast_operations_ulsfo',
                'report.consumption.dg_crane_operations_ulsfo',
                'report.consumption.dg_cdl_operations_ulsfo',
                'report.consumption.dg_cargo_heating_ulsfo',
                'report.consumption.dg_tank_cleaning_ulsfo',
                'report.consumption.dg_cargo_shifting_ulsfo',
                'report.consumption.dg_inert_gas_systems_ulsfo',
                'report.consumption.dg_cargo_hold_washing_ulsfo',
                'report.consumption.dg_scrubber_ulsfo',
            ]
            var lsmdoDieselGenBreakdown = [
                'report.consumption.mdg_port_lsmdo',
                'report.consumption.mdg_starboard_lsmdo',
                'report.consumption.hdg_lsmdo',
                'report.consumption.dg_lsmdo',
                'report.consumption.dg_ballast_operations_lsmdo',
                'report.consumption.dg_crane_operations_lsmdo',
                'report.consumption.dg_cdl_operations_lsmdo',
                'report.consumption.dg_cargo_heating_lsmdo',
                'report.consumption.dg_tank_cleaning_lsmdo',
                'report.consumption.dg_cargo_shifting_lsmdo',
                'report.consumption.dg_inert_gas_systems_lsmdo',
                'report.consumption.dg_cargo_hold_washing_lsmdo',
                'report.consumption.dg_scrubber_lsmdo',
            ]
            var hsmgoDieselGenBreakdown = [
                'report.consumption.mdg_port_hsmgo',
                'report.consumption.mdg_starboard_hsmgo',
                'report.consumption.hdg_hsmgo',
                'report.consumption.dg_hsmgo',
                'report.consumption.dg_ballast_operations_hsmgo',
                'report.consumption.dg_crane_operations_hsmgo',
                'report.consumption.dg_cdl_operations_hsmgo',
                'report.consumption.dg_cargo_heating_hsmgo',
                'report.consumption.dg_tank_cleaning_hsmgo',
                'report.consumption.dg_cargo_shifting_hsmgo',
                'report.consumption.dg_inert_gas_systems_hsmgo',
                'report.consumption.dg_cargo_hold_washing_hsmgo',
                'report.consumption.dg_scrubber_hsmgo',
            ]
            var lsmgoDieselGenBreakdown = [
                'report.consumption.mdg_port_lsmgo',
                'report.consumption.mdg_starboard_lsmgo',
                'report.consumption.hdg_lsmgo',
                'report.consumption.dg_lsmgo',
                'report.consumption.dg_ballast_operations_lsmgo',
                'report.consumption.dg_crane_operations_lsmgo',
                'report.consumption.dg_cdl_operations_lsmgo',
                'report.consumption.dg_cargo_heating_lsmgo',
                'report.consumption.dg_tank_cleaning_lsmgo',
                'report.consumption.dg_cargo_shifting_lsmgo',
                'report.consumption.dg_inert_gas_systems_lsmgo',
                'report.consumption.dg_cargo_hold_washing_lsmgo',
                'report.consumption.dg_scrubber_lsmgo',
            ]

            scope.$watchGroup(hshfoDieselGenBreakdown, function(newValues, oldValues, scope){
                var mdg_port = newValues[0] || 0;
                var mdg_starboard = newValues[1] || 0;
                var hdg = newValues[2] || 0;
                var dg = newValues[3] || 0;
                let dgBaseLoadConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(1-(scope.propulsion_power/scope.diesel_gen_total_power))) || 0;
                let propulsionConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(scope.propulsion_power/scope.diesel_gen_total_power)) || 0;
                var breakdown = newValues.slice(4);
                if (breakdown && breakdown.length > 0) {
                    dgBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.dg_basic_load_hshfo = roundToPlaces(dgBaseLoadConsumption, 2);
                scope.report.consumption.prop_cons_hshfo = roundToPlaces(propulsionConsumption, 2);
                // scope.reportForm.consumptions.dgBasicLoadHSHFO.$setValidity('dgBasicLoadHSHFO', dgBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(lshfoDieselGenBreakdown, function(newValues, oldValues, scope){
                var mdg_port = newValues[0] || 0;
                var mdg_starboard = newValues[1] || 0;
                var hdg = newValues[2] || 0;
                var dg = newValues[3] || 0;
                let dgBaseLoadConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(1-(scope.propulsion_power/scope.diesel_gen_total_power))) || 0;
                let propulsionConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(scope.propulsion_power/scope.diesel_gen_total_power)) || 0;
                var breakdown = newValues.slice(4);
                if (breakdown && breakdown.length > 0) {
                    dgBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.dg_basic_load_lshfo = roundToPlaces(dgBaseLoadConsumption, 2);
                scope.report.consumption.prop_cons_lshfo = roundToPlaces(propulsionConsumption, 2);
                // scope.reportForm.consumptions.dgBasicLoadLSHFO.$setValidity('dgBasicLoadLSHFO', dgBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(ulsfoDieselGenBreakdown, function(newValues, oldValues, scope){
                var mdg_port = newValues[0] || 0;
                var mdg_starboard = newValues[1] || 0;
                var hdg = newValues[2] || 0;
                var dg = newValues[3] || 0;
                let dgBaseLoadConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(1-(scope.propulsion_power/scope.diesel_gen_total_power))) || 0;
                let propulsionConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(scope.propulsion_power/scope.diesel_gen_total_power)) || 0;
                var breakdown = newValues.slice(4);
                if (breakdown && breakdown.length > 0) {
                    dgBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.dg_basic_load_methanol = roundToPlaces(dgBaseLoadConsumption, 2);
                scope.report.consumption.prop_cons_methanol = roundToPlaces(propulsionConsumption, 2);
                // scope.reportForm.consumptions.dgBasicLoadMETHANOL.$setValidity('dgBasicLoadMETHANOL', dgBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(lsmdoDieselGenBreakdown, function(newValues, oldValues, scope){
                var mdg_port = newValues[0] || 0;
                var mdg_starboard = newValues[1] || 0;
                var hdg = newValues[2] || 0;
                var dg = newValues[3] || 0;
                let dgBaseLoadConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(1-(scope.propulsion_power/scope.diesel_gen_total_power))) || 0;
                let propulsionConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(scope.propulsion_power/scope.diesel_gen_total_power)) || 0;
                var breakdown = newValues.slice(4);
                if (breakdown && breakdown.length > 0) {
                    dgBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.dg_basic_load_lsmdo = roundToPlaces(dgBaseLoadConsumption, 2);
                scope.report.consumption.prop_cons_lsmdo = roundToPlaces(propulsionConsumption, 2);
                // scope.reportForm.consumptions.dgBasicLoadLSMDO.$setValidity('dgBasicLoadLSMDO', dgBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(hsmgoDieselGenBreakdown, function(newValues, oldValues, scope){
                var mdg_port = newValues[0] || 0;
                var mdg_starboard = newValues[1] || 0;
                var hdg = newValues[2] || 0;
                var dg = newValues[3] || 0;
                let dgBaseLoadConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(1-(scope.propulsion_power/scope.diesel_gen_total_power))) || 0;
                let propulsionConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(scope.propulsion_power/scope.diesel_gen_total_power)) || 0;
                var breakdown = newValues.slice(4);
                if (breakdown && breakdown.length > 0) {
                    dgBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.dg_basic_load_hsmgo = roundToPlaces(dgBaseLoadConsumption, 2);
                scope.report.consumption.prop_cons_hsmgo = roundToPlaces(propulsionConsumption, 2);
                // scope.reportForm.consumptions.dgBasicLoadHSMGO.$setValidity('dgBasicLoadHSMGO', dgBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(lsmgoDieselGenBreakdown, function(newValues, oldValues, scope){
                var mdg_port = newValues[0] || 0;
                var mdg_starboard = newValues[1] || 0;
                var hdg = newValues[2] || 0;
                var dg = newValues[3] || 0;
                let dgBaseLoadConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(1-(scope.propulsion_power/scope.diesel_gen_total_power))) || 0;
                let propulsionConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(scope.propulsion_power/scope.diesel_gen_total_power)) || 0;
                var breakdown = newValues.slice(5);
                if (breakdown && breakdown.length > 0) {
                    dgBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.dg_basic_load_lsmgo = roundToPlaces(dgBaseLoadConsumption, 2);
                scope.report.consumption.prop_cons_lsmgo = roundToPlaces(propulsionConsumption, 2);
                // scope.reportForm.consumptions.dgBasicLoadLSMGO.$setValidity('dgBasicLoadLSMGO', dgBaseLoadConsumption >= 0);
            });

            var hfoDieselGenBreakdown = [
                'report.consumption.mdg_port_hfo',
                'report.consumption.mdg_starboard_hfo',
                'report.consumption.hdg_hfo',
                'report.consumption.dg_hfo',
                'report.consumption.dg_ballast_operations_hfo',
                'report.consumption.dg_crane_operations_hfo',
                'report.consumption.dg_cdl_operations_hfo',
                'report.consumption.dg_cargo_heating_hfo',
                'report.consumption.dg_tank_cleaning_hfo',
                'report.consumption.dg_cargo_shifting_hfo',
                'report.consumption.dg_inert_gas_systems_hfo',
                'report.consumption.dg_cargo_hold_washing_hfo',
                'report.consumption.dg_scrubber_hfo',
            ]
            var lfoDieselGenBreakdown = [
                'report.consumption.mdg_port_lfo',
                'report.consumption.mdg_starboard_lfo',
                'report.consumption.hdg_lfo',
                'report.consumption.dg_lfo',
                'report.consumption.dg_ballast_operations_lfo',
                'report.consumption.dg_crane_operations_lfo',
                'report.consumption.dg_cdl_operations_lfo',
                'report.consumption.dg_cargo_heating_lfo',
                'report.consumption.dg_tank_cleaning_lfo',
                'report.consumption.dg_cargo_shifting_lfo',
                'report.consumption.dg_inert_gas_systems_lfo',
                'report.consumption.dg_cargo_hold_washing_lfo',
                'report.consumption.dg_scrubber_lfo',
            ]
            var mgoDieselGenBreakdown = [
                'report.consumption.mdg_port_mgo',
                'report.consumption.mdg_starboard_mgo',
                'report.consumption.hdg_mgo',
                'report.consumption.dg_mgo',
                'report.consumption.dg_ballast_operations_mgo',
                'report.consumption.dg_crane_operations_mgo',
                'report.consumption.dg_cdl_operations_mgo',
                'report.consumption.dg_cargo_heating_mgo',
                'report.consumption.dg_tank_cleaning_mgo',
                'report.consumption.dg_cargo_shifting_mgo',
                'report.consumption.dg_inert_gas_systems_mgo',
                'report.consumption.dg_cargo_hold_washing_mgo',
                'report.consumption.dg_scrubber_mgo',
            ]
            var mdoDieselGenBreakdown = [
                'report.consumption.mdg_port_mdo',
                'report.consumption.mdg_starboard_mdo',
                'report.consumption.hdg_mdo',
                'report.consumption.dg_mdo',
                'report.consumption.dg_ballast_operations_mdo',
                'report.consumption.dg_crane_operations_mdo',
                'report.consumption.dg_cdl_operations_mdo',
                'report.consumption.dg_cargo_heating_mdo',
                'report.consumption.dg_tank_cleaning_mdo',
                'report.consumption.dg_cargo_shifting_mdo',
                'report.consumption.dg_inert_gas_systems_mdo',
                'report.consumption.dg_cargo_hold_washing_mdo',
                'report.consumption.dg_scrubber_mdo',
            ]
            var b10lfoDieselGenBreakdown = [
                'report.consumption.mdg_port_b10lfo',
                'report.consumption.mdg_starboard_b10lfo',
                'report.consumption.hdg_b10lfo',
                'report.consumption.dg_b10lfo',
                'report.consumption.dg_ballast_operations_b10lfo',
                'report.consumption.dg_crane_operations_b10lfo',
                'report.consumption.dg_cdl_operations_b10lfo',
                'report.consumption.dg_cargo_heating_b10lfo',
                'report.consumption.dg_tank_cleaning_b10lfo',
                'report.consumption.dg_cargo_shifting_b10lfo',
                'report.consumption.dg_inert_gas_systems_b10lfo',
                'report.consumption.dg_cargo_hold_washing_b10lfo',
                'report.consumption.dg_scrubber_b10lfo',
            ]
            var b10mgoDieselGenBreakdown = [
                'report.consumption.mdg_port_b10mgo',
                'report.consumption.mdg_starboard_b10mgo',
                'report.consumption.hdg_b10mgo',
                'report.consumption.dg_b10mgo',
                'report.consumption.dg_ballast_operations_b10mgo',
                'report.consumption.dg_crane_operations_b10mgo',
                'report.consumption.dg_cdl_operations_b10mgo',
                'report.consumption.dg_cargo_heating_b10mgo',
                'report.consumption.dg_tank_cleaning_b10mgo',
                'report.consumption.dg_cargo_shifting_b10mgo',
                'report.consumption.dg_inert_gas_systems_b10mgo',
                'report.consumption.dg_cargo_hold_washing_b10mgo',
                'report.consumption.dg_scrubber_b10mgo',
            ]
            var biolfoDieselGenBreakdown = [
                'report.consumption.mdg_port_biolfo',
                'report.consumption.mdg_starboard_biolfo',
                'report.consumption.hdg_biolfo',
                'report.consumption.dg_biolfo',
                'report.consumption.dg_ballast_operations_biolfo',
                'report.consumption.dg_crane_operations_biolfo',
                'report.consumption.dg_cdl_operations_biolfo',
                'report.consumption.dg_cargo_heating_biolfo',
                'report.consumption.dg_tank_cleaning_biolfo',
                'report.consumption.dg_cargo_shifting_biolfo',
                'report.consumption.dg_inert_gas_systems_biolfo',
                'report.consumption.dg_cargo_hold_washing_biolfo',
                'report.consumption.dg_scrubber_biolfo',
            ]
            var biomgoDieselGenBreakdown = [
                'report.consumption.mdg_port_biomgo',
                'report.consumption.mdg_starboard_biomgo',
                'report.consumption.hdg_biomgo',
                'report.consumption.dg_biomgo',
                'report.consumption.dg_ballast_operations_biomgo',
                'report.consumption.dg_crane_operations_biomgo',
                'report.consumption.dg_cdl_operations_biomgo',
                'report.consumption.dg_cargo_heating_biomgo',
                'report.consumption.dg_tank_cleaning_biomgo',
                'report.consumption.dg_cargo_shifting_biomgo',
                'report.consumption.dg_inert_gas_systems_biomgo',
                'report.consumption.dg_cargo_hold_washing_biomgo',
                'report.consumption.dg_scrubber_biomgo',
            ]
            var ulsfo2020DieselGenBreakdown = [
                'report.consumption.mdg_port_ulsfo2020',
                'report.consumption.mdg_starboard_ulsfo2020',
                'report.consumption.hdg_ulsfo2020',
                'report.consumption.dg_ulsfo2020',
                'report.consumption.dg_ballast_operations_ulsfo2020',
                'report.consumption.dg_crane_operations_ulsfo2020',
                'report.consumption.dg_cdl_operations_ulsfo2020',
                'report.consumption.dg_cargo_heating_ulsfo2020',
                'report.consumption.dg_tank_cleaning_ulsfo2020',
                'report.consumption.dg_cargo_shifting_ulsfo2020',
                'report.consumption.dg_inert_gas_systems_ulsfo2020',
                'report.consumption.dg_cargo_hold_washing_ulsfo2020',
                'report.consumption.dg_scrubber_ulsfo2020',
            ]
            var ulslfo2020DieselGenBreakdown = [
                'report.consumption.mdg_port_ulslfo2020',
                'report.consumption.mdg_starboard_ulslfo2020',
                'report.consumption.hdg_ulslfo2020',
                'report.consumption.dg_ulslfo2020',
                'report.consumption.dg_ballast_operations_ulslfo2020',
                'report.consumption.dg_crane_operations_ulslfo2020',
                'report.consumption.dg_cdl_operations_ulslfo2020',
                'report.consumption.dg_cargo_heating_ulslfo2020',
                'report.consumption.dg_tank_cleaning_ulslfo2020',
                'report.consumption.dg_cargo_shifting_ulslfo2020',
                'report.consumption.dg_inert_gas_systems_ulslfo2020',
                'report.consumption.dg_cargo_hold_washing_ulslfo2020',
                'report.consumption.dg_scrubber_ulslfo2020',
            ]
            var ulsmdo2020DieselGenBreakdown = [
                'report.consumption.mdg_port_ulsmdo2020',
                'report.consumption.mdg_starboard_ulsmdo2020',
                'report.consumption.hdg_ulsmdo2020',
                'report.consumption.dg_ulsmdo2020',
                'report.consumption.dg_ballast_operations_ulsmdo2020',
                'report.consumption.dg_crane_operations_ulsmdo2020',
                'report.consumption.dg_cdl_operations_ulsmdo2020',
                'report.consumption.dg_cargo_heating_ulsmdo2020',
                'report.consumption.dg_tank_cleaning_ulsmdo2020',
                'report.consumption.dg_cargo_shifting_ulsmdo2020',
                'report.consumption.dg_inert_gas_systems_ulsmdo2020',
                'report.consumption.dg_cargo_hold_washing_ulsmdo2020',
                'report.consumption.dg_scrubber_ulsmdo2020',
            ]
            var ulsmgo2020DieselGenBreakdown = [
                'report.consumption.mdg_port_ulsmgo2020',
                'report.consumption.mdg_starboard_ulsmgo2020',
                'report.consumption.hdg_ulsmgo2020',
                'report.consumption.dg_ulsmgo2020',
                'report.consumption.dg_ballast_operations_ulsmgo2020',
                'report.consumption.dg_crane_operations_ulsmgo2020',
                'report.consumption.dg_cdl_operations_ulsmgo2020',
                'report.consumption.dg_cargo_heating_ulsmgo2020',
                'report.consumption.dg_tank_cleaning_ulsmgo2020',
                'report.consumption.dg_cargo_shifting_ulsmgo2020',
                'report.consumption.dg_inert_gas_systems_ulsmgo2020',
                'report.consumption.dg_cargo_hold_washing_ulsmgo2020',
                'report.consumption.dg_scrubber_ulsmgo2020',
            ]
            var vlsfo2020DieselGenBreakdown = [
                'report.consumption.mdg_port_vlsfo2020',
                'report.consumption.mdg_starboard_vlsfo2020',
                'report.consumption.hdg_vlsfo2020',
                'report.consumption.dg_vlsfo2020',
                'report.consumption.dg_ballast_operations_vlsfo2020',
                'report.consumption.dg_crane_operations_vlsfo2020',
                'report.consumption.dg_cdl_operations_vlsfo2020',
                'report.consumption.dg_cargo_heating_vlsfo2020',
                'report.consumption.dg_tank_cleaning_vlsfo2020',
                'report.consumption.dg_cargo_shifting_vlsfo2020',
                'report.consumption.dg_inert_gas_systems_vlsfo2020',
                'report.consumption.dg_cargo_hold_washing_vlsfo2020',
                'report.consumption.dg_scrubber_vlsfo2020',
            ]
            var vlslfo2020DieselGenBreakdown = [
                'report.consumption.mdg_port_vlslfo2020',
                'report.consumption.mdg_starboard_vlslfo2020',
                'report.consumption.hdg_vlslfo2020',
                'report.consumption.dg_vlslfo2020',
                'report.consumption.dg_ballast_operations_vlslfo2020',
                'report.consumption.dg_crane_operations_vlslfo2020',
                'report.consumption.dg_cdl_operations_vlslfo2020',
                'report.consumption.dg_cargo_heating_vlslfo2020',
                'report.consumption.dg_tank_cleaning_vlslfo2020',
                'report.consumption.dg_cargo_shifting_vlslfo2020',
                'report.consumption.dg_inert_gas_systems_vlslfo2020',
                'report.consumption.dg_cargo_hold_washing_vlslfo2020',
                'report.consumption.dg_scrubber_vlslfo2020',
            ]
            var lpgpDieselGenBreakdown = [
                'report.consumption.mdg_port_lpgp',
                'report.consumption.mdg_starboard_lpgp',
                'report.consumption.hdg_lpgp',
                'report.consumption.dg_lpgp',
                'report.consumption.dg_ballast_operations_lpgp',
                'report.consumption.dg_crane_operations_lpgp',
                'report.consumption.dg_cdl_operations_lpgp',
                'report.consumption.dg_cargo_heating_lpgp',
                'report.consumption.dg_tank_cleaning_lpgp',
                'report.consumption.dg_cargo_shifting_lpgp',
                'report.consumption.dg_inert_gas_systems_lpgp',
                'report.consumption.dg_cargo_hold_washing_lpgp',
                'report.consumption.dg_scrubber_lpgp',
            ]
            var lpgbDieselGenBreakdown = [
                'report.consumption.mdg_port_lpgb',
                'report.consumption.mdg_starboard_lpgb',
                'report.consumption.hdg_lpgb',
                'report.consumption.dg_lpgb',
                'report.consumption.dg_ballast_operations_lpgb',
                'report.consumption.dg_crane_operations_lpgb',
                'report.consumption.dg_cdl_operations_lpgb',
                'report.consumption.dg_cargo_heating_lpgb',
                'report.consumption.dg_tank_cleaning_lpgb',
                'report.consumption.dg_cargo_shifting_lpgb',
                'report.consumption.dg_inert_gas_systems_lpgb',
                'report.consumption.dg_cargo_hold_washing_lpgb',
                'report.consumption.dg_scrubber_lpgb',
            ]
            var lngDieselGenBreakdown = [
                'report.consumption.mdg_port_lng',
                'report.consumption.mdg_starboard_lng',
                'report.consumption.hdg_lng',
                'report.consumption.dg_lng',
                'report.consumption.dg_ballast_operations_lng',
                'report.consumption.dg_crane_operations_lng',
                'report.consumption.dg_cdl_operations_lng',
                'report.consumption.dg_cargo_heating_lng',
                'report.consumption.dg_tank_cleaning_lng',
                'report.consumption.dg_cargo_shifting_lng',
                'report.consumption.dg_inert_gas_systems_lng',
                'report.consumption.dg_cargo_hold_washing_lng',
                'report.consumption.dg_scrubber_lng',
            ]
            var methanolDieselGenBreakdown = [
                'report.consumption.mdg_port_methanol',
                'report.consumption.mdg_starboard_methanol',
                'report.consumption.hdg_methanol',
                'report.consumption.dg_methanol',
                'report.consumption.dg_ballast_operations_methanol',
                'report.consumption.dg_crane_operations_methanol',
                'report.consumption.dg_cdl_operations_methanol',
                'report.consumption.dg_cargo_heating_methanol',
                'report.consumption.dg_tank_cleaning_methanol',
                'report.consumption.dg_cargo_shifting_methanol',
                'report.consumption.dg_inert_gas_systems_methanol',
                'report.consumption.dg_cargo_hold_washing_methanol',
                'report.consumption.dg_scrubber_methanol',
            ]
            var ethanolDieselGenBreakdown = [
                'report.consumption.mdg_port_ethanol',
                'report.consumption.mdg_starboard_ethanol',
                'report.consumption.hdg_ethanol',
                'report.consumption.dg_ethanol',
                'report.consumption.dg_ballast_operations_ethanol',
                'report.consumption.dg_crane_operations_ethanol',
                'report.consumption.dg_cdl_operations_ethanol',
                'report.consumption.dg_cargo_heating_ethanol',
                'report.consumption.dg_tank_cleaning_ethanol',
                'report.consumption.dg_cargo_shifting_ethanol',
                'report.consumption.dg_inert_gas_systems_ethanol',
                'report.consumption.dg_cargo_hold_washing_ethanol',
                'report.consumption.dg_scrubber_ethanol',
            ]
            var otherDieselGenBreakdown = [
                'report.consumption.mdg_port_other',
                'report.consumption.mdg_starboard_other',
                'report.consumption.hdg_other',
                'report.consumption.dg_other',
                'report.consumption.dg_ballast_operations_other',
                'report.consumption.dg_crane_operations_other',
                'report.consumption.dg_cdl_operations_other',
                'report.consumption.dg_cargo_heating_other',
                'report.consumption.dg_tank_cleaning_other',
                'report.consumption.dg_cargo_shifting_other',
                'report.consumption.dg_inert_gas_systems_other',
                'report.consumption.dg_cargo_hold_washing_other',
                'report.consumption.dg_scrubber_other',
            ]

            // setValidity for dgBasicLoad is commented out due to issue with formulas. 
            // Formula for dbBaseLoadConsumption is as was provided to us, however 
            // vessels reported issues here when propulsion_power was greater than diesel_gen_total_power.
            // Our formula says this case shouldn't be allowed, but vessels said this should not lead to a negative basic load.

            scope.$watchGroup(hfoDieselGenBreakdown, function(newValues, oldValues, scope){
                var mdg_port = newValues[0] || 0;
                var mdg_starboard = newValues[1] || 0;
                var hdg = newValues[2] || 0;
                var dg = newValues[3] || 0;
                let dgBaseLoadConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(1-(scope.propulsion_power/scope.diesel_gen_total_power))) || 0;
                let propulsionConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(scope.propulsion_power/scope.diesel_gen_total_power)) || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    dgBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.dg_basic_load_hfo = roundToPlaces(dgBaseLoadConsumption, 2);
                scope.report.consumption.prop_cons_hfo = roundToPlaces(propulsionConsumption, 2);
                // scope.reportForm.consumptions.dgBasicLoadHFO.$setValidity('dgBasicLoadHFO', dgBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(lfoDieselGenBreakdown, function(newValues, oldValues, scope){
                var mdg_port = newValues[0] || 0;
                var mdg_starboard = newValues[1] || 0;
                var hdg = newValues[2] || 0;
                var dg = newValues[3] || 0;
                let dgBaseLoadConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(1-(scope.propulsion_power/scope.diesel_gen_total_power))) || 0;
                let propulsionConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(scope.propulsion_power/scope.diesel_gen_total_power)) || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    dgBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.dg_basic_load_lfo = roundToPlaces(dgBaseLoadConsumption, 2);
                scope.report.consumption.prop_cons_lfo = roundToPlaces(propulsionConsumption, 2);
                // scope.reportForm.consumptions.dgBasicLoadLFO.$setValidity('dgBasicLoadLFO', dgBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(mgoDieselGenBreakdown, function(newValues, oldValues, scope){
                var mdg_port = newValues[0] || 0;
                var mdg_starboard = newValues[1] || 0;
                var hdg = newValues[2] || 0;
                var dg = newValues[3] || 0;
                let dgBaseLoadConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(1-(scope.propulsion_power/scope.diesel_gen_total_power))) || 0;
                let propulsionConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(scope.propulsion_power/scope.diesel_gen_total_power)) || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    dgBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.dg_basic_load_mgo = roundToPlaces(dgBaseLoadConsumption, 2);
                scope.report.consumption.prop_cons_mgo = roundToPlaces(propulsionConsumption, 2);
                // scope.reportForm.consumptions.dgBasicLoadMGO.$setValidity('dgBasicLoadMGO', dgBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(mdoDieselGenBreakdown, function(newValues, oldValues, scope){
                var mdg_port = newValues[0] || 0;
                var mdg_starboard = newValues[1] || 0;
                var hdg = newValues[2] || 0;
                var dg = newValues[3] || 0;
                let dgBaseLoadConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(1-(scope.propulsion_power/scope.diesel_gen_total_power))) || 0;
                let propulsionConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(scope.propulsion_power/scope.diesel_gen_total_power)) || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    dgBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.dg_basic_load_mdo = roundToPlaces(dgBaseLoadConsumption, 2);
                scope.report.consumption.prop_cons_mdo = roundToPlaces(propulsionConsumption, 2);
                // scope.reportForm.consumptions.dgBasicLoadMDO.$setValidity('dgBasicLoadMDO', dgBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(b10lfoDieselGenBreakdown, function(newValues, oldValues, scope){
                var mdg_port = newValues[0] || 0;
                var mdg_starboard = newValues[1] || 0;
                var hdg = newValues[2] || 0;
                var dg = newValues[3] || 0;
                let dgBaseLoadConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(1-(scope.propulsion_power/scope.diesel_gen_total_power))) || 0;
                let propulsionConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(scope.propulsion_power/scope.diesel_gen_total_power)) || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    dgBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.dg_basic_load_b10lfo = roundToPlaces(dgBaseLoadConsumption, 2);
                scope.report.consumption.prop_cons_b10lfo = roundToPlaces(propulsionConsumption, 2);
                // scope.reportForm.consumptions.dgBasicLoadB10LFO.$setValidity('dgBasicLoadB10LFO', dgBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(b10mgoDieselGenBreakdown, function(newValues, oldValues, scope){
                var mdg_port = newValues[0] || 0;
                var mdg_starboard = newValues[1] || 0;
                var hdg = newValues[2] || 0;
                var dg = newValues[3] || 0;
                let dgBaseLoadConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(1-(scope.propulsion_power/scope.diesel_gen_total_power))) || 0;
                let propulsionConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(scope.propulsion_power/scope.diesel_gen_total_power)) || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    dgBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.dg_basic_load_b10mgo = roundToPlaces(dgBaseLoadConsumption, 2);
                scope.report.consumption.prop_cons_b10mgo = roundToPlaces(propulsionConsumption, 2);
                // scope.reportForm.consumptions.dgBasicLoadB10MGO.$setValidity('dgBasicLoadB10MGO', dgBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(biolfoDieselGenBreakdown, function(newValues, oldValues, scope){
                var mdg_port = newValues[0] || 0;
                var mdg_starboard = newValues[1] || 0;
                var hdg = newValues[2] || 0;
                var dg = newValues[3] || 0;
                let dgBaseLoadConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(1-(scope.propulsion_power/scope.diesel_gen_total_power))) || 0;
                let propulsionConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(scope.propulsion_power/scope.diesel_gen_total_power)) || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    dgBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.dg_basic_load_biolfo = roundToPlaces(dgBaseLoadConsumption, 2);
                scope.report.consumption.prop_cons_biolfo = roundToPlaces(propulsionConsumption, 2);
                // scope.reportForm.consumptions.dgBasicLoadBIOLFO.$setValidity('dgBasicLoadBIOLFO', dgBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(biomgoDieselGenBreakdown, function(newValues, oldValues, scope){
                var mdg_port = newValues[0] || 0;
                var mdg_starboard = newValues[1] || 0;
                var hdg = newValues[2] || 0;
                var dg = newValues[3] || 0;
                let dgBaseLoadConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(1-(scope.propulsion_power/scope.diesel_gen_total_power))) || 0;
                let propulsionConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(scope.propulsion_power/scope.diesel_gen_total_power)) || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    dgBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.dg_basic_load_biomgo = roundToPlaces(dgBaseLoadConsumption, 2);
                scope.report.consumption.prop_cons_biomgo = roundToPlaces(propulsionConsumption, 2);
                // scope.reportForm.consumptions.dgBasicLoadBIOMGO.$setValidity('dgBasicLoadBIOMGO', dgBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(ulsfo2020DieselGenBreakdown, function(newValues, oldValues, scope){
                var mdg_port = newValues[0] || 0;
                var mdg_starboard = newValues[1] || 0;
                var hdg = newValues[2] || 0;
                var dg = newValues[3] || 0;
                let dgBaseLoadConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(1-(scope.propulsion_power/scope.diesel_gen_total_power))) || 0;
                let propulsionConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(scope.propulsion_power/scope.diesel_gen_total_power)) || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    dgBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.dg_basic_load_ulsfo2020 = roundToPlaces(dgBaseLoadConsumption, 2);
                scope.report.consumption.prop_cons_ulsfo2020 = roundToPlaces(propulsionConsumption, 2);
                // scope.reportForm.consumptions.dgBasicLoadULSFO2020.$setValidity('dgBasicLoadULSFO2020', dgBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(ulslfo2020DieselGenBreakdown, function(newValues, oldValues, scope){
                var mdg_port = newValues[0] || 0;
                var mdg_starboard = newValues[1] || 0;
                var hdg = newValues[2] || 0;
                var dg = newValues[3] || 0;
                let dgBaseLoadConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(1-(scope.propulsion_power/scope.diesel_gen_total_power))) || 0;
                let propulsionConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(scope.propulsion_power/scope.diesel_gen_total_power)) || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    dgBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.dg_basic_load_ulslfo2020 = roundToPlaces(dgBaseLoadConsumption, 2);
                scope.report.consumption.prop_cons_ulslfo2020 = roundToPlaces(propulsionConsumption, 2);
                // scope.reportForm.consumptions.dgBasicLoadULSLFO2020.$setValidity('dgBasicLoadULSLFO2020', dgBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(ulsmdo2020DieselGenBreakdown, function(newValues, oldValues, scope){
                var mdg_port = newValues[0] || 0;
                var mdg_starboard = newValues[1] || 0;
                var hdg = newValues[2] || 0;
                var dg = newValues[3] || 0;
                let dgBaseLoadConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(1-(scope.propulsion_power/scope.diesel_gen_total_power))) || 0;
                let propulsionConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(scope.propulsion_power/scope.diesel_gen_total_power)) || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    dgBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.dg_basic_load_ulsmdo2020 = roundToPlaces(dgBaseLoadConsumption, 2);
                scope.report.consumption.prop_cons_ulsmdo2020 = roundToPlaces(propulsionConsumption, 2);
                // scope.reportForm.consumptions.dgBasicLoadULSMDO2020.$setValidity('dgBasicLoadULSMDO2020', dgBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(ulsmgo2020DieselGenBreakdown, function(newValues, oldValues, scope){
                var mdg_port = newValues[0] || 0;
                var mdg_starboard = newValues[1] || 0;
                var hdg = newValues[2] || 0;
                var dg = newValues[3] || 0;
                let dgBaseLoadConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(1-(scope.propulsion_power/scope.diesel_gen_total_power))) || 0;
                let propulsionConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(scope.propulsion_power/scope.diesel_gen_total_power)) || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    dgBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.dg_basic_load_ulsmgo2020 = roundToPlaces(dgBaseLoadConsumption, 2);
                scope.report.consumption.prop_cons_ulsmgo2020 = roundToPlaces(propulsionConsumption, 2);
                // scope.reportForm.consumptions.dgBasicLoadULSMGO2020.$setValidity('dgBasicLoadULSMGO2020', dgBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(vlsfo2020DieselGenBreakdown, function(newValues, oldValues, scope){
                var mdg_port = newValues[0] || 0;
                var mdg_starboard = newValues[1] || 0;
                var hdg = newValues[2] || 0;
                var dg = newValues[3] || 0;
                let dgBaseLoadConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(1-(scope.propulsion_power/scope.diesel_gen_total_power))) || 0;
                let propulsionConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(scope.propulsion_power/scope.diesel_gen_total_power)) || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    dgBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.dg_basic_load_vlsfo2020 = roundToPlaces(dgBaseLoadConsumption, 2);
                scope.report.consumption.prop_cons_vlsfo2020 = roundToPlaces(propulsionConsumption, 2);
                // scope.reportForm.consumptions.dgBasicLoadVLSFO2020.$setValidity('dgBasicLoadVLSFO2020', dgBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(vlslfo2020DieselGenBreakdown, function(newValues, oldValues, scope){
                var mdg_port = newValues[0] || 0;
                var mdg_starboard = newValues[1] || 0;
                var hdg = newValues[2] || 0;
                var dg = newValues[3] || 0;
                let dgBaseLoadConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(1-(scope.propulsion_power/scope.diesel_gen_total_power))) || 0;
                let propulsionConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(scope.propulsion_power/scope.diesel_gen_total_power)) || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    dgBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.dg_basic_load_vlslfo2020 = roundToPlaces(dgBaseLoadConsumption, 2);
                scope.report.consumption.prop_cons_vlslfo2020 = roundToPlaces(propulsionConsumption, 2);
                // scope.reportForm.consumptions.dgBasicLoadVLSLFO2020.$setValidity('dgBasicLoadVLSLFO2020', dgBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(lpgpDieselGenBreakdown, function(newValues, oldValues, scope){
                var mdg_port = newValues[0] || 0;
                var mdg_starboard = newValues[1] || 0;
                var hdg = newValues[2] || 0;
                var dg = newValues[3] || 0;
                let dgBaseLoadConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(1-(scope.propulsion_power/scope.diesel_gen_total_power))) || 0;
                let propulsionConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(scope.propulsion_power/scope.diesel_gen_total_power)) || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    dgBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.dg_basic_load_lpgp = roundToPlaces(dgBaseLoadConsumption, 2);
                scope.report.consumption.prop_cons_lpgp = roundToPlaces(propulsionConsumption, 2);
                // scope.reportForm.consumptions.dgBasicLoadLPGP.$setValidity('dgBasicLoadLPGP', dgBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(lpgbDieselGenBreakdown, function(newValues, oldValues, scope){
                var mdg_port = newValues[0] || 0;
                var mdg_starboard = newValues[1] || 0;
                var hdg = newValues[2] || 0;
                var dg = newValues[3] || 0;
                let dgBaseLoadConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(1-(scope.propulsion_power/scope.diesel_gen_total_power))) || 0;
                let propulsionConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(scope.propulsion_power/scope.diesel_gen_total_power)) || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    dgBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.dg_basic_load_lpgb = roundToPlaces(dgBaseLoadConsumption, 2);
                scope.report.consumption.prop_cons_lpgb = roundToPlaces(propulsionConsumption, 2);
                // scope.reportForm.consumptions.dgBasicLoadLPGB.$setValidity('dgBasicLoadLPGB', dgBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(lngDieselGenBreakdown, function(newValues, oldValues, scope){
                var mdg_port = newValues[0] || 0;
                var mdg_starboard = newValues[1] || 0;
                var hdg = newValues[2] || 0;
                var dg = newValues[3] || 0;
                let dgBaseLoadConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(1-(scope.propulsion_power/scope.diesel_gen_total_power))) || 0;
                let propulsionConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(scope.propulsion_power/scope.diesel_gen_total_power)) || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    dgBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.dg_basic_load_lng = roundToPlaces(dgBaseLoadConsumption, 2);
                scope.report.consumption.prop_cons_lng = roundToPlaces(propulsionConsumption, 2);
                // scope.reportForm.consumptions.dgBasicLoadLNG.$setValidity('dgBasicLoadLNG', dgBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(methanolDieselGenBreakdown, function(newValues, oldValues, scope){
                var mdg_port = newValues[0] || 0;
                var mdg_starboard = newValues[1] || 0;
                var hdg = newValues[2] || 0;
                var dg = newValues[3] || 0;
                let dgBaseLoadConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(1-(scope.propulsion_power/scope.diesel_gen_total_power))) || 0;
                let propulsionConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(scope.propulsion_power/scope.diesel_gen_total_power)) || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    dgBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.dg_basic_load_methanol = roundToPlaces(dgBaseLoadConsumption, 2);
                scope.report.consumption.prop_cons_methanol = roundToPlaces(propulsionConsumption, 2);
                // scope.reportForm.consumptions.dgBasicLoadMETHANOL.$setValidity('dgBasicLoadMETHANOL', dgBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(ethanolDieselGenBreakdown, function(newValues, oldValues, scope){
                var mdg_port = newValues[0] || 0;
                var mdg_starboard = newValues[1] || 0;
                var hdg = newValues[2] || 0;
                var dg = newValues[3] || 0;
                let dgBaseLoadConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(1-(scope.propulsion_power/scope.diesel_gen_total_power))) || 0;
                let propulsionConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(scope.propulsion_power/scope.diesel_gen_total_power)) || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    dgBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.dg_basic_load_ethanol = roundToPlaces(dgBaseLoadConsumption, 2);
                scope.report.consumption.prop_cons_ethanol = roundToPlaces(propulsionConsumption, 2);
                // scope.reportForm.consumptions.dgBasicLoadETHANOL.$setValidity('dgBasicLoadETHANOL', dgBaseLoadConsumption >= 0);
            });
            scope.$watchGroup(otherDieselGenBreakdown, function(newValues, oldValues, scope){
                var mdg_port = newValues[0] || 0;
                var mdg_starboard = newValues[1] || 0;
                var hdg = newValues[2] || 0;
                var dg = newValues[3] || 0;
                let dgBaseLoadConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(1-(scope.propulsion_power/scope.diesel_gen_total_power))) || 0;
                let propulsionConsumption = ((mdg_port + mdg_starboard + hdg + dg)*(scope.propulsion_power/scope.diesel_gen_total_power)) || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    dgBaseLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.dg_basic_load_other = roundToPlaces(dgBaseLoadConsumption, 2);
                scope.report.consumption.prop_cons_other = roundToPlaces(propulsionConsumption, 2);
                // scope.reportForm.consumptions.dgBasicLoadOTHER.$setValidity('dgBasicLoadOTHER', dgBaseLoadConsumption >= 0);
            });

            // aux boiler breakdown calculations
            var hshfoBoilerBreakdown = [
                'report.consumption.aux_boiler_hshfo',
                'report.consumption.ab_cargo_heating_hshfo',
                'report.consumption.ab_bunker_heating_hshfo',
                'report.consumption.ab_ballast_operations_hshfo',
                'report.consumption.ab_tank_cleaning_hshfo',
                'report.consumption.ab_cargo_shifting_hshfo',
                'report.consumption.ab_inert_gas_systems_hshfo',
            ];
            var lshfoBoilerBreakdown = [
                'report.consumption.aux_boiler_lshfo',
                'report.consumption.ab_cargo_heating_lshfo',
                'report.consumption.ab_bunker_heating_lshfo',
                'report.consumption.ab_ballast_operations_lshfo',
                'report.consumption.ab_tank_cleaning_lshfo',
                'report.consumption.ab_cargo_shifting_lshfo',
                'report.consumption.ab_inert_gas_systems_lshfo',
            ];
            var ulsfoBoilerBreakdown = [
                'report.consumption.aux_boiler_ulsfo',
                'report.consumption.ab_cargo_heating_ulsfo',
                'report.consumption.ab_bunker_heating_ulsfo',
                'report.consumption.ab_ballast_operations_ulsfo',
                'report.consumption.ab_tank_cleaning_ulsfo',
                'report.consumption.ab_cargo_shifting_ulsfo',
                'report.consumption.ab_inert_gas_systems_ulsfo',
            ];
            var lsmdoBoilerBreakdown = [
                'report.consumption.aux_boiler_lsmdo',
                'report.consumption.ab_cargo_heating_lsmdo',
                'report.consumption.ab_bunker_heating_lsmdo',
                'report.consumption.ab_ballast_operations_lsmdo',
                'report.consumption.ab_tank_cleaning_lsmdo',
                'report.consumption.ab_cargo_shifting_lsmdo',
                'report.consumption.ab_inert_gas_systems_lsmdo',
            ];
            var hsmgoBoilerBreakdown = [
                'report.consumption.aux_boiler_hsmgo',
                'report.consumption.ab_cargo_heating_hsmgo',
                'report.consumption.ab_bunker_heating_hsmgo',
                'report.consumption.ab_ballast_operations_hsmgo',
                'report.consumption.ab_tank_cleaning_hsmgo',
                'report.consumption.ab_cargo_shifting_hsmgo',
                'report.consumption.ab_inert_gas_systems_hsmgo',
            ];
            var lsmgoBoilerBreakdown = [
                'report.consumption.aux_boiler_lsmgo',
                'report.consumption.ab_cargo_heating_lsmgo',
                'report.consumption.ab_bunker_heating_lsmgo',
                'report.consumption.ab_ballast_operations_lsmgo',
                'report.consumption.ab_tank_cleaning_lsmgo',
                'report.consumption.ab_cargo_shifting_lsmgo',
                'report.consumption.ab_inert_gas_systems_lsmgo',
            ];

            scope.$watchGroup(hshfoBoilerBreakdown, function(newValues, oldValues, scope) {
                var abBasicLoad = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 1) {
                    abBasicLoad -= sumValues(breakdown);
                }
                scope.report.consumption.ab_basic_load_hshfo = roundToPlaces(abBasicLoad, 2);
                scope.reportForm?.consumptions?.abBasicLoadHSHFO?.$setValidity('abBasicLoadHSHFO', abBasicLoad >= 0);
            })
            scope.$watchGroup(lshfoBoilerBreakdown, function(newValues, oldValues, scope) {
                var abBasicLoad = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 1) {
                    abBasicLoad -= sumValues(breakdown);
                }
                scope.report.consumption.ab_basic_load_lshfo = roundToPlaces(abBasicLoad, 2);
                scope.reportForm?.consumptions?.abBasicLoadLSHFO?.$setValidity('abBasicLoadLSHFO', abBasicLoad >= 0);
            })
            scope.$watchGroup(ulsfoBoilerBreakdown, function(newValues, oldValues, scope) {
                var abBasicLoad = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 1) {
                    abBasicLoad -= sumValues(breakdown);
                }
                scope.report.consumption.ab_basic_load_ulsfo = roundToPlaces(abBasicLoad, 2);
                scope.reportForm?.consumptions?.abBasicLoadULSFO?.$setValidity('abBasicLoadULSFO', abBasicLoad >= 0);
            })
            scope.$watchGroup(lsmdoBoilerBreakdown, function(newValues, oldValues, scope) {
                var abBasicLoad = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 1) {
                    abBasicLoad -= sumValues(breakdown);
                }
                scope.report.consumption.ab_basic_load_lsmdo = roundToPlaces(abBasicLoad, 2);
                scope.reportForm?.consumptions?.abBasicLoadLSMDO?.$setValidity('abBasicLoadLSMDO', abBasicLoad >= 0);
            })
            scope.$watchGroup(hsmgoBoilerBreakdown, function(newValues, oldValues, scope) {
                var abBasicLoad = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 1) {
                    abBasicLoad -= sumValues(breakdown);
                }
                scope.report.consumption.ab_basic_load_hsmgo = roundToPlaces(abBasicLoad, 2);
                scope.reportForm?.consumptions?.abBasicLoadHSMGO?.$setValidity('abBasicLoadHSMGO', abBasicLoad >= 0);
            })
            scope.$watchGroup(lsmgoBoilerBreakdown, function(newValues, oldValues, scope) {
                var abBasicLoad = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 1) {
                    abBasicLoad -= sumValues(breakdown);
                }
                scope.report.consumption.ab_basic_load_lsmgo = roundToPlaces(abBasicLoad, 2);
                scope.reportForm?.consumptions?.abBasicLoadLSMGO?.$setValidity('abBasicLoadLSMGO', abBasicLoad >= 0);
            })

            var hfoBoilerBreakdown = [
                'report.consumption.aux_boiler_hfo',
                'report.consumption.ab_ballast_operations_hfo',
                'report.consumption.ab_cargo_heating_hfo',
                'report.consumption.ab_tank_cleaning_hfo',
                'report.consumption.ab_cargo_shifting_hfo',
                'report.consumption.ab_inert_gas_systems_hfo',
            ]
            var lfoBoilerBreakdown = [
                'report.consumption.aux_boiler_lfo',
                'report.consumption.ab_ballast_operations_lfo',
                'report.consumption.ab_cargo_heating_lfo',
                'report.consumption.ab_tank_cleaning_lfo',
                'report.consumption.ab_cargo_shifting_lfo',
                'report.consumption.ab_inert_gas_systems_lfo',
            ]
            var mgoBoilerBreakdown = [
                'report.consumption.aux_boiler_mgo',
                'report.consumption.ab_ballast_operations_mgo',
                'report.consumption.ab_cargo_heating_mgo',
                'report.consumption.ab_tank_cleaning_mgo',
                'report.consumption.ab_cargo_shifting_mgo',
                'report.consumption.ab_inert_gas_systems_mgo',
            ]
            var mdoBoilerBreakdown = [
                'report.consumption.aux_boiler_mdo',
                'report.consumption.ab_ballast_operations_mdo',
                'report.consumption.ab_cargo_heating_mdo',
                'report.consumption.ab_tank_cleaning_mdo',
                'report.consumption.ab_cargo_shifting_mdo',
                'report.consumption.ab_inert_gas_systems_mdo',
            ]
            var b10lfoBoilerBreakdown = [
                'report.consumption.aux_boiler_b10lfo',
                'report.consumption.ab_ballast_operations_b10lfo',
                'report.consumption.ab_cargo_heating_b10lfo',
                'report.consumption.ab_tank_cleaning_b10lfo',
                'report.consumption.ab_cargo_shifting_b10lfo',
                'report.consumption.ab_inert_gas_systems_b10lfo',
            ]
            var b10mgoBoilerBreakdown = [
                'report.consumption.aux_boiler_b10mgo',
                'report.consumption.ab_ballast_operations_b10mgo',
                'report.consumption.ab_cargo_heating_b10mgo',
                'report.consumption.ab_tank_cleaning_b10mgo',
                'report.consumption.ab_cargo_shifting_b10mgo',
                'report.consumption.ab_inert_gas_systems_b10mgo',
            ]
            var biolfoBoilerBreakdown = [
                'report.consumption.aux_boiler_biolfo',
                'report.consumption.ab_ballast_operations_biolfo',
                'report.consumption.ab_cargo_heating_biolfo',
                'report.consumption.ab_tank_cleaning_biolfo',
                'report.consumption.ab_cargo_shifting_biolfo',
                'report.consumption.ab_inert_gas_systems_biolfo',
            ]
            var biomgoBoilerBreakdown = [
                'report.consumption.aux_boiler_biomgo',
                'report.consumption.ab_ballast_operations_biomgo',
                'report.consumption.ab_cargo_heating_biomgo',
                'report.consumption.ab_tank_cleaning_biomgo',
                'report.consumption.ab_cargo_shifting_biomgo',
                'report.consumption.ab_inert_gas_systems_biomgo',
            ]
            var ulsfo2020BoilerBreakdown = [
                'report.consumption.aux_boiler_ulsfo2020',
                'report.consumption.ab_ballast_operations_ulsfo2020',
                'report.consumption.ab_cargo_heating_ulsfo2020',
                'report.consumption.ab_tank_cleaning_ulsfo2020',
                'report.consumption.ab_cargo_shifting_ulsfo2020',
                'report.consumption.ab_inert_gas_systems_ulsfo2020',
            ]
            var ulslfo2020BoilerBreakdown = [
                'report.consumption.aux_boiler_ulslfo2020',
                'report.consumption.ab_ballast_operations_ulslfo2020',
                'report.consumption.ab_cargo_heating_ulslfo2020',
                'report.consumption.ab_tank_cleaning_ulslfo2020',
                'report.consumption.ab_cargo_shifting_ulslfo2020',
                'report.consumption.ab_inert_gas_systems_ulslfo2020',
            ]
            var ulsmdo2020BoilerBreakdown = [
                'report.consumption.aux_boiler_ulsmdo2020',
                'report.consumption.ab_ballast_operations_ulsmdo2020',
                'report.consumption.ab_cargo_heating_ulsmdo2020',
                'report.consumption.ab_tank_cleaning_ulsmdo2020',
                'report.consumption.ab_cargo_shifting_ulsmdo2020',
                'report.consumption.ab_inert_gas_systems_ulsmdo2020',
            ]
            var ulsmgo2020BoilerBreakdown = [
                'report.consumption.aux_boiler_ulsmgo2020',
                'report.consumption.ab_ballast_operations_ulsmgo2020',
                'report.consumption.ab_cargo_heating_ulsmgo2020',
                'report.consumption.ab_tank_cleaning_ulsmgo2020',
                'report.consumption.ab_cargo_shifting_ulsmgo2020',
                'report.consumption.ab_inert_gas_systems_ulsmgo2020',
            ]
            var vlsfo2020BoilerBreakdown = [
                'report.consumption.aux_boiler_vlsfo2020',
                'report.consumption.ab_ballast_operations_vlsfo2020',
                'report.consumption.ab_cargo_heating_vlsfo2020',
                'report.consumption.ab_tank_cleaning_vlsfo2020',
                'report.consumption.ab_cargo_shifting_vlsfo2020',
                'report.consumption.ab_inert_gas_systems_vlsfo2020',
            ]
            var vlslfo2020BoilerBreakdown = [
                'report.consumption.aux_boiler_vlslfo2020',
                'report.consumption.ab_ballast_operations_vlslfo2020',
                'report.consumption.ab_cargo_heating_vlslfo2020',
                'report.consumption.ab_tank_cleaning_vlslfo2020',
                'report.consumption.ab_cargo_shifting_vlslfo2020',
                'report.consumption.ab_inert_gas_systems_vlslfo2020',
            ]
            var lpgpBoilerBreakdown = [
                'report.consumption.aux_boiler_lpgp',
                'report.consumption.ab_ballast_operations_lpgp',
                'report.consumption.ab_cargo_heating_lpgp',
                'report.consumption.ab_tank_cleaning_lpgp',
                'report.consumption.ab_cargo_shifting_lpgp',
                'report.consumption.ab_inert_gas_systems_lpgp',
            ]
            var lpgbBoilerBreakdown = [
                'report.consumption.aux_boiler_lpgb',
                'report.consumption.ab_ballast_operations_lpgb',
                'report.consumption.ab_cargo_heating_lpgb',
                'report.consumption.ab_tank_cleaning_lpgb',
                'report.consumption.ab_cargo_shifting_lpgb',
                'report.consumption.ab_inert_gas_systems_lpgb',
            ]
            var lngBoilerBreakdown = [
                'report.consumption.aux_boiler_lng',
                'report.consumption.ab_ballast_operations_lng',
                'report.consumption.ab_cargo_heating_lng',
                'report.consumption.ab_tank_cleaning_lng',
                'report.consumption.ab_cargo_shifting_lng',
                'report.consumption.ab_inert_gas_systems_lng',
            ]
            var methanolBoilerBreakdown = [
                'report.consumption.aux_boiler_methanol',
                'report.consumption.ab_ballast_operations_methanol',
                'report.consumption.ab_cargo_heating_methanol',
                'report.consumption.ab_tank_cleaning_methanol',
                'report.consumption.ab_cargo_shifting_methanol',
                'report.consumption.ab_inert_gas_systems_methanol',
            ]
            var ethanolBoilerBreakdown = [
                'report.consumption.aux_boiler_ethanol',
                'report.consumption.ab_ballast_operations_ethanol',
                'report.consumption.ab_cargo_heating_ethanol',
                'report.consumption.ab_tank_cleaning_ethanol',
                'report.consumption.ab_cargo_shifting_ethanol',
                'report.consumption.ab_inert_gas_systems_ethanol',
            ]
            var otherBoilerBreakdown = [
                'report.consumption.aux_boiler_other',
                'report.consumption.ab_ballast_operations_other',
                'report.consumption.ab_cargo_heating_other',
                'report.consumption.ab_tank_cleaning_other',
                'report.consumption.ab_cargo_shifting_other',
                'report.consumption.ab_inert_gas_systems_other',
            ]

            scope.$watchGroup(hfoBoilerBreakdown, function(newValues, oldValues, scope){
                let abBasicLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    abBasicLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ab_basic_load_hfo = roundToPlaces(abBasicLoadConsumption, 2);
                scope.reportForm?.consumptions?.abBasicLoadHFO?.$setValidity('abBasicLoadHFO', abBasicLoadConsumption >= 0);
            });
            scope.$watchGroup(lfoBoilerBreakdown, function(newValues, oldValues, scope){
                let abBasicLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    abBasicLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ab_basic_load_lfo = roundToPlaces(abBasicLoadConsumption, 2);
                scope.reportForm?.consumptions?.abBasicLoadLFO?.$setValidity('abBasicLoadLFO', abBasicLoadConsumption >= 0);
            });
            scope.$watchGroup(mgoBoilerBreakdown, function(newValues, oldValues, scope){
                let abBasicLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    abBasicLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ab_basic_load_mgo = roundToPlaces(abBasicLoadConsumption, 2);
                scope.reportForm?.consumptions?.abBasicLoadMGO?.$setValidity('abBasicLoadMGO', abBasicLoadConsumption >= 0);
            });
            scope.$watchGroup(mdoBoilerBreakdown, function(newValues, oldValues, scope){
                let abBasicLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    abBasicLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ab_basic_load_mdo = roundToPlaces(abBasicLoadConsumption, 2);
                scope.reportForm?.consumptions?.abBasicLoadMDO?.$setValidity('abBasicLoadMDO', abBasicLoadConsumption >= 0);
            });
            scope.$watchGroup(b10lfoBoilerBreakdown, function(newValues, oldValues, scope){
                let abBasicLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    abBasicLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ab_basic_load_b10lfo = roundToPlaces(abBasicLoadConsumption, 2);
                scope.reportForm?.consumptions?.abBasicLoadB10LFO?.$setValidity('abBasicLoadB10LFO', abBasicLoadConsumption >= 0);
            });
            scope.$watchGroup(b10mgoBoilerBreakdown, function(newValues, oldValues, scope){
                let abBasicLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    abBasicLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ab_basic_load_b10mgo = roundToPlaces(abBasicLoadConsumption, 2);
                scope.reportForm?.consumptions?.abBasicLoadB10MGO?.$setValidity('abBasicLoadB10MGO', abBasicLoadConsumption >= 0);
            });
            scope.$watchGroup(biolfoBoilerBreakdown, function(newValues, oldValues, scope){
                let abBasicLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    abBasicLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ab_basic_load_biolfo = roundToPlaces(abBasicLoadConsumption, 2);
                scope.reportForm?.consumptions?.abBasicLoadBIOLFO?.$setValidity('abBasicLoadBIOLFO', abBasicLoadConsumption >= 0);
            });
            scope.$watchGroup(biomgoBoilerBreakdown, function(newValues, oldValues, scope){
                let abBasicLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    abBasicLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ab_basic_load_biomgo = roundToPlaces(abBasicLoadConsumption, 2);
                scope.reportForm?.consumptions?.abBasicLoadBIOMGO?.$setValidity('abBasicLoadBIOMGO', abBasicLoadConsumption >= 0);
            });
            scope.$watchGroup(ulsfo2020BoilerBreakdown, function(newValues, oldValues, scope){
                let abBasicLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    abBasicLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ab_basic_load_ulsfo2020 = roundToPlaces(abBasicLoadConsumption, 2);
                scope.reportForm?.consumptions?.abBasicLoadULSFO2020?.$setValidity('abBasicLoadULSFO2020', abBasicLoadConsumption >= 0);
            });
            scope.$watchGroup(ulslfo2020BoilerBreakdown, function(newValues, oldValues, scope){
                let abBasicLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    abBasicLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ab_basic_load_ulslfo2020 = roundToPlaces(abBasicLoadConsumption, 2);
                scope.reportForm?.consumptions?.abBasicLoadULSLFO2020?.$setValidity('abBasicLoadULSLFO2020', abBasicLoadConsumption >= 0);
            });
            scope.$watchGroup(ulsmdo2020BoilerBreakdown, function(newValues, oldValues, scope){
                let abBasicLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    abBasicLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ab_basic_load_ulsmdo2020 = roundToPlaces(abBasicLoadConsumption, 2);
                scope.reportForm?.consumptions?.abBasicLoadULSMDO2020?.$setValidity('abBasicLoadULSMDO2020', abBasicLoadConsumption >= 0);
            });
            scope.$watchGroup(ulsmgo2020BoilerBreakdown, function(newValues, oldValues, scope){
                let abBasicLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    abBasicLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ab_basic_load_ulsmgo2020 = roundToPlaces(abBasicLoadConsumption, 2);
                scope.reportForm?.consumptions?.abBasicLoadULSMGO2020?.$setValidity('abBasicLoadULSMGO2020', abBasicLoadConsumption >= 0);
            });
            scope.$watchGroup(vlsfo2020BoilerBreakdown, function(newValues, oldValues, scope){
                let abBasicLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    abBasicLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ab_basic_load_vlsfo2020 = roundToPlaces(abBasicLoadConsumption, 2);
                scope.reportForm?.consumptions?.abBasicLoadVLSFO2020?.$setValidity('abBasicLoadVLSFO2020', abBasicLoadConsumption >= 0);
            });
            scope.$watchGroup(vlslfo2020BoilerBreakdown, function(newValues, oldValues, scope){
                let abBasicLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    abBasicLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ab_basic_load_vlslfo2020 = roundToPlaces(abBasicLoadConsumption, 2);
                scope.reportForm?.consumptions?.abBasicLoadVLSLFO2020?.$setValidity('abBasicLoadVLSLFO2020', abBasicLoadConsumption >= 0);
            });
            scope.$watchGroup(lpgpBoilerBreakdown, function(newValues, oldValues, scope){
                let abBasicLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    abBasicLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ab_basic_load_lpgp = roundToPlaces(abBasicLoadConsumption, 2);
                scope.reportForm?.consumptions?.abBasicLoadLPGP?.$setValidity('abBasicLoadLPGP', abBasicLoadConsumption >= 0);
            });
            scope.$watchGroup(lpgbBoilerBreakdown, function(newValues, oldValues, scope){
                let abBasicLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    abBasicLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ab_basic_load_lpgb = roundToPlaces(abBasicLoadConsumption, 2);
                scope.reportForm?.consumptions?.abBasicLoadLPGB?.$setValidity('abBasicLoadLPGB', abBasicLoadConsumption >= 0);
            });
            scope.$watchGroup(lngBoilerBreakdown, function(newValues, oldValues, scope){
                let abBasicLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    abBasicLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ab_basic_load_lng = roundToPlaces(abBasicLoadConsumption, 2);
                scope.reportForm?.consumptions?.abBasicLoadLNG?.$setValidity('abBasicLoadLNG', abBasicLoadConsumption >= 0);
            });
            scope.$watchGroup(methanolBoilerBreakdown, function(newValues, oldValues, scope){
                let abBasicLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    abBasicLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ab_basic_load_methanol = roundToPlaces(abBasicLoadConsumption, 2);
                scope.reportForm?.consumptions?.abBasicLoadMETHANOL?.$setValidity('abBasicLoadMETHANOL', abBasicLoadConsumption >= 0);
            });
            scope.$watchGroup(ethanolBoilerBreakdown, function(newValues, oldValues, scope){
                let abBasicLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    abBasicLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ab_basic_load_ethanol = roundToPlaces(abBasicLoadConsumption, 2);
                scope.reportForm?.consumptions?.abBasicLoadETHANOL?.$setValidity('abBasicLoadETHANOL', abBasicLoadConsumption >= 0);
            });
            scope.$watchGroup(otherBoilerBreakdown, function(newValues, oldValues, scope){
                let abBasicLoadConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    abBasicLoadConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.ab_basic_load_other = roundToPlaces(abBasicLoadConsumption, 2);
                scope.reportForm?.consumptions?.abBasicLoadOTHER?.$setValidity('abBasicLoadOTHER', abBasicLoadConsumption >= 0);
            });

            scope.isAFuelTypeSelected = function() {
                return !!scope.report.consumption.fuel_type_1 || !!scope.report.consumption.fuel_type_2 || !!scope.report.consumption.fuel_type_3
            }

            scope.shouldRequireMEConsumptions = function(fuelType) {
                return fuelType != null && (scope.isSeaReport(scope.report) || scope.report.power.main_engine_1_diff_rh > 0 || scope.report.power.main_engine_2_diff_rh > 0)
                    && scope.total_main_engine_consumption <= 0;
            };

            scope.shouldRequireMDGPortConsumptions = function() {
                return (sumValues([scope.report.power.diesel_gen_1_diff_rh,scope.report.power.diesel_gen_2_diff_rh])>0)
                    && scope.total_mdg_port_consumption <= 0;
            };

            scope.shouldRequireMDGStarboardConsumptions = function() {
                return (sumValues([scope.report.power.diesel_gen_3_diff_rh,scope.report.power.diesel_gen_4_diff_rh])>0)
                    && scope.total_mdg_starboard_consumption <= 0;
            };

            scope.shouldRequireHDGConsumptions = function() {
                return (scope.report.power.diesel_gen_5_diff_rh>0)
                    && scope.total_hdg_consumption <= 0;
            };

            scope.$watchGroup(['report.consumption.fuel_type_1','report.consumption.fuel_type_2','report.consumption.fuel_type_3'], function(newValues,oldValues,scope) {
                var cons_fuel_grades = newValues;
                var consumption_fuel_grades = cons_fuel_grades.filter(function(fg) { return !! fg; });
                const removed = oldValues.filter((val) => !newValues.includes(val)) 
                if (removed?.length) {
                    const removedFuelType = removed[0]
                    scope.report.consumption[`lcv_${removedFuelType}`] = ''
                    scope.report.consumption[`sulphur_${removedFuelType}`] = ''
                    scope.report.consumption[`density_${removedFuelType}`] = ''
                    scope.report.consumption[`serv_${removedFuelType}`] = ''
                    scope.report.consumption[`main_engine_${removedFuelType}`] = undefined
                    scope.report.consumption[`aux_engine_${removedFuelType}`] = undefined
                    scope.report.consumption[`mdg_port_${removedFuelType}`] = undefined
                    scope.report.consumption[`mdg_starboard_${removedFuelType}`] = undefined
                    scope.report.consumption[`hdg_${removedFuelType}`] = undefined
                    scope.report.consumption[`dg_${removedFuelType}`] = undefined
                    scope.report.consumption[`aux_boiler_${removedFuelType}`] = undefined
                    scope.report.consumption[`ig_generator_${removedFuelType}`] = undefined
                    scope.report.consumption[`incinerator_${removedFuelType}`] = undefined
                    scope.report.consumption[`other_${removedFuelType}`] = undefined
                    scope.report.consumption[`cargo_related_${removedFuelType}`] = undefined
                    scope.report.consumption[`ae_basic_load_${removedFuelType}`] = undefined
                    scope.report.consumption[`ae_ballast_operations_${removedFuelType}`] = undefined
                    scope.report.consumption[`ae_crane_operations_${removedFuelType}`] = undefined
                    scope.report.consumption[`ae_cargo_hold_washing_${removedFuelType}`] = undefined
                    scope.report.consumption[`ae_cdl_operations_${removedFuelType}`] = undefined
                    scope.report.consumption[`ae_cargo_heating_${removedFuelType}`] = undefined
                    scope.report.consumption[`ae_cargo_cooling_${removedFuelType}`] = undefined
                    scope.report.consumption[`ae_tank_cleaning_${removedFuelType}`] = undefined
                    scope.report.consumption[`ae_cargo_shifting_${removedFuelType}`] = undefined
                    scope.report.consumption[`ae_inert_gas_systems_${removedFuelType}`] = undefined
                    scope.report.consumption[`ae_scrubber_${removedFuelType}`] = undefined
                    scope.report.consumption[`ae_bunker_heating_${removedFuelType}`] = undefined
                    scope.report.consumption[`total_consumption_${removedFuelType}`] = undefined
                    scope.report.consumption[`dg_basic_load_${removedFuelType}`] = undefined
                    scope.report.consumption[`dg_ballast_operations_${removedFuelType}`] = undefined
                    scope.report.consumption[`dg_cargo_heating_${removedFuelType}`] = undefined
                    scope.report.consumption[`dg_tank_cleaning_${removedFuelType}`] = undefined
                    scope.report.consumption[`dg_cargo_shifting_${removedFuelType}`] = undefined
                    scope.report.consumption[`dg_inert_gas_systems_${removedFuelType}`] = undefined
                    scope.report.consumption[`ab_basic_load_${removedFuelType}`] = undefined
                    scope.report.consumption[`ab_ballast_operations_${removedFuelType}`] = undefined
                    scope.report.consumption[`ab_cargo_heating_${removedFuelType}`] = undefined
                    scope.report.consumption[`ab_tank_cleaning_${removedFuelType}`] = undefined
                    scope.report.consumption[`ab_cargo_shifting_${removedFuelType}`] = undefined
                    scope.report.consumption[`ab_inert_gas_systems_${removedFuelType}`] = undefined
                    scope.report.consumption[`ab_bunker_heating_${removedFuelType}`] = undefined
                }

                scope.duplicateFuelTypesSelected = cons_fuel_grades && cons_fuel_grades.length > 1 && consumption_fuel_grades.length != Array.from(new Set(consumption_fuel_grades)).length;
                scope.reportForm.consumptions.fuelType1?.$setValidity('fuelType1',!scope.duplicateFuelTypesSelected)
                scope.report.$validate()
            })
            
            scope.$watchGroup([
                "report.consumption.main_engine_hfo",
                "report.consumption.main_engine_lfo",
                "report.consumption.main_engine_mgo",
                "report.consumption.main_engine_b10lfo",
                "report.consumption.main_engine_mdo",
                "report.consumption.main_engine_b10mgo",
                "report.consumption.main_engine_biolfo",
                "report.consumption.main_engine_biomgo",
                "report.consumption.main_engine_ulsfo2020",
                "report.consumption.main_engine_ulslfo2020",
                "report.consumption.main_engine_ulsmgo2020",
                "report.consumption.main_engine_ulsmdo2020",
                "report.consumption.main_engine_vlsfo2020",
                "report.consumption.main_engine_vlslfo2020",
                "report.consumption.main_engine_lpgp",
                "report.consumption.main_engine_lpgb",
                "report.consumption.main_engine_methanol",
                "report.consumption.main_engine_lng",
                "report.consumption.main_engine_ethanol",
                "report.consumption.main_engine_other",
                'report.power.main_engine_1_diff_rh',
                'report.power.main_engine_2_diff_rh',
                'report.operational.time_sailed',
                'form',
            ], function(newValues) {
                if (scope.report.bdn_based_reporting) return;
                if (!scope.vesselSpecs || !scope.vesselSpecs.engine) return;
                var meCons = sumValues(newValues.slice(0, mainEngineConsumptions.length));
                var me1rh = newValues[20];
                var me2rh = newValues[21];
                var totalRunningHours = sumValues([me1rh, me2rh]);
                var installedPower = scope.vesselSpecs.engine.me_engines[0].mcr;
                var isDieselElectricPropulsion = false;
                if (scope.vesselSpecs?.information && scope.vesselSpecs?.information?.prime_mover_type) {
                    isDieselElectricPropulsion = (scope.vesselSpecs.information.prime_mover_type=='diesel_electric_propulsion');
                    
                }
                if (scope.vesselSpecs?.engine?.number_of_main_engines === 2 && scope.vesselSpecs.engine?.me_engines && scope.vesselSpecs.engine?.me_engines[1]?.mcr && isDieselElectricPropulsion != true) {
                    installedPower += (scope.vesselSpecs.engine.me_engines[1].mcr);
                }            
                var calculatedMax = 250 * Math.pow(10, -6) * totalRunningHours * installedPower * 1.2; // Allow 20% margin of error
                if (scope.vesselSpecs.engine.number_of_main_engines === 2) {
                    calculatedMax = calculatedMax * 2;
                }
                calculatedMax = roundToPlaces(calculatedMax, 2);
                var machineryRHCounterEnabled = scope.showByDefault('power.machinery_rh_counter', 'sections')
                if (!machineryRHCounterEnabled) {
                    scope.reportForm.warningMessages['report.consumption.main_engine'] = null;
                    return;
                }
                if (meCons >= calculatedMax) {
                    scope.reportForm.warningMessages['report.consumption.main_engine'] = {
                        message: 'Total ME Consumption should be less than or equal to ' + calculatedMax + ', check consumptions or running hours or time sailed.'
                    }
                } else {
                    scope.reportForm.warningMessages['report.consumption.main_engine'] = null;
                }
            });

            var auxEngineConsumptions = [
                "report.consumption.aux_engine_hfo",
                "report.consumption.aux_engine_lfo",
                "report.consumption.aux_engine_mgo",
                "report.consumption.aux_engine_mdo",
                "report.consumption.aux_engine_b10lfo",
                "report.consumption.aux_engine_b10mgo",
                "report.consumption.aux_engine_biolfo",
                "report.consumption.aux_engine_biomgo",
                "report.consumption.aux_engine_ulsfo2020",
                "report.consumption.aux_engine_ulslfo2020",
                "report.consumption.aux_engine_ulsmdo2020",
                "report.consumption.aux_engine_ulsmgo2020",
                "report.consumption.aux_engine_vlsfo2020",
                "report.consumption.aux_engine_vlslfo2020",
                "report.consumption.aux_engine_lpgp",
                "report.consumption.aux_engine_lpgb",
                "report.consumption.aux_engine_lng",
                "report.consumption.aux_engine_methanol",
                "report.consumption.aux_engine_ethanol",
                "report.consumption.aux_engine_other"
            ];
            scope.$watchGroup(auxEngineConsumptions, function(newValues, oldValues, scope) {
                scope.total_aux_engine_consumption = roundToPlaces(sumValues(newValues), 2);
            });
                       
            scope.register(scope,'aux_engine_diff_rh_validation');

            scope.$watchGroup(['report.consumption.total_waste_oil', 'report.consumption.incinerated_waste_oil', 'report.consumption.evaporated_waste_oil', 'report.stock.discharged_waste_oil'], function(newValues) {
                if (!scope.carryOverReport) return;
                var totalWasteOil = newValues[0];
                var previousTotalWasteOil = scope.carryOverReport.consumption.total_waste_oil;
                var target = (previousTotalWasteOil || 0) - (scope.report.consumption.incinerated_waste_oil || 0) - (scope.report.consumption.evaporated_waste_oil || 0) - (scope.report.stock.discharged_waste_oil || 0);
                var min = roundToPlaces(target - 1, 1);
                var max = roundToPlaces(target + 5, 1);
                scope.report.warningMessages['report.consumption.total_waste_oil'] = (totalWasteOil < min || totalWasteOil > max) && previousTotalWasteOil != undefined ? 'Total Waste Oil should be between ' + min + ' m³ and ' + max + ' m³': null;
            });

            scope.$watch('report.consumption.incinerated_waste_oil', function(incineratedWasteOil) {
                scope.report.warningMessages['report.consumption.incinerated_waste_oil'] = incineratedWasteOil > 5 ? 'Incinerated Waste Oil should be less than 5 m³' : null;
            });

            scope.$watch('report.consumption.evaporated_waste_oil', function(evaporatedWasteOil) {
                scope.report.warningMessages['report.consumption.evaporated_waste_oil'] = evaporatedWasteOil > 10 ? 'Evaporated Waste Oil should be less than 10 m³' : null;
            });

            const fuelTypes = [
                'report.consumption.fuel_type_1',
                'report.consumption.fuel_type_2',
                'report.consumption.fuel_type_3',
            ];
            // set min max for fuel spec
            angular.forEach(fuelTypes, function(fuel_type_option) {
                scope.$watch(fuel_type_option, function(fuelSelection) {
                    if (fuelSelection) {
                        const fuelTypeSelect = fuel_type_option.split('.')[2];
                        // lcv
                        scope['lcv_' + fuelTypeSelect + '_min'] = fuelTypeProperties['lcv_' + fuelSelection].min || 37000;
                        scope['lcv_' + fuelTypeSelect + '_max'] = fuelTypeProperties['lcv_' + fuelSelection].max || 44000;
                        // density
                        scope['density_' + fuelTypeSelect + '_min'] = fuelTypeProperties['density_' + fuelSelection].min || 600;
                        scope['density_' + fuelTypeSelect + '_max'] = fuelTypeProperties['density_' + fuelSelection].max || 1200;
                    }
                })
            })
            scope.fuelTypeMECons = {}
            scope.$watchGroup(['report.consumption.fuel_type_1','report.consumption.fuel_type_2','report.consumption.fuel_type_3'],function(newValues,oldValues,s) {
                angular.forEach(newValues, function(fuel_type) {
                    if (fuel_type != null && fuel_type != '') {
                        scope.$watch('report.consumption.main_engine_'+fuel_type, function(newValue, oldValue, s) {
                            scope.fuelTypeMECons[fuel_type] = scope.report.consumption['main_engine_'+fuel_type]
                        })
                    }
                })
            })

            function getMeCons() {
                if (scope.report.bdn_based_reporting) return scope.bdnMECons
    
                return scope.fuelTypeMECons
            }

            function getSulphurPercent(bdnNumber: string) {
                if (scope.report.bdn_based_reporting) {
                    scope.sulphur_percent = getBdnSulphur(bdnNumber)
                } else {
                    scope.sulphur_percent = scope.report.consumption['sulphur_' + bdnNumber];
                }
            }

            function getBdnSulphur(bdnNumber) {
                const indexFromBdn = scope.getIndexFromBdn(scope.max_me_key)
                const bdnObj = scope.report.consumption.bdn_consumptions[indexFromBdn]
                if (bdnObj && bdnObj.hasOwnProperty('sulphur')) return bdnObj.sulphur

                return
            }

            function getBnWarningValue() {
                if (scope.sulphur_percent > 0.5) return scope.report.consumption.cyl_oil_bn <= 50;
                if (scope.sulphur_percent <= 0.5) return scope.report.consumption.cyl_oil_bn > 50

                return false
            }

            scope.bnWarning = function() {
                const meCons = getMeCons()
                if (Object.keys(meCons).length > 0) {
                    scope.max_me_cons = Math.max(...Object.keys(meCons).map(key => meCons[key]))
                    scope.max_me_key = Object.keys(meCons).find(key => meCons[key] === scope.max_me_cons);
                    scope.sulphur_percent = getSulphurPercent(scope.max_me_key)

                    return getBnWarningValue()
                }
            }

            scope.$watch('report.consumption.cyl_oil_bn',function(newValue,oldValue,s) {
                scope.bnWarning()
            })

            const getCargo = (conObj: IBdnTankConsumption) => {
                const cargo = [
                    'ig_generator',
                    'ae_crane_operations',
                    'ae_cdl_operations',
                    'other',
                    'ae_tank_cleaning',
                    'ab_tank_cleaning',
                    'ae_cargo_heating',
                    'ab_cargo_heating',
                    'ae_cargo_shifting',
                    'ab_cargo_shifting',
                    'ae_inert_gas_systems',
                    'ab_inert_gas_systems',
                    'ae_cargo_hold_washing',
                    'ae_bunker_heating',
                    'ab_bunker_heating'
                ]
                return cargo.map((field) => {
                    return conObj[field]
                })
            }

            const getConsumptions = (conObj: IBdnTankConsumption) => {
                const consumptions = [
                    'main_engine',
                    'aux_engine',
                    'mdg_port',
                    'mdg_starboard',
                    'hdg',
                    'dg',
                    'aux_boiler',
                    'ig_generator',
                    'other',
                    'incinerator',
                    'gas_combustion_units',
                    'dpp'
                ]
                return consumptions.map((field) => {
                    return conObj[field]
                })
            }

            const getAuxEngineBreakdown = (conObj: IBdnTankConsumption) => {
                const auxEngineBreakdown = [
                    'aux_engine',
                    'ae_ballast_operations',
                    'ae_crane_operations',
                    'ae_cdl_operations',
                    'ae_cargo_heating',
                    'ae_bunker_heating',
                    'ae_tank_cleaning',
                    'ae_cargo_shifting',
                    'ae_inert_gas_systems',
                    'ae_cargo_hold_washing',
                    'ae_scrubber'
                ]
                return auxEngineBreakdown.map((field) => {
                    return conObj[field]
                })
            }

            const getBoilerBreakdown = (conObj: IBdnTankConsumption) => {
                const boilerBreakdown = [
                    'aux_boiler',
                    'ab_cargo_heating',
                    'ab_bunker_heating',
                    'ab_ballast_operations',
                    'ab_tank_cleaning',
                    'ab_cargo_shifting',
                    'ab_inert_gas_systems'
                ]
                return boilerBreakdown.map((field) => {
                    return conObj[field]
                })
            }

            function getConsumptionBaseLoad(consumptionBreakdown) {
                let initialBaseLoad = consumptionBreakdown[0] || 0;
                const baseLoadBreakdown = consumptionBreakdown.slice(1);
                if (baseLoadBreakdown && baseLoadBreakdown.length > 0) {
                    return initialBaseLoad -= sumValues(baseLoadBreakdown);
                }

                return initialBaseLoad
            }

            function getCurrPristine(key: string) {
                if (!scope.consumption_pristine) return true

                return scope.consumption_pristine[key] || true
            }

            function getNewPristine(consumptionObj: IBdnTankConsumption, fieldName) {
                const bdnNumber = consumptionObj.bdn_number
                const reportFormConsumptions = scope.reportForm.consumptions
                const fullName = fieldName + '_' + bdnNumber
                if (!bdnNumber) return true
                if (reportFormConsumptions.hasOwnProperty(fullName)) return reportFormConsumptions[fullName].$pristine

                return true
            }

            const getDieselGenBreakdown = (conObj: IBdnTankConsumption) => {
                const dieselGenBreakdown = [
                    'report.consumption.dg_ballast_operations',
                    'report.consumption.dg_crane_operations',
                    'report.consumption.dg_cdl_operations',
                    'report.consumption.dg_cargo_heating',
                    'report.consumption.dg_tank_cleaning',
                    'report.consumption.dg_cargo_shifting',
                    'report.consumption.dg_inert_gas_systems',
                    'report.consumption.dg_cargo_hold_washing',
                    'report.consumption.dg_scrubber',
                ]
                return dieselGenBreakdown.map((field) => {
                    return conObj[field]
                })
            }

            const getDGBaseLoadConsumption = (conObj: IBdnTankConsumption, dgSum) => {
                const initialConsumption = (dgSum * (1-(scope.propulsion_power/scope.diesel_gen_total_power))) || 0;
                const breakdown = getDieselGenBreakdown(conObj);
                if (breakdown && breakdown.length > 0) {
                    return initialConsumption - sumValues(breakdown);
                }

                return initialConsumption
            }
            
            scope.bdnMECons = {}
            
            scope.$watch('report.consumption.bdn_consumptions', function(newValue: IBdnTankConsumption[], oldValue: IBdnTankConsumption[], scope) {
                if (!newValue || !oldValue) return
                const bdnArray = newValue.map(a => a.bdn_number).filter(bdnNumber => bdnNumber)
                scope.duplicateBdnSelected = new Set(bdnArray).size !== bdnArray.length

                if (scope.report.bdn_based_reporting) {
                    scope.register(scope,'aux_engine_diff_rh_validation')
                }

                for (let i = 0; i < newValue.length; i++) {
                    
                    if (!angular.equals(newValue[i], oldValue[i])) {
                        // calculate Cargo Related
                        scope.report.consumption.bdn_consumptions[i].cargo_related = sumValues(getCargo(newValue[i]));

                        // calculate Total Consumption
                        scope.report.consumption.bdn_consumptions[i].total_consumption = sumValues(getConsumptions(newValue[i]));

                        // calculate AE Basic Load
                        const auxEngineBreakdown = getAuxEngineBreakdown(newValue[i])
                        const auxBaseLoad = getConsumptionBaseLoad(auxEngineBreakdown)
                        const aeBasicLoadId = 'ae_basic_load' + newValue[i].bdn_number
                        scope.report.consumption.bdn_consumptions[i].ae_basic_load = roundToPlaces(auxBaseLoad, 2);
                        scope.reportForm?.consumptions?.[aeBasicLoadId]?.$setValidity(aeBasicLoadId, auxBaseLoad >= 0);

                        // calculate AB Basic Load
                        const boilerBreakdown = getBoilerBreakdown(newValue[i])
                        const abBasicLoad = getConsumptionBaseLoad(boilerBreakdown)
                        const abBasicLoadId = 'ab_basic_load' + newValue[i].bdn_number
                        scope.report.consumption.bdn_consumptions[i].ab_basic_load = roundToPlaces(abBasicLoad, 2);
                        scope.reportForm?.consumptions?.[abBasicLoadId]?.$setValidity(abBasicLoadId, abBasicLoad >= 0);

                        // Calculate Diesel Gen Breakdown
                        const mdg_port = newValue[i].mdg_port || 0;
                        const mdg_starboard = newValue[i].mdg_starboard || 0;
                        const hdg = newValue[i].hdg || 0;
                        const dg = newValue[i].dg || 0;
                        const dgSum = mdg_port + mdg_starboard + hdg + dg
                        const propulsionConsumption = (dgSum * (scope.propulsion_power/scope.diesel_gen_total_power)) || 0;
                        const dgBaseLoadConsumption = getDGBaseLoadConsumption(newValue[i], dgSum);
                        scope.report.consumption.bdn_consumptions[i].dg_basic_load = roundToPlaces(dgBaseLoadConsumption, 2);
                        scope.report.consumption.bdn_consumptions[i].prop_cons = roundToPlaces(propulsionConsumption, 2);
                        
                        for (let key in newValue[i]) {
                            const currPristine = getCurrPristine(key)
                            const newPristine = getNewPristine(newValue[i], key)
                            scope.consumption_pristine[key] = currPristine && currPristine === newPristine
                        }
                    }
                    
                    const bdnNumber = newValue[i].bdn_number
                    if (bdnNumber && newValue[i].fuel_grade) {
                        const fuelGrade = newValue[i].fuel_grade.toLowerCase()
                        scope['lcv_' + bdnNumber + '_min'] = fuelTypeProperties['lcv_' + fuelGrade].min || 37000;
                        scope['lcv_' + bdnNumber + '_max'] = fuelTypeProperties['lcv_' + fuelGrade].max || 44000;
                        scope['density_' + bdnNumber + '_min'] = fuelTypeProperties['density_' + fuelGrade].min || 600;
                        scope['density_' + bdnNumber + '_max'] = fuelTypeProperties['density_' + fuelGrade].max || 1200;

                        scope.bdnMECons[bdnNumber] = newValue[i].main_engine
                    }
                }

                scope.total_main_engine_consumption = newValue.map((a) => a.main_engine)
                    .filter(b => b)
                    .reduce((sum, c) => sum + c, 0) || 0;

                scope.total_aux_engine_consumption = roundToPlaces(newValue.map((a) => a.aux_engine)
                    .filter(b => b)
                    .reduce((sum, c) => sum + c, 0), 2) || 0;

                scope.total_mdg_port_consumption = roundToPlaces(newValue.map((a) => a.mdg_port)
                    .filter(b => b)
                    .reduce((sum, c) => sum + c, 0), 2) || 0;

                scope.total_mdg_starboard_consumption = roundToPlaces(newValue.map((a) => a.mdg_starboard)
                    .filter(b => b)
                    .reduce((sum, c) => sum + c, 0), 2) || 0;

                scope.total_hdg_consumption = roundToPlaces(newValue.map((a) => a.hdg)
                    .filter(b => b)
                    .reduce((sum, c) => sum + c, 0), 2) || 0;

                scope.total_dg_consumption = roundToPlaces(newValue.map((a) => a.dg)
                    .filter(b => b)
                    .reduce((sum, c) => sum + c, 0), 2) || 0;

                setMEWarningMessage()
            }, true)

            scope.$watchGroup([
                'report.power.main_engine_1_diff_rh',
                'report.power.main_engine_2_diff_rh',
                'report.operational.time_sailed',
                'form'
            ], function(newValues) {
                setMEWarningMessage()
            })

            scope.$watch('report.bdn_based_reporting', function(newValue: boolean, oldValue: boolean, scope) {
                const bdnCons = scope.report.consumption.bdn_consumptions
                for (let i = 0; i < bdnCons.length; i++) {
                    scope.setConsValuesFromTank(i)
                }
            })

            function getDieselElectricPropulsion() {
                if (scope.vesselSpecs?.information && scope.vesselSpecs?.information?.prime_mover_type) {
                    return scope.vesselSpecs.information.prime_mover_type === 'diesel_electric_propulsion';
                }

                return false
            }

            function getInstalledPower() {
                const initialInstalledPower = scope.vesselSpecs.engine.me_engines[0].mcr;
                if (scope.vesselSpecs?.engine?.number_of_main_engines === 2 && 
                    scope.vesselSpecs.engine?.me_engines && 
                    scope.vesselSpecs.engine?.me_engines[1]?.mcr && 
                    getDieselElectricPropulsion() != true
                ) {
                    return initialInstalledPower + scope.vesselSpecs.engine.me_engines[1].mcr
                }

                return initialInstalledPower
            }

            function getMaxConsumption(totalRunningHours, installedPower) {
                const initialMax = 250 * Math.pow(10, -6) * totalRunningHours * installedPower * 1.2; // Allow 20% margin of error
                if (scope.vesselSpecs.engine.number_of_main_engines === 2) {
                    return initialMax * 2;
                }

                return initialMax
            }

            function setMEWarningMessage() {
                if (!scope.report.bdn_based_reporting) return;
                if (!scope.vesselSpecs || !scope.vesselSpecs.engine) return;

                const meCons = scope.report.consumption.bdn_consumptions.map((a) => a.main_engine).reduce((sum, b) => sum + b, 0);
                const me1rh = scope.report.power.main_engine_1_diff_rh;
                const me2rh = scope.report.power.main_engine_2_diff_rh;
                const totalRunningHours = sumValues([me1rh, me2rh]);
                const installedPower = getInstalledPower();
                const calculatedMaxCons = roundToPlaces(getMaxConsumption(totalRunningHours, installedPower), 2);
                const machineryRHCounterEnabled = scope.showByDefault('power.machinery_rh_counter', 'sections');
                
                if (!machineryRHCounterEnabled) {
                    scope.reportForm.warningMessages['report.consumption.main_engine'] = null;
                    return;
                }
                if (meCons >= calculatedMaxCons) {
                    scope.reportForm.warningMessages['report.consumption.main_engine'] = {
                        message: 'Total ME Consumption should be less than or equal to ' + calculatedMaxCons + ', check consumptions or running hours or time sailed.'
                    }
                    return;
                }
                scope.reportForm.warningMessages['report.consumption.main_engine'] = null;
            }
        }
    }
});

routerApp.directive('bunkerTab', function() {
    return {
        templateUrl: getCacheBustedTemplate('pages/vessel-reports/tabs/bunker.html'),
        link: function(scope, element, attributes, control) {
            scope.$watch('report.bdn_based_reporting', function(newValue, oldValue, s) { 
                scope.report.bdn_based_reporting = newValue
            });
            scope.report.bunker.new_fuel_configuration = true;

            scope.$watch('report.consumption.sulphur_hshfo', function(newValue, oldValue, s) { 
                if (newValue != undefined && s.report.consumption['previous_sulphur_hshfo'] == undefined) 
                    s.report.consumption['previous_sulphur_hshfo'] = newValue;
            });
            scope.$watch('report.consumption.sulphur_lshfo', function(newValue, oldValue, s) { 
                if (newValue != undefined && s.report.consumption['previous_sulphur_lshfo'] == undefined) 
                    s.report.consumption['previous_sulphur_lshfo'] = newValue;
            });
            scope.$watch('report.consumption.sulphur_ulsfo', function(newValue, oldValue, s) {
                if (newValue != undefined && s.report.consumption['previous_sulphur_ulsfo'] == undefined)
                    s.report.consumption['previous_sulphur_ulsfo'] = newValue;
            });
            scope.$watch('report.consumption.sulphur_hsmdo', function(newValue, oldValue, s) { 
                if (newValue != undefined && s.report.consumption['previous_sulphur_hsmdo'] == undefined) 
                    s.report.consumption['previous_sulphur_hsmdo'] = newValue;
            });
            scope.$watch('report.consumption.sulphur_lsmdo', function(newValue, oldValue, s) { 
                if (newValue != undefined && s.report.consumption['previous_sulphur_lsmdo'] == undefined) 
                    s.report.consumption['previous_sulphur_lsmdo'] = newValue;
            });
            scope.$watch('report.consumption.sulphur_hsmgo', function(newValue, oldValue, s) { 
                if (newValue != undefined && s.report.consumption['previous_sulphur_hsmgo'] == undefined) 
                    s.report.consumption['previous_sulphur_hsmgo'] = newValue;
            });
            scope.$watch('report.consumption.sulphur_lsmgo', function(newValue, oldValue, s) { 
                if (newValue != undefined && s.report.consumption['previous_sulphur_lsmgo'] == undefined) 
                    s.report.consumption['previous_sulphur_lsmgo'] = newValue;
            });

            scope.$watch('report.consumption.lcv_hshfo', function(newValue, oldValue, s) { 
                if (newValue != undefined && s.report.consumption['previous_lcv_hshfo'] == undefined) 
                    s.report.consumption['previous_lcv_hshfo'] = newValue;
            }); 
            scope.$watch('report.consumption.lcv_lshfo', function(newValue, oldValue, s) { 
                if (newValue != undefined && s.report.consumption['previous_lcv_lshfo'] == undefined) 
                    s.report.consumption['previous_lcv_lshfo'] = newValue;
            });
            scope.$watch('report.consumption.lcv_ulsfo', function(newValue, oldValue, s) {
                if (newValue != undefined && s.report.consumption['previous_lcv_ulsfo'] == undefined)
                    s.report.consumption['previous_lcv_ulsfo'] = newValue;
            });
            scope.$watch('report.consumption.lcv_hsmdo', function(newValue, oldValue, s) { 
                if (newValue != undefined && s.report.consumption['previous_lcv_hsmdo'] == undefined) 
                    s.report.consumption['previous_lcv_hsmdo'] = newValue;
            }); 
            scope.$watch('report.consumption.lcv_lsmdo', function(newValue, oldValue, s) { 
                if (newValue != undefined && s.report.consumption['previous_lcv_lsmdo'] == undefined) 
                    s.report.consumption['previous_lcv_lsmdo'] = newValue;
            }); 
            scope.$watch('report.consumption.lcv_hsmgo', function(newValue, oldValue, s) { 
                if (newValue != undefined && s.report.consumption['previous_lcv_hsmgo'] == undefined) 
                    s.report.consumption['previous_lcv_hsmgo'] = newValue;
            }); 
            scope.$watch('report.consumption.lcv_lsmgo', function(newValue, oldValue, s) { 
                if (newValue != undefined && s.report.consumption['previous_lcv_lsmgo'] == undefined) 
                    s.report.consumption['previous_lcv_lsmgo'] = newValue;
            });

            scope.$watch('report.consumption.density_hshfo', function(newValue, oldValue, s) { 
                if (newValue != undefined && s.report.consumption['previous_density_hshfo'] == undefined) 
                    s.report.consumption['previous_density_hshfo'] = newValue;
            }); 
            scope.$watch('report.consumption.density_lshfo', function(newValue, oldValue, s) { 
                if (newValue != undefined && s.report.consumption['previous_density_lshfo'] == undefined) 
                    s.report.consumption['previous_density_lshfo'] = newValue;
            });
            scope.$watch('report.consumption.density_ulsfo', function(newValue, oldValue, s) {
                if (newValue != undefined && s.report.consumption['previous_density_ulsfo'] == undefined)
                    s.report.consumption['previous_density_ulsfo'] = newValue;
            });
            scope.$watch('report.consumption.density_hsmdo', function(newValue, oldValue, s) { 
                if (newValue != undefined && s.report.consumption['previous_density_hsmdo'] == undefined) 
                    s.report.consumption['previous_density_hsmdo'] = newValue;
            });
            scope.$watch('report.consumption.density_lsmdo', function(newValue, oldValue, s) { 
                if (newValue != undefined && s.report.consumption['previous_density_lsmdo'] == undefined) 
                    s.report.consumption['previous_density_lsmdo'] = newValue;
            }); 
            scope.$watch('report.consumption.density_hsmgo', function(newValue, oldValue, s) { 
                if (newValue != undefined && s.report.consumption['previous_density_hsmgo'] == undefined) 
                    s.report.consumption['previous_density_hsmgo'] = newValue;
            }); 
            scope.$watch('report.consumption.density_lsmgo', function(newValue, oldValue, s) { 
                if (newValue != undefined && s.report.consumption['previous_density_lsmgo'] == undefined) 
                    s.report.consumption['previous_density_lsmgo'] = newValue;
            });

            scope.fuelProperties = fuelTypeProperties;
            // set min max for fuel spec
            scope.bunkered_lcv_min = [];
            scope.bunkered_lcv_max = [];
            scope.bunkered_density_min = [];
            scope.bunkered_density_max = [];
            scope.$watch('report.bunker.bunkered_fuel_grades', function(newBunkeredFuelGrades: any[], oldBunkeredFuelGrades, scope) {
                scope.bunkered_lcv_min = [];
                scope.bunkered_lcv_max = [];
                scope.bunkered_density_min = [];
                scope.bunkered_density_max = [];
                if (!newBunkeredFuelGrades) {
                    return;
                }
                for (let index = 0; index < newBunkeredFuelGrades.length; index++) {
                    const fuelSelection = newBunkeredFuelGrades[index];
                    if (fuelSelection) {
                        scope.bunkered_lcv_min.push(fuelTypeProperties['lcv_' + fuelSelection].min || 37000);
                        scope.bunkered_lcv_max.push(fuelTypeProperties['lcv_' + fuelSelection].max || 44000);
                        scope.bunkered_density_min.push(fuelTypeProperties['density_' + fuelSelection].min || 600);
                        scope.bunkered_density_max.push(fuelTypeProperties['density_' + fuelSelection].max || 1200);
                    } else {
                        scope.bunkered_lcv_min.push(37000);
                        scope.bunkered_lcv_max.push(44000);
                        scope.bunkered_density_min.push(600);
                        scope.bunkered_density_max.push(1200);
                    }
                }
            }, true);

            angular.forEach(['hfo', 'lfo', 'mgo', 'mdo', 'b10lfo', 'b10mgo', 'biolfo','biomgo','ulsfo2020','ulslfo2020','ulsmdo2020','ulsmgo2020','vlsfo2020','vlslfo2020','lpgp','lpgb','lng','methanol','ethanol','other'], function(grade) {
                scope.$watch('report.consumption.sulphur_' + grade, function(newValue, oldValue, s) {
                    if (newValue != undefined && s.report.consumption['previous_sulphur_'+grade] == undefined) {
                        s.report.consumption['previous_sulphur_'+grade] = newValue;
                    }
                });
                scope.$watch('report.consumption.lcv_' + grade, function(newValue, oldValue, s) {
                    if (newValue != undefined && s.report.consumption['previous_lcv_'+grade] == undefined) {
                        s.report.consumption['previous_lcv_'+grade] = newValue;
                    }
                });
                scope.$watch('report.consumption.density_' + grade, function(newValue, oldValue, s) {
                    if (newValue != undefined && s.report.consumption['previous_density_'+grade] == undefined) {
                        s.report.consumption['previous_density_'+grade] = newValue;
                    }
                });
            });

            scope.$watch('report.bunker.sulphur_bdn_hshfo', function(newValue, oldValue, s) {
                // validation for %S > 0.5
                if (!s.reportForm || !s.reportForm.bunker || !s.reportForm.bunker.sulphurBDN || !s.reportForm.bunker.sulphurBDNhshfo) {
                    return;
                }
                var valid = true;
                var validationMessage = null;
                if (newValue != undefined) {
                    valid = newValue > 0.5;
                    validationMessage = 'Sulphur % must be > 0.5%';
                }
                s.validationMessages['report.bunker.sulphur_bdn_hshfo'] = valid ? null : validationMessage;
                s.reportForm.bunker.sulphurBDNhshfo.$setValidity('invalid_sulphur_percent', valid);
            });

            scope.$watch('report.bunker.sulphur_bdn_lshfo', function(newValue, oldValue, s) {
                // validation for 0.1 < %S <= 0.5
                if (!s.reportForm || !s.reportForm.bunker || !s.reportForm.bunker.sulphurBDN || !s.reportForm.bunker.sulphurBDNlshfo) {
                    return;
                }
                var valid = true;
                var validationMessage = null;
                if (newValue != undefined) {
                    valid = newValue > 0.1 && newValue <= 0.5;
                    validationMessage = 'Sulphur % must be between 0.1% < S% <= 0.5%';
                }
                s.validationMessages['report.bunker.sulphur_bdn_lshfo'] = valid ? null : validationMessage;
                s.reportForm.bunker.sulphurBDNlshfo.$setValidity('invalid_sulphur_percent', valid);
            });

            scope.$watch('report.bunker.sulphur_bdn_ulsfo', function(newValue, oldValue, s) {
                // validation for %S <= 0.1
                if (!s.reportForm || !s.reportForm.bunker || !s.reportForm.bunker.sulphurBDN || !s.reportForm.bunker.sulphurBDNulsfo) {
                    return;
                }
                var valid = true;
                var validationMessage = null;
                if (newValue != undefined) {
                    valid = newValue <= 0.1;
                    validationMessage = 'Sulphur % must be between <= 0.1%';
                }
                s.validationMessages['report.bunker.sulphur_bdn_ulsfo'] = valid ? null : validationMessage;
                s.reportForm.bunker.sulphurBDNulsfo.$setValidity('invalid_sulphur_percent', valid);
            });

            scope.$watch('report.bunker.sulphur_bdn_lsmdo', function(newValue, oldValue, s) {
                // validation for 0.1 < %S <= 0.5
                if (!s.reportForm || !s.reportForm.bunker || !s.reportForm.bunker.sulphurBDN || !s.reportForm.bunker.sulphurBDNlsmdo) {
                    return;
                }
                var valid = true;
                var validationMessage = null;
                if (newValue != undefined) {
                    valid = newValue > 0.1 && newValue <= 0.5;
                    validationMessage = 'Sulphur % must be between 0.1% < S% <= 0.5%';
                }
                s.validationMessages['report.bunker.sulphur_bdn_lsmdo'] = valid ? null : validationMessage;
                s.reportForm.bunker.sulphurBDNlsmdo.$setValidity('invalid_sulphur_percent', valid);
            });

            scope.$watch('report.bunker.sulphur_bdn_hsmgo', function(newValue, oldValue, s) {
                // validation for 0.1 < %S <= 0.5
                if (!s.reportForm || !s.reportForm.bunker || !s.reportForm.bunker.sulphurBDN || !s.reportForm.bunker.sulphurBDNhsmgo) {
                    return;
                }
                var valid = true;
                var validationMessage = null;
                if (newValue != undefined) {
                    valid = newValue > 0.1 && newValue <= 0.5;
                    validationMessage = 'Sulphur % must be between 0.1% < S% <= 0.5%';
                }
                s.validationMessages['report.bunker.sulphur_bdn_hsmgo'] = valid ? null : validationMessage;
                s.reportForm?.bunker?.sulphurBDNhsmgo?.$setValidity('invalid_sulphur_percent', valid);
            });

            scope.$watch('report.bunker.sulphur_bdn_lsmgo', function(newValue, oldValue, s) {
                // validation for 0.1 < %S <= 0.5
                if (!s.reportForm || !s.reportForm.bunker || !s.reportForm.bunker.sulphurBDN || !s.reportForm.bunker.sulphurBDNlsmgo) {
                    return;
                }
                var valid = true;
                var validationMessage = null;
                if (newValue != undefined) {
                    valid = newValue <= 0.1;
                    validationMessage = 'Sulphur % must be between 0.1% < S% <= 0.5%';
                }
                s.validationMessages['report.bunker.sulphur_bdn_lsmgo'] = valid ? null : validationMessage;
                s.reportForm?.bunker?.sulphurBDNlsmgo?.$setValidity('invalid_sulphur_percent', valid);
            });

            var main_fuel_type_specs = {
                'ulsfo2020' : {
                    'max_sulphur':0.1,
                    'min_sulphur':null
                },
                'ulslfo2020' : {
                    'max_sulphur':0.1,
                    'min_sulphur':null
                },
                'ulsmdo2020' : {
                    'max_sulphur':0.1,
                    'min_sulphur':null
                },
                'ulsmgo2020' : {
                    'max_sulphur':0.1,
                    'min_sulphur':null
                },
                'vlsfo2020' : {
                    'max_sulphur':0.5,
                    'min_sulphur':0.1
                },
                'vlslfo2020' : {
                    'max_sulphur':0.5,
                    'min_sulphur':0.1
                }
            }

            var iso_fuel_type_specs = {
                'DMX': {
                    'max_viscosity':5.5,
                    'min_viscosity':1.4,
                    'max_density':null,
                    'max_sulphur':1.0,
                    'min_sulphur':null
                },
                'DMA': {
                    'max_viscosity':6.0,
                    'min_viscosity':2.0,
                    'max_density':890,
                    'max_sulphur':1.0,
                    'min_sulphur':null
                },
                'DFA': {
                    'max_viscosity':6.0,
                    'min_viscosity':2.0,
                    'max_density':890,
                    'max_sulphur':1.0,
                    'min_sulphur':null
                },
                'DMZ': {
                    'max_viscosity':6.0,
                    'min_viscosity':3.0,
                    'max_density':890,
                    'max_sulphur':1.0,
                    'min_sulphur':null
                },
                'DFZ': {
                    'max_viscosity':6.0,
                    'min_viscosity':3.0,
                    'max_density':890,
                    'max_sulphur':1.0,
                    'min_sulphur':null
                },
                'DMB': {
                    'max_viscosity':11.0,
                    'min_viscosity':2.0,
                    'max_density':900,
                    'max_sulphur':1.0,
                    'min_sulphur':null
                },
                'DFB': {
                    'max_viscosity':11.0,
                    'min_viscosity':2.0,
                    'max_density':900,
                    'max_sulphur':1.5,
                    'min_sulphur':null
                },
                'RMA10': {
                    'max_viscosity':10.0,
                    'min_viscosity':null,
                    'max_density':920,
                    'max_sulphur':null,
                    'min_sulphur':null
                },
                'RMB30': {
                    'max_viscosity':30.0,
                    'min_viscosity':null,
                    'max_density':960,
                    'max_sulphur':null,
                    'min_sulphur':null
                },
                'RMD80': {
                    'max_viscosity':80.0,
                    'min_viscosity':null,
                    'max_density':970,
                    'max_sulphur':null,
                    'min_sulphur':null
                },
                'RME180': {
                    'max_viscosity':180.0,
                    'min_viscosity':null,
                    'max_density':991,
                    'max_sulphur':null,
                    'min_sulphur':null
                },
                'RMG180': {
                    'max_viscosity':180.0,
                    'min_viscosity':null,
                    'max_density':991,
                    'max_sulphur':null,
                    'min_sulphur':null
                },
                'RMG380': {
                    'max_viscosity':380.0,
                    'min_viscosity':null,
                    'max_density':991,
                    'max_sulphur':null,
                    'min_sulphur':null
                },
                'RMG500': {
                    'max_viscosity':500.0,
                    'min_viscosity':null,
                    'max_density':991,
                    'max_sulphur':null,
                    'min_sulphur':null
                },
                'RMG700': {
                    'max_viscosity':700.0,
                    'min_viscosity':null,
                    'max_density':991,
                    'max_sulphur':null,
                    'min_sulphur':null
                },
                'RMK380': {
                    'max_viscosity':380.0,
                    'min_viscosity':null,
                    'max_density':1010,
                    'max_sulphur':null,
                    'min_sulphur':null
                },
                'RMK500': {
                    'max_viscosity':500.0,
                    'min_viscosity':null,
                    'max_density':1010,
                    'max_sulphur':null,
                    'min_sulphur':null
                },
                'RMK700': {
                    'max_viscosity':700.0,
                    'min_viscosity':null,
                    'max_density':1010,
                    'max_sulphur':null,
                    'min_sulphur':null
                },
                'LNG': {
                    'max_viscosity':null,
                    'min_viscosity':null,
                    'max_density':null,
                    'max_sulphur':null,
                    'min_sulphur':null
                },
                'LPG': {
                    'max_viscosity':null,
                    'min_viscosity':null,
                    'max_density':null,
                    'max_sulphur':null,
                    'min_sulphur':null
                },
                'Methanol': {
                    'max_viscosity':null,
                    'min_viscosity':null,
                    'max_density':null,
                    'max_sulphur':null,
                    'min_sulphur':null
                },
                'Ethanol': {
                    'max_viscosity':null,
                    'min_viscosity':null,
                    'max_density':null,
                    'max_sulphur':null,
                    'min_sulphur':null
                }
                
            }

            for (var n=0; n<3; n++) {
                scope.$watchGroup(['report.bunker.bunkered_fuel_grades['+n+']','report.bunker.bunkered_iso_fuel_grades['+n+']'], function(newValues, oldValues, scope) {
                    scope.main_fuel_grade = newValues[0];
                    scope.iso_fuel_grade = newValues[1];
                    var valid = scope.iso_fuel_grade != undefined;
                    scope.reportForm?.bunker['bunkeredISOFuelGrade['+n+']']?.$setValidity('bunkeredISOFuelGrade['+n+']',valid);
                })
            }
            scope.$watch('report.bunker.bunkered_iso_fuel_grades', function(newValue) {
                // sometimes bunkered_iso_fuel_grades is initializes as an dictionary instead of an array when setting values 
                // this ensures field is an array
                if (newValue && newValue.constructor == Object && newValue.constructor != Array) {
                    scope.report.bunker.bunkered_iso_fuel_grades = Object.values(newValue);
                }
            })

            angular.forEach(['hfo', 'lfo', 'mgo', 'mdo', 'b10lfo', 'b10mgo', 'biolfo','biomgo','ulsfo2020','ulslfo2020','ulsmdo2020','ulsmgo2020','vlsfo2020','vlslfo2020','lpgp','lpgb','lng','methanol','ethanol','other'], function(grade) {
                scope.$watch('report.bunker.sulphur_bdn_' + grade, function(newValue, oldValue, s) {
                    if (!s.reportForm || !s.reportForm.bunker || !s.reportForm.bunker.sulphurBDN || !s.reportForm.bunker['sulphurBDN'+grade] ) {
                        return;
                    }
                    var valid = true;
                    var validationMessage = null;

                    var max_sulphur = null;
                    var min_sulphur = null;

                    if (grade == 'hfo') {
                        if (newValue <= 0) {
                            valid = false;
                            validationMessage = 'Sulphur % must be > 0%';
                        }
                    }
                    else if (grade == 'methanol' || grade == 'ethanol' || grade == 'lng' || grade == 'lpgp' || grade == 'lpgb') {
                        if (newValue <= 0.1) {
                            valid = false
                            validationMessage = 'Sulphur % must be > 0.1%';
                        }
                    }
                    else {
                        if (newValue < 0) {
                            valid = false;
                            validationMessage = 'Sulphur % must be >= 0%';
                        }
                    }

                    for (let i in iso_fuel_type_specs) {
                        if (i==scope.iso_fuel_grade) {
                            max_sulphur = iso_fuel_type_specs[i]['max_sulphur'];
                            
                            if (!s.reportForm || !s.reportForm.bunker || !s.reportForm.bunker.sulphurBDN || !s.reportForm.bunker['sulphurBDN'+scope.main_fuel_grade] ) {
                                return;
                            }
                            if (newValue != undefined) {
                                if (max_sulphur != null) {
                                    valid = newValue <= max_sulphur;
                                    validationMessage = 'Sulphur % must be <= ' + max_sulphur + '%';
                                }
                            }
                        }
                    }

                    for (let i in main_fuel_type_specs) {
                        if (i==scope.main_fuel_grade) {
                            max_sulphur = main_fuel_type_specs[i]['max_sulphur']
                            min_sulphur = main_fuel_type_specs[i]['min_sulphur']
                            
                            if (!s.reportForm || !s.reportForm.bunker || !s.reportForm.bunker.sulphurBDN || !s.reportForm.bunker['sulphurBDN'+scope.main_fuel_grade] ) {
                                return;
                            }
                            if (newValue != undefined) {
                                if (max_sulphur != null) {
                                    if (min_sulphur != null) {
                                        valid = min_sulphur <= newValue && newValue <= max_sulphur;
                                        validationMessage = 'Sulphur % must be between '+min_sulphur+'% < S% <= '+max_sulphur+'%';
                                    }
                                    else {
                                        valid = newValue <= max_sulphur;
                                        validationMessage = 'Sulphur % must be <= ' + max_sulphur + '%';
                                    }
                                }
                            }
                        }
                    }
                    s.validationMessages['report.bunker.sulphur_bdn_'+scope.main_fuel_grade] = valid ? null : validationMessage;
                    s.reportForm?.bunker['sulphurBDN'+scope.main_fuel_grade]?.$setValidity('invalid_sulphur_percent',valid);
                });
                scope.$watch('report.bunker.density_' + grade, function(newValue, oldValue, s) {
                    if (!s.reportForm || !s.reportForm.bunker || !s.reportForm.bunker.density || !s.reportForm.bunker['density'+grade] ) {
                        return;
                    }
                    var valid = true;
                    var validationMessage = null;
                    var n = scope.report.bunker.bunkered_fuel_grades.indexOf(grade);
                    var min_density = scope.bunkered_density_min[n] || 600;
                    var max_density = scope.bunkered_density_max[n] || 1200;
                    
                    if (newValue != undefined) {
                        valid = min_density <= newValue && newValue <= max_density;
                        validationMessage = 'Density value is required and must be between ' + min_density + ' and ' + max_density + ' kg/m3';
                    }

                    for (let i in iso_fuel_type_specs) {
                        if (i==scope.iso_fuel_grade) {
                            max_density = iso_fuel_type_specs[i]['max_density'];
                            if (!s.reportForm || !s.reportForm.bunker || !s.reportForm.bunker.density || !s.reportForm.bunker['density'+grade] ) {
                                return;
                            }
                            if (newValue != undefined) {
                                if (max_density != null) {
                                    valid = min_density <= newValue && newValue <= max_density;
                                    validationMessage = 'Density must be between ' + min_density + ' and ' + max_density + ' kg/m³';
                                }
                            }
                        }
                    }
                    s.validationMessages['report.bunker.density_'+scope.main_fuel_grade] = valid ? null : validationMessage;
                    s.reportForm.bunker['density'+scope.main_fuel_grade].$setValidity('invalid_density',valid);
                });
                scope.$watch('report.bunker.viscosity_' + grade, function(newValue, oldValue, s) {
                    if (!s.reportForm || !s.reportForm.bunker || !s.reportForm.bunker.viscosity || !s.reportForm.bunker['viscosity'+grade] ) {
                        return;
                    }
                    var valid = true;
                    var validationMessage = null;

                    var max_viscosity = null;
                    var min_viscosity = null;

                    for (let i in iso_fuel_type_specs) {
                        if (i==scope.iso_fuel_grade) {
                            max_viscosity = iso_fuel_type_specs[i]['max_viscosity'];
                            min_viscosity = iso_fuel_type_specs[i]['min_viscosity'];
                            
                            if (!s.reportForm || !s.reportForm.bunker || !s.reportForm.bunker.viscosity || !s.reportForm.bunker['viscosity'+grade] ) {
                                return;
                            }
                            if (newValue != undefined) {
                                if (max_viscosity != null) {
                                    if (min_viscosity != null) {
                                        valid = min_viscosity <= newValue <= max_viscosity;
                                        validationMessage = 'Viscosity must be between ' + min_viscosity + ' and ' + max_viscosity + ' cSt'
                                    }
                                    else {
                                        valid = newValue <= max_viscosity;
                                        validationMessage = 'Viscosity must be <= ' + max_viscosity + ' cSt';
                                    }
                                }
                            }
                            s.validationMessages['report.bunker.viscosity_'+scope.main_fuel_grade] = valid ? null : validationMessage;
                            s.reportForm?.bunker['viscosity'+scope.main_fuel_grade]?.$setValidity('invalid_viscosity',valid);
                        }
                    }
                });
            });

            scope.bunkerRowNumbers = function() {
                return range(3);
            }
            scope.debunkerRowNumbers = function() {
                return range(2);
            }
            
            scope.duplicateFuelGradesSelected = function() {
                var bunkered_fuel_grades = scope.report.bunker.bunkered_fuel_grades.filter(function(fg) { return !!fg; });
                return scope.report.bunker.bunkered_fuel_grades && scope.report.bunker.bunkered_fuel_grades.length > 1 && bunkered_fuel_grades.length != Array.from(new Set(bunkered_fuel_grades)).length;
            }
            scope.duplicateDebunkeredFuelGradesSelected = function() {
                var debunkered_fuel_grades = scope.report.bunker.debunkered_fuel_grades.filter(function(fg) { return !!fg; });
                return scope.report.bunker.debunkered_fuel_grades && scope.report.bunker.debunkered_fuel_grades.length > 1 && debunkered_fuel_grades.length != Array.from(new Set(debunkered_fuel_grades)).length;
            }
            scope.duplicateDebunkeredBdnsSelected = function() {
                if (!scope.report || !scope.report.bunker || !scope.report.bunker.bdn_based_reporting_debunkered_fuels) return
                var bdn_based_reporting_debunkered_fuels = scope.report.bunker.bdn_based_reporting_debunkered_fuels.filter(function(fg) { return !!fg; });
                return scope.report.bunker.bdn_based_reporting_debunkered_fuels && scope.report.bunker.bdn_based_reporting_debunkered_fuels.length > 1 && bdn_based_reporting_debunkered_fuels.length != Array.from(new Set(bdn_based_reporting_debunkered_fuels)).length;
            }
          scope.debunkerFuelGradeRequired = function (index) {
              return scope.vesselDebunkered() && index === 0;
          }
          scope.debunkerBdnRequired = function (index) {
            return scope.vesselDebunkered() && index === 0;
          }        


          scope.$watch('report.bunker.debunkered_fuel_grades', function (debunkered_fuel_grades: string[], oldValues) {
                debunkered_fuel_grades.map(function(newGrade, i) {
                    var oldGrade = oldValues[i];
                    var newGradeAlreadySelected = oldValues.indexOf(newGrade) != -1;
                    if (!!newGrade && !!oldGrade && newGrade != oldGrade && !newGradeAlreadySelected) {
                        scope.report.bunker['quantity_debunkered_' + newGrade] = scope.report.bunker['quantity_debunkered_' + oldGrade];
                    } else if (!newGrade) {
                        scope.report.bunker['quantity_debunkered_' + newGrade] = undefined;
                    }
                });
                
                if (scope.availableFuelGrades) {
                    var notSelectedFuelGrades = Object.keys(scope.availableFuelGrades).filter(function(fuelGrade) { return debunkered_fuel_grades.indexOf(fuelGrade) === -1; });
                    angular.forEach(notSelectedFuelGrades, function(fuelGrade) {
                        if (!!fuelGrade) {
                          scope.report.bunker['quantity_debunkered_' + fuelGrade] = undefined;
                          
                        }
                    });
                }
            
            const isDebunkeredFuelGradesDuplicated = scope.duplicateDebunkeredFuelGradesSelected()
            

            scope.debunkerRowNumbers().map((index)=> {
              scope.reportForm.bunker['debunkeredFuelGrade' + index]?.$setValidity('duplicateGrades', !isDebunkeredFuelGradesDuplicated); 
            })
            
            }, true);

            scope.$watch('report.bunker.bdn_based_reporting_debunkered_fuels', function (newBdnValues: any[], oldBdnValues: any[]) {
                for (let i = 0; i < newBdnValues.length; i++) {
                    const newDebunkeredQuantity = newBdnValues[i]?.quantity_debunkered
                    const oldDebunkeredQuantity = oldBdnValues[i]?.quantity_debunkered
                    if (newDebunkeredQuantity !== oldDebunkeredQuantity) {
                        console.log('debunkered quantity changed. index:', i)
                        // TODO - validate against actual tank stock?
                    }
                }
            }, true);

            scope.$watch('report.bunker.bunkered_fuel_grades', function(bunkered_fuel_grades: string[], oldValues) {
                bunkered_fuel_grades.map(function(newGrade, i) {
                    var oldGrade = oldValues[i];
                    var newGradeAlreadySelected = oldValues.indexOf(newGrade) != -1;
                    if (!!newGrade && !!oldGrade && newGrade != oldGrade && !newGradeAlreadySelected) {
                        scope.report.bunker['quantity_ordered_' + newGrade] = scope.report.bunker['quantity_ordered_' + oldGrade];
                        scope.report.bunker['quantity_received_' + newGrade] = scope.report.bunker['quantity_received_' + oldGrade];
                        scope.report.bunker['bdn_figures_' + newGrade] = scope.report.bunker['bdn_figures_' + oldGrade];
                        scope.report.bunker['density_' + newGrade] = scope.report.bunker['density_' + oldGrade];
                        scope.report.bunker['lcv_' + newGrade] = scope.report.bunker['lcv_' + oldGrade];
                        scope.report.bunker['sulphur_bdn_' + newGrade] = scope.report.bunker['sulphur_bdn_' + oldGrade];
                        scope.report.bunker['sulphur_lab_' + newGrade] = scope.report.bunker['sulphur_lab_' + oldGrade];
                        scope.report.bunker['stable_until_' + newGrade] = scope.report.bunker['stable_until_' + oldGrade];
                    } else if (!newGrade) {
                        scope.report.bunker['quantity_ordered_' + newGrade] = undefined;
                        scope.report.bunker['quantity_received_' + newGrade] = undefined;
                        scope.report.bunker['bdn_figures_' + newGrade] = undefined;
                        scope.report.bunker['density_' + newGrade] = undefined;
                        scope.report.bunker['lcv_' + newGrade] = undefined;
                        scope.report.bunker['sulphur_bdn_' + newGrade] = undefined;
                        scope.report.bunker['sulphur_lab_' + newGrade] = undefined;
                        scope.report.bunker['stable_until_' + newGrade] = undefined;
                    }
                });
                
                if (scope.availableFuelGrades) {
                    var notSelectedFuelGrades = Object.keys(scope.availableFuelGrades).filter(function(fuelGrade) { return bunkered_fuel_grades.indexOf(fuelGrade) === -1; });
                    angular.forEach(notSelectedFuelGrades, function(fuelGrade) {
                        if (!!fuelGrade) {
                            scope.report.bunker['quantity_ordered_' + fuelGrade] = undefined;
                            scope.report.bunker['quantity_received_' + fuelGrade] = undefined;
                            scope.report.bunker['bdn_figures_' + fuelGrade] = undefined;
                            scope.report.bunker['density_' + fuelGrade] = undefined;
                            scope.report.bunker['lcv_' + fuelGrade] = undefined;
                            scope.report.bunker['sulphur_bdn_' + fuelGrade] = undefined;
                            scope.report.bunker['sulphur_lab_' + fuelGrade] = undefined;
                            scope.report.bunker['stable_until_' + fuelGrade] = undefined;
                        }
                    });
                }
            }, true);

            const hasBunkered = () => {
                return scope.report.bunker.bunker_rows?.some((row) => !!row.fuel_grade)
            }

            scope.$watch('report.bunker.took_fuel_oil', function(newValue,oldValue,scope) {
                if (newValue == false) {
                    for (var n in scope.bunkerRowNumbers()) {
                        scope.report.bunker.bunkered_fuel_grades[n] = null;
                    }
                }
                if (scope.report.bdn_based_reporting) {
                    scope.$parent.reportForm.bunker.tankReporting.$setValidity('notBunkered', true);
                    if (newValue) {
                        scope.$parent.reportForm.bunker.tankReporting.$setValidity('notBunkered', hasBunkered());
                    }
                }
            })

            scope.$watch('report.stock.tanks', function (newTankValues: any, oldTankValues, scope) {
                if (!newTankValues) return

                for (let key in newTankValues) {
                    const newTank = newTankValues[key]
                    const oldTank = oldTankValues[key]
                    // validation
                    const bdnRemoved = !newTank?.bdn_added_in_tank && !!oldTank?.bdn_added_in_tank
                    // remove bdn if bdn is removed
                    if (bdnRemoved) {
                        newTank.quantity_added_in_tank = undefined
                        newTank.bdn_added_in_tank = undefined
                        newTank.sulphur_after_bunkering = undefined
                        newTank.new_bdn = undefined
                        validateBunkerTankBdn(key, newTank, scope)
                        fixDuplicateBdnNumbers(scope)
                        return
                    }

                    const qtyChanged = newTank?.quantity_added_in_tank !== oldTank?.quantity_added_in_tank
                    const bdnChanged = newTank?.bdn_added_in_tank?.bdn_number !== oldTank?.bdn_added_in_tank?.bdn_number
                    if (!oldTank || bdnChanged || qtyChanged) {
                        const updateTanks = getTanksUsingBdn(newTank?.bdn_added_in_tank?.bdn_number, scope)
                        validateBunkerTanksBdn(updateTanks, scope)
                    }
                }
            }, true)

            scope.$watch('report.bunker.bdn_based_reporting_bunkered_fuels', function (newBunkerRows: any, oldBunkerRows, scope) {
                if (!newBunkerRows) {
                    scope.$parent.reportForm.bunker.tankReporting.$setValidity('notBunkered', false);
                    return
                }
                if (!oldBunkerRows) return

                scope.availableBdns = []
                scope.$parent.reportForm.bunker.tankReporting.$setValidity('notBunkered', hasBunkered());

                for (let key in newBunkerRows) {
                    const newBunkerRow = newBunkerRows[key]
                    const oldBunkerRow = oldBunkerRows[key]

                    if (newBunkerRow.bdn_number && !scope.availableBdns.includes(newBunkerRow.bdn_number)) {
                        scope.availableBdns.push(newBunkerRow)
                    }

                    const bdnChanged = newBunkerRow?.bdn_number !== oldBunkerRow?.bdn_number
                    const quantityChanged = newBunkerRow?.quantity_received !== oldBunkerRow?.quantity_received
                    const sulphurChanged = newBunkerRow?.sulphur !== oldBunkerRow?.sulphur

                    if (bdnChanged || quantityChanged || sulphurChanged) {
                        const updateTanks = getTanksUsingBdn(newBunkerRow?.bdn_number, scope)
                        validateBunkerTanksBdn(updateTanks, scope)
                    }
                }
            }, true)

            var updateBdnBlend = function(tankId, tank, s) {
                if (!tank.bdn_added_in_tank) {
                    tank.new_bdn = undefined
                    tank.sulphur_after_bunkering = undefined
                    return
                }

                const newTankStock = new TankStock()
                newTankStock.setFuelGrade('blend')

                const totalFuel = tank.amount_before_bunkering + tank.quantity_added_in_tank
                const bdn1SulphurRatio = tank.sulphur_before_bunkering * (tank.amount_before_bunkering / totalFuel)
                const bdn2SulphurRatio = tank.bdn_added_in_tank.sulphur * (tank.quantity_added_in_tank / totalFuel)
                tank.sulphur_after_bunkering = bdn1SulphurRatio + bdn2SulphurRatio
                tank.new_bdn = newTankStock
            }

            var getTanksUsingBdn = function(bdn, s) {
                if (!bdn) return []

                const tanksUsingBdn = []
                for (let tankId in s.report.stock.tanks) {
                    const tank = s.report.stock.tanks[tankId]
                    if (tank.bdn_added_in_tank?.bdn_number === bdn) {
                        tanksUsingBdn.push([tankId, tank])
                    }
                }

                return tanksUsingBdn
            }

            var fixDuplicateBdnNumbers = function(scope) {
                const existingBdns = getStockTankObjects(scope)
                const newBdns = (Object.values(scope.report.stock.tanks) as any).map((tank) => tank.new_bdn)
                const allBdns = [...existingBdns, ...newBdns].filter((tankStock) => tankStock !== undefined)
                parseBdnObjects(allBdns)
            }

            var validateBunkerTanksBdn = function(tanksToUpdate, s) {
                tanksToUpdate.forEach((update) => {
                    validateBunkerTankBdn(update[0], update[1], s)
                })

                fixDuplicateBdnNumbers(s)
            }

            var validateBunkerTankBdn = function(tankId, tank, s) {
                if (!tank) {
                    tank = s.report.stock.tanks[tankId]
                    if (!tank) {
                        return
                    }
                }

                updateBdnBlend(tankId, tank, scope)

                scope.$parent.reportForm.bunker.tankReporting.$setValidity('overCapacity' + tankId, true);
                scope.$parent.reportForm.bunker.tankReporting.$setValidity('insufficientFuel' + tankId, true);

                // check all tanks receiving this bdn
                const tanks = getTanksUsingBdn(tank.bdn_added_in_tank?.bdn_number, s)
                const added = tanks.map((t) => t[1].quantity_added_in_tank).reduce((sum, a) => sum + a, 0)
                if (added > tank.bdn_added_in_tank?.quantity_received) {
                    scope.$parent.reportForm.bunker.tankReporting.$setValidity('insufficientFuel' + tankId, false);
                    scope.bunkerValidationMessages['insufficientFuel' + tankId] = 'Insufficient fuel'
                }
                if (tank.amount_before_bunkering + tank.quantity_added_in_tank > tank.capacity98MT) {
                    scope.bunkerValidationMessages['overCapacity' + tankId] = 'Tank over capacity'
                    scope.$parent.reportForm.bunker.tankReporting.$setValidity('overCapacity' + tankId, false);
                } 
            }
        }
    }
});

routerApp.directive('resultsTab', function() {
    return {
        templateUrl: getCacheBustedTemplate('pages/vessel-reports/tabs/results.html'),
        link: function(scope, element, attributes, control) {

            $("#kpiKnob").knob({
            'readOnly': true,
                'draw' : function () {
                    // @ts-ignore
                 $(this.i).css('font-size', '40px').css('font-weight', 'normal').css('font-family', 'inherit');
              },
              'fgColor': Color.GRAPH_BLUE
            });
            if (scope.shouldAnalyzeReport) {
                $('#kpiKnob').val(scope.report.computed.kpi);
                $('#kpiKnob').trigger('change');
                $('#kpiBox').attr('data-target', '#kpiModal');
            } else {
                $('#kpiKnob').val(0);
                $('#kpiKnob').trigger('change');
                $('#kpiKnob').val('N/A');
                $('#kpiBox').removeAttr('data-target');
            }

            var plotGeneratorData = function(newValue) {
                $(function () {
                    if (scope.vessel_specifications.information.prime_mover_type!='diesel_electric_propulsion') {
                        var aux_name = 'Auxiliary Engine'
                        var aux_data = newValue[2]
                        var pm_name = 'Main Engine'
                        var pm_data = newValue[3]
                    }
                    else {
                        var aux_name = 'Auxiliary'
                        var aux_data = newValue[4]
                        var pm_name = 'Propulsion'
                        var pm_data = newValue[5]
                    }

                Highcharts.chart('generator-power', {
                    credits: {
                        enabled: false
                    },
                    exporting: {
                        enabled: false
                    },
                    chart: {
                        type: 'column'
                    },
                    title: {
                        text: null
                    },
                    xAxis: {
                        // categories: ['03/10', '03/11', '03/12', '03/13', '03/14'],
                        categories: newValue[0],
                        tickLength: 0
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: 'Fuel Consumption (MT)'
                        },
                        stackLabels: {
                            enabled: false
                        }
                    },

                    tooltip: {
                        headerFormat: '<b>{point.x}</b><br/>',
                        pointFormat: '{series.name}: {point.y} MT<br/>Total: {point.stackTotal} MT'
                    },
                    plotOptions: {
                        column: {
                            stacking: 'normal',
                            dataLabels: {
                                enabled: false,
                                color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white'
                            }
                        }
                    },
                    series: [{
                        name: 'Boiler',
                        // data: [6.2, 8.4, 8.5, 6, 10],
                        data: newValue[1],
                        color: themePalette.colors.GRAPH_BLUE,
                        legendIndex: 2
                    }, {
                        name: aux_name,
                        // data: [4.1, 5, 6, 5.5, 4],
                        data: aux_data,
                        color: Color.DATA_1,
                        legendIndex: 1
                    }, {
                        name: pm_name,
                        // data: [8.4, 14.8, 4.2, 14.4, 6.4],
                        data: pm_data,
                        color: Color.DATA_2,
                        legendIndex: 0
                    }]
                });



            });
            }

            scope.$watch('report.computed.historical_data.consumptions', function(newValue, oldValue, scope) {
                if (newValue != undefined) {
                    plotGeneratorData(newValue);
                }
            });

            if (scope.report.historical_data.consumptions != undefined) {
                plotGeneratorData(scope.report.historical_data.consumptions);
            }

        }
    }
});

routerApp.directive('portResultsTab', function() {
    return {
        templateUrl: getCacheBustedTemplate('pages/vessel-reports/tabs/port-results.html'),
        link: function(scope, element, attributes, control) {

        }
    }
});

routerApp.directive('veslinkReports', function() {
    return {
        templateUrl: getCacheBustedTemplate('pages/vessel-reports/tabs/sections/veslink-reports.html'),
        link: function(scope, element, attributes, control) {

        }
    }
});

routerApp.directive('reportSubmit', function() {
    return {
        templateUrl: getCacheBustedTemplate('pages/vessel-reports/tabs/sections/report-submit.html'),
        link: function(scope, element, attributes, control) {

        }
    }
});

routerApp.directive('navboxTrackpoint', function() {
    return {
        templateUrl: getCacheBustedTemplate('pages/vessel-reports/tabs/sections/navbox-trackpoint.html'),
        link: function(scope, element, attributes, control) {

        }
    }
});


routerApp.directive('reportHistory', ['ReportsApiService', '$rootScope', function(ReportsApiService, $rootScope) {
    return {
        templateUrl: getCacheBustedTemplate('pages/vessel-reports/tabs/sections/history.html'),
        link: function(scope, element, attributes, control) {
            ReportsApiService.getReportHistory($rootScope.selectedVessel.id, scope.report.id, scope);
        }
    }
}]);


routerApp.directive('stockTab', ['ReportService', 'VesselSpecificationService',function(ReportService, VesselSpecificationService) {
    return {
        templateUrl: getCacheBustedTemplate('pages/vessel-reports/tabs/stock.html'),
        link: function(scope, element, attributes, control) {
            scope.report.stock.new_fuel_configuration = true;
            scope.fuelProperties = fuelTypeProperties;
            const vesselSpec = VesselSpecificationService.getSpecifications()
            scope.form = vesselSpec.form;
            scope.tvaISOFuelGradeMapping = {
                'hfo' :['RME180','RMG180','RMG380','RMG500','RMG700','RMK380','RMK500','RMK700'],
                'lfo' :['RMA10','RMB30','RMD80'],
                'mgo' :['DMA','DMX','DFA','DMZ','DFZ','DMB','DFB'],
                'mdo' :['DMA','DMX','DFA','DMZ','DFZ','DMB','DFB'],
                'b10lfo' :['RMA10','RMB30','RMD80'],
                'b10mgo' :['DMA','DMX','DFA','DMZ','DFZ','DMB','DFB'],
                'biolfo' :['RMA10','RMB30','RMD80'],
                'biomgo' :['DMA','DMX','DFA','DMZ','DFZ','DMB','DFB'],
                'ulsfo2020' :['RME180','RMG180','RMG380','RMG500','RMG700','RMK380','RMK500','RMK700'],
                'ulslfo2020' :['RMA10','RMB30','RMD80'],
                'ulsmdo2020' :['DMA','DMX','DFA','DMZ','DFZ','DMB','DFB'],
                'ulsmgo2020' :['DMA','DMX','DFA','DMZ','DFZ','DMB','DFB'],
                'vlsfo2020' :['RME180','RMG180','RMG380','RMG500','RMG700','RMK380','RMK500','RMK700'],
                'vlslfo2020' :['RMA10','RMB30','RMD80'],
                'lpgp' :['LPG'],
                'lpgb' :['LPG'],
                'lng' :['LNG'],
                'methanol' :['Methanol'],
                'ethanol' :['Ethanol'],
                'other' :['Other']
            }

            var setISOFuelMapping = function() {
                scope.isoFuelGrades = [];
                for (var i=0;i<3;i++) {
                    var fuel_type=scope.report.bunker.bunkered_fuel_grades[i];
                    angular.forEach(Object.keys(scope.tvaISOFuelGradeMapping), function(fuelGrade) {
                        if (fuel_type == fuelGrade) scope.isoFuelGrades[i] = scope.tvaISOFuelGradeMapping[fuelGrade];
                    })
                    
                }    
            }

            scope.$watchGroup(['report.bunker.bunkered_fuel_grades[0]','report.bunker.bunkered_fuel_grades[1]','report.bunker.bunkered_fuel_grades[2]'], function(newValues) {
                if (scope.report.bunker && scope.report.bunker.bunkered_fuel_grades && scope.report.bunker.bunkered_fuel_grades.length > 0) {
                    setISOFuelMapping();
                }
            })

            var validateBunkerTanks = function(tanks, s) {
                if (s.report.bdn_based_reporting) return

                if (s.report.bunker && s.report.bunker.took_fuel_oil && tanks && s.report.stock && s.report.stock.tank_based_reporting) {
                    var fuelGradeQuantityAdded = {};
                    var tankSulphurs = {};
                    var tankLCVs = {};
                    var tankDensities = {};
                     // Reset validations...
                    scope.bunkerValidationMessages = {};

                    Object.keys(tanks).map(function(tank_id, index) {
                        var tankStock = tanks[tank_id];
                        var fuelGrade = tankStock.fuel_grade_added_in_tank;
                        if (s.report.bunker['quantity_received_' + fuelGrade] > 0) {
                            if (fuelGradeQuantityAdded[tankStock.fuel_grade_added_in_tank] == undefined) {
                                fuelGradeQuantityAdded[tankStock.fuel_grade_added_in_tank] = 0;
                            }
                            fuelGradeQuantityAdded[tankStock.fuel_grade_added_in_tank] += tankStock.quantity_added_in_tank || 0;
                        }

                        var newSulphurValue = 0;
                        var newLCVValue = 0;
                        var newDensityValue = 0;

                        var totalFuelInTank = 0;
                        if (tankStock.amount_before_bunkering > 0) {
                            if (tankStock.sulphur_before_bunkering > 0) {
                                newSulphurValue += tankStock.amount_before_bunkering * tankStock.sulphur_before_bunkering;
                            }
                            if (tankStock.lcv_before_bunkering) {
                                newLCVValue += tankStock.amount_before_bunkering * tankStock.lcv_before_bunkering;
                            }
                            if (tankStock.density_before_bunkering) {
                                newDensityValue += tankStock.amount_before_bunkering * tankStock.density_before_bunkering;
                            }
                            if (tankStock.sulphur_before_bunkering > 0 || tankStock.lcv_before_bunkering || tankStock.density_before_bunkering) {
                                totalFuelInTank += tankStock.amount_before_bunkering;
                            }
                        }

                        if (tankStock.quantity_added_in_tank > 0) {
                            var sulphurPercent = s.report.bunker['sulphur_bdn_' + fuelGrade];
                            if (s.report.bunker['sulphur_lab_' + fuelGrade]) {
                                sulphurPercent = s.report.bunker['sulphur_lab_' + fuelGrade];
                            }
                            if (sulphurPercent > 0) {
                                newSulphurValue += tankStock.quantity_added_in_tank * sulphurPercent;
                            }
                            if (s.report.bunker['lcv_' + fuelGrade] > 0) {
                                newLCVValue += tankStock.quantity_added_in_tank * s.report.bunker['lcv_' + fuelGrade];
                            }
                            if (s.report.bunker['density_' + fuelGrade] > 0) {
                                newDensityValue += tankStock.quantity_added_in_tank * s.report.bunker['density_' + fuelGrade];
                            }
                            if (sulphurPercent > 0 || s.report.bunker['lcv_' + fuelGrade] > 0 || s.report.bunker['density_' + fuelGrade] > 0) {
                                totalFuelInTank += tankStock.quantity_added_in_tank;
                            }
                        }

                        tankSulphurs[tank_id] = newSulphurValue = totalFuelInTank > 0 ? newSulphurValue / totalFuelInTank : null;
                        tankLCVs[tank_id] = newLCVValue = totalFuelInTank > 0 ? newLCVValue / totalFuelInTank : null;
                        tankDensities[tank_id] = newDensityValue = totalFuelInTank > 0 ? newDensityValue / totalFuelInTank : null;

                        // bunker tank capacity limit validation
                        if (scope.vesselSpecs && scope.vesselSpecs.tanks) {
                            var vesselTank = scope.vesselSpecs.tanks.find(function(vesselTank) { return vesselTank.tank_id['$oid'] == tank_id; });
                            if (vesselTank) {
                                var capacity100 = vesselTank.volume_ratings.find(function(tank) { return tank.percent == 100; });
                                if (capacity100) {
                                    let capacity98MT = capacity100.unit == 'm3' ? capacity100.value * 0.98 * (tankStock.density / 1000) : capacity100.value * 0.98;
                                    capacity98MT = roundToPlaces(capacity98MT, 2);
                                    let isOverCapacity = totalFuelInTank > capacity98MT;
                                    scope.bunkerValidationMessages['overCapacity' + tank_id] = isOverCapacity ? 'Tank is over 98% capacity by weight (' + capacity98MT + ' MT).' : undefined;
                                    scope.reportForm.bunker.tankReporting.$setValidity('overCapacity' + tank_id, isOverCapacity == false);
                                }
                            }
                        }
                    });

                    if (s.report.stock) {
                        // run this inside set timeout so that angular doesn't know about it and triggers 
                        // a watch event / loop
                        setTimeout(function(){
                            Object.keys(tankSulphurs).map(function(tank_id, index) { 
                                if (tankSulphurs[tank_id] > 0) { 
                                    tanks[tank_id].sulphur_after_bunkering = Math.round(tankSulphurs[tank_id] * 100) / 100;
                                }
                                if (tankLCVs[tank_id] > 0) { 
                                    tanks[tank_id].lcv = Math.round(tankLCVs[tank_id] * 100) / 100;
                                }
                                if (tankDensities[tank_id] > 0) { 
                                    tanks[tank_id].density = Math.round(tankDensities[tank_id] * 100) / 100;
                                }
                                if (tanks[tank_id].fuel_grade_added_in_tank && tanks[tank_id].fuel_grade_added_in_tank != '') {
                                    tanks[tank_id].fuel_grade = tanks[tank_id].fuel_grade_added_in_tank;
                                }
                            });
                        }, 0)
                    }

                    // Reset validations...
                    scope.bunkerValidationMessages = {};
                    angular.forEach(Object.keys(scope.availableFuelGrades), function(grade) {
                        if (grade && scope.reportForm && scope.reportForm.bunker && scope.reportForm.bunker.tankReporting) scope.reportForm.bunker.tankReporting.$setValidity('total_quantity_added_' + grade, true);
                    });
                    // ...then check on added quantities to make sure they are close to bunkered figures
                    Object.keys(fuelGradeQuantityAdded).map(function(grade, index) {
                        if (grade && scope.reportForm && scope.reportForm.bunker && scope.reportForm.bunker.tankReporting) {
                            var quantityAdded = fuelGradeQuantityAdded[grade] || 0;
                            var quantityReceived = scope.report.bunker['quantity_received_' + grade];
                            var deviation = Math.round(Math.abs(quantityReceived - quantityAdded) * 100) / 100;
                            var valid = deviation == 0;
                            var validationMessage = 'The total quantity of ' + grade.toUpperCase() + ' added to tanks deviates by ' + deviation + ' MT from the total bunkered quantity for this fuel grade.';
                            scope.bunkerValidationMessages[grade] = valid ? null : validationMessage;
                            scope.reportForm.bunker.tankReporting.$setValidity('total_quantity_added_' + grade, valid);
                        }
                    });

                    
                };
            }
            
            var total_stock_fuel_types = []
            var default_consumption_fuel_types = []
            var old_fuel_grades = ['hshfo', 'lshfo', 'ulsfo', 'lsmdo', 'hsmgo', 'lsmgo']
            var setDefaultConsFuelTypes = function(tanks ,s) {
                Object.keys(tanks).map(function(tank_id, index) {
                    var tankStock = tanks[tank_id]
                    if (tankStock.in_use == true && default_consumption_fuel_types.includes(tankStock.fuel_grade) != true && old_fuel_grades.includes(tankStock.fuel_grade) != true) default_consumption_fuel_types.push(tankStock.fuel_grade)
                })
            }

            var setTotalStockFuelTypes = function(tanks ,s) {
                Object.keys(tanks).map(function(tank_id, index) {
                    var tankStock = tanks[tank_id]
                    if (total_stock_fuel_types.includes(tankStock.fuel_grade) != true && old_fuel_grades.includes(tankStock.fuel_grade) != true) total_stock_fuel_types.push(tankStock.fuel_grade)
                    for (var i=0; i<total_stock_fuel_types.length;i++) {
                        if (scope.report.stock.tank_based_reporting) scope.report.stock['fuel_type_'+(i+1)] = total_stock_fuel_types[i]
                    }
                })
            }

            var setAvailableFuelGrades = function() {
                // we should only be returning the fuel grades that were bunkered, but
                // returning all fuel grades makes it easier to handle validations on bunker tab
                scope.availableFuelGrades = {};
                // todo: uncomment if we need to only show fuel grades that were bunkered
                // scope.availableFuelGrades = fuelGrades.filter(function(fuelGrade) {
                //     return scope.report.bunker['quantity_received_' + fuelGrade.value] > 0;
                // });

                var availableFuelGrades = []
                for (var key in fuelGrades) {
                    if (scope.vessel_specifications.emissions[key+'_enabled']) {
                        availableFuelGrades.push(key)
                    }
                }
                if (availableFuelGrades.length == 0) {
                    scope.availableFuelGrades = fuelGrades;
                    
                }
                else {
                    if (scope.report.stock.tank_based_reporting) {
                        angular.forEach(Object.keys(scope.report.stock.tanks), function(tankId) {
                            var tank = scope.report.stock.tanks[tankId];
                            var current_fuel_grade = tank.fuel_grade
                            if (availableFuelGrades.includes(current_fuel_grade) != true) {
                                availableFuelGrades.push(current_fuel_grade)
                            }
                        })
                    }
                    var stock_fuel_in_use = []
                    for (var j=1; j<=3; j++) {
                        if (scope.report.stock && scope.report.stock['fuel_type_'+j]) {
                            stock_fuel_in_use.push(scope.report.stock['fuel_type_'+j])
                        }
                    }

                    availableFuelGrades = addUsedFuelstoListOfFuels(availableFuelGrades, stock_fuel_in_use)
                    availableFuelGrades = addUsedFuelstoListOfFuels(availableFuelGrades, scope.report.computed?.enabled_fuel_types)
                    availableFuelGrades = addUsedFuelstoListOfFuels(availableFuelGrades, scope.report.bunker?.bunkered_fuel_grades)
    
                    for (var i=0; i<availableFuelGrades.length; i++) {
                        scope.availableFuelGrades[availableFuelGrades[i]] = availableFuelGrades[i].toUpperCase()
                    }
                }
            };

          scope.isDateTimeValid = (index) => {
            const maxDate = scope.report.operational.report_to;
            const minDate = scope.report.operational.report_from;
            const dateTime = scope.report.stock.tank_transfers[index].date_time;

            if (!dateTime || dateTime == "") return true;

             const dateTimeObj = moment(scope.report.stock.tank_transfers[index].date_time, "DD/MM/YYYY hh:mm")
            if (minDate && maxDate) {
              const maxDateObj = moment(scope.report.operational.report_to, "DD/MM/YYYY hh:mm")
              const minDateObj = moment(scope.report.operational.report_from, "DD/MM/YYYY hh:mm")
              return dateTimeObj.isBetween(minDateObj, maxDateObj);
            }

            if (scope.report.operational.report_to) {
              const maxDateObj = moment(scope.report.operational.report_to, "DD/MM/YYYY hh:mm")
              return dateTimeObj.isSameOrBefore(maxDateObj);
            }
            if (scope.report.operational.report_from) {
              const minDateObj = moment(scope.report.operational.report_from, "DD/MM/YYYY hh:mm")
              return dateTimeObj.isSameOrAfter(minDateObj);
            }
            return true
          }
         

            scope.$watchGroup([
                'bunkered_fuel_grades', 
                'report.bunker.quantity_received_hfo',
                'report.bunker.quantity_received_lfo',
                'report.bunker.quantity_received_mgo',
                'report.bunker.quantity_received_mdo',
                'report.bunker.quantity_received_b10lfo',
                'report.bunker.quantity_received_b10mgo',
                'report.bunker.quantity_received_biolfo',
                'report.bunker.quantity_received_biomgo',
                'report.bunker.quantity_received_ulsfo2020',
                'report.bunker.quantity_received_ulslfo2020',
                'report.bunker.quantity_received_ulsmdo2020',
                'report.bunker.quantity_received_ulsmgo2020',
                'report.bunker.quantity_received_vlsfo2020',
                'report.bunker.quantity_received_vlslfo2020',
                'report.bunker.quantity_received_lpgp',
                'report.bunker.quantity_received_lpgb',
                'report.bunker.quantity_received_lng',
                'report.bunker.quantity_received_methanol',
                'report.bunker.quantity_received_ethanol',
                'report.bunker.quantity_received_other',
                'report.bunker.sulphur_bdn_hfo',
                'report.bunker.sulphur_bdn_lfo',
                'report.bunker.sulphur_bdn_mgo',
                'report.bunker.sulphur_bdn_mdo',
                'report.bunker.sulphur_bdn_b10lfo',
                'report.bunker.sulphur_bdn_b10mgo',
                'report.bunker.sulphur_bdn_biolfo',
                'report.bunker.sulphur_bdn_biomgo',
                'report.bunker.sulphur_bdn_ulsfo2020',
                'report.bunker.sulphur_bdn_ulslfo2020',
                'report.bunker.sulphur_bdn_ulsmdo2020',
                'report.bunker.sulphur_bdn_ulsmgo2020',
                'report.bunker.sulphur_bdn_vlsfo2020',
                'report.bunker.sulphur_bdn_vlslfo2020',
                'report.bunker.sulphur_bdn_lpgp',
                'report.bunker.sulphur_bdn_lpgb',
                'report.bunker.sulphur_bdn_lng',
                'report.bunker.sulphur_bdn_methanol',
                'report.bunker.sulphur_bdn_ethanol',
                'report.bunker.sulphur_bdn_other',
                'report.bunker.sulphur_lab_hfo',
                'report.bunker.sulphur_lab_lfo',
                'report.bunker.sulphur_lab_mgo',
                'report.bunker.sulphur_lab_mdo',
                'report.bunker.sulphur_lab_b10lfo', 
                'report.bunker.sulphur_lab_b10mgo',
                'report.bunker.sulphur_lab_biolfo',
                'report.bunker.sulphur_lab_biomgo',
                'report.bunker.sulphur_lab_ulsfo2020',
                'report.bunker.sulphur_lab_ulslfo2020',
                'report.bunker.sulphur_lab_ulsmdo2020',
                'report.bunker.sulphur_lab_ulsmgo2020', 
                'report.bunker.sulphur_lab_vlsfo2020',
                'report.bunker.sulphur_lab_vlslfo2020',
                'report.bunker.sulphur_lab_lpgp',
                'report.bunker.sulphur_lab_lpgb',
                'report.bunker.sulphur_lab_lng',
                'report.bunker.sulphur_lab_methanol',
                'report.bunker.sulphur_lab_ethanol', 
                'report.bunker.sulphur_lab_other',
                'report.bunker.lcv_hfo',
                'report.bunker.lcv_lfo',
                'report.bunker.lcv_mgo',
                'report.bunker.lcv_mdo',
                'report.bunker.lcv_b10lfo', 
                'report.bunker.lcv_b10mgo',
                'report.bunker.lcv_biolfo',
                'report.bunker.lcv_biomgo',
                'report.bunker.lcv_ulsfo2020',
                'report.bunker.lcv_ulslfo2020',
                'report.bunker.lcv_ulsmdo2020',
                'report.bunker.lcv_ulsmgo2020', 
                'report.bunker.lcv_vlsfo2020',
                'report.bunker.lcv_vlslfo2020',
                'report.bunker.lcv_lpgp',
                'report.bunker.lcv_lpgb',
                'report.bunker.lcv_lng',
                'report.bunker.lcv_methanol',
                'report.bunker.lcv_ethanol', 
                'report.bunker.lcv_other',
                'report.bunker.density_hfo',
                'report.bunker.density_lfo',
                'report.bunker.density_mgo',
                'report.bunker.density_mdo',
                'report.bunker.density_b10lfo', 
                'report.bunker.density_b10mgo',
                'report.bunker.density_biolfo',
                'report.bunker.density_biomgo',
                'report.bunker.density_ulsfo2020',
                'report.bunker.density_ulslfo2020',
                'report.bunker.density_ulsmdo2020',
                'report.bunker.density_ulsmgo2020', 
                'report.bunker.density_vlsfo2020',
                'report.bunker.density_vlslfo2020',
                'report.bunker.density_lpgp',
                'report.bunker.density_lpgb',
                'report.bunker.density_lng',
                'report.bunker.density_methanol',
                'report.bunker.density_ethanol', 
                'report.bunker.density_other',
            ], function(newValues, oldValues, s) {
                validateBunkerTanks(s.report.stock.tanks, s);
                setAvailableFuelGrades();
            });

            scope.$watchGroup(['report.stock.hfo', 'report.stock.lfo', 'report.stock.mgo', 'report.stock.mdo', 'report.stock.b10lfo', 'report.stock.b10mgo', 'report.stock.biolfo', 'report.stock.biomgo', 'report.stock.ulsfo2020', 'report.stock.ulslfo2020', 'report.stock.ulsmdo2020', 'report.stock.ulsmgo2020', 'report.stock.vlsfo2020', 'report.stock.vlslfo2020', 'report.stock.lpgp', 'report.stock.lpgb', 'report.stock.lng', 'report.stock.methanol', 'report.stock.ethanol', 'report.stock.other'], function(newValues) {
                if (!scope.isManReport(scope.r)) {
                    scope.report.stock.total_stock_fuel = sumValuesFuel(newValues);
                }
            });

            var assignTankAttributes = function() {
                if (scope.isPortReport(scope.report) || scope.isAnchorReport(scope.report)) {
                    angular.forEach(Object.keys(scope.report.stock.tanks), function(tankId) {
                        var tank = scope.report.stock.tanks[tankId];
                        // If tank took bunkers, then set attributes appropriately.
                        if (tank.quantity_added_in_tank > 0) {
                            if (scope.report.bunker.fuel_started_bunkering_date) { tank.bunker_date = scope.report.bunker.fuel_started_bunkering_date; }
                            if ( scope.report.operational.port_name) { tank.bunker_port = scope.report.operational.port_name; }
                            if (scope.report.bunker['stable_until_' + tank.fuel_grade_added_in_tank]) { tank.stable_until = scope.report.bunker['stable_until_' + tank.fuel_grade_added_in_tank]; }
                        } else if (scope.carryOverReport) {
                            var carryOverTank = scope.carryOverReport.stock.tanks[tankId] || {};
                            if (carryOverTank.bunker_date) { tank.bunker_date = ReportService.formatMoment(ReportService.parseFieldDate(carryOverTank.bunker_date)); }
                            if (carryOverTank.bunker_report) { tank.bunker_port = carryOverTank.bunker_port; }
                            if (carryOverTank.stable_until) { tank.stable_until = ReportService.formatMoment(ReportService.parseFieldDate(carryOverTank.stable_until)); }
                        }
                    });
                }
                setTankGroupColors(Object.keys(scope.report.stock.tanks).map(function(tankId) { return scope.report.stock.tanks[tankId]; }));
            }
            
            setTankGroupColors(Object.keys(scope.report.stock.tanks).map(function(tankId) { return scope.report.stock.tanks[tankId]; }));

            var setTankModifiedDates = function(newTanks) {
                if (!scope.carryOverReport || !scope.carryOverReport.stock) {
                    return;
                }
                angular.forEach(Object.keys(newTanks), function(tankId) {
                    var newTank = newTanks[tankId];
                    var carryOverTank = scope.carryOverReport.stock.tanks[tankId] || {};
                    var propertiesToCheck = [
                        "amount",
                        "bunker_date",
                        "bunker_port",
                        "density",
                        "fuel_grade",
                        "fuel_grade_added_in_tank",
                        "in_use",
                        "lcv",
                        "stable_until",
                        "sulphur_after_bunkering",
                    ];
                    var tanksDiffer = false;
                    angular.forEach(propertiesToCheck, function(propName) {
                        var newPropValue = newTank[propName];
                        var carryOverPropValue = typeof carryOverTank[propName] == 'object' && '$date' in carryOverTank[propName] ?  
                        ReportService.formatMoment(ReportService.parseFieldDate(carryOverTank[propName])) : carryOverTank[propName];
                        if (newPropValue != carryOverPropValue) {
                            tanksDiffer = true;
                        }
                    });
                    newTank.modified_date = tanksDiffer ? moment(new Date()).format('DD/MM/YYYY HH:mm') : ReportService.formatMoment(ReportService.parseFieldDate(carryOverTank.modified_date));
                });
            };
            setTankModifiedDates(scope.report.stock.tanks);

            // @ts-ignore
            var tanksWatch = scope.$watch('report.stock.tanks', function(tanks, oldValue, s) {
                validateBunkerTanks(tanks, s);
                assignTankAttributes();
                setTankModifiedDates(tanks);
                setTotalStockFuelTypes(tanks, s);
                setDefaultConsFuelTypes(tanks, s);
            }, 1);
            scope.totalStockFuelTypes = total_stock_fuel_types;
            scope.defaultConsFuelTypes = default_consumption_fuel_types;

            scope.$watchGroup([ 
                'report.bunker.stable_until_hfo',
                'report.bunker.stable_until_lfo',
                'report.bunker.stable_until_mgo',
                'report.bunker.stable_until_mdo',
                'report.bunker.stable_until_b10lfo',
                'report.bunker.stable_until_b10mgo',
                'report.bunker.stable_until_biolfo',
                'report.bunker.stable_until_biomgo',
                'report.bunker.stable_until_ulsfo2020',
                'report.bunker.stable_until_ulslfo2020',
                'report.bunker.stable_until_ulsmdo2020',
                'report.bunker.stable_until_ulsmgo2020',
                'report.bunker.stable_until_vlsfo2020',
                'report.bunker.stable_until_vlslfo2020',
                'report.bunker.stable_until_lpgp',
                'report.bunker.stable_until_lpgb',
                'report.bunker.stable_until_lng',
                'report.bunker.stable_until_methanol',
                'report.bunker.stable_until_ethanol',
                'report.bunker.stable_until_other',
                'report.bunker.fuel_started_bunkering_date',
                'report.operational.port_name'
                ], assignTankAttributes);
            
            // get all of the vessel's supported fuel grades
            scope.$watch('vesselSpecs.tanks', function(newValue: any[], oldValue, scope) {
                if (!newValue) return;

                var vesselTanks = newValue;
                if (newValue == undefined) {
                    return;
                }
                var vesselSupportedFuelGrades = {};
                for (var i = 0; i < vesselTanks.length; i++) {
                    var tankFuelGrades = vesselTanks[i].fuel_types;
                    for (var j = 0; j < tankFuelGrades.length; j++) {
                        vesselSupportedFuelGrades[tankFuelGrades[j]] = true;
                    }
                }
                vesselSupportedFuelGrades = Object.keys(vesselSupportedFuelGrades);
                scope.vesselSupportedFuelGrades = vesselSupportedFuelGrades;
            });

            // sum tank amounts based on fuel grades and map them back to
            // standard HSHFO, HSMDO and LSMGO fuel grades
            var sumTankAmounts = function(tanks) {
                if (tanks && scope.report.stock.tank_based_reporting) {
                    // used for calculating fuel specs for tanks in use
                    var weightedSumOfLCVByFuelGrade = {};
                    var weightedSumOfSulphurByFuelGrade = {};
                    var weightedSumOfDensityByFuelGrade = {};
                    var totalFuelInUseByFuelGrade = {};
                    // set total stock values
                    var fuelGradeTotals = {};
                    Object.keys(tanks).map(function(tank_id, index) {
                        var tankStock = tanks[tank_id];
                        var fuelGrade = tankStock.fuel_grade;
                        if (tankStock.amount > 0) {
                            if (fuelGradeTotals[tankStock.fuel_grade] != undefined) {
                                fuelGradeTotals[tankStock.fuel_grade] += tankStock.amount;
                            } else {
                                fuelGradeTotals[tankStock.fuel_grade] = tankStock.amount;
                            }

                            if (tankStock.in_use) {
                                if (weightedSumOfSulphurByFuelGrade[fuelGrade] == undefined) { weightedSumOfSulphurByFuelGrade[fuelGrade] = 0 };
                                if (weightedSumOfLCVByFuelGrade[fuelGrade] == undefined) { weightedSumOfLCVByFuelGrade[fuelGrade] = 0 };
                                if (weightedSumOfDensityByFuelGrade[fuelGrade] == undefined) { weightedSumOfDensityByFuelGrade[fuelGrade] = 0 };
                                if (totalFuelInUseByFuelGrade[fuelGrade] == undefined) { totalFuelInUseByFuelGrade[fuelGrade] = 0; }
                                weightedSumOfLCVByFuelGrade[fuelGrade] += tankStock.amount * tankStock.lcv;
                                weightedSumOfSulphurByFuelGrade[fuelGrade] += tankStock.amount * tankStock.sulphur_after_bunkering;
                                weightedSumOfDensityByFuelGrade[fuelGrade] += tankStock.amount * tankStock.density;
                                totalFuelInUseByFuelGrade[fuelGrade] += tankStock.amount;
                            }
                        }
                    });
                    if (scope.report.stock) {
                        scope.report.stock.hshfo = Math.round(fuelGradeTotals['hshfo'] * 100) / 100;
                        scope.report.stock.lshfo = Math.round(fuelGradeTotals['lshfo'] * 100) / 100;
                        scope.report.stock.ulsfo = Math.round(fuelGradeTotals['ulsfo'] * 100) / 100;
                        scope.report.stock.hsmdo = Math.round(fuelGradeTotals['hsmdo'] * 100) / 100;
                        scope.report.stock.lsmdo = Math.round(fuelGradeTotals['lsmdo'] * 100) / 100;
                        scope.report.stock.hsmgo = Math.round(fuelGradeTotals['hsmgo'] * 100) / 100;
                        scope.report.stock.lsmgo = Math.round(fuelGradeTotals['lsmgo'] * 100) / 100;

                        scope.report.stock.hfo = Math.round(fuelGradeTotals['hfo'] * 100) / 100;
                        scope.report.stock.lfo = Math.round(fuelGradeTotals['lfo'] * 100) / 100;
                        scope.report.stock.mgo = Math.round(fuelGradeTotals['mgo'] * 100) / 100;
                        scope.report.stock.mdo = Math.round(fuelGradeTotals['mdo'] * 100) / 100;
                        scope.report.stock.b10lfo = Math.round(fuelGradeTotals['b10lfo'] * 100) / 100;
                        scope.report.stock.b10mgo = Math.round(fuelGradeTotals['b10mgo'] * 100) / 100;
                        scope.report.stock.biolfo = Math.round(fuelGradeTotals['biolfo'] * 100) / 100;
                        scope.report.stock.biomgo = Math.round(fuelGradeTotals['biomgo'] * 100) / 100;
                        scope.report.stock.ulsfo2020 = Math.round(fuelGradeTotals['ulsfo2020'] * 100) / 100;
                        scope.report.stock.ulslfo2020 = Math.round(fuelGradeTotals['ulslfo2020'] * 100) / 100;
                        scope.report.stock.ulsmdo2020 = Math.round(fuelGradeTotals['ulsmdo2020'] * 100) / 100;
                        scope.report.stock.ulsmgo2020 = Math.round(fuelGradeTotals['ulsmgo2020'] * 100) / 100;
                        scope.report.stock.vlsfo2020 = Math.round(fuelGradeTotals['vlsfo2020'] * 100) / 100;
                        scope.report.stock.vlslfo2020 = Math.round(fuelGradeTotals['vlslfo2020'] * 100) / 100;
                        scope.report.stock.lpgp = Math.round(fuelGradeTotals['lpgp'] * 100) / 100;
                        scope.report.stock.lpgb = Math.round(fuelGradeTotals['lpgb'] * 100) / 100;
                        scope.report.stock.lng = Math.round(fuelGradeTotals['lng'] * 100) / 100;
                        scope.report.stock.methanol = Math.round(fuelGradeTotals['methanol'] * 100) / 100;
                        scope.report.stock.ethanol = Math.round(fuelGradeTotals['ethanol'] * 100) / 100;
                        scope.report.stock.other = Math.round(fuelGradeTotals['other'] * 100) / 100;

                        Object.keys(totalFuelInUseByFuelGrade).map(function(grade, index) {
                            let totalFuelGradeAmount = totalFuelInUseByFuelGrade[grade];
                            // be careful when updating fuel specs on consumptions tab when these values are not being accurately calculated
                            if (totalFuelGradeAmount > 0) {
                                var newSulphur = Math.round(weightedSumOfSulphurByFuelGrade[grade] * 10 / totalFuelGradeAmount) / 10;
                                if (newSulphur > 0) { scope.report.consumption['sulphur_' + grade] = newSulphur };

                                var newLCV = Math.round(weightedSumOfLCVByFuelGrade[grade] * 10 / totalFuelGradeAmount) / 10;
                                if (newLCV > 0) { scope.report.consumption['lcv_' + grade] = newLCV };

                                var newDensity = Math.round(weightedSumOfDensityByFuelGrade[grade] * 10 / totalFuelGradeAmount) / 10;
                                if (newDensity > 0) { scope.report.consumption['density_' + grade] = newDensity };
                            }
                        });
                    }
                }
            }

            // @ts-ignore
            scope.$watch('report.stock.tanks', function(tanks) {
                sumTankAmounts(tanks);
            }, 1);

            scope.$watch('report.stock.tank_based_reporting', function(tankBasedReporting, _, scope) {
                if (tankBasedReporting) {
                    sumTankAmounts(scope.report.stock.tanks);
                }
            });


            var validateStockTanks = function(tanks) {
                if (scope.report.stock.tank_based_reporting && scope.report.stock.tank_sounding && tanks) {
                    if (!scope.vesselSpecs) return;

                    var reportedFuelGrades = {};
                    Object.keys(tanks).map(function(tank_id, index) {
                        var tank = tanks[tank_id];
                        var tankFuelGrade = tank.fuel_grade || tank.fuel_grade_added_in_tank;                        
                        // stock tank capacity limit validation
                        if (scope.vesselSpecs && scope.vesselSpecs.tanks) {
                            var vesselTank = scope.vesselSpecs.tanks.find(function(vesselTank) { return vesselTank.tank_id['$oid'] == tank_id; });
                            if (vesselTank) {
                                var capacity100 = vesselTank.volume_ratings.find(function(tank) { return tank.percent == 100; });
                                if (capacity100) {
                                    var capacity98MT = capacity100.unit == 'm3' ? capacity100.value * 0.98 * (tank.density / 1000) : capacity100.value * 0.98;
                                    tank.capacity98MT = roundToPlaces(capacity98MT, 2);
                                    tank.isOverCapacity = tank.amount > capacity98MT;
                                    scope.validationMessages['overCapacity' + tank_id] = tank.isOverCapacity ? 'Tank is over 98% capacity by weight (' + tank.capacity98MT + ' MT).' : undefined;

                                    tank.isUnderCapacity = tank.amount < 0
                                    scope.validationMessages['underCapacity' + tank_id] = tank.isUnderCapacity ? 'Tank cannot hold a negative value.' : undefined;

                                    tank.capacityNaN = isNaN(tank.amount)
                                    scope.validationMessages['nanCapacity' + tank_id] = tank.capacityNaN ? 'Not a number.' : undefined;
                                }
                            }
                        }
                        if (reportedFuelGrades[tankFuelGrade]) return;
                        var consumedFuelGrade = scope.report.consumption['total_consumption_' + tankFuelGrade] > 0;
                        // If a certain fuel grade has been consumed, make sure at least one of the tanks of that fuel
                        // grade have stock reported and one is in use.
                        reportedFuelGrades[tankFuelGrade] = consumedFuelGrade ? (tank.amount != undefined || tank.amount_before_bunkering != undefined || tank.fuel_grade_added_in_tank != undefined) && tank.in_use : true
                    });

                    angular.forEach(Object.keys(reportedFuelGrades), function(fg) {
                        scope.validationMessages['tankReportingFuelGrades_' + fg] = !reportedFuelGrades[fg] ? '' + scope.availableFuelGrades[fg] + ' was consumed, at least one tank should be marked in-use.' : undefined;
                    });
                    scope.reportForm.stock.tankReporting.$setValidity('all_fuel_grades_reported', Object.keys(reportedFuelGrades).map(function(fg) { return reportedFuelGrades[fg]; }).filter(function(inUseCheckedForFuelGrade) { return !inUseCheckedForFuelGrade; }).length == 0);
                }
            }

            // make sure all of the vessel's supported fuel grades are reported
            // at least once
            // @ts-ignore
            scope.$watch('report.stock.tanks', function(newValue, oldValue, scope) {
                validateStockTanks(newValue);
            }, 1);
            scope.$watchGroup([
                'report.consumption.total_consumption_hfo',
                'report.consumption.total_consumption_lfo',
                'report.consumption.total_consumption_mgo',
                'report.consumption.total_consumption_mdo',
                'report.consumption.total_consumption_b10lfo',
                'report.consumption.total_consumption_b10mgo',
                'report.consumption.total_consumption_biolfo',
                'report.consumption.total_consumption_biomgo',
                'report.consumption.total_consumption_ulsfo2020',
                'report.consumption.total_consumption_ulslfo2020',
                'report.consumption.total_consumption_ulsmdo2020',
                'report.consumption.total_consumption_ulsmgo2020',
                'report.consumption.total_consumption_vlsfo2020',
                'report.consumption.total_consumption_vlslfo2020',
                'report.consumption.total_consumption_lpgp',
                'report.consumption.total_consumption_lpgb',
                'report.consumption.total_consumption_lng',
                'report.consumption.total_consumption_methanol',
                'report.consumption.total_consumption_ethanol',
                'report.consumption.total_consumption_other'
                ], function() {
                validateStockTanks(scope.report.stock.tanks);
            });

            // re-validate if tank sounding checkbox has changed
            scope.$watch('report.stock.tank_sounding', function(newValue, oldValue, scope) {
                if (scope.report.stock && scope.report.stock.tanks) {
                    // validateStockTanks(scope.report.stock.tanks);
                }
            })
          
          scope.isTransferTanksDuplicated = function () {
            const transfers = scope.report.stock.tank_transfers
            const usedTanks = []
            transfers.map((transfer) => {
              if (transfer.from_tank) {
                usedTanks.push(transfer.from_tank.name)
              }
              if (transfer.to_tank) {
                usedTanks.push(transfer.to_tank.name)
              }
            })
            const uniqueArray = new Set(usedTanks)
            return !(usedTanks.length === uniqueArray.size)  
          }

          const resetBdnsOnDuplicate = (fromId, toId, index) => {
            scope.clearStockTank(fromId)
            scope.clearStockTank(toId)
            scope.report.stock.tank_transfers[index].bdn = null;
          }

          const getEarlierTransfers = (index) => {
            const earlierTransfers = []
            scope.report.stock.tank_transfers.slice(0,index).map((transfer) => {
              earlierTransfers.push(transfer.from_tank?.tank_id['$oid'])
              earlierTransfers.push(transfer.to_tank?.tank_id['$oid'])
            })   
            return earlierTransfers.filter((id)=> id != undefined)
          }

          const resetChangedTanks = (index, fromHasChanged, toHasChanged, oldValue) => {
            const earlierTransfers = getEarlierTransfers(index)

            const resetFromTank = fromHasChanged && oldValue.from_tank?.tank_id['$oid'] != undefined && !earlierTransfers.includes(oldValue.from_tank?.tank_id['$oid'])
            const resetToTank = toHasChanged && oldValue.to_tank?.tank_id['$oid'] != undefined && !earlierTransfers.includes(oldValue.to_tank?.tank_id['$oid'])
                 
            if (resetFromTank) scope.clearStockTank(oldValue.from_tank?.tank_id['$oid'])
            if (resetToTank) scope.clearStockTank(oldValue.to_tank?.tank_id['$oid'])
          }
          
          const actionTransfer = (preTransferStock, fromId, toId, tankTransfer ) => {
            const blendedBdn = TankStock.createChildBdn(preTransferStock[fromId], preTransferStock[toId], tankTransfer.quantity, false)
            tankTransfer.bdn = blendedBdn
            scope.report.stock.tanks[toId] = blendedBdn
            scope.report.stock.tanks[fromId].amount_after_transfer = scope.report.stock.tanks[fromId].amount_before_bunkering - tankTransfer.quantity
            if (!scope.report.stock.tanks[fromId].in_use) scope.report.stock.tanks[fromId].amount = scope.report.stock.tanks[fromId].amount_after_transfer
          }

          const getChangeCheck = (newValue, oldValue)=> {
            const from = newValue.from_tank?.tank_id['$oid'] != oldValue.from_tank?.tank_id['$oid']
            const to = newValue.to_tank?.tank_id['$oid'] != oldValue.to_tank?.tank_id['$oid']
            const quantity = newValue.quantity != oldValue.quantity
            const any = to || from || quantity
            return {from, to, any}
          }

            // make changes for tank transfers
            // @ts-ignore
          scope.$watch('report.stock.tank_transfers', function (newValues, oldValues, scope) {   
            if (scope.report.bdn_based_reporting) {
              const preTransferStock = _.cloneDeep(ReportService.getPreTransferStock())

              angular.forEach(scope.report.stock.tank_transfers, function (tankTransfer, index) {
                const fromId = tankTransfer.from_tank?.tank_id['$oid']
                const toId = tankTransfer.to_tank?.tank_id['$oid']
                const isTransferTanksDuplicated = scope.isTransferTanksDuplicated()

                if (isTransferTanksDuplicated) resetBdnsOnDuplicate(fromId, toId, index)

                const newValue = newValues[index]
                const oldValue = oldValues[index]
                const changeCheck = getChangeCheck(newValue, oldValue)
                
                resetChangedTanks(index, changeCheck.from, changeCheck.to, oldValue)

                if (!isTransferTanksDuplicated) {
                  const hasChanged = changeCheck.any
                  const hasAllValues = tankTransfer.to_tank && tankTransfer.from_tank && tankTransfer.quantity
                  if (!hasAllValues) tankTransfer.bdn = null
                  
                  const completeTransferCalculationCheck = (hasChanged && hasAllValues) || (hasAllValues && !tankTransfer.bdn)
                  if (!completeTransferCalculationCheck) return
                  
                  actionTransfer(preTransferStock, fromId, toId, tankTransfer)
                }
              })
              parseBdnObjects(getStockTankObjects(scope))
            }
              
            if (!scope.report.bdn_based_reporting) {
               angular.forEach(scope.report.stock.tank_transfers, function(tankTransfer) {
                    // Update sulphur %, lcv, and density for tank transfers
                    var tanks = scope.report.stock.tanks;
                    if (tankTransfer.from_tank && tankTransfer.to_tank && tankTransfer.quantity) {
                        var fromTank = tanks[tankTransfer.from_tank];
                        var toTank = tanks[tankTransfer.to_tank];
                        var newTotalQuantity = tankTransfer.quantity + toTank.amount;

                        toTank.sulphur_after_bunkering = roundToPlaces(toTank.amount / newTotalQuantity * toTank.sulphur_after_bunkering + tankTransfer.quantity / newTotalQuantity * fromTank.sulphur_after_bunkering, 2);
                        toTank.lcv = roundToPlaces(toTank.amount / newTotalQuantity * toTank.lcv + tankTransfer.quantity / newTotalQuantity * fromTank.lcv, 2);
                        toTank.density = roundToPlaces(toTank.amount / newTotalQuantity * toTank.density + tankTransfer.quantity / newTotalQuantity * fromTank.density, 2);
                        // todo: disabled this so that this can be set manually in case it needs to be overriden
                        // toTank.fuel_grade = fromTank.fuel_grade;
                    }
                });
            }
               
            }, 1);

            scope.$watchGroup(['report.stock.lube_oil_system', 'report.stock.lube_oil_spare', 'report.bunker.quantity_received_me_lube_oil_system', 'report.bunker.quantity_received_me_lube_oil_spare'], function(newValues) {
                if (newValues[0] == undefined || newValues[1] == undefined || scope.carryOverReport == undefined) return;
                var previousTotalMELubeOil = scope.carryOverReport.stock.lube_oil_system + scope.carryOverReport.stock.lube_oil_spare;
                var currentTotalMELubeOil  = scope.report.stock.lube_oil_system + scope.report.stock.lube_oil_spare;
                currentTotalMELubeOil -= scope.report.bunker.quantity_received_me_lube_oil_system || 0;
                currentTotalMELubeOil -= scope.report.bunker.quantity_received_me_lube_oil_spare || 0;
                var percentChange = previousTotalMELubeOil ? Math.abs(previousTotalMELubeOil - currentTotalMELubeOil) / previousTotalMELubeOil : 0;

                var valid = percentChange <= 0.02;
                var systemHasWarning = !!scope.report.warningMessages['report.stock.lube_oil_system'];
                var spareHasWarning = !!scope.report.warningMessages['report.stock.lube_oil_spare'];

                if (!systemHasWarning) {
                    var systemStockChanged = scope.carryOverReport.stock.lube_oil_system != scope.report.stock.lube_oil_system;
                    scope.report.warningMessages['report.stock.lube_oil_system'] = valid || !systemStockChanged ? null : { message: 'The sum of ME Lube Oil Stock and Bunkered should not change by more than 2%. (Previous value: ' + scope.carryOverReport.stock.lube_oil_system + ')' };
                }

                if (!spareHasWarning) {
                    var spareStockChanged = scope.carryOverReport.stock.lube_oil_spare != scope.report.stock.lube_oil_spare;
                    scope.report.warningMessages['report.stock.lube_oil_spare'] = valid || !spareStockChanged ? null : { message: 'The sum of ME Lube Oil Stock and Bunkered should not change by more than 2%. (Previous value: ' + scope.carryOverReport.stock.lube_oil_spare + ')' };
                }
            });

            scope.register(scope, 'stock_lo_aux');
            scope.register(scope, 'stock_caustic_soda');
            scope.register(scope, 'stock_urea_stock');

            scope.$watchGroup(['report.stock.cyl_oil', 'report.stock.cyl_oil_low_bn', 'report.bunker.quantity_received_cylinder_oil', 'report.bunker.quantity_received_cylinder_oil_low_bn', 'report.consumption.cyl_oil_consumption'], function(newValues) {
                if (newValues[0] == undefined || newValues[1] == undefined || scope.carryOverReport == undefined) return;
                var previousTotalCLOStock = (scope.carryOverReport.stock.cyl_oil || 0) + (scope.carryOverReport.stock.cyl_oil_low_bn || 0);
                var currentTotalCLOStock  = (scope.report.stock.cyl_oil || 0) + (scope.report.stock.cyl_oil_low_bn || 0);
                var totalCLOBunker = scope.report.bunker.quantity_received_cylinder_oil || 0 + scope.report.bunker.quantity_received_cylinder_oil_low_bn || 0;
                var cylOilCons = scope.report.consumption.cyl_oil_consumption || 0;
                var stockChange = -(previousTotalCLOStock - currentTotalCLOStock - cylOilCons + totalCLOBunker);

                var valid = Math.abs(stockChange) <= 10;

                var warningMessage = 'Total Cylinder Oil stock must not change by more than 10L. Current calculated change: ' + stockChange + 'L.';

                scope.report.warningMessages['report.stock.cyl_oil'] = valid ? null : { message: warningMessage };
                scope.report.warningMessages['report.stock.cyl_oil_low_bn'] = valid ? null : { message: warningMessage };
            });

            scope.hideTankInUseCheckbox = function(tank) {
                return !!tank.name.match(/SERV|SETT|OVERFLOW/i);
            }

            var round = function(num, n) {
                return Math.round(num * Math.pow(10, n)) / Math.pow(10, n);
            };

            const stockFuelTypesToValidate = [
                'hfo',
                'lfo',
                'mgo',
                'mdo',
                'b10lfo',
                'b10mgo',
                'biolfo',
                'biomgo',
                'ulsfo2020',
                'ulslfo2020',
                'ulsmdo2020',
                'ulsmgo2020',
                'vlsfo2020',
                'vlslfo2020',
                'lpgp',
                'lpgb',
                'lng',
                'methanol',
                'ethanol',
                'other'];
            
            // validate stock fuel ROB against consumption and bunkered / debunker tabs
            angular.forEach(stockFuelTypesToValidate, function(grade) {
                var stockPath = 'report.stock.' + grade;
                var totalTankStockFieldName = 'tank' + grade.toUpperCase();
                scope.$watchGroup([stockPath, 'report.consumption.total_consumption_' + grade, 'report.bunker.quantity_received_' + grade, 'carryOverReport', 'report.bunker.quantity_debunkered_' + grade, 'report.stock.tank_based_reporting'], function(newValues) {
                    var currentStock = newValues[0] || 0;
                    var currentConsumption = newValues[1] || 0;
                    var bunker = parseFloat(newValues[2]) || 0;
                    var debunker = newValues[4] || 0;
                    var tankBasedReporting = !!newValues[5];
                    if (!scope.report.warningMessages) scope.report.warningMessages = {};
                    // Reset default validity to true, before checking calculation below.
                    if (scope.reportForm.stock[grade]) {
                        scope.reportForm.stock[grade].$setValidity('stockCalculation', true);
                    }
                    /**
                     * if the report.stock[fuel_grade] path is set to required = false on the vessel_specification.form.fields
                     * than we do not validate it
                     */
                    const shouldBeValidated = requireByDefault(scope.form, null, stockPath, 'fields');
                    if (!shouldBeValidated) {
                        return;
                    }
                    // (previous stock - current consumption + Bunker - Debunker - threshold) < Current stock < (previous stock-current consumption + Bunker - Debunker + threshold)
                    var threshold = 5;
                    var previousStock = (scope.carryOverReport && scope.carryOverReport.stock && scope.carryOverReport.stock[grade]);
                    var calculatedPreviousStock = (scope.carryOverReport && scope.carryOverReport.stock && scope.carryOverReport.stock[grade + '_calculated']);
                    if ((previousStock == 0 || previousStock == undefined) && calculatedPreviousStock > 0) {
                        previousStock = calculatedPreviousStock;
                    }
                    // avoid validating stock fuel for cases where first report is man report or some error causes negative previousStock
                    if (previousStock < 0 || previousStock == undefined) return;
                    var target = round(previousStock - currentConsumption + bunker - debunker, 1);
                    var min = target - threshold;
                    var max = target + threshold;

                    // For maneuvering reports, calculate and set the current stock
                    // based on the different between previous stock and current total
                    // consumption.
                    if (scope.isManReport(scope.report)) {
                        scope.report.stock[grade + '_calculated'] = previousStock - currentConsumption;
                    } else {
                        var gradeName = grade.toUpperCase();
                        if (currentStock < min || currentStock > max) {
                            scope.report.warningMessages[stockPath] = { message: gradeName + ' should be within ' + threshold + ' MT of ' + target + 'MT. (Previous stock [' + round(previousStock, 1) + ' MT] - current consumption [' + round(currentConsumption, 1) + ' MT] + bunker - debunker)' };
                            if (!tankBasedReporting && scope.reportForm.stock[grade]) {
                                scope.reportForm.stock[grade].$setValidity('stockCalculation', false);
                            }
                            if (tankBasedReporting && scope.reportForm && scope.reportForm.stock && scope.reportForm.stock.tankReporting && scope.reportForm.stock.tankReporting[totalTankStockFieldName]) {
                                scope.reportForm.stock.tankReporting[totalTankStockFieldName].$setValidity('inaccurate_bunkers', false);
                            }
                        } else {
                            scope.report.warningMessages[stockPath] = null;
                            if (scope.reportForm.stock && scope.reportForm.stock.tankReporting && scope.reportForm.stock.tankReporting[totalTankStockFieldName]) {
                                scope.reportForm.stock.tankReporting[totalTankStockFieldName].$setValidity('inaccurate_bunkers', true);
                            }
                        }
                    }
                });
            });

            scope.$watchGroup(['report.stock.fuel_type_1','report.stock.fuel_type_2','report.stock.fuel_type_3'], function(newValues,oldValues,scope) {
                var fuel_type_1 = newValues[0] || '';
                var fuel_type_2 = newValues[1] || '';
                var fuel_type_3 = newValues[2] || '';
                var stock_fuel = newValues;
                var stock_fuel_grades = stock_fuel.filter(function(fg) { return !! fg; });
                scope.duplicateStockFuelGradesSelected = stock_fuel && stock_fuel.length > 1 && stock_fuel_grades.length != Array.from(new Set(stock_fuel_grades)).length;

                var prev_fuel_type_1 = scope.carryOverReport?.stock?.fuel_type_1 || '';
                var prev_fuel_type_2 = scope.carryOverReport?.stock?.fuel_type_2 || '';
                var prev_fuel_type_3 = scope.carryOverReport?.stock?.fuel_type_3 || '';
                var prev_fuel_type_1_stock = scope.carryOverReport?.stock[prev_fuel_type_1] || 0;
                var prev_fuel_type_2_stock = scope.carryOverReport?.stock[prev_fuel_type_2] || 0;
                var prev_fuel_type_3_stock = scope.carryOverReport?.stock[prev_fuel_type_3] || 0;

                var prev_fuel_types = [];

                var prev_stock_values = {
                    [prev_fuel_type_1]:prev_fuel_type_1_stock,
                    [prev_fuel_type_2]:prev_fuel_type_2_stock,
                    [prev_fuel_type_3]:prev_fuel_type_3_stock
                }
                Object.keys(prev_stock_values).map(function(prev_fuel_type,index) {
                    if (prev_stock_values[prev_fuel_type] > 0 && prev_fuel_types.includes(prev_stock_values[prev_fuel_type]) != true) {
                        prev_fuel_types.push(prev_fuel_type)
                    }
                })

                var valid1 = true;
                var valid2 = true;
                var valid3 = true;
                var missingFuelType = '';
                var current_fuel_types = [fuel_type_1,fuel_type_2,fuel_type_3];
                scope.report.warningMessages['report.stock.fuel_type_1'] = null;
                scope.report.warningMessages['report.stock.fuel_type_2'] = null;
                scope.report.warningMessages['report.stock.fuel_type_3'] = null;
                angular.forEach(prev_fuel_types , function(prev_fuel_type) {
                    if (current_fuel_types.includes(prev_fuel_type) != true) {
                        valid1 = false;
                        valid2 = false;
                        valid3 = false;
                        missingFuelType = prev_fuel_type;
                    }
                })
                if (prev_fuel_types.includes(fuel_type_1)) valid1 = !scope.duplicateStockFuelGradesSelected;
                if (prev_fuel_types.includes(fuel_type_2)) valid2 = !scope.duplicateStockFuelGradesSelected;
                if (prev_fuel_types.includes(fuel_type_3)) valid3 = !scope.duplicateStockFuelGradesSelected;
                scope.reportForm?.stock?.fuelType1.$setValidity('fuelType1',valid1)
                scope.reportForm?.stock?.fuelType2.$setValidity('fuelType2',valid2)
                scope.reportForm?.stock?.fuelType3.$setValidity('fuelType3',valid3)
                if (valid1 != true && missingFuelType != '') {
                    scope.report.warningMessages['report.stock.fuel_type_1'] = 'Please provide the stock for fuel type ' + missingFuelType.toUpperCase();
                }
                if (valid2 != true && missingFuelType != '') {
                    scope.report.warningMessages['report.stock.fuel_type_2'] = 'Please provide the stock for fuel type ' + missingFuelType.toUpperCase();
                }
                if (valid3 != true && missingFuelType != '') {
                    scope.report.warningMessages['report.stock.fuel_type_3'] = 'Please provide the stock for fuel type ' + missingFuelType.toUpperCase();
                }
            })
        }
    }
}]);

routerApp.directive('ciiTab', function() {
    return {
        templateUrl: getCacheBustedTemplate('pages/vessel-reports/tabs/cii.html'),
        link: function(scope, element, attributes, control) {

            scope.initializeFormFields();
            scope.fuelTypes = [
                { code: 'hfo', name:'HFO'},
                { code: 'lfo', name:'LFO'},
                { code: 'mgo', name:'MGO'},
                { code: 'mdo', name:'MDO'},
                { code: 'b10lfo', name:'B10LFO'},
                { code: 'b10mgo', name:'B10MGO'},
                { code: 'biolfo', name:'BioLFO'},
                { code: 'biomgo', name:'BioMGO'},
                { code: 'ulsfo2020', name:'ULSFO2020'},
                { code: 'ulslfo2020', name:'ULSLFO2020'},
                { code: 'ulsmdo2020', name:'ULSMDO2020'},
                { code: 'ulsmgo2020', name:'ULSMGO2020'},
                { code: 'vlsfo2020', name:'VLSFO2020'},
                { code: 'vlslfo2020', name:'VLSLFO2020'},
                { code: 'lpgp', name:'LPGP'},
                { code: 'lpgb', name:'LPGB'},
                { code: 'lng', name:'LNG'},
                { code: 'methanol', name:'Methanol'},
                { code: 'ethanol', name:'Ethanol'},
                { code: 'other', name:'Other'},
            ];

            let vesselSpecs = scope.vessel_specifications;
            scope.operatingCodes = filterListByVesselForm(vesselSpecs.form, 'report.operational.operating_code', operatingCodes);
            
            var hfoFuelBreakdown = [
                'report.consumption.total_consumption_hfo',
                'report.consumption.other_hfo',
                'report.consumption.ae_ballast_operations_hfo',
                'report.consumption.ae_cargo_heating_hfo',
                'report.consumption.ae_tank_cleaning_hfo',
                'report.consumption.ae_cargo_shifting_hfo',
                'report.consumption.ae_tank_cooling_hfo',
                'report.consumption.ab_ballast_operations_hfo',
                'report.consumption.ab_cargo_heating_hfo',
                'report.consumption.ab_tank_cleaning_hfo',
                'report.consumption.ab_cargo_shifting_hfo'
            ]

            var lfoFuelBreakdown = [
                'report.consumption.total_consumption_lfo',
                'report.consumption.other_lfo',
                'report.consumption.ae_ballast_operations_lfo',
                'report.consumption.ae_cargo_heating_lfo',
                'report.consumption.ae_tank_cleaning_lfo',
                'report.consumption.ae_cargo_shifting_lfo',
                'report.consumption.ae_tank_cooling_lfo',
                'report.consumption.ab_ballast_operations_lfo',
                'report.consumption.ab_cargo_heating_lfo',
                'report.consumption.ab_tank_cleaning_lfo',
                'report.consumption.ab_cargo_shifting_lfo'
            ]

            var mgoFuelBreakdown = [
                'report.consumption.total_consumption_mgo',
                'report.consumption.other_mgo',
                'report.consumption.ae_ballast_operations_mgo',
                'report.consumption.ae_cargo_heating_mgo',
                'report.consumption.ae_tank_cleaning_mgo',
                'report.consumption.ae_cargo_shifting_mgo',
                'report.consumption.ae_tank_cooling_mgo',
                'report.consumption.ab_ballast_operations_mgo',
                'report.consumption.ab_cargo_heating_mgo',
                'report.consumption.ab_tank_cleaning_mgo',
                'report.consumption.ab_cargo_shifting_mgo'
            ]

            var mdoFuelBreakdown = [
                'report.consumption.total_consumption_mdo',
                'report.consumption.other_mdo',
                'report.consumption.ae_ballast_operations_mdo',
                'report.consumption.ae_cargo_heating_mdo',
                'report.consumption.ae_tank_cleaning_mdo',
                'report.consumption.ae_cargo_shifting_mdo',
                'report.consumption.ae_tank_cooling_mdo',
                'report.consumption.ab_ballast_operations_mdo',
                'report.consumption.ab_cargo_heating_mdo',
                'report.consumption.ab_tank_cleaning_mdo',
                'report.consumption.ab_cargo_shifting_mdo'
            ]

            var b10lfoFuelBreakdown = [
                'report.consumption.total_consumption_b10lfo',
                'report.consumption.other_b10lfo',
                'report.consumption.ae_ballast_operations_b10lfo',
                'report.consumption.ae_cargo_heating_b10lfo',
                'report.consumption.ae_tank_cleaning_b10lfo',
                'report.consumption.ae_cargo_shifting_b10lfo',
                'report.consumption.ae_tank_cooling_b10lfo',
                'report.consumption.ab_ballast_operations_b10lfo',
                'report.consumption.ab_cargo_heating_b10lfo',
                'report.consumption.ab_tank_cleaning_b10lfo',
                'report.consumption.ab_cargo_shifting_b10lfo'
            ]

            var b10mgoFuelBreakdown = [
                'report.consumption.total_consumption_b10mgo',
                'report.consumption.other_b10mgo',
                'report.consumption.ae_ballast_operations_b10mgo',
                'report.consumption.ae_cargo_heating_b10mgo',
                'report.consumption.ae_tank_cleaning_b10mgo',
                'report.consumption.ae_cargo_shifting_b10mgo',
                'report.consumption.ae_tank_cooling_b10mgo',
                'report.consumption.ab_ballast_operations_b10mgo',
                'report.consumption.ab_cargo_heating_b10mgo',
                'report.consumption.ab_tank_cleaning_b10mgo',
                'report.consumption.ab_cargo_shifting_b10mgo'
            ]

            var biolfoFuelBreakdown = [
                'report.consumption.total_consumption_biolfo',
                'report.consumption.other_biolfo',
                'report.consumption.ae_ballast_operations_biolfo',
                'report.consumption.ae_cargo_heating_biolfo',
                'report.consumption.ae_tank_cleaning_biolfo',
                'report.consumption.ae_cargo_shifting_biolfo',
                'report.consumption.ae_tank_cooling_biolfo',
                'report.consumption.ab_ballast_operations_biolfo',
                'report.consumption.ab_cargo_heating_biolfo',
                'report.consumption.ab_tank_cleaning_biolfo',
                'report.consumption.ab_cargo_shifting_biolfo'
            ]

            var biomgoFuelBreakdown = [
                'report.consumption.total_consumption_biomgo',
                'report.consumption.other_biomgo',
                'report.consumption.ae_ballast_operations_biomgo',
                'report.consumption.ae_cargo_heating_biomgo',
                'report.consumption.ae_tank_cleaning_biomgo',
                'report.consumption.ae_cargo_shifting_biomgo',
                'report.consumption.ae_tank_cooling_biomgo',
                'report.consumption.ab_ballast_operations_biomgo',
                'report.consumption.ab_cargo_heating_biomgo',
                'report.consumption.ab_tank_cleaning_biomgo',
                'report.consumption.ab_cargo_shifting_biomgo'
            ]

            var ulsfo2020FuelBreakdown = [
                'report.consumption.total_consumption_ulsfo2020',
                'report.consumption.other_ulsfo2020',
                'report.consumption.ae_ballast_operations_ulsfo2020',
                'report.consumption.ae_cargo_heating_ulsfo2020',
                'report.consumption.ae_tank_cleaning_ulsfo2020',
                'report.consumption.ae_cargo_shifting_ulsfo2020',
                'report.consumption.ae_tank_cooling_ulsfo2020',
                'report.consumption.ab_ballast_operations_ulsfo2020',
                'report.consumption.ab_cargo_heating_ulsfo2020',
                'report.consumption.ab_tank_cleaning_ulsfo2020',
                'report.consumption.ab_cargo_shifting_ulsfo2020'
            ]

            var ulslfo2020FuelBreakdown = [
                'report.consumption.total_consumption_ulslfo2020',
                'report.consumption.other_ulslfo2020',
                'report.consumption.ae_ballast_operations_ulslfo2020',
                'report.consumption.ae_cargo_heating_ulslfo2020',
                'report.consumption.ae_tank_cleaning_ulslfo2020',
                'report.consumption.ae_cargo_shifting_ulslfo2020',
                'report.consumption.ae_tank_cooling_ulslfo2020',
                'report.consumption.ab_ballast_operations_ulslfo2020',
                'report.consumption.ab_cargo_heating_ulslfo2020',
                'report.consumption.ab_tank_cleaning_ulslfo2020',
                'report.consumption.ab_cargo_shifting_ulslfo2020'
            ]

            var ulsmdo2020FuelBreakdown = [
                'report.consumption.total_consumption_ulsmdo2020',
                'report.consumption.other_ulsmdo2020',
                'report.consumption.ae_ballast_operations_ulsmdo2020',
                'report.consumption.ae_cargo_heating_ulsmdo2020',
                'report.consumption.ae_tank_cleaning_ulsmdo2020',
                'report.consumption.ae_cargo_shifting_ulsmdo2020',
                'report.consumption.ae_tank_cooling_ulsmdo2020',
                'report.consumption.ab_ballast_operations_ulsmdo2020',
                'report.consumption.ab_cargo_heating_ulsmdo2020',
                'report.consumption.ab_tank_cleaning_ulsmdo2020',
                'report.consumption.ab_cargo_shifting_ulsmdo2020'
            ]

            var ulsmgo2020FuelBreakdown = [
                'report.consumption.total_consumption_ulsmgo2020',
                'report.consumption.other_ulsmgo2020',
                'report.consumption.ae_ballast_operations_ulsmgo2020',
                'report.consumption.ae_cargo_heating_ulsmgo2020',
                'report.consumption.ae_tank_cleaning_ulsmgo2020',
                'report.consumption.ae_cargo_shifting_ulsmgo2020',
                'report.consumption.ae_tank_cooling_ulsmgo2020',
                'report.consumption.ab_ballast_operations_ulsmgo2020',
                'report.consumption.ab_cargo_heating_ulsmgo2020',
                'report.consumption.ab_tank_cleaning_ulsmgo2020',
                'report.consumption.ab_cargo_shifting_ulsmgo2020'
            ]

            var vlsfo2020FuelBreakdown = [
                'report.consumption.total_consumption_vlsfo2020',
                'report.consumption.other_vlsfo2020',
                'report.consumption.ae_ballast_operations_vlsfo2020',
                'report.consumption.ae_cargo_heating_vlsfo2020',
                'report.consumption.ae_tank_cleaning_vlsfo2020',
                'report.consumption.ae_cargo_shifting_vlsfo2020',
                'report.consumption.ae_tank_cooling_vlsfo2020',
                'report.consumption.ab_ballast_operations_vlsfo2020',
                'report.consumption.ab_cargo_heating_vlsfo2020',
                'report.consumption.ab_tank_cleaning_vlsfo2020',
                'report.consumption.ab_cargo_shifting_vlsfo2020'
            ]

            var vlslfo2020FuelBreakdown = [
                'report.consumption.total_consumption_vlslfo2020',
                'report.consumption.other_vlslfo2020',
                'report.consumption.ae_ballast_operations_vlslfo2020',
                'report.consumption.ae_cargo_heating_vlslfo2020',
                'report.consumption.ae_tank_cleaning_vlslfo2020',
                'report.consumption.ae_cargo_shifting_vlslfo2020',
                'report.consumption.ae_tank_cooling_vlslfo2020',
                'report.consumption.ab_ballast_operations_vlslfo2020',
                'report.consumption.ab_cargo_heating_vlslfo2020',
                'report.consumption.ab_tank_cleaning_vlslfo2020',
                'report.consumption.ab_cargo_shifting_vlslfo2020'
            ]

            var lpgpFuelBreakdown = [
                'report.consumption.total_consumption_lpgp',
                'report.consumption.other_lpgp',
                'report.consumption.ae_ballast_operations_lpgp',
                'report.consumption.ae_cargo_heating_lpgp',
                'report.consumption.ae_tank_cleaning_lpgp',
                'report.consumption.ae_cargo_shifting_lpgp',
                'report.consumption.ae_tank_cooling_lpgp',
                'report.consumption.ab_ballast_operations_lpgp',
                'report.consumption.ab_cargo_heating_lpgp',
                'report.consumption.ab_tank_cleaning_lpgp',
                'report.consumption.ab_cargo_shifting_lpgp'
            ]

            var lpgbFuelBreakdown = [
                'report.consumption.total_consumption_lpgb',
                'report.consumption.other_lpgb',
                'report.consumption.ae_ballast_operations_lpgb',
                'report.consumption.ae_cargo_heating_lpgb',
                'report.consumption.ae_tank_cleaning_lpgb',
                'report.consumption.ae_cargo_shifting_lpgb',
                'report.consumption.ae_tank_cooling_lpgb',
                'report.consumption.ab_ballast_operations_lpgb',
                'report.consumption.ab_cargo_heating_lpgb',
                'report.consumption.ab_tank_cleaning_lpgb',
                'report.consumption.ab_cargo_shifting_lpgb'
            ]

            var lngFuelBreakdown = [
                'report.consumption.total_consumption_lng',
                'report.consumption.other_lng',
                'report.consumption.ae_ballast_operations_lng',
                'report.consumption.ae_cargo_heating_lng',
                'report.consumption.ae_tank_cleaning_lng',
                'report.consumption.ae_cargo_shifting_lng',
                'report.consumption.ae_tank_cooling_lng',
                'report.consumption.ab_ballast_operations_lng',
                'report.consumption.ab_cargo_heating_lng',
                'report.consumption.ab_tank_cleaning_lng',
                'report.consumption.ab_cargo_shifting_lng'
            ]

            var methanolFuelBreakdown = [
                'report.consumption.total_consumption_methanol',
                'report.consumption.other_methanol',
                'report.consumption.ae_ballast_operations_methanol',
                'report.consumption.ae_cargo_heating_methanol',
                'report.consumption.ae_tank_cleaning_methanol',
                'report.consumption.ae_cargo_shifting_methanol',
                'report.consumption.ae_tank_cooling_methanol',
                'report.consumption.ab_ballast_operations_methanol',
                'report.consumption.ab_cargo_heating_methanol',
                'report.consumption.ab_tank_cleaning_methanol',
                'report.consumption.ab_cargo_shifting_methanol'
            ]

            var ethanolFuelBreakdown = [
                'report.consumption.total_consumption_ethanol',
                'report.consumption.other_ethanol',
                'report.consumption.ae_ballast_operations_ethanol',
                'report.consumption.ae_cargo_heating_ethanol',
                'report.consumption.ae_tank_cleaning_ethanol',
                'report.consumption.ae_cargo_shifting_ethanol',
                'report.consumption.ae_tank_cooling_ethanol',
                'report.consumption.ab_ballast_operations_ethanol',
                'report.consumption.ab_cargo_heating_ethanol',
                'report.consumption.ab_tank_cleaning_ethanol',
                'report.consumption.ab_cargo_shifting_ethanol'
            ]

            var otherFuelBreakdown = [
                'report.consumption.total_consumption_other',
                'report.consumption.other_other',
                'report.consumption.ae_ballast_operations_other',
                'report.consumption.ae_cargo_heating_other',
                'report.consumption.ae_tank_cleaning_other',
                'report.consumption.ae_cargo_shifting_other',
                'report.consumption.ae_tank_cooling_other',
                'report.consumption.ab_ballast_operations_other',
                'report.consumption.ab_cargo_heating_other',
                'report.consumption.ab_tank_cleaning_other',
                'report.consumption.ab_cargo_shifting_other'
            ]

            scope.$watchGroup(hfoFuelBreakdown, function(newValues, oldValues, scope){
                let hfoConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    hfoConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.basic_load_hfo = roundToPlaces(hfoConsumption, 2);
                scope.reportForm.cii.totalConsumptionhfo.$setValidity('totalConsumptionhfo', hfoConsumption >= 0);
            });

            scope.$watchGroup(lfoFuelBreakdown, function(newValues, oldValues, scope){
                let lfoConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    lfoConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.basic_load_lfo = roundToPlaces(lfoConsumption, 2);
                scope.reportForm.cii.totalConsumptionlfo.$setValidity('totalConsumptionlfo', lfoConsumption >= 0);
            });

            scope.$watchGroup(mgoFuelBreakdown, function(newValues, oldValues, scope){
                let mgoConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    mgoConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.basic_load_mgo = roundToPlaces(mgoConsumption, 2);
                scope.reportForm.cii.totalConsumptionmgo.$setValidity('totalConsumptionmgo', mgoConsumption >= 0);
            });

            scope.$watchGroup(mdoFuelBreakdown, function(newValues, oldValues, scope){
                let mdoConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    mdoConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.basic_load_mdo = roundToPlaces(mdoConsumption, 2);
                scope.reportForm.cii.totalConsumptionmdo.$setValidity('totalConsumptionmdo', mdoConsumption >= 0);
            });

            scope.$watchGroup(b10lfoFuelBreakdown, function(newValues, oldValues, scope){
                let b10lfoConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    b10lfoConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.basic_load_b10lfo = roundToPlaces(b10lfoConsumption, 2);
                scope.reportForm.cii.totalConsumptionb10lfo.$setValidity('totalConsumptionb10lfo', b10lfoConsumption >= 0);
            });

            scope.$watchGroup(b10mgoFuelBreakdown, function(newValues, oldValues, scope){
                let b10mgoConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    b10mgoConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.basic_load_b10mgo = roundToPlaces(b10mgoConsumption, 2);
                scope.reportForm.cii.totalConsumptionb10mgo.$setValidity('totalConsumptionb10mgo', b10mgoConsumption >= 0);
            });

            scope.$watchGroup(biolfoFuelBreakdown, function(newValues, oldValues, scope){
                let biolfoConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    biolfoConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.basic_load_biolfo = roundToPlaces(biolfoConsumption, 2);
                scope.reportForm.cii.totalConsumptionbiolfo.$setValidity('totalConsumptionbiolfo', biolfoConsumption >= 0);
            });

            scope.$watchGroup(biomgoFuelBreakdown, function(newValues, oldValues, scope){
                let biomgoConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    biomgoConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.basic_load_biomgo = roundToPlaces(biomgoConsumption, 2);
                scope.reportForm.cii.totalConsumptionbiomgo.$setValidity('totalConsumptionbiomgo', biomgoConsumption >= 0);
            });

            scope.$watchGroup(ulsfo2020FuelBreakdown, function(newValues, oldValues, scope){
                let ulsfo2020Consumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    ulsfo2020Consumption -= sumValues(breakdown);
                }
                scope.report.consumption.basic_load_ulsfo2020 = roundToPlaces(ulsfo2020Consumption, 2);
                scope.reportForm.cii.totalConsumptionulsfo2020.$setValidity('totalConsumptionulsfo2020', ulsfo2020Consumption >= 0);
            });

            scope.$watchGroup(ulslfo2020FuelBreakdown, function(newValues, oldValues, scope){
                let ulslfo2020Consumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    ulslfo2020Consumption -= sumValues(breakdown);
                }
                scope.report.consumption.basic_load_ulslfo2020 = roundToPlaces(ulslfo2020Consumption, 2);
                scope.reportForm.cii.totalConsumptionulslfo2020.$setValidity('totalConsumptionulslfo2020', ulslfo2020Consumption >= 0);
            });

            scope.$watchGroup(ulsmdo2020FuelBreakdown, function(newValues, oldValues, scope){
                let ulsmdo2020Consumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    ulsmdo2020Consumption -= sumValues(breakdown);
                }
                scope.report.consumption.basic_load_ulsmdo2020 = roundToPlaces(ulsmdo2020Consumption, 2);
                scope.reportForm.cii.totalConsumptionulsmdo2020.$setValidity('totalConsumptionulsmdo2020', ulsmdo2020Consumption >= 0);
            });

            scope.$watchGroup(ulsmgo2020FuelBreakdown, function(newValues, oldValues, scope){
                let ulsmgo2020Consumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    ulsmgo2020Consumption -= sumValues(breakdown);
                }
                scope.report.consumption.basic_load_ulsmgo2020 = roundToPlaces(ulsmgo2020Consumption, 2);
                scope.reportForm.cii.totalConsumptionulsmgo2020.$setValidity('totalConsumptionulsmgo2020', ulsmgo2020Consumption >= 0);
            });

            scope.$watchGroup(vlsfo2020FuelBreakdown, function(newValues, oldValues, scope){
                let vlsfo2020Consumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    vlsfo2020Consumption -= sumValues(breakdown);
                }
                scope.report.consumption.basic_load_vlsfo2020 = roundToPlaces(vlsfo2020Consumption, 2);
                scope.reportForm.cii.totalConsumptionvlsfo2020.$setValidity('totalConsumptionvlsfo2020', vlsfo2020Consumption >= 0);
            });

            scope.$watchGroup(vlslfo2020FuelBreakdown, function(newValues, oldValues, scope){
                let vlslfo2020Consumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    vlslfo2020Consumption -= sumValues(breakdown);
                }
                scope.report.consumption.basic_load_vlslfo2020 = roundToPlaces(vlslfo2020Consumption, 2);
                scope.reportForm.cii.totalConsumptionvlslfo2020.$setValidity('totalConsumptionvlslfo2020', vlslfo2020Consumption >= 0);
            });

            scope.$watchGroup(lpgpFuelBreakdown, function(newValues, oldValues, scope){
                let lpgpConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    lpgpConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.basic_load_lpgp = roundToPlaces(lpgpConsumption, 2);
                scope.reportForm.cii.totalConsumptionlpgp.$setValidity('totalConsumptionlpgp', lpgpConsumption >= 0);
            });

            scope.$watchGroup(lpgbFuelBreakdown, function(newValues, oldValues, scope){
                let lpgbConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    lpgbConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.basic_load_lpgb = roundToPlaces(lpgbConsumption, 2);
                scope.reportForm.cii.totalConsumptionlpgb.$setValidity('totalConsumptionlpgb', lpgbConsumption >= 0);
            });

            scope.$watchGroup(lngFuelBreakdown, function(newValues, oldValues, scope){
                let lngConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    lngConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.basic_load_lng = roundToPlaces(lngConsumption, 2);
                scope.reportForm.cii.totalConsumptionlng.$setValidity('totalConsumptionlng', lngConsumption >= 0);
            });

            scope.$watchGroup(methanolFuelBreakdown, function(newValues, oldValues, scope){
                let methanolConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    methanolConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.basic_load_methanol = roundToPlaces(methanolConsumption, 2);
                scope.reportForm.cii.totalConsumptionmethanol.$setValidity('totalConsumptionmethanol', methanolConsumption >= 0);
            });

            scope.$watchGroup(ethanolFuelBreakdown, function(newValues, oldValues, scope){
                let ethanolConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    ethanolConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.basic_load_ethanol = roundToPlaces(ethanolConsumption, 2);
                scope.reportForm.cii.totalConsumptionethanol.$setValidity('totalConsumptionethanol', ethanolConsumption >= 0);
            });

            scope.$watchGroup(otherFuelBreakdown, function(newValues, oldValues, scope){
                let otherConsumption = newValues[0] || 0;
                var breakdown = newValues.slice(1);
                if (breakdown && breakdown.length > 0) {
                    otherConsumption -= sumValues(breakdown);
                }
                scope.report.consumption.basic_load_other = roundToPlaces(otherConsumption, 2);
                scope.reportForm.cii.totalConsumptionother.$setValidity('totalConsumptionother', otherConsumption >= 0);
            });
        }
    }
});

routerApp.directive('detailsTab', function() {
    return {
        templateUrl: getCacheBustedTemplate('pages/engine-reports/tabs/details.html'),
        link: function(scope, element, attributes, control) {

        }
    }
});

routerApp.directive('cylinderTab', function() {
    return {
        templateUrl: getCacheBustedTemplate('pages/engine-reports/tabs/cylinder.html'),
        link: function(scope, element, attributes, control) {

        }
    }
});

routerApp.directive('meCylinderTab', function() {
    return {
        templateUrl: getCacheBustedTemplate('pages/engine-reports/tabs/me-cylinder.html'),
        link: function(scope, element, attributes, control) {

        }
    }
});

routerApp.directive('meResultsTab', function() {
    return {
        templateUrl: getCacheBustedTemplate('pages/engine-reports/tabs/me-results.html'),
        link: function(scope, element, attributes, control) {



        }
    }
});



routerApp.directive('meTurbochargingTab', function() {
    return {
        templateUrl: getCacheBustedTemplate('pages/engine-reports/tabs/me-turbocharging.html'),
        link: function(scope, element, attributes, control) {
        }
    }
});

routerApp.directive('aeDetailsTab', function() {
    return {
        templateUrl: getCacheBustedTemplate('pages/engine-reports/tabs/ae-details.html'),
        link: function(scope, element, attributes, control) {
            // do nothing
        }
    }
});

routerApp.directive('aeCylinderTab', function() {
    return {
        templateUrl: getCacheBustedTemplate('pages/engine-reports/tabs/ae-cylinder.html'),
        link: function(scope, element, attributes, control) {

        }
    }
});

routerApp.directive('aeTurbochargingTab', function() {
    return {
        templateUrl: getCacheBustedTemplate('pages/engine-reports/tabs/ae-turbocharging.html'),
        link: function(scope, element, attributes, control) {

        }
    }
});

routerApp.directive('dateField', ['$timeout', 'AutoSaveService', function($timeout, AutoSaveService) {
    return {
        require: 'ngModel',
        scope: {
            ngModel: '=',
            dateObject: '@',
            fieldDisabled: '=',
            fieldName: '@',
            fieldId: '@',
            minDate: '@',
            ngRequired: '=',
            min: '@',
            max: '@',
            isFocused: '=',
            isChanged: '=',
            isBlurred: '=',
            ngModelName: '@?',
        },
        templateUrl: 'pages/directives/date-field.html',
        link: function(scope, element, attributes, ngModel) {
            $timeout(function() {
                var datePickerId = 'datepicker_' + scope.fieldId;
                var defaultDate = scope.ngModel;

                var options = {
                    format: 'DD/MM/YYYY HH:mm',
                    sideBySide: true,
                    viewMode: 'days'
                }

                if (scope.min) options['minDate'] = moment(scope.min).toDate();
                if (scope.max) options['maxDate'] = moment(scope.max).toDate();

                $('#' + datePickerId).datetimepicker(options);
                $('#' + datePickerId).on('dp.change', function(e) {
                    scope.ngModel = $('#' + datePickerId + ' input').val();
                     $('#' + datePickerId).removeClass('ng-pristine').addClass('ng-dirty');
                    if (scope.ngModelName && typeof scope.ngModelName == 'string') {
                        scope.isChanged();
                    }
                    scope.$apply();
                });

                $('#' + datePickerId + ' input').on('paste', function(e) {
                    $timeout(function() {
                        var inputValue = $('#' + datePickerId + ' input').val();
                        var datePicker = $('#' + datePickerId);
                        datePicker.data('DateTimePicker').date(null);
                        datePicker.data('DateTimePicker').date(inputValue);
                        datePicker.removeClass('ng-pristine').addClass('ng-dirty');
                        scope.$apply();
                    });
                });

                var hasBeenFocused = false; //hasBeenFocused flag needed to avoid duplicate onfocus when clicking on calendar
                $('#' + datePickerId).on('dp.show', function(e) {
                    scope.$emit('datepicker-show', {datepickerShowing: true});
                      if (scope.ngModelName && typeof scope.ngModelName == 'string' && !hasBeenFocused) {
                        scope.isFocused(scope.ngModelName, defaultDate);
                        hasBeenFocused = true;
                        hasBeenBlurred = false;
                    }
                });

                var hasBeenBlurred = false; //hasBeenBlurred flag needed to avoid duplicate onblur when clicking on calendar
                $('#' + datePickerId).on('dp.hide', function(e) {
                    scope.$emit('datepicker-show', {datepickerShowing: false});
                    if (scope.ngModelName && typeof scope.ngModelName == 'string' && !hasBeenBlurred) {
                        scope.isBlurred(scope.ngModelName, scope.ngModel)
                        hasBeenFocused = false;
                        hasBeenBlurred = true;
                    }
                });

                // if the scope variable is actually set and we need to set
                // the date, if we did everything right the date here should
                // just be a string
                if (defaultDate != undefined) {
                    if (scope.min && moment(defaultDate, 'DD/MM/YYYY HH:mm') < moment(scope.min)) {
                        defaultDate = moment(scope.min).toDate();
                    }
                    if (scope.max && moment(defaultDate, 'DD/MM/YYYY HH:mm') > moment(scope.max)) {
                        defaultDate = moment(scope.max).toDate();
                    }
                    $('#' + datePickerId).data('DateTimePicker').defaultDate(defaultDate);
                }
            });

            // this should set the min value of the datepicker
            // to do: it's not working
            if (scope.minDate != undefined) {
                scope.$watch(scope.minDate, function(newValue, oldValue, scope) {
                    if (newValue != undefined) {
                        $('#datepicker_' + scope.fieldId).data('DateTimePicker').minDate(newValue);
                    }
                });
            }

        }
    }
}]);

routerApp.directive('sensorWrapper', ['$timeout','$rootScope', function($timeout, $rootScope) {
    return {
        transclude: true,
        replace: true,
        scope: {
            path: '@',
            class: '@',
            sensorData: '=',
            latestReading: '=',
        },
        templateUrl: 'pages/directives/sensor-wrapper.html',
        link: function(scope, element, attributes) {
            $(function () {
                $('.sensor-popover').popover({
                  container: '#ui-view',
                  trigger: 'focus',
                });
              })
            
            scope.isValidPositionTag = function() {
                return !(CustomLatitudePaths.includes(scope.path) || CustomLongitudePaths.includes(scope.path)|| (scope.path ==='position.observed_distance'));
            };

            scope.reload = function() {
                scope.$emit('updateSelectedSensorTag', scope.path);
            };
        }
    }
}]);

routerApp.directive('modalMessage', function() {
    return {
      transclude: true,
      templateUrl: 'pages/directives/modal-message.html',
      scope: {        
        currentModalId: '=',
        modalId: '@?'
      },
      link: function(scope, element, attributes) {
          scope.$watch('currentModalId', function(newValue) {
              if (newValue) {
                let modal = modalService.getModal(scope.currentModalId);
                scope.title = modal.title;
                scope.message = modal.message;
              } else {
                scope.title = null;
                scope.message = null;
              }
          })
      }
    }
});


routerApp.directive('modalContainer', function() {
    return {
      transclude: true,
      templateUrl: 'pages/directives/modal-container.html',
      scope: {
        modalId: '@',
        modalMessages: '=',
        currentModalId: '='
      },
      link: function(scope, element, attributes) {
        let selectedMessageIndex = scope.currentModalId ? scope.modalMessages.indexOf(scope.currentModalId) : 0;
        scope.selectedMessageIndex = selectedMessageIndex + 1;
      }
    }
});

routerApp.directive('incidentsTable', ['OffhireReportService', function(OffhireReportService) {
    return {
        scope: {
            client: '='
        },
        templateUrl: 'pages/directives/incidents.html',
        link: function(scope, element, attributes, control) {
            $('.incident-dropdown.dropdown a').on('click', function (event) {
                $(this).parent().toggleClass('open');
            });
           scope.incidentDateRange = '3_month';

            var dateRangeToLabel = {
                '10_days': '10 Days',
                'mtd': 'Month to Date',
                '1_month': '1 Month',
                '2_month': '2 Months',
                '3_month': '3 Months',
                'ytd': 'Past Year',
                'custom': 'Custom'
            };

            var getDateRangeText = function(label, fromDate, toDate) {
                return label + ' (' + fromDate.toISOString().substring(0, 10) + ' - ' + toDate.toISOString().substring(0, 10) + ')';
            };

            var refreshIncidentTableData = function(reportFrom, reportTo) {
                OffhireReportService.getIncidentTableData(attributes.client, reportFrom, reportTo).then(function (response) {
                    var res = response.data;
                    scope.incidentTableData = res.data;
                });
            };

            var closeDropdowns = function() {
                $('.dropdown-menu').parent().removeClass('open');
            };

            scope.incidentChangeDateRange = function(dateRange) {
                scope.incidentDateRange = dateRange;
                var now = new Date();
                var reportFrom = new Date();
                if (dateRange == '10_days') {
                    scope.incidentReportTo = now;
                    reportFrom.setDate(reportFrom.getDate() - 10);
                    scope.incidentReportFrom = reportFrom;
                    closeDropdowns();
                } else if (dateRange == '1_month') {
                    scope.incidentReportTo = now;
                    reportFrom.setDate(reportFrom.getDate() - 30);
                    scope.incidentReportFrom = reportFrom;
                    closeDropdowns();
                } else if (dateRange == '2_month') {
                    scope.incidentReportTo = now;
                    reportFrom.setDate(reportFrom.getDate() - 61);
                    scope.incidentReportFrom = reportFrom;
                    closeDropdowns();
                } else if (dateRange == '3_month') {
                    scope.incidentReportTo = now;
                    reportFrom.setDate(reportFrom.getDate() - 90);
                    scope.incidentReportFrom = reportFrom;
                    closeDropdowns();
                } else if (dateRange == 'mtd') {
                    scope.incidentReportTo = now;
                    scope.incidentReportFrom = new Date(now.getFullYear(), now.getMonth(), 1);
                    closeDropdowns();
                } else if (dateRange == 'ytd') {
                    scope.incidentReportTo = now;
                    reportFrom.setDate(reportFrom.getDate() - 365);
                    scope.incidentReportFrom = reportFrom;
                    closeDropdowns();
                } else {
                    if (scope.incidentCustomReportTo) {
                        scope.incidentReportTo = moment(scope.incidentCustomReportTo, 'DD/MM/YYYY HH:mm').toDate();
                    }
                    if (scope.incidentCustomReportFrom) {
                        scope.incidentReportFrom = moment(scope.incidentCustomReportFrom, 'DD/MM/YYYY HH:mm').toDate();
                    }
                }
            };

            scope.incidentChangeDateRange(scope.incidentDateRange);

            scope.$watchGroup(['incidentReportTo', 'incidentReportFrom'], function(values) {
                if (scope.incidentReportTo && scope.incidentReportFrom) {
                    refreshIncidentTableData(scope.incidentReportFrom, scope.incidentReportTo);
                    scope.incidentDateRangeText = getDateRangeText(
                        dateRangeToLabel[scope.incidentDateRange],
                        scope.incidentReportFrom,
                        scope.incidentReportTo
                    );
                }
            });
            scope.$watch('incidentCustomReportTo', function(value) {
                if (value) {
                    scope.incidentReportTo = moment(value, 'DD/MM/YYYY HH:mm').toDate();
                }
            });
            scope.$watch('incidentCustomReportFrom', function(value) {
                if (value) {
                    scope.incidentReportFrom = moment(value, 'DD/MM/YYYY HH:mm').toDate();
                }
            });

        }
    };
}]);

routerApp.directive('cpBaselineDetails', ['VesselSpecificationService', '$rootScope', 'notify', 'UtilityService', 
    function(VesselSpecificationService, $rootScope, notify, UtilityService) {
    return {
        templateUrl: getCacheBustedTemplate('pages/vessel-reports/tabs/cp-baseline-detail.html'),
        scope: {
            cpBaselineDetail: '=',
            parentForm: '=',
            formName: '@',
            cpBaselineDetailIndex: '=',
            loopIndex: '=',
        },
        link: function(scope, element, attributes, control) {
            if (!scope.cpBaselineDetail.cp_baselines || scope.cpBaselineDetail.cp_baselines.length == 0) {
                scope.cpBaselineDetail.cp_baselines = [{}];
            }
            scope.warningMessages = {};

            scope.bfOptions = [3,4,5,6,7,8,9,10,11,12];
            scope.dssOptions = ['', 3,4];

            scope.cpBaselineDetailForm = scope.parentForm[scope.formName];

            scope.$watchGroup(['cpBaselineDetail.from_date', 'cpBaselineDetail.to_date'], function(newValues) {
                var fromDateFormField = scope.cpBaselineDetailForm[scope.formName + 'fromDate'];
                var toDateFormField = scope.cpBaselineDetailForm[scope.formName + 'toDate'];
                if (fromDateFormField) fromDateFormField.tooltipMessage = null;
                if (toDateFormField) toDateFormField.tooltipMessage = null;
                var fromDate = newValues[0];
                var toDate = newValues[1];
                var fromDateMoment = moment(newValues[0], 'DD/MM/YYYY HH:mm');
                var toDateMoment = moment(newValues[1], 'DD/MM/YYYY HH:mm');

                // Check if to date is after from date
                if (fromDateFormField && scope.cpBaselineDetail.to_date) {
                    fromDateFormField.$setValidity('date_validation', toDateMoment > fromDateMoment);
                    toDateFormField.tooltipMessage = 'To Date must be after From Date';
                }
                if (toDateFormField && scope.cpBaselineDetail.from_date) {
                    toDateFormField.$setValidity('date_validation', toDateMoment > fromDateMoment);
                    fromDateFormField.tooltipMessage = 'From Date must be before To Date';
                }

                for (var i = 0; i < scope.$parent.vessel_specifications.cp_baseline_details.length; i++) {
                    var otherDetail = scope.$parent.vessel_specifications.cp_baseline_details[i];
                    if (otherDetail.id == scope.cpBaselineDetail.id) continue;

                    // Handle case where current detail completely overlaps another
                    if (!!fromDate && !!toDate) {
                        var overlaps = UtilityService.isBetweenDates(fromDate, otherDetail.from_date, toDate);
                        if (fromDateFormField) fromDateFormField.$setValidity('complete_overlap_validation', !overlaps);
                        if (toDateFormField) toDateFormField.$setValidity('complete_overlap_validation', !overlaps);
                        if (overlaps) {
                            fromDateFormField.tooltipMessage = 'Dates overlap with CP Detail #' + (i + 1);
                            toDateFormField.tooltipMessage = 'Date overlap with CP Detail #' + (i + 1);
                        }
                    }

                    // Check if from date overlaps
                    if (!!fromDate) {
                        var fromDateOverlaps = UtilityService.isBetweenDates(otherDetail.from_date, fromDateMoment, otherDetail.to_date);
                        if (fromDateFormField && scope.cpBaselineDetail.from_date) fromDateFormField.$setValidity('overlap_validation', !fromDateOverlaps);
                        if (fromDateOverlaps) {
                            fromDateFormField.tooltipMessage = 'From Date overlaps with CP Detail #' + (i + 1);
                        }
                    }

                    // Check if to date overlaps
                    if (!!toDate) {
                        var toDateOverlaps = UtilityService.isBetweenDates(otherDetail.from_date, toDateMoment, otherDetail.to_date);
                        if (toDateFormField && scope.cpBaselineDetail.to_date) toDateFormField.$setValidity('overlap_validation', !toDateOverlaps);
                        if (toDateOverlaps) {
                            toDateFormField.tooltipMessage = 'To Date overlaps with CP Detail #' + (i + 1);
                        }
                    }
                }
            });

            scope.extractCPBaselines = function() {
                notify({message: 'Please wait, the extract is being processed', duration: 2000, classes: ['warning-notification']});
                VesselSpecificationService.extractCPBaselines($rootScope.selectedVessel.id, scope.cpBaselineDetail.id).then(function(response) {
                    var data = response.data;
                    var fromDate = moment(scope.cpBaselineDetail.from_date, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD');
                    var toDate = moment(scope.cpBaselineDetail.to_date, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD');
                    var filename = $rootScope.selectedVessel.name + ' CP Baselines for ' + fromDate + ' to ' + toDate + '.csv';
                    var dataUrl = 'data:attachment/csv;charset=utf-8,' + encodeURI(data);
                    UtilityService.downloadData(dataUrl, filename);
                    notify({message: 'Extract download successful!', duration: 2000, classes: ['ok-notification']});
                }, function() {
                    notify({message: 'Error extracting data, try again in a bit!', duration: 2000, classes: ['bad-notification']});
                })
            };

            scope.downloadSampleCPBaselinesExtract = function() {
                notify({message: 'Please wait, the extract is being processed', duration: 2000, classes: ['warning-notification']});
                VesselSpecificationService.downloadSampleCPBaselinesExtract($rootScope.selectedVessel.id, scope.cpBaselineDetail.id).then(function(response) {
                    var data = response.data;
                    var filename = $rootScope.selectedVessel.name + ' Sample CP Baselines.csv';
                    var dataUrl = 'data:attachment/csv;charset=utf-8,' + encodeURI(data);
                    UtilityService.downloadData(dataUrl, filename);
                    notify({message: 'Extract download successful!', duration: 2000, classes: ['ok-notification']});
                }, function() {
                    notify({message: 'Error downloading sample extract, try again in a bit!', duration: 2000, classes: ['bad-notification']});
                })
            };
        }
    };
}]);

routerApp.directive('ngInputChange', function() {
    return {
        scope: {
            ngInputChange: '=',
            ngModel: '@'
        },
        link: function(scope, element, attrs) {
            element.on("change", function(event) {
                let target = event.target as unknown as HTMLInputElement;
                let inputValue = target.value as any;
                if (target.value && target.type == 'number' || !isNaN(inputValue)) {
                    inputValue = parseFloat(target.value);
                }
                scope.ngInputChange(scope.ngModel, inputValue);
            })
            scope.$on("$destroy", function() {
                element.off();
            });
        }
      }
});
