import { Client } from '../models/client'
export interface IForm {
    fields: any,
    sections: any,
    tabs: any,
}

export class Vessel {
    _id?: string;
    client: Client;
    client_name: string;
    navtor_vessel_id: string;
    disabled: boolean;
    information: {};
    form: IForm;
    form_configuration: string;
    engine: {};
    prime_mover: {};
    tanks: [];
    propeller: {};
    engine_model: string;
    fm_configuration: string;
    main_flow_meter_installation: {};
    boiler_flow_meter_installation: {};
    email_alert: {};
    dnv: {};
    veslink: {};
    carry_over_form: {};
    bdn_based_reporting?: boolean;

    constructor(vessel: any, creating: boolean) {
        if (creating) {
            this.navtor_vessel_id = null;
            this.client = new Client({},creating);
            this.disabled = false;
            this.information = {};
            this.form = {
                fields: {},
                sections: {},
                tabs: {},
            };
            this.form_configuration = null;
            this.engine = {
                me_engines: [],
                ae_engines: [],
            };
            this.prime_mover = {
                propulsion_motors: [],
                diesel_generators: [],
            };
            this.tanks = [];
            this.propeller = {};
            this.main_flow_meter_installation = {};
            this.boiler_flow_meter_installation = {};
            this.fm_configuration = null;
            this.email_alert = {};
            this.dnv = {};
            this.veslink = {};
            this.carry_over_form = {};
        } else {
            this._id = vessel._id;
            this.client = vessel.client;
            this.client_name = vessel.client_name || null;
            this.navtor_vessel_id = vessel.navtor_vessel_id || null;
            this.disabled = vessel.disabled;
            
            this.information = vessel.information || {};
            this.form = vessel.form || {};
            this.form_configuration = vessel.form_configuration;
            this.engine = vessel.engine || {};
            this.prime_mover = vessel.prime_mover || {};
            this.tanks = vessel.tanks;
            this.propeller = vessel.propeller;
            this.engine_model = vessel.engine_model || null;
            this.main_flow_meter_installation = vessel.main_flow_meter_installation;
            this.boiler_flow_meter_installation = vessel.boiler_flow_meter_installation;
            this.fm_configuration = vessel.fm_configuration;
            this.email_alert = vessel.email_alert;
            this.dnv = vessel.dnv;
            this.veslink = vessel.veslink;
            this.carry_over_form = vessel.carry_over_form;
        }
    }
}

var numberOfMainEngineOptions = [
    { value: 1, label: "1"},
    { value: 2, label: "2"}
];

var numberOfAuxEngineOptions = [
    { value: 1, label: "1 Auxiliary Engine"},
    { value: 2, label: "2 Auxiliary Engines"},
    { value: 3, label: "3 Auxiliary Engines"},
    { value: 4, label: "4 Auxiliary Engines"},
    { value: 5, label: "5 Auxiliary Engines"},
    { value: 6, label: "6 Auxiliary Engines"}
];

var numberOfDieselGenOptions = [
    { value: 1, label: "1 Diesel Generator"},
    { value: 2, label: "2 Diesel Generators"},
    { value: 3, label: "3 Diesel Generators"},
    { value: 4, label: "4 Diesel Generators"},
    { value: 5, label: "5 Diesel Generators"},
    { value: 6, label: "6 Diesel Generators"}
];

var numberOfAuxBoilerOptions = [
    { value: 1, label: "1 Auxiliary Boiler"},
    { value: 2, label: "2 Auxiliary Boilers"},
];

var propellerTypes = [
    { value: 'fixed', label: "Fixed Pitch Propeller"},
    { value: 'controllable', label: "Controllable Pitch Propeller"},
];

var nCylinders = [
    { value: 1, label: "1 Cylinder"},
    { value: 2, label: "2 Cylinders"},
    { value: 3, label: "3 Cylinders"},
    { value: 4, label: "4 Cylinders"},
    { value: 5, label: "5 Cylinders"},
    { value: 6, label: "6 Cylinders"},
    { value: 7, label: "7 Cylinders"},
    { value: 8, label: "8 Cylinders"},
    { value: 9, label: "9 Cylinders"},
    { value: 10, label: "10 Cylinders"},
    { value: 11, label: "11 Cylinders"},
    { value: 12, label: "12 Cylinders"},
    { value: 13, label: "13 Cylinders"},
    { value: 14, label: "14 Cylinders"},
    { value: 15, label: "15 Cylinders"},
    { value: 16, label: "16 Cylinders"},
    { value: 17, label: "17 Cylinders"},
    { value: 18, label: "18 Cylinders"},
    { value: 19, label: "19 Cylinders"},
    { value: 20, label: "20 Cylinders"},
];

var nTurbochargers = [
    { value: 1, label: "1 Turbocharger"},
    { value: 2, label: "2 Turbochargers"},
    { value: 3, label: "3 Turbochargers"},
    { value: 4, label: "4 Turbochargers"},
    { value: 5, label: "5 Turbochargers"},
    { value: 6, label: "6 Turbochargers"}
];

const veslinkFuelMapping = {
    'IFO': 
        {
            'veslink_type': 'IFO',
            'veslink_name': 'IFO', 
            'new_fuel_grade_name': 'hfo', 
            'veslink_type_id': 3, 
            'track_sulfur_content': false, 
            'description': null, 
            'is_low_sulfur': false
        }, 
    'LGO': 
        {
            'veslink_type': 'LGO',
            'veslink_name': 'LGO', 
            'new_fuel_grade_name': 'ulsmgo2020', 
            'veslink_type_id': 9, 
            'track_sulfur_content': false, 
            'description': null, 
            'is_low_sulfur': false
        }, 
    'VLSFO': 
        {
            'veslink_type': 'VLSFO',
            'veslink_name': 'VLSFO', 
            'new_fuel_grade_name': 'ulsfo2020', 
            'veslink_type_id': 11, 
            'track_sulfur_content': false, 
            'description': null, 
            'is_low_sulfur': false
        }, 
    'HFO': 
        {
            'veslink_type': 'HFO',
            'veslink_name': 'HFO', 
            'new_fuel_grade_name': 'hfo', 
            'veslink_type_id': 1, 
            'track_sulfur_content': false, 
            'description': 'Heavy Fuel Oil', 
            'is_low_sulfur': false
        }, 
    'LMG': 
        {
            'veslink_type': 'LMG',
            'veslink_name': 'LSMGO', 
            'new_fuel_grade_name': 'ulsmgo2020', 
            'veslink_type_id': 4, 
            'track_sulfur_content': false, 
            'description': 'Low Sulfur Marine Gas Oil', 
            'is_low_sulfur': true
        }, 
    'LSF': 
        {
            'veslink_type': 'LSF',
            'veslink_name': 'LSHFO', 
            'new_fuel_grade_name': 'ulsfo2020',
            'veslink_type_id': 2, 
            'track_sulfur_content': false, 
            'description': 'Low Sulfur Fuel Oil', 
            'is_low_sulfur': true
        }, 
    'MGO': 
        {
            'veslink_type': 'MGO',
            'veslink_name': 'MGO', 
            'new_fuel_grade_name': null, 
            'veslink_type_id': 5, 
            'track_sulfur_content': false, 
            'description': 'Marine Gas Oil', 
            'is_low_sulfur': false
        }, 
    'LPG': 
        {
            'veslink_type': 'LPG',
            'veslink_name': 'LPG', 
            'new_fuel_grade_name': null, 
            'veslink_type_id': 7, 
            'track_sulfur_content': false, 
            'description': 'Liquified Petroleum Gas', 
            'is_low_sulfur': true
        }
    }

export {
    numberOfMainEngineOptions,
    numberOfAuxEngineOptions,
    numberOfDieselGenOptions,
    numberOfAuxBoilerOptions,
    propellerTypes,
    nCylinders,
    nTurbochargers,
    veslinkFuelMapping
}